import React from 'react'
import { Switch, Row, Col } from 'antd'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { IntlTranslated } from './IntlTranslated'
import SettingsCard from '../SettingsCard/SettingsCard'
import generalIcon from 'assets/general.svg'
import paymentIcon from 'assets/payments.svg'
import signaturesIcon from 'assets/signatures.svg'
import organisationIcon from 'assets/organisation-details.svg'
import { NotificationRules } from 'types/rules'
import { Profile } from 'types/profile'
import { User } from 'types/user'

interface Props {
  rules: NotificationRules | undefined
  user?: User
  updateState: (section: string, title: string, key: string, checked: boolean) => void
  updateRules: (body: NotificationRules | undefined) => Promise<void>
}

const PreferenceList = (props: Props): React.JSX.Element => {
  const isCorpUser = (): boolean => {
    const activeProfile = props.user?.profiles.find((profile: Profile) => profile.id === props.user?.activeProfileId)
    return !!activeProfile?.entity.class?.corp
  }

  const preferenceSections = (): string[] | undefined => {
    const sectionTitles = props.rules && Object.keys(props.rules.notificationPreferences.rule.profile)

    if (sectionTitles && !isCorpUser()) {
      return sectionTitles.filter((section: string) => !['paymentSignatures', 'entityProfiles'].includes(section))
    }

    return sectionTitles
  }

  const preferenceItems = (section: string) => {
    const data = props.rules
      ? Object.keys(props.rules.notificationPreferences.rule.profile[section]).map((item: string) => {
          return {
            title: item,
            description: IntlTranslated(item, 'description'),
          }
        })
      : []

    // hide date breaks
    const dateBreakIndex = data.findIndex((item) => item.title === 'dateBreak')
    const dataCopy = [...data]
    if (dateBreakIndex > -1) {
      dataCopy.splice(dateBreakIndex, 1)
    }

    return dataCopy
  }

  const generateIcon = (section: string): string => {
    switch (section) {
      case 'general':
        return generalIcon

      case 'paymentStates':
        return paymentIcon

      case 'paymentSignatures':
        return signaturesIcon

      case 'entityProfiles':
        return organisationIcon

      default:
        return generalIcon
    }
  }

  const generateSwitches = (section: string, title: string) => {
    const obj = props.rules ? props.rules.notificationPreferences.rule.profile[section][title] : {}
    // if mail or push is missing we give that a value of undefined to be able to hide it in the ui.
    if (!Object.keys(obj).includes('mail' && 'push')) {
      // don't have mail
      if (!Object.keys(obj).includes('mail')) obj.mail = undefined
      // don't have push
      else obj.push = undefined
    }

    // hide push switches
    delete obj.push
    return Object.entries(obj)
  }

  const getOnSwitchClick = (section: string, item: { title: string }, key: string) => {
    const onSwitchClick: SwitchChangeEventHandler = (checked) => {
      if (props.rules) {
        props.rules.notificationPreferences.rule.profile[section][item.title][key] = checked
        props.updateState(section, item.title, key, checked)
      }

      void props.updateRules(props.rules)
    }

    return onSwitchClick
  }

  return (
    <div className="messages">
      {props.rules &&
        preferenceSections()?.map((section, index) => (
          <SettingsCard
            key={index}
            title={IntlTranslated(section, 'heading')}
            subtitle={IntlTranslated(section, 'description')}
            icon={generateIcon(section)}
          >
            <React.Fragment>
              {preferenceItems(section)?.map((item, index) => (
                <Row className="row space-between" key={index}>
                  <Col className="left" xl={18} md={8} sm={18} xs={18}>
                    <div className="flex-column">
                      <span>{IntlTranslated(item.title, 'heading', section)}</span>
                      <span className="description">{IntlTranslated(item.description, 'description', section)}</span>
                    </div>
                  </Col>
                  <Col className="right" xl={4} md={4} sm={4} xs={4}>
                    {generateSwitches(section, item.title).map(([key]) => {
                      const switchValue =
                        props.rules && props.rules.notificationPreferences.rule.profile[section][item.title][key]

                      return (
                        <Switch
                          key={`switch-${key}`}
                          disabled={switchValue === undefined ? true : false}
                          className={
                            switchValue === undefined ? ('switch-placeholder' as string) : ('switch-button' as string)
                          }
                          defaultChecked={
                            props.rules &&
                            (props.rules.notificationPreferences.rule.profile[section][item.title][key] as boolean)
                          }
                          checkedChildren={IntlTranslated('switch.yes')}
                          unCheckedChildren={IntlTranslated('switch.no')}
                          onClick={getOnSwitchClick(section, item, key)}
                        />
                      )
                    })}
                  </Col>
                </Row>
              ))}
            </React.Fragment>
          </SettingsCard>
        ))}
    </div>
  )
}

export default PreferenceList
