import React, { useEffect, useState } from 'react'
import { Form, Button } from 'antd'
import { Phone, UserResponseBody } from '../../../types/user'
import { useIntl } from 'react-intl'
import { page } from '../../../lang/definitions/index'
import PhoneInput from 'react-phone-input-2'
import { IPhoneInput } from '../../Register/utils'
import { phoneInputMasks } from '../../../utils/helpers'
import { useSession } from 'stores/session'

interface PhoneFormProps {
  onSubmit: (phone: string, phoneCountry: string) => void
  userDetails: UserResponseBody
}

const PhoneForm = ({ userDetails, onSubmit }: PhoneFormProps): React.JSX.Element => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const { state: sessionState } = useSession()
  const userCountryCode = sessionState.user!.user.countryCode
  const [phone, setPhone] = useState<Phone>()

  const handlePhoneChange = (value: IPhoneInput, prefix: string) => {
    const phone: Phone = {
      countryCode: value.countryCode.toUpperCase(),
      number: prefix,
    }
    setPhone(phone)
  }

  useEffect(() => {
    if (userDetails.phone) {
      setPhone(userDetails.phone)
    }
  }, [userDetails])

  return (
    <Form
      className="phone-form"
      form={form}
      id="phone-form"
      layout="vertical"
      name="basic"
      requiredMark={false}
      size="middle"
      onFinish={() => phone && onSubmit(phone.number, phone.countryCode)}
    >
      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(page['page.settings.slider.mobile.phone.label'])}
        name="number"
      >
        <PhoneInput
          inputClass="bh-phone-input ant-input"
          buttonClass="bh-phone-btn"
          value={phone?.number}
          enableSearch={true}
          containerClass="bh-phone"
          country={userCountryCode || 'gb'}
          preferredCountries={['ie', 'fi', 'nl', 'it', 'se', 'gb']}
          countryCodeEditable={false}
          onChange={(value: string, details: IPhoneInput) => handlePhoneChange(details, value)}
          masks={phoneInputMasks()}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" disabled={!phone?.number} block>
          {intl.formatMessage(page['page.settings.slider.mobile.update.button.label'])}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PhoneForm
