import React, { useState, ReactElement } from 'react'
import { Form, Button, notification, Row, Col } from 'antd'
import { sendNewOtp } from 'api/auth'
import { getUser, updateUser } from 'api/user'
import OTPInput from '../OTPInput/OTPInput'
import { useParams } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Phone } from 'types/user'
import { useIntl } from 'react-intl'
import { messages, page } from 'lang/definitions'
import * as Sentry from '@sentry/react'
import './OTPSetup.less'

interface ParamTypes {
  countryCode: string
}

interface Props {
  userId: string
  sessionId: string
  setLoginStatus: (status: string) => void
}

interface CountryData {
  name: string
  dialCode: string
  countryCode: string
  format: string
}

const OTPSetup = (props: Props): ReactElement => {
  const intl = useIntl()
  const { userId, sessionId, setLoginStatus } = props

  const [form] = Form.useForm()
  const { countryCode } = useParams<ParamTypes>()

  const [tfaToken, setTfaToken] = useState<string>('')
  const [otpSent, setOtpSent] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<Phone | string>('')
  const [phoneCountry, setPhoneCountry] = useState<string>(countryCode)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSuccess = async (): Promise<void> => {
    try {
      const oldUser = await getUser(userId)
      const phone = { number: phoneNumber, countryCode: phoneCountry } as Phone
      const updatedUser = { ...oldUser.user, ...oldUser.user.user, phone } // add phone to user

      // remove mocked, and dateMocked to not get PUT error for using additionalProperties
      delete updatedUser.mocked
      delete updatedUser.dateMocked

      await updateUser(userId, updatedUser)
    } catch (err) {
      notification.error({
        message: intl.formatMessage(messages['messages.error.settings.updateUser']),
        placement: 'topRight',
      })
      Sentry.captureException(err)
    }
  }

  const handleOtpSetupFormSubmit = async () => {
    setIsLoading(true)

    try {
      // Send otp to phone
      const response = await sendNewOtp(phoneNumber as Phone, countryCode, 'sms', undefined, undefined, sessionId)

      setTfaToken(response.token)
      setOtpSent(true)
    } catch (error) {
      notification.error({
        message: intl.formatMessage(messages['messages.error.login.otp.send.code']),
        placement: 'topRight',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      {otpSent && (
        <OTPInput
          token={tfaToken}
          phone={phoneNumber as string}
          method={'sms'}
          onSuccess={() => void onSuccess()}
          sessionId={sessionId}
        />
      )}
      {!otpSent && (
        <Row>
          <Col
            xs={{ span: 18, offset: 3 }}
            sm={{ span: 18, offset: 3 }}
            md={{ span: 18, offset: 3 }}
            lg={{ span: 16, offset: 4 }}
            xl={{ span: 16, offset: 4 }}
          >
            <Form
              form={form}
              id="opt-setup-form"
              layout="vertical"
              name="basic"
              requiredMark={false}
              onFinish={() => void handleOtpSetupFormSubmit()}
            >
              <p>{intl.formatMessage(messages['messages.login.otp'])}</p>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(messages['messages.error.login.otp.enter.phone']),
                  },
                ]}
              >
                <div className="bh-phone">
                  <PhoneInput
                    inputClass="bh-phone-input"
                    buttonClass="bh-phone-btn"
                    enableSearch={true}
                    containerClass="phone-input"
                    country={countryCode}
                    countryCodeEditable={false}
                    preferredCountries={['ie', 'fi', 'nl', 'it', 'se', 'gb']}
                    value={phoneNumber as string}
                    onChange={(phone, country: CountryData) => {
                      const { countryCode } = country
                      setPhoneNumber(phone)
                      setPhoneCountry(countryCode)
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <Button loading={isLoading} disabled={isLoading || !phoneNumber} type="primary" htmlType="submit" block>
                  {intl.formatMessage(messages['messages.login.otp.save.phone'])}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={() => setLoginStatus('')} type="link" htmlType="button" block>
                  {intl.formatMessage(page['page.login.link.login'])}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default OTPSetup
