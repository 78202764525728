import { Input, Tag } from 'antd'
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import './EmailTags.less'
import { useIntl } from 'react-intl'
import { reports } from '../../lang/definitions/reports'
import { validateEmail } from '../../utils/helpers'
import { messages } from '../../lang/definitions'

interface EmailTagsProps {
  tags: string[]
  setTags: (tags: string[]) => void
}

const EmailTags = ({ tags, setTags }: EmailTagsProps): React.JSX.Element => {
  const intl = useIntl()
  const [inputVisible, setInputVisible] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [editInputIndex, setEditInputIndex] = useState<number>(-1)
  const [editInputValue, setEditInputValue] = useState<string>('')

  const handleClose = (removedTag: string): void => {
    setTags(tags.filter((tag: string) => tag !== removedTag))
  }

  const showInput = (): void => {
    setInputVisible(true)
  }

  const handleInputChange = (value: string): void => {
    setInputValue(value)
  }

  const handleInputConfirm = () => {
    let newTags = [...tags]
    if (inputValue && tags.indexOf(inputValue) === -1 && validateEmail(inputValue)) {
      newTags = [...tags, inputValue]
    }
    setTags(newTags)
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputChange = (value: string): void => {
    setEditInputValue(value)
  }

  const handleEditInputConfirm = (): void => {
    const newTags = [...tags]
    if (validateEmail(editInputValue)) {
      newTags[editInputIndex] = editInputValue
    }
    setTags(newTags)
    setEditInputIndex(-1)
    setEditInputValue('')
  }
  return (
    <div className="email-tags-wrapper" data-testid="email-tags-wrapper">
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <div className="input-group" key={`${tag}${index}`}>
              <Input
                autoFocus
                key={tag}
                size="small"
                className="tag-input"
                value={editInputValue}
                onChange={(event) => handleEditInputChange(event.target.value)}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
                data-testid="edit-email-input"
              />
              {!validateEmail(editInputValue) && (
                <span className="valid-msg">
                  {intl.formatMessage(messages['messages.error.login.incorrect.emailFormat'])}
                </span>
              )}
            </div>
          )
        } else {
          return (
            <Tag
              closable={tags.length !== 1}
              className="edit-tag"
              key={tag}
              onClose={() => handleClose(tag)}
              data-testid="email-tag"
            >
              <span
                data-testid="email-tag-span"
                onDoubleClick={(e) => {
                  setEditInputIndex(index)
                  setEditInputValue(tag)
                  e.preventDefault()
                }}
              >
                {tag}
              </span>
            </Tag>
          )
        }
      })}
      {inputVisible && (
        <div className="input-group">
          <Input
            autoFocus
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={(event) => handleInputChange(event.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            data-testid="add-email-input"
          />
          {!validateEmail(inputValue) && (
            <span className="valid-msg">
              {intl.formatMessage(messages['messages.error.login.incorrect.emailFormat'])}
            </span>
          )}
        </div>
      )}
      {!inputVisible && (
        <Tag className="site-tag-plus" onClick={showInput} data-testid="add-email-button">
          <PlusOutlined /> {intl.formatMessage(reports['reports.add.email'])}
        </Tag>
      )}
    </div>
  )
}

export default EmailTags
