import React, { useRef, useState } from 'react'
import BGPG, { BeneficiaryLookupForm } from './BGPG'
import IBAN, { IBANForm } from './IBAN'
import { PaymentType, clearingNetworkByPaymentType } from 'types/beneficiary'
import { useIntl } from 'react-intl'
import { Button, Divider, Form, FormInstance } from 'antd'
import { beneficiaryStrings } from 'lang/definitions'
import { PaymentMethodSelect, CurrencySelect } from '../components'
import { Beneficiary } from 'types/beneficiary'
import LocalGB, { LocalGBForm } from './LocalGB'
import { BeneficiaryFormProps } from '../utils'
import SWIFT, { SWIFTForm } from './SWIFT'
import LocalUS, { LocalUSForm } from './LocalUS'
import { useAvailablePaymentTypes as useAvailablePaymentTypes } from 'hooks'
import * as Sentry from '@sentry/react'
import 'styles/bh-form.less'
import ABA, { ABAForm } from './ABA'
import { useSession } from 'stores/session'

export type RecipientsForm = {
  currency: string
  paymentType?: PaymentType
}

export default function RecipientsDrawer(props: BeneficiaryFormProps): React.JSX.Element {
  const [form] = Form.useForm<RecipientsForm>()
  const intl = useIntl()
  const {
    state: { rules },
  } = useSession()
  const defaultCurrency = rules?.logic?.defaultCurrency?.rule || ''
  const [beneficiary, setBeneficiary] = useState<Partial<Beneficiary>>()
  const paymentType = Form.useWatch('paymentType', form)
  const lookupForm = useRef<FormInstance<BeneficiaryLookupForm>>()
  const localGBForm = useRef<FormInstance<LocalGBForm>>()
  const ibanForm = useRef<FormInstance<IBANForm>>()
  const swiftForm = useRef<FormInstance<SWIFTForm>>()
  const achForm = useRef<FormInstance<LocalUSForm>>()
  const abaForm = useRef<FormInstance<ABAForm>>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const availablePaymentTypes = useAvailablePaymentTypes()

  const initialValues: RecipientsForm = {
    currency: defaultCurrency,
    paymentType: availablePaymentTypes[defaultCurrency]?.defaultPaymentType,
  }

  const onSubmit = (data: Beneficiary) => {
    if (!paymentType) return

    setIsSubmitting(true)
    props
      .onSubmit({
        ...data,
        currency: form.getFieldValue('currency') as string,
        paymentType,
        clearingNetwork: clearingNetworkByPaymentType[paymentType],
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const isFormValid = async (formToCheck: FormInstance<unknown> | undefined): Promise<boolean> => {
    if (!formToCheck) {
      return false
    }

    try {
      await formToCheck.validateFields()
    } catch (err) {
      return false
    }

    return true
  }

  const onFinish = async () => {
    switch (paymentType) {
      case PaymentType.LocalGB:
        if (await isFormValid(localGBForm.current)) {
          const values = localGBForm.current?.getFieldsValue(true) as Beneficiary
          onSubmit(values)
        } else {
          localGBForm.current?.submit()
        }
        break

      case PaymentType.IBAN:
        if (await isFormValid(ibanForm.current)) {
          const values = ibanForm.current?.getFieldsValue(true) as Beneficiary
          onSubmit(values)
        } else {
          ibanForm.current?.submit()
        }
        break
      case PaymentType.Swift:
        if (await isFormValid(swiftForm.current)) {
          const values = swiftForm.current?.getFieldsValue(true) as Beneficiary

          if ('countryMatch' in values) {
            delete values.countryMatch
          }

          onSubmit(values)
        } else {
          swiftForm.current?.submit()
        }
        break
      case PaymentType.LocalUS:
        if (await isFormValid(achForm.current)) {
          const values = achForm.current?.getFieldsValue(true) as Beneficiary

          if (typeof values.address.street === 'string') {
            values.address.street = [values.address.street]
          }

          onSubmit(values)
        } else {
          achForm.current?.submit()
        }
        break
      case PaymentType.Aba:
        if (await isFormValid(abaForm.current)) {
          const values = abaForm.current?.getFieldsValue(true) as Beneficiary

          onSubmit(values)
        } else {
          abaForm.current?.submit()
        }
        break
      case PaymentType.BankGiro:
      case PaymentType.PlusGiro:
        if (beneficiary) {
          onSubmit(beneficiary as Beneficiary)
        } else {
          lookupForm.current?.submit()
        }
        break
      default:
        alert(`Payment type ${paymentType} not yet implemented`)
    }
  }

  return (
    <div className="bh-form" data-testid="beneficiary-drawer-v2">
      <Form
        form={form}
        id="add-beneficiary-form"
        layout="vertical"
        name="basic"
        requiredMark={false}
        initialValues={initialValues}
        onFinish={() => void onFinish()}
      >
        <CurrencySelect availablePaymentTypes={availablePaymentTypes} />

        <Divider className="form-divider" />

        <PaymentMethodSelect availablePaymentTypes={availablePaymentTypes} />

        <Divider className="form-divider" />
      </Form>

      {paymentType === PaymentType.IBAN && (
        <IBAN
          getFormInstance={(form) => {
            ibanForm.current = form
          }}
          onFinish={() => form.submit()}
        />
      )}

      {(paymentType === PaymentType.BankGiro || paymentType === PaymentType.PlusGiro) && (
        <BGPG
          paymentType={paymentType}
          onBeneficiaryChange={setBeneficiary}
          getFormInstance={(form) => {
            lookupForm.current = form
          }}
        />
      )}

      {paymentType === PaymentType.LocalGB && (
        <LocalGB getFormInstance={(form) => (localGBForm.current = form)} onFinish={() => form.submit()} />
      )}

      {paymentType === PaymentType.Swift && (
        <SWIFT getFormInstance={(form) => (swiftForm.current = form)} onFinish={() => form.submit()} />
      )}

      {paymentType === PaymentType.Aba && (
        <ABA getFormInstance={(form) => (abaForm.current = form)} onFinish={() => form.submit()} />
      )}

      {paymentType === PaymentType.LocalUS && <LocalUS getFormInstance={(form) => (achForm.current = form)} />}

      <Divider />

      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={() => void onFinish()}
        loading={isSubmitting}
        size="large"
        data-testid="submit-recipient"
      >
        {intl.formatMessage(beneficiaryStrings['beneficiary.drawer.submit.btn.label'])}
      </Button>
    </div>
  )
}
