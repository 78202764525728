import React, { useState, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { getRules, updateRules } from '../../../api/rules'
import { NotificationRules } from '../../../types/rules'
import './Messages.less'
import { getSessionId } from '../../../utils/storage'
import { getProfileId } from '../../../api/pIMethods'
import PreferenceList from './PreferenceList'
import { getError } from '../../../utils/error'
import { useSession } from 'stores/session'

const Messages: React.FC = () => {
  const { state: sessionState } = useSession()
  const user = sessionState.user!
  const sessionId = getSessionId()
  const [rules, setRules] = useState<NotificationRules | undefined>(undefined)
  const [profileId, setProfileId] = useState('')
  const [, setError] = useState<Error | undefined>(undefined)

  const getMyRules = async (): Promise<void> => {
    try {
      const initRules = await getRules()
      const notificationPreferences = initRules.logic.notificationPreferences
      setRules({ notificationPreferences })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const getProfile = async () => {
    try {
      const profile = await getProfileId()
      const { activeProfileId } = profile
      setProfileId(activeProfileId as string)
    } catch (error) {
      Sentry.captureException(error)
      setError(error as Error)
    }
  }

  useEffect(() => {
    void getProfile()
  }, [profileId])

  useEffect(() => {
    if (sessionId) {
      void getMyRules()
    }
  }, [sessionId])

  const handleUpdateRules = async (data: NotificationRules | undefined): Promise<void> => {
    try {
      const body = {
        ...data,
      }
      await updateRules(body, profileId)
    } catch (error) {
      Sentry.captureException(error)
      setError(getError(error))
    }
  }

  const updateRulesState = (section: string, title: string, key: string, checked: boolean) => {
    const updatedRules = rules

    if (updatedRules) {
      updatedRules.notificationPreferences.rule.profile[section][title][key] = checked
    }
    return setRules(updatedRules)
  }

  return <PreferenceList rules={rules} user={user} updateState={updateRulesState} updateRules={handleUpdateRules} />
}

export default Messages
