import { CardNetworkBankDay } from 'types/paymentInstruction'
import { cardNetworkNames } from '.'
import { Moment } from 'moment'

type Days = {
  [day: string]: {
    cardNetwork: string
    bankDay: Moment
  }
}

export default function formatCardNetworksBankDays(bankDays: CardNetworkBankDay[]): CardNetworkBankDay[] {
  return Object.values(
    bankDays.reduce((days: Days, day: CardNetworkBankDay) => {
      const dayString = day.bankDay.format('YYYY-MM-DD')

      if (days[dayString]) {
        days[dayString].cardNetwork = `${days[dayString].cardNetwork} / ${cardNetworkNames[day.cardNetwork]}`

        return days
      }

      days[dayString] = {
        cardNetwork: cardNetworkNames[day.cardNetwork],
        bankDay: day.bankDay,
      }

      return days
    }, {})
  )
}
