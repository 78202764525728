import { useState } from 'react'
import useInterval from './useInterval'
import * as piApi from 'api/paymentInstruction'
import { ChargGroup, Set } from 'types/paymentInstruction'
import { ChargeStatus } from 'types/charge'
import * as Sentry from '@sentry/react'

const POLLING_INTERVAL = 2000

export default function useSetPolling() {
  const [chargingSet, setChargingSet] = useState<Set | undefined>()
  const [chargedSet, setChargedSet] = useState<Set | undefined>()
  const [requireActionSet, setRequireActionSet] = useState<Set | undefined>()

  const stopPolling = () => {
    setChargingSet(undefined)
    setRequireActionSet(undefined)
  }

  const resetState = () => {
    setChargingSet(undefined)
    setChargedSet(undefined)
    setRequireActionSet(undefined)
  }

  const startPolling = (set: Set) => {
    setChargedSet(undefined)
    setChargingSet(set)
  }

  useInterval(
    async () => {
      const matchRequireActionCharge = (cg: ChargGroup) => cg.charge?.status === ChargeStatus.REQUIRES_ACTION

      const shouldUpdateRequireActionSet = (set: Set) => {
        if (!requireActionSet) {
          return true
        }

        // A fee charge group may REQUIRE_ACTION after the SET is already in CLOSED
        const requireActionCgs = requireActionSet.chargeGroups?.filter(matchRequireActionCharge)

        const setCgs = set.chargeGroups?.filter(matchRequireActionCharge)

        // If any REQUIRE_ACTION we currently have is not yet processed
        // Do not update to avoid flickering
        if (requireActionCgs?.some((cg) => setCgs?.find((cg2) => cg2.id === cg.id))) {
          return false
        }

        // If there are new charge group REQUIRE_ACTION we haven't processed
        return setCgs?.some((cg) => requireActionCgs?.find((cg2) => cg2.id !== cg.id))
      }

      try {
        if (!chargingSet?.id) {
          return
        }

        const setUpToDate = await piApi.getSetById(chargingSet.id)

        // While at least one has a charge and charge is PROCESSING
        if (setUpToDate.chargeGroups?.some((cg) => cg.charge?.status === ChargeStatus.PROCESSING)) {
          setChargingSet(setUpToDate)
          return
        }

        // In case one needs action
        if (setUpToDate.chargeGroups?.some(matchRequireActionCharge)) {
          // Only set it once, return if already set
          if (!shouldUpdateRequireActionSet(setUpToDate)) {
            return
          }

          setChargingSet(setUpToDate) // To allow updating progress
          setRequireActionSet(setUpToDate)
          return
        }

        // If any charge group is not charged, return
        if (setUpToDate.chargeGroups?.some((cg) => !cg.charge)) {
          // Only continue polling if outcome is not FAIL
          if (setUpToDate.outcome?.status !== 'FAIL') {
            setChargingSet(setUpToDate)
            return
          }
        }

        // Finish polling if none of above is met
        setChargedSet(setUpToDate)
        stopPolling()
      } catch (err) {
        // Report and allow it to try again
        Sentry.captureException(err)
      }
    },
    chargingSet ? POLLING_INTERVAL : null
  )

  return {
    stopPolling,
    startPolling,
    chargedSet,
    chargingSet,
    requireActionSet,
    resetState,
  }
}
