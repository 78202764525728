import React, { useEffect, useState } from 'react'
import dateRangeIcon from 'assets/date-range.svg'
import currencyIcon from 'assets/currency.svg'
import cardIcon from 'assets/card.svg'
import paymentStatusIcon from 'assets/payment-status.svg'
import entityIcon from 'assets/organisation-details.svg'
import { DateRangeObject } from '../utils'
import { searchBeneficiaries } from 'api/beneficiary'
import { FilterBody } from 'components/Filter/Filter'
import { Beneficiary } from 'types/beneficiary'
import Loader from 'components/Loader/Loader'
import { useIntl } from 'react-intl'
import { reports } from 'lang/definitions'
import { ReportTemplate } from 'types/report'
import * as Sentry from '@sentry/react'
import './TemplateSummary.less'

interface Props {
  template: ReportTemplate
}

const TemplateSummary = ({ template }: Props): React.JSX.Element => {
  const intl = useIntl()
  const { values } = template
  const {
    keys: reportFields,
    clearingNetworks,
    cardNetworks,
    states,
    currency,
    beneficiaryIds,
    dateDue,
    dateUserPaid,
  } = values

  // handle dateField
  let dateField: string
  let dateRange: DateRangeObject | string

  const [showLoader, setShowLoader] = useState(false)
  const [beneficiariesInTemplate, setBeneficiariesInTemplate] = useState<Beneficiary[]>([])

  const fetchBeneficiaries = async (benefeciaryIds: FilterBody): Promise<void> => {
    setShowLoader(true)
    try {
      const { beneficiaries } = await searchBeneficiaries(benefeciaryIds)
      setBeneficiariesInTemplate(beneficiaries)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  useEffect(() => {
    beneficiaryIds && void fetchBeneficiaries({ id: beneficiaryIds })
  }, [])

  if (dateDue) {
    dateField = intl.formatMessage(reports['reports.date.due'])
    dateRange = dateDue
  } else if (dateUserPaid) {
    dateField = intl.formatMessage(reports['reports.date.user.paid'])
    dateRange = dateUserPaid
  } else {
    dateField = ''
    dateRange = { from: '', to: '' }
  }

  return (
    <Loader showLoader={showLoader}>
      <div className="summary-card-wrapper" data-testid="template-summary-card-wrapper">
        {dateField ? (
          <div className="content">
            <div className="item">
              <img src={dateRangeIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.date.field'])}</span>
            </div>
            <div className="item-selected">
              <div className="box">
                <span data-testid="summary-date">{dateField}</span>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        {dateRange && (
          <div className="content">
            <div className="item">
              <img src={dateRangeIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.date.range'])}</span>
            </div>
            <div className="item-selected">
              <div className="box">
                <span>
                  {dateRange.from} - {dateRange.to}
                </span>
              </div>
            </div>
          </div>
        )}

        {states ? (
          <div className="content">
            <div className="item">
              <img src={paymentStatusIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.payment.status'])}</span>
            </div>
            <div className="item-selected">
              {states.map((state, index) => (
                <div className="box" key={index}>
                  <span>{state}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        {currency ? (
          <div className="content">
            <div className="item">
              <img src={currencyIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.currency'])}</span>
            </div>
            <div className="item-selected">
              {currency.map((c, index) => (
                <div className="box" key={index}>
                  <span>{c}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        {cardNetworks ? (
          <div className="content">
            <div className="item">
              <img src={cardIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.card.networks'])}</span>
            </div>
            <div className="item-selected">
              {cardNetworks.map((cardNetwork, index) => (
                <div className="box" key={index} data-testid="cards-in-template">
                  <span>{cardNetwork}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        {beneficiariesInTemplate.length > 0 ? (
          <div className="content">
            <div className="item">
              <img src={cardIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.beneficiaries'])}</span>
            </div>
            <div className="item-selected">
              {beneficiariesInTemplate.map((beneficiary, index) => (
                <div className="box" key={index} data-testid="beneficiaries-in-template">
                  <span>{beneficiary.title}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        {clearingNetworks ? (
          <div className="content">
            <div className="item">
              <img src={cardIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.clearing.networks'])}</span>
            </div>
            <div className="item-selected">
              {clearingNetworks.map((clearingNetwork, index) => (
                <div className="box" key={index} data-testid="clearing-networks-in-template">
                  <span>{clearingNetwork}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}

        {reportFields && reportFields.length < 32 ? (
          <div className="content">
            <div className="item" data-testid="report-fields-in-template">
              <img src={entityIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.report.fields'])}</span>
            </div>
            <div className="item-selected">
              {reportFields.map((field, index) => (
                <div className="box" key={index}>
                  <span>{field}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Loader>
  )
}

export default TemplateSummary
