import React from 'react'
import { Result, Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useIntl } from 'react-intl'
import { useUtils } from '../../hooks/useUtils'
import { card } from '../../lang/definitions'
import { Charge, RedirectData } from '../../types/charge'

interface Props {
  open: boolean
  onClose: (value: boolean) => void
  afterClose: () => void
  processingState: string | null
  activeCharge: Charge | undefined
  data: RedirectData | undefined
}

const CardModal = React.memo(
  (props: Props) => {
    const intl = useIntl()
    const { getTranslatedErrorMessage } = useUtils()
    const { data: iFrameData, open, onClose, afterClose, processingState, activeCharge } = props

    const SuccessView = (
      <Result
        status="success"
        title={intl.formatMessage(card['card.modal.success'])}
        extra={[
          <Button key={1} type="primary" onClick={() => onClose(false)}>
            {intl.formatMessage(card['card.modal.btn.title'])}
          </Button>,
        ]}
      />
    )

    const FailView = (
      <Result
        status="warning"
        title={intl.formatMessage(card['card.modal.warning'])}
        subTitle={intl.formatMessage(card['card.modal.errorCodeInfo'], {
          errorCode: (
            <strong>
              {activeCharge && activeCharge.outcome
                ? getTranslatedErrorMessage(activeCharge.outcome.errorCode || '')
                : getTranslatedErrorMessage('GENERAL_ERROR')}
            </strong>
          ),
        })}
        extra={[
          <Button key={1} type="primary" onClick={() => onClose(false)}>
            {intl.formatMessage(card['card.modal.btn.fail.title'])}
          </Button>,
        ]}
      />
    )

    return (
      <Modal
        open={open}
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
        title={null}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
        afterClose={() => {
          afterClose()
        }}
      >
        {processingState && processingState === 'SUCCESS' && SuccessView}
        {processingState && processingState === 'FAIL' && FailView}
        {iFrameData && (
          <>
            {iFrameData.method.toLowerCase() === 'post' ? (
              <iframe
                style={{
                  border: 'none',
                  height: '550px',
                  width: '100%',
                  background: 'transparent',
                }}
                title="validationFrame"
                srcDoc={`
              <html>
                <body>
                  <form action="${iFrameData.url}" method='${iFrameData.method}' name='theForm' id='theForm'>
                  <!--${iFrameData.params
                    .map(
                      (p: { key: string; value: string }) =>
                        `<label htmlFor="${p.key}">${p.key}</label><br><input name="${p.key}" id="${p.key}" type="text" value="${p.value}">`
                    )
                    .join('<br>')}
                    <input type="submit" />-->
                  ${iFrameData.params
                    .map(
                      (p: { key: string; value: string }) =>
                        `<input name="${p.key}" id="${p.key}" type="hidden" value="${p.value}">`
                    )
                    .join('')}
                  </form>
                  <script>
                    document.theForm.submit();
                  </script>
                </body>
              </html>`}
              ></iframe>
            ) : iFrameData.method.toLowerCase() === 'get' ? (
              <iframe
                style={{
                  border: 'none',
                  height: '550px',
                  width: '100%',
                  background: 'transparent',
                }}
                title="validationFrame"
                src={iFrameData.url}
              ></iframe>
            ) : (
              <span>{intl.formatMessage(card['card.form.invalid.type.of.api.call'])}</span>
            )}
          </>
        )}
      </Modal>
    )
  },
  (prevProps, nextProps) => {
    const { data: prevIframeData, open: prevVisible, activeCharge: { status: prevChargeStatus } = {} } = prevProps
    const { data: nextIframeData, open: nextVisible, activeCharge: { status: nextChargeStatus } = {} } = nextProps

    if (prevVisible !== nextVisible) {
      return false
    } else if (JSON.stringify(prevIframeData) !== JSON.stringify(nextIframeData)) {
      return false
    } else if (prevChargeStatus && prevChargeStatus === nextChargeStatus) {
      // Don't rerender if status is not changed
      return true
    }
    return false
  }
)
export default CardModal
