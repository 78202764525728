import React from 'react'
import { Descriptions } from 'antd'
import { capitalize } from 'lodash'
import { useIntl } from 'react-intl'
import { useRenderAccountNumberLabel, useRenderRoutingNumberLabel } from './utils'
import { Beneficiary } from 'types/beneficiary'
import AccountNumber from 'components/Format/AccountNumberNew/AccountNumberNew'
import { useTranslation } from 'utils/helpers'
import './NewBeneficiaryDetails.less'

interface BeneficiaryDetailsProps {
  beneficiary: Beneficiary
}

const NewBeneficiaryDetails = ({ beneficiary }: BeneficiaryDetailsProps): React.JSX.Element => {
  const intl = useIntl()
  const t = useTranslation()

  const renderAccountNumberLabel = useRenderAccountNumberLabel()
  const renderRoutingNumberLabel = useRenderRoutingNumberLabel()

  return (
    <>
      <Descriptions colon={false}>
        {beneficiary.title && (
          <Descriptions.Item label={t('beneficiary.details.name')} span={3}>
            {beneficiary.title}
          </Descriptions.Item>
        )}
        {beneficiary.address.careOf && (
          <Descriptions.Item label={t('beneficiary.form.ach.careof.label')} span={3}>
            {beneficiary.address.careOf}
          </Descriptions.Item>
        )}
        {beneficiary.currency && (
          <Descriptions.Item label={t('beneficiary.details.currency')} span={3}>
            {beneficiary.currency.toUpperCase()}
          </Descriptions.Item>
        )}
        {beneficiary.type && beneficiary.clearingNetwork !== 'bg' && beneficiary.clearingNetwork !== 'pg' && (
          <Descriptions.Item label={t('beneficiary.details.type')} span={3}>
            {capitalize(beneficiary.type)}
          </Descriptions.Item>
        )}
        {beneficiary.routingNumber && (
          <Descriptions.Item label={renderRoutingNumberLabel(beneficiary.clearingNetwork)} span={3}>
            {beneficiary.clearingNetwork === 'bacs'
              ? beneficiary.routingNumber.match(/.{1,2}/g)?.join('-')
              : beneficiary.routingNumber}
          </Descriptions.Item>
        )}
        {beneficiary.accountNumber && (
          <Descriptions.Item
            label={renderAccountNumberLabel(beneficiary.clearingNetwork, beneficiary.accountNumber)}
            span={3}
          >
            <AccountNumber
              accountNumber={beneficiary.accountNumber}
              routingNumber={beneficiary.routingNumber}
              clearingNetwork={beneficiary.clearingNetwork}
            ></AccountNumber>
          </Descriptions.Item>
        )}
        {beneficiary.clearingNetwork === 'ach' && (
          <>
            {beneficiary.accountType && (
              <Descriptions.Item label={t('beneficiary.achAccountType')} span={3}>
                {capitalize(beneficiary.accountType)}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={t('beneficiary.streetAddress')} span={3}>
              <ul className="address-list">
                {beneficiary.address.street && !!beneficiary.address.street.length && (
                  <li>
                    {Array.isArray(beneficiary.address.street)
                      ? beneficiary.address.street.join('')
                      : beneficiary.address.street}
                  </li>
                )}
                {beneficiary.address.apartment && <li>{beneficiary.address.apartment}</li>}
                {beneficiary.address.city && <li>{beneficiary.address.city}</li>}
                {beneficiary.address.zip && <li>{beneficiary.address.zip}</li>}
                {beneficiary.address.region && <li>{beneficiary.address.region}</li>}
                {beneficiary.address.countryCode && (
                  <>{intl.formatDisplayName(beneficiary.address.countryCode.toUpperCase(), { type: 'region' })}</>
                )}
              </ul>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </>
  )
}

export default NewBeneficiaryDetails
