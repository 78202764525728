import React from 'react'
import './ActionPage.less'
import BHDrawer from '../Drawers/BHDrawer'

interface ActionPageProps {
  title: string
  children: React.ReactNode | React.ReactNode[]
  additionalClass?: string | undefined
  hash: string | undefined
  pathname: string
  maskClosable?: boolean
}
const ActionPage = ({
  additionalClass,
  title,
  hash,
  children,
  pathname,
  maskClosable = true,
}: ActionPageProps): React.JSX.Element => {
  const addClass = additionalClass ? additionalClass : ''
  return (
    <BHDrawer
      className={`action-page ${addClass}`}
      title={title}
      destroyOnClose={true}
      hash={hash}
      pathname={pathname}
      maskClosable={maskClosable}
    >
      {children}
    </BHDrawer>
  )
}

export default ActionPage
