import { ExclamationCircleFilled } from '@ant-design/icons'
import './errorMessages.less'

export const constructNetworkErrorMessage = (message: string) => {
  return {
    content: message,
    icon: <ExclamationCircleFilled />,
    className: 'network-error-message',
  }
}
