import React, { useEffect, useState } from 'react'
import { EntityRoles, MessageError, UserData } from 'types/user'
import ProfileGroupRoles from './ProfileGroupRoles'
import * as api from 'api/user'
import * as barracksApi from 'api/barracks'
import { useOrganisationHierarchy, useOrganisationHierarchyUtils } from 'stores/OrganisationHierarchy/hooks'
import { resetDrawerHash } from 'components/Drawers/utils'
import { Button, Checkbox, Col, Collapse, Form, Input, Row, Select, notification } from 'antd'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { action, messages, page, register, user as userStrings } from 'lang/definitions'
import * as Sentry from '@sentry/react'
import { useUtils } from 'hooks'
import acl from 'utils/acl'
import { Entity } from 'types/entity'
import { renderRoleLabel } from './utils'
import { admin } from 'lang/definitions/admin'
import PhoneInput, { MIN_PHONE_NUMBER_LENGTH, PhoneValue } from 'components/PhoneInput/PhoneInput'
import { Profile } from 'types/profile'
import { getEntityTitle } from '../utils'
import { useSession } from 'stores/session'

const { Panel } = Collapse

type Name = {
  first: string
  last: string
}

type FormEntityRole = {
  [entityId: string]: {
    [role: string]: boolean
  }
}

interface FormData {
  countryCode: string
  email: string
  name: Name
  phone: PhoneValue
  entityRoles: FormEntityRole
}
const UserForm = (): React.JSX.Element => {
  const intl = useIntl()
  const history = useHistory()
  const [form] = Form.useForm()
  const { state: sessionState } = useSession()
  const iam = sessionState.iam!
  const me = sessionState.user!

  const {
    state: { countryOptions, selectedUser, entityHierarchies, usersFilterBody, profileGroups },
    actions: { setSelectedUser },
  } = useOrganisationHierarchy()

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(!!selectedUser)
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<string[]>([])
  const [isCreatingUser, setIsCreatingUser] = useState<boolean>(false)
  const { getTranslatedUserErrorMessage } = useUtils()
  const { getUsers, getEntityHierarchies, fetchProfileGroups } = useOrganisationHierarchyUtils()

  useEffect(() => {
    return () => {
      // cleanup
      setSelectedUser(undefined)
    }
  }, [])

  useEffect(() => {
    if (selectedUser) {
      void preselectEntityRoles(selectedUser.id!, entityHierarchies)
      void fetchProfileGroups(selectedUser)
    }
  }, [selectedUser, entityHierarchies])

  const getEntityIds = (entityHierarchies: Entity[]): string[] => {
    const entityIds: string[] = []
    entityHierarchies.forEach((entity: Entity) => {
      entityIds.push(entity.id)
      if (entity.children) {
        entityIds.push(...getEntityIds(entity.children))
      }
    })
    return entityIds
  }

  const getPreselectedEntityRoles = (profiles: Profile[]): FormEntityRole => {
    const entityRoles: FormEntityRole = {}
    profiles.forEach((profile: Profile) => {
      if (profile.entityRoles) {
        entityRoles[profile.entityId] = {}
        profile.entityRoles.forEach((role: string) => {
          entityRoles[profile.entityId][role] = true
        })
      }
    })
    return entityRoles
  }

  const preselectEntityRoles = async (userId: string, entityHierarchies: Entity[]): Promise<void> => {
    try {
      const entityIds = getEntityIds(entityHierarchies)

      const { result: profiles } = await barracksApi.search<Profile>('profile', {
        userId: [userId],
        entityId: entityIds,
      })

      const entityRoles = getPreselectedEntityRoles(profiles)
      form.setFieldsValue({
        entityRoles: { ...entityRoles },
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const canUpdateProfileRoles = (entityId: string) =>
    acl({
      iam,
      me,
      kind: 'profile',
      barracksId: entityId,
      action: 'update',
    })

  const handleCollapse = (key: string | string[]): void => {
    const keysArray = Array.isArray(key) ? key : [key]
    setCollapseActiveKeys(keysArray)
  }

  const renderUpdateUserAction = (): React.JSX.Element => {
    if (isFormDisabled) {
      return (
        <Button type="link" onClick={() => setIsFormDisabled(false)}>
          {intl.formatMessage(action['action.admin.user.change'])}
        </Button>
      )
    } else {
      return (
        <div>
          <Button type="link" onClick={() => setIsFormDisabled(true)}>
            {intl.formatMessage(action['action.admin.user.cancel'])}
          </Button>
          <Button type="link" onClick={() => form.submit()}>
            {intl.formatMessage(action['action.admin.user.save'])}
          </Button>
        </div>
      )
    }
  }

  const handleCreateUser = async (data: UserData): Promise<void> => {
    try {
      setIsCreatingUser(true)
      await api.createUser(data)
      resetDrawerHash(history)
      await getUsers(usersFilterBody)
      await getEntityHierarchies(me.user.id)
      notification.success({
        message: intl.formatMessage(messages['messages.success.user.add']),
        placement: 'topRight',
      })
    } catch (error) {
      const err = error as MessageError
      notification.warning({
        message: getTranslatedUserErrorMessage(err.details.errorMessage),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setIsCreatingUser(false)
    }
  }

  const handleUpdateUser = async (data: UserData): Promise<void> => {
    try {
      await api.updateUser(data.id!, data)
      await getUsers(usersFilterBody)

      resetDrawerHash(history)
      notification.success({
        message: intl.formatMessage(messages['messages.success.user.edit']),
        placement: 'topRight',
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const prepareEntityRoles = (entityRoles?: FormEntityRole): EntityRoles[] => {
    if (!entityRoles) {
      return []
    }
    const entityRolesArray: EntityRoles[] = []
    for (const [entityId, roles] of Object.entries(entityRoles)) {
      const selectedRoles = Object.entries(roles).filter(([, value]) => value)
      const entityRole: EntityRoles = {
        entityId,
        entityRoles: selectedRoles.map(([role]) => role),
      }
      entityRolesArray.push(entityRole)
    }

    return entityRolesArray
  }

  const onSubmit = async (data: FormData): Promise<void> => {
    const payload: UserData = {
      name: {
        first: data.name.first,
        last: data.name.last,
      },
      email: data.email,
      countryCode: data.countryCode,
    }

    if (data?.phone?.number) {
      payload.phone = {
        countryCode: data.phone.countryCode,
        number: data.phone.number,
      }
    }

    if (selectedUser) {
      await handleUpdateUser({
        ...selectedUser,
        ...payload,
      })
    } else {
      payload.entityRoles = prepareEntityRoles(data.entityRoles).filter(
        (entityRole) => entityRole.entityRoles.length > 0
      )
      // check if user has selected any entity roles
      if (payload.entityRoles.every((entityRole) => entityRole.entityRoles.length === 0)) {
        notification.warning({
          message: intl.formatMessage(messages['messages.error.entityRoles.user']),
          placement: 'topRight',
        })
        return
      }
      if (data?.phone?.number) {
        payload.phone = {
          countryCode: data.phone.countryCode,
          number: data.phone.number,
        }
      }
      await handleCreateUser(payload)
    }
  }

  const handleAssignRole = async (entityRoles: FormEntityRole, user: UserData): Promise<void> => {
    try {
      const [changedEntity] = prepareEntityRoles(entityRoles)
      if (changedEntity) {
        const entityId = changedEntity.entityId
        const formEntityRoles = form.getFieldValue('entityRoles') as FormEntityRole
        const changedRolesForSelectedEntity = formEntityRoles[entityId]

        const selectedRoles = Object.entries(changedRolesForSelectedEntity)
          .filter(([, value]) => value)
          .map(([role]) => role)

        await api.editUserRoles(user.id!, entityId, selectedRoles)
        notification.success({
          message: intl.formatMessage(messages['messages.success.user.editRoles']),
          placement: 'topRight',
        })

        // refresh the data
        await getEntityHierarchies(me.user.id)
        await getUsers(usersFilterBody)
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.error({
        message: intl.formatMessage(messages['messages.error.user.editRoles']),
        placement: 'topRight',
      })
    }
  }

  const renderCollapseWithRoles = (entity: Entity): React.JSX.Element => (
    <Collapse activeKey={collapseActiveKeys} onChange={handleCollapse}>
      <Panel key={`with-permission-${entity.id}`} header={getEntityTitle(entity)}>
        {entity.availableRoles?.map((role: string) => (
          <div data-testid={`entity-roles-${entity.id}-${role}`} key={`entity-roles-${entity.id}-${role}`}>
            <Row gutter={16}>
              <Col span={8}>
                <span className="entity-roles-labels">{renderRoleLabel(role, intl)}</span>
              </Col>
              <Col span={16}>
                <Form.Item name={['entityRoles', entity.id, role]} valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ))}
        {Array.isArray(entity.children) && entity.children.length > 0 && (
          <div>
            <p className="child-entities-title">{intl.formatMessage(admin['admin.user.entityRoles.childEntities'])}</p>
            <Collapse expandIconPosition="end">
              {entity.children?.length > 0 && entity.children.map((child: Entity) => renderCollapseWithRoles(child))}
            </Collapse>
          </div>
        )}
      </Panel>
    </Collapse>
  )

  const renderCollapseWithoutPermission = (entity: Entity): React.JSX.Element => (
    <Collapse activeKey={collapseActiveKeys} onChange={handleCollapse}>
      <Panel key={`no-permission-${entity.id}`} header={getEntityTitle(entity)}>
        <div className="role-permission-wrapper">
          <span>{intl.formatMessage(admin['admin.user.entityRoles.update.noPermission'])}</span>
        </div>
      </Panel>
    </Collapse>
  )

  return (
    <React.Fragment>
      <Form
        size="middle"
        form={form}
        layout="vertical"
        onFinish={(values: FormData) => void onSubmit(values)}
        onValuesChange={(changedValues: FormData) => {
          if (changedValues.entityRoles && selectedUser) {
            void handleAssignRole(changedValues.entityRoles, selectedUser)
          }
        }}
        initialValues={{
          name: {
            first: selectedUser?.name?.first,
            last: selectedUser?.name?.last,
          },
          email: selectedUser?.email,
          countryCode: selectedUser?.countryCode,
          phone: {
            number: selectedUser?.phone?.number,
            countryCode: selectedUser?.phone?.countryCode,
          },
        }}
      >
        <div className="user-details-wrapper">
          <div className="edit-user-heading">
            <span className="details-title">{intl.formatMessage(admin['admin.user.userDetails'])}</span>
            {selectedUser && renderUpdateUserAction()}
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage(userStrings['user.country'])}
                name="countryCode"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(userStrings['user.country.errorMessage.required']),
                  },
                ]}
              >
                <Select disabled={isFormDisabled} placeholder={intl.formatMessage(userStrings['user.choseCountry'])}>
                  {countryOptions.map((country: string) => (
                    <Select.Option key={country} value={country}>
                      {intl.formatDisplayName(country.toUpperCase(), {
                        type: 'region',
                      })}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage(userStrings['user.firstName'])}
                name={['name', 'first']}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(userStrings['user.country.firstName.errorMessage.required']),
                  },
                ]}
              >
                <Input
                  disabled={isFormDisabled}
                  placeholder={intl.formatMessage(userStrings['user.firstName.placeholder'])}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage(userStrings['user.emailAddress'])}
                name="email"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(userStrings['user.country.email.errorMessage.required']),
                  },
                  {
                    type: 'email',
                    message: intl.formatMessage(register['register.bankid.msg.email']),
                  },
                ]}
              >
                <Input
                  disabled={isFormDisabled}
                  placeholder={intl.formatMessage(userStrings['user.emailAddress.placeholder'])}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage(userStrings['user.lastName'])}
                name={['name', 'last']}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(userStrings['user.country.lastName.errorMessage.required']),
                  },
                ]}
              >
                <Input
                  disabled={isFormDisabled}
                  placeholder={intl.formatMessage(userStrings['user.lastName.placeholder'])}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={intl.formatMessage(page['page.settings.slider.mobile.phone.label'])}
                name="phone"
                rules={[
                  {
                    validator: async (_, value: PhoneValue) => {
                      if (value?.number && value?.number.length < MIN_PHONE_NUMBER_LENGTH) {
                        return Promise.reject(intl.formatMessage(register['register.validation.field.invalid.phone']))
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <PhoneInput
                  enableSearch
                  disabled={isFormDisabled}
                  placeholder={intl.formatMessage(page['page.settings.slider.mobile.phone.label'])}
                  defaultCountry={selectedUser?.countryCode || 'gb'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {entityHierarchies.map((entity: Entity) =>
                canUpdateProfileRoles(entity.id) ? (
                  <div key={`wrapper-${entity.id}`}>{renderCollapseWithRoles(entity)}</div>
                ) : (
                  <div key={`no-permission-wrapper-${entity.id}`}>{renderCollapseWithoutPermission(entity)}</div>
                )
              )}
            </Col>
          </Row>
          {!selectedUser && (
            <Row className="form-action-container">
              <Col span={24}>
                <Form.Item>
                  <Button block type="primary" htmlType="submit" loading={isCreatingUser}>
                    {intl.formatMessage(action['action.admin.user.add'])}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </div>
      </Form>
      {profileGroups.length > 0 && <ProfileGroupRoles />}
    </React.Fragment>
  )
}

export default UserForm
