import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useUtils } from 'hooks/useUtils'
import { menu } from 'lang/definitions'
import './Logout.less'

interface Props {
  icon?: ReactElement | null
  customClass?: string | undefined
}

export const Logout = (props: Props): ReactElement => {
  const intl = useIntl()
  const { logoutUser } = useUtils()

  return (
    <div className={props.customClass || 'logout-wrapper'} onClick={() => void logoutUser()}>
      {props.icon}
      <label>{intl.formatMessage(menu['menu.right.logout'])}</label>
    </div>
  )
}
