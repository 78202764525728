import React, { useState, useEffect } from 'react'
import { Descriptions, Button, Collapse, message, Avatar } from 'antd'
import * as Sentry from '@sentry/react'
import { Attachment, PAYMENT_STATE, PaymentInstruction } from 'types/paymentInstruction'
import { useLanguageState } from 'stores/language/LanguageStore'
import Date from 'components/Format/Date/Date'
import Currency from 'components/Format/Currency/Currency'
import { useIntl } from 'react-intl'
import { pi, action } from 'lang/definitions/index'
import AccountNumber from 'components/Format/AccountNumber/AccountNumber'
import { Approver } from 'types/user'
import { getProfileDetails } from 'api/user'
import { Profile } from 'types/profile'
import Loader from 'components/Loader/Loader'
import ACL from 'components/ACL/ACL'
import { getAttachment, getPaymentInstruction } from 'api/paymentInstruction'
import { downloadSinglePaymentInstructionPDF } from 'api/pdf'
import { FilePdfOutlined, RedoOutlined } from '@ant-design/icons'
import { getEntityName } from 'utils/helpers'
import { getInitials, stringToColor } from 'components/BHAvatar/utils'
import PaymentState from './components/PaymentState/PaymentState'
import './PaymentInstructionDetails.less'

interface PaymentInstructionDetailsProps {
  paymentInstructionId: string
  handleRemovePayment?: (id: string) => void
}
const { Panel } = Collapse

const PaymentInstructionDetails = ({
  paymentInstructionId,
  handleRemovePayment,
}: PaymentInstructionDetailsProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const intl = useIntl()

  const [profile, setProfile] = useState<Profile>()
  const [payment, setPayment] = useState<PaymentInstruction>()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [downloadLoader, setDownloadLoader] = useState<boolean>(false)
  const [remittanceDownLoader, setRemittanceDownLoader] = useState<boolean>(false)
  const [externalReceiptLoader, setExternalReceiptLoader] = useState<boolean>(false)

  useEffect(() => {
    if (paymentInstructionId) {
      void getData(paymentInstructionId)
    }
  }, [paymentInstructionId])

  const getData = async (paymentInstructionId: string) => {
    try {
      setShowLoader(true)
      const payment = await getPaymentInstruction(paymentInstructionId)
      setPayment(payment)
      if (payment) {
        const { profile } = await getProfileDetails(payment.profileId)
        setProfile(profile)
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const handleDownloadReceipts = async (reportType: string): Promise<void> => {
    const classType = Object.keys(profile?.entity?.class || [])

    if (reportType === 'remittance-advice') {
      setRemittanceDownLoader(true)
    } else if (reportType === 'internal-receipt') {
      setDownloadLoader(true)
    } else {
      setExternalReceiptLoader(true)
    }

    try {
      const key = 'updatable'
      void message.loading({
        content: intl.formatMessage(action['action.pi.page.download.progress']),
        key,
      })

      const response = await downloadSinglePaymentInstructionPDF(paymentInstructionId, reportType, classType[0])

      void message.success({
        content: intl.formatMessage(action['action.pi.page.download.finished']),
        key,
        duration: 2,
      })

      let fileName = `billhop-receipt-${paymentInstructionId}`
      if (reportType === 'remittance-advice') {
        fileName = `billhop-remittance-advice-${paymentInstructionId}`
      }

      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${fileName}.pdf`)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setDownloadLoader(false)
      setRemittanceDownLoader(false)
      setExternalReceiptLoader(false)
    }
  }

  const handleDownloadAttachment = async (piId: string, attachmentId: string): Promise<void> => {
    try {
      const response = await getAttachment(piId, attachmentId)

      const fileName = payment?.attachments![0].filename

      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${fileName!}`)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const refundButtonLabel =
    payment && payment.state === PAYMENT_STATE.PENDING
      ? intl.formatMessage(action['action.pi.refund'])
      : intl.formatMessage(action['action.pi.delete'])

  return (
    <Loader showLoader={showLoader}>
      {payment && (
        <div className="dropdown-filter-wrapper payment-details" data-testid="payment-instruction-wrapper">
          <Collapse expandIconPosition="end" className="dropdown-filter" defaultActiveKey={[1, 2, 3, 4, 5, 6, 7]}>
            <Panel header={intl.formatMessage(pi['pi.details.payment'])} key="1" className="filter-panel">
              <Descriptions size="small" className="no-border bh-description-horizontal" bordered>
                {profile && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.createdBy'])} span={4}>
                    <Avatar
                      style={{
                        backgroundColor: stringToColor(`${profile.user.name.first} ${profile.user.name.last}`),
                        verticalAlign: 'middle',
                        color: 'black',
                      }}
                      size="small"
                    >
                      {getInitials(`${profile.user.name.first} ${profile.user.name.last}`)}
                    </Avatar>
                    {`${profile.user.name.first} ${profile.user.name.last}`}
                  </Descriptions.Item>
                )}
                {payment.state && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.status'])} span={3}>
                    <PaymentState state={payment.state} />
                  </Descriptions.Item>
                )}
                {profile && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.entity'])} span={3}>
                    {getEntityName(profile)}
                  </Descriptions.Item>
                )}
                {payment.dateDue && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.dateDue'])} span={3}>
                    <Date value={payment.dateDue} locale={language} />
                  </Descriptions.Item>
                )}
                {payment.amount && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.amount'])} span={3}>
                    <Currency value={payment.amount.toString()} locale={language} currency={payment.currency} />
                  </Descriptions.Item>
                )}
                {payment.fee && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.fee'])} span={3}>
                    <Currency value={payment.fee.toString()} locale={language} currency={payment.currency} />
                  </Descriptions.Item>
                )}
                {payment.amount && payment.fee && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.total'])} span={3}>
                    <Currency
                      value={`${payment.fee + payment.amount}`.toString()}
                      locale={language}
                      currency={payment.currency}
                    />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
            <Panel header={intl.formatMessage(pi['pi.details.recipient'])} key="2" className="filter-panel">
              <Descriptions size="small" className="no-border bh-description-horizontal" bordered>
                {payment.beneficiary!.title && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.beneficiary.title'])} span={3}>
                    {payment.beneficiary!.title}
                  </Descriptions.Item>
                )}
                {payment.beneficiary!.accountNumber && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.beneficiary.accountNumber'])} span={3}>
                    <AccountNumber
                      accountNumber={payment.beneficiary!.accountNumber}
                      routingNumber={payment.beneficiary!.routingNumber}
                      clearingNetwork={payment.beneficiary!.clearingNetwork}
                    ></AccountNumber>
                  </Descriptions.Item>
                )}
                {payment.reference && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.reference'])} span={3}>
                    {payment.reference}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
            {payment.signatures && (
              <Panel header={intl.formatMessage(pi['pi.details.signatures'])} key="3" className="filter-panel">
                <Descriptions size="small" className="no-border bh-description-horizontal" bordered>
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.requireSignatureBy'])} span={3}>
                    {payment.signatures.signatureRequests.map((approversRound: Approver[], index: number) => (
                      <div className="approvers-rounds" key={index}>
                        {payment.signatures!.signatureOrder === 'sequential' && (
                          <span className="round-label">
                            {intl.formatMessage(pi['pi.details.round'])}
                            &nbsp;
                            {index + 1}: &nbsp;
                          </span>
                        )}
                        <div>
                          {approversRound.map((approver: Approver) => (
                            <div
                              key={`approver-${approver.userId}`}
                            >{`${approver.name.first} ${approver.name.last}`}</div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.signedBy'])} span={3}>
                    {payment.signatures.signedBy.map((approversRound: Approver[], index: number) => (
                      <div className="approvers-rounds" key={index}>
                        {payment.signatures!.signatureOrder === 'sequential' && (
                          <span className="round-label">
                            {intl.formatMessage(pi['pi.details.round'])}
                            &nbsp;
                            {index + 1}: &nbsp;
                          </span>
                        )}
                        <div>
                          {approversRound.map((approver: Approver) => (
                            <div
                              className="round-user"
                              key={`approver-${approver.userId}`}
                            >{`${approver.name.first} ${approver.name.last}`}</div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            )}
            <Panel header={intl.formatMessage(pi['pi.details.paymentCards'])} key="4" className="filter-panel">
              <Descriptions size="small" className="no-border bh-description-horizontal" bordered>
                {payment.source?.title && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.cardTitle'])} span={3}>
                    {payment.source.title}
                  </Descriptions.Item>
                )}
                {payment.source && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.cardNubmer'])} span={3}>
                    {`${payment.source.typeProperties.bin} •••• •••• ${payment.source.typeProperties.last4}`}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
            {payment.attachments && (
              <Panel header={intl.formatMessage(pi['pi.details.attachment'])} className="filter-panel" key="6">
                <div className="attachments">
                  {payment.attachments.map((attachment: Attachment) => (
                    <div key={`attachment-${attachment.attachmentId!}`}>
                      <Button
                        type="link"
                        onClick={() => void handleDownloadAttachment(paymentInstructionId, attachment.attachmentId!)}
                      >
                        {attachment.filename}
                      </Button>
                      {attachment.comment && <div className="attachment-comment">{attachment.comment}</div>}
                    </div>
                  ))}
                </div>
              </Panel>
            )}
            <Panel key="7" header={intl.formatMessage(pi['pi.actions'])} className="filter-panel">
              <Descriptions
                data-testid="actions-panel"
                size="small"
                className="no-border bh-description-horizontal action-panel"
              >
                <Descriptions.Item span={3}>
                  <Button
                    icon={<FilePdfOutlined />}
                    type="link"
                    disabled={!['PENDING', 'BANK', 'PAID'].includes(payment.state!)}
                    loading={downloadLoader}
                    onClick={() => void handleDownloadReceipts('internal-receipt')}
                  >
                    {intl.formatMessage(pi['pi.details.receiptOption.withFees'])}
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item span={3}>
                  <Button
                    icon={<FilePdfOutlined />}
                    type="link"
                    disabled={!['PENDING', 'BANK', 'PAID'].includes(payment.state!)}
                    loading={externalReceiptLoader}
                    onClick={() => void handleDownloadReceipts('external-receipt')}
                  >
                    {intl.formatMessage(pi['pi.details.receiptOption.withoutFees'])}
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item span={3}>
                  <Button
                    icon={<FilePdfOutlined />}
                    type="link"
                    disabled={!['PENDING', 'BANK', 'PAID'].includes(payment.state!)}
                    loading={remittanceDownLoader}
                    onClick={() => void handleDownloadReceipts('remittance-advice')}
                  >
                    {intl.formatMessage(pi['pi.details.receiptOption.remittanceAdvice'])}
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item span={3}>
                  {handleRemovePayment && (
                    <ACL
                      requiredACL={[
                        { kind: 'charge', action: 'delete' },
                        { kind: 'entity', action: 'delete' },
                      ]}
                      barracksId={payment.profileId}
                    >
                      <Button
                        data-testid="delete-refund-button"
                        icon={<RedoOutlined />}
                        type="link"
                        danger={true}
                        disabled={!['PENDING', 'REQUIRE_SIGNATURE', 'READY'].includes(payment.state!)}
                        onClick={() => handleRemovePayment(payment.id!)}
                      >
                        {refundButtonLabel}
                      </Button>
                    </ACL>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Panel>
          </Collapse>
        </div>
      )}
    </Loader>
  )
}

export default PaymentInstructionDetails
