import React from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Button, Tag, Collapse, Badge } from 'antd'
import classNames from 'classnames'
import { useLanguageState } from '../../../../stores/language/LanguageStore'
import { SCREEN_XL, Size } from '../../../../utils/getDeviceType'
import { EditOutlined, DeleteOutlined, CalendarOutlined, InfoCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { createFromIconfontCN } from '@ant-design/icons'
import Currency from '../../../../components/Format/Currency/Currency'
import AccountNumber from '../../../../components/Format/AccountNumber/AccountNumber'
import { Payment as PaymentType, PAYMENT_KIND, PAYMENT_STATE } from '../../../../types/paymentInstruction'
import { pi } from '../../../../lang/definitions'
import './Payment.less'
import ACL from '../../../../components/ACL/ACL'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { getActiveUser } from '../../../../utils/helpers'
import { useCurrentPayment } from 'stores/Payment'
import { useSession } from 'stores/session'

interface PaymentProps {
  disableSelect?: boolean
  data: PaymentType
  isSelected: boolean
  selectToggle: (payment: PaymentType) => void
  editPayment?: (kind: PAYMENT_KIND, id: string) => void
  deletePayment?: (payment: PaymentType) => void
  showPaymentDetails?: (kind: PAYMENT_KIND, id: string) => void
  removePaymentFromSet?: (kind: PAYMENT_KIND, id: string) => void
}

const { Panel } = Collapse

const Payment = (props: PaymentProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const { state: sessionState } = useSession()
  const me = sessionState.user!
  const { entityId } = getActiveUser(me)
  const size: Size = useWindowSize()
  const {
    state: { isSelectingPayments },
  } = useCurrentPayment()

  const {
    disableSelect,
    data,
    isSelected,
    selectToggle,
    editPayment,
    deletePayment,
    showPaymentDetails,
    removePaymentFromSet,
  } = props

  const intl = useIntl()

  const CardIcon = createFromIconfontCN({
    scriptUrl: '/credit-card-icons.js',
  })

  const getCardIcon = (cardNetwork: string): string => {
    switch (cardNetwork) {
      case 'amex':
        return 'iconicon_zhifuamex'
      case 'diners':
        return 'iconicon_zhifudinersclub'
      case 'mc':
        return 'iconicon_zhifumastercard'
      case 'visa':
        return 'iconicon_zhifuvisa'
      case 'maestro':
        return 'iconicon_zhifumaestro'
      case 'up':
        return 'iconicon_up'
      default:
        return ''
    }
  }

  const renderSignatureData = (): React.ReactNode => (
    <span className="signature-request-label danger">
      {intl.formatMessage(pi['pi.add.reviewPayments.payment.state.requiredSignature'])}
    </span>
  )

  const paymentClassName = classNames('payment', {
    selected: isSelected,
    'select-disabled': disableSelect,
  })

  const cardReferenceText = data.source?.title ?? data.cardNumber
  const renderDesktopView = (data: PaymentType): React.JSX.Element => (
    <div className={paymentClassName}>
      <div className="checkbox" onClick={() => selectToggle(data)}>
        <Checkbox checked={isSelected} disabled={isSelectingPayments} />
      </div>
      <div className="payment-details" onClick={() => selectToggle(data)}>
        <div className="beneficiary-signatures-container">
          <div className="beneficiary-group">
            <div className="beneficiary-title">
              <span>{data.beneficiaryTitle}</span>
            </div>
            {data.newBeneficiary && <Badge>NEW</Badge>}
          </div>
          <div className="beneficiary-account">
            <AccountNumber
              accountNumber={data.beneficiaryAccountNumber}
              routingNumber={data.beneficiaryRoutingNumber}
              clearingNetwork={data.beneficiaryClearingNetwork}
            ></AccountNumber>
          </div>
        </div>
        <div className="date-due-card-container">
          <div className="date-due">
            <span>{data.dateDue}</span>
          </div>
          <div className="card-details">
            {data.cardNetwork && (
              <div className="card-logo">
                <CardIcon
                  className={`icon-container ${getCardIcon(data.cardNetwork) === 'iconicon_up' ? 'network-up' : ''}`}
                  type={getCardIcon(data.cardNetwork) || ''}
                  style={{
                    fontSize: 30,
                  }}
                />
              </div>
            )}
            {cardReferenceText && (
              <div className="card-reference">
                <span>{cardReferenceText}</span>
              </div>
            )}
          </div>
          {data.datePlannedCharge && (
            <div className="card-planned-charge">
              <CalendarOutlined className="calendar-icon" />
              <span className="text">{`Card charged ${data.datePlannedCharge}`}</span>
            </div>
          )}
        </div>
        <div className="price-supplier-funded-container">
          <div className="payment-amount">
            <Currency value={data.amount.toString()} locale={language} currency={data.currency} />
            {data.isSupplierFunded && (
              <div className="supplier-funded">
                <Tag color="green">
                  <span>{intl.formatMessage(pi['pi.supplierFunded.label'])}</span>
                </Tag>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="payment-buttons">
        {editPayment && (
          <ACL
            kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
            barracksId={me.activeProfileId}
            action="update"
          >
            <Button className="bh-track-edit-payment-button" onClick={() => editPayment(data.kind, data.id)}>
              <EditOutlined />
            </Button>
          </ACL>
        )}
        {deletePayment && (
          <>
            {me.activeProfileId === data.profileId ? (
              <ACL
                kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                barracksId={me.activeProfileId}
                action="delete"
              >
                <Button onClick={() => deletePayment(data)}>
                  <DeleteOutlined />
                </Button>
              </ACL>
            ) : (
              <ACL
                kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                barracksId={entityId}
                action="delete"
              >
                <Button className="bh-track-remove-payment-button" onClick={() => deletePayment(data)}>
                  <DeleteOutlined />
                </Button>
              </ACL>
            )}
          </>
        )}
        {showPaymentDetails && (
          <Button onClick={() => showPaymentDetails(data.kind, data.id)}>
            <InfoCircleOutlined />
          </Button>
        )}
        {removePaymentFromSet && (
          <Button onClick={() => removePaymentFromSet(data.kind, data.id)}>
            <CloseOutlined />
          </Button>
        )}
      </div>
    </div>
  )

  const paymentCollapseHeaderClass = classNames('payment-collapse-header', {
    selected: isSelected,
    'select-disabled': disableSelect,
  })

  const paymentCollapseClass = classNames('payment-collapse', {
    selected: isSelected,
    'select-disabled': disableSelect,
  })

  const paymentCollapseHeader = (
    <div className={paymentCollapseHeaderClass}>
      <div
        className="checkbox"
        onClick={(e) => {
          e.stopPropagation()
          selectToggle(data)
        }}
      >
        <Checkbox checked={isSelected} />
      </div>
      <div className="details-wrapper">
        <div className="beneficiary-wrapper">
          <div className="b-group">
            <div className="b-title">
              <span>{data.beneficiaryTitle}</span>
            </div>
            {data.newBeneficiary && <Badge className="new-badge">NEW</Badge>}
          </div>
          <div className="b-account">
            <AccountNumber
              accountNumber={data.beneficiaryAccountNumber}
              routingNumber={data.beneficiaryRoutingNumber}
              clearingNetwork={data.beneficiaryClearingNetwork}
            />
          </div>
          <div className="b-state">
            {data.state === PAYMENT_STATE.READY ? (
              <span>{intl.formatMessage(pi['pi.add.reviewPayments.payment.state.ready'])}</span>
            ) : (
              <React.Fragment>{renderSignatureData()}</React.Fragment>
            )}
          </div>
        </div>
        <div className="price-wrapper">
          <Currency value={data.amount.toString()} locale={language} currency={data.currency} />
          {data.isSupplierFunded && (
            <div className="supplier-funded">
              <Tag color="green">
                <span>{intl.formatMessage(pi['pi.supplierFunded.label'])}</span>
              </Tag>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const renderMobileView = (data: PaymentType): React.JSX.Element => (
    <div className={paymentCollapseClass}>
      <Collapse expandIconPosition="end" ghost className="collapse-item">
        <Panel header={paymentCollapseHeader} key={data.id} className="panel-wrapper">
          <div className="panel-content">
            <div className="date-due-wrapper">
              <span>{data.dateDue}</span>
            </div>
            <div className="card-wrapper">
              {data.cardNetwork && (
                <div className="card-logo">
                  <CardIcon
                    className="icon-container"
                    type={getCardIcon(data.cardNetwork) || ''}
                    style={{
                      fontSize: 30,
                    }}
                  />
                </div>
              )}
              {cardReferenceText && (
                <div className="card-reference">
                  <span>{cardReferenceText}</span>
                </div>
              )}
            </div>
            {data.datePlannedCharge && (
              <div className="planned-charge-wrapper">
                <CalendarOutlined className="calendar-icon" />
                <span className="text">{`Card charged ${data.datePlannedCharge}`}</span>
              </div>
            )}
          </div>
          <div className="panel-actions">
            {editPayment && !data.signatures?.signatureRequests?.length && (
              <ACL
                kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                barracksId={me.activeProfileId}
                action="update"
              >
                <Button onClick={() => editPayment(data.kind, data.id)}>
                  <EditOutlined />
                </Button>
              </ACL>
            )}
            {deletePayment && (
              <>
                {me.activeProfileId === data.profileId ? (
                  <ACL
                    kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                    barracksId={me.activeProfileId}
                    action="delete"
                  >
                    <Button onClick={() => deletePayment(data)}>
                      <DeleteOutlined />
                    </Button>
                  </ACL>
                ) : (
                  <ACL
                    kind={data.kind === PAYMENT_KIND.TEMPLATE ? 'paymentInstructionTemplate' : 'paymentInstruction'}
                    barracksId={entityId}
                    action="delete"
                  >
                    <Button onClick={() => deletePayment(data)}>
                      <DeleteOutlined />
                    </Button>
                  </ACL>
                )}
              </>
            )}
            {showPaymentDetails && (
              <Button onClick={() => showPaymentDetails(data.kind, data.id)}>
                <InfoCircleOutlined />
              </Button>
            )}
            {removePaymentFromSet && (
              <Button onClick={() => removePaymentFromSet(data.kind, data.id)}>
                <CloseOutlined />
              </Button>
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  )

  return (size.width || 0) < SCREEN_XL ? renderMobileView(data) : renderDesktopView(data)
}

export default Payment
