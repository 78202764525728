/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Modal as ModalInterface, notification, Tabs, TabsProps } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ActionPage from 'components/ActionPage/ActionPage'
import Page from 'components/Page/Page'
import { Class, Corp } from 'types/entity'
import { ChangePasswordRequest, UserResponseBody, UserUpdateRequestBody, User } from 'types/user'
import { useLanguageState } from 'stores/language/LanguageStore'
import { SET_LANGUAGE } from 'stores/language/LanguageActionTypes'
import { getSessionId } from './../../utils/storage'
import AccountDetails from './AccountDetails/AccountDetails'
import * as api from 'api/user'
import * as apiRules from 'api/rules'
import * as apiAuth from 'api/auth'
import { defaultCorp, defaultTwoFactorAuthenticationRule, defaultUserDetails } from './AccountDetails/utils'
import EmailForm from './AccountDetails/EmailForm'
import PhoneForm from './AccountDetails/PhoneForm'
import ChangePasswordForm from './AccountDetails/ChangePasswordForm'
import LanguageForm from './AccountDetails/LanguageForm'
import { TwoFactorAuthentication, TwoFactorAuthenticationRule, Rules } from 'types/rules'
import UpdateTwoFactorAuthenticationForm from './AccountDetails/UpdateTwoFactorAuthenticationForm'
import Messages from './Messages/Messages'
import { action, messages, page } from 'lang/definitions/index'
import { getActiveUser } from 'utils/helpers'
import Documents from './Documents/Documents'
import { savePreferredLanguage } from 'utils/storage'
import { hyperlink } from 'lang/definitions/hyperlink'
import OTPInput from 'components/OTPInput/OTPInput'
import '../PaymentInstructions/PaymentInstructions.less'
import { useHistory } from 'react-router-dom'
import { useUtils } from 'hooks/useUtils'
import { resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import * as Sentry from '@sentry/react'
import './Settings.less'
import { useSession } from 'stores/session'

export interface CustomVariables {
  name: string
  value: string
}

const Settings: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const { logoutUser } = useUtils()

  const sessionId = getSessionId() || ''
  const [languageState, dispatchLanguage] = useLanguageState()
  const language = languageState.language
  const setLanguage = (locale: string) => dispatchLanguage({ type: SET_LANGUAGE, language: locale })
  const {
    state: { user, rules },
    actions: { setUser, setRules },
  } = useSession()
  const { profileId, profile } = getActiveUser(user!)
  const userId = profile.userId
  const entityId = profile.entityId
  const [otpToken, setOtpToken] = useState<string>('')
  const [typeOfMessage] = useState<string>('sms')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [phoneCountryCode, setPhoneCountryCode] = useState('')
  const twoFA = rules!.logic.twoFactorAuthentication.rule
  let corp = { corp: { cin: '', title: '' } } as Class
  const hasCorp = profile?.entity?.class?.corp != undefined
  const [userDetails, setUserDetails] = useState<UserResponseBody>(defaultUserDetails)

  const [organizationDetails, setOrganizationDetails] = useState<Corp>(defaultCorp)
  const [twoFactorAuthentication, setTwoFactorAuthentication] = useState<TwoFactorAuthenticationRule>(
    defaultTwoFactorAuthenticationRule
  )

  const [otpVerificationRequired, setOtpVerificationRequired] = useState(false)
  const [activeTab, setActiveTab] = useState<string>('1')

  const handleUpdateEmail = async (email: string): Promise<void> => {
    try {
      const data: UserUpdateRequestBody = {
        name: userDetails.name,
        email: email,
        phone: userDetails.phone,
        timeZone: userDetails.timeZone,
        countryCode: userDetails.countryCode,
        tags: userDetails.tags,
        dateUpdated: new Date().toISOString(),
      }
      userDetails.email = email
      if (profileId && userId) {
        await api.updateUser(userId, data)
        const userWithDetails: User = {
          ...user!,
          user: userDetails,
        }
        setUser(userWithDetails)
        resetDrawerHash(history)
        void reloadRules()
        notification.success({
          message: intl.formatMessage(messages['messages.success.settings.updateUser']),
          placement: 'topRight',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.updateUser']),
        placement: 'topRight',
      })
    }
  }

  const verifyOtpAndUpdatePhone = async (phone: string, phoneCountry: string): Promise<void> => {
    setPhoneCountryCode(phoneCountry)
    setPhoneNumber(phone)

    try {
      const response = await apiAuth.sendOTP(
        phone,
        typeOfMessage, //should it be dynamic? include call as well?
        phoneCountry,
        language,
        sessionId
      )

      setOtpToken(response.token)
      if (response.token) {
        setOtpVerificationRequired(true)
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.updatePhone']),
        placement: 'topRight',
      })
    }
  }

  const otpVerified = () => {
    setOtpVerificationRequired(false)
    void updatePhoneNumber()
  }

  const updatePhoneNumber = async () => {
    let newUserDetails = { ...userDetails }

    if (!newUserDetails.phone) {
      newUserDetails.phone = {
        countryCode: '',
        number: '',
      }
    }
    newUserDetails = {
      ...newUserDetails,
      phone: {
        ...newUserDetails.phone,
        countryCode: phoneCountryCode,
        number: phoneNumber,
      },
    }

    const dateUpdated = new Date().toISOString()
    const data: UserUpdateRequestBody = {
      name: newUserDetails.name,
      email: newUserDetails.email,
      phone: newUserDetails.phone,
      timeZone: newUserDetails.timeZone,
      countryCode: newUserDetails.countryCode,
      tags: newUserDetails.tags,
      dateUpdated: dateUpdated,
    }
    try {
      if (userId && profileId) {
        await api.updateUser(userId, data)
        const userWithDetails: User = {
          ...user!,
          user: newUserDetails,
        }

        setUser(userWithDetails)
        resetDrawerHash(history)

        notification.success({
          message: intl.formatMessage(messages['messages.success.settings.updateUser']),
          placement: 'topRight',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.updateUser']),
        placement: 'topRight',
      })
    }
  }

  const handleChangePassword = async (password: string, newPassword: string): Promise<void> => {
    try {
      if (userId && profileId) {
        const data: ChangePasswordRequest = {
          new: {
            password: btoa(newPassword),
            legacy: false,
            userId: userId,
            passwordHash: '',
          },
          previous: {
            password: btoa(password),
            legacy: false,
            userId: userId,
            passwordHash: '',
          },
        }
        await api.changePassword(userId, data)
        resetDrawerHash(history)
        notification.success({
          message: intl.formatMessage(messages['messages.success.settings.updateUser']),
          placement: 'topRight',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.updateUser']),
        placement: 'topRight',
      })
    }
  }
  const handleChangeLanguage = async (language: string): Promise<void> => {
    try {
      if (userId && profileId) {
        const data = { rule: language }
        await apiRules.changeLanguage(profileId, data)
        setLanguage(language)
        resetDrawerHash(history)
        savePreferredLanguage(language)
        notification.success({
          message: intl.formatMessage(messages['messages.success.settings.updateUser']),
          placement: 'topRight',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.updateUser']),
        placement: 'topRight',
      })
    }
  }
  const handleUpdate2FA = async (rule: TwoFactorAuthenticationRule): Promise<void> => {
    try {
      if (userId && profileId) {
        const data: Partial<TwoFactorAuthentication> = {
          rule: rule,
        }
        await apiRules.update2FA(profileId, data)
        const rulesWithUpdated2FA: Rules = {
          ...rules!,
          logic: {
            ...rules!.logic,
            twoFactorAuthentication: data as TwoFactorAuthentication,
          },
        }
        setRules(rulesWithUpdated2FA)
        setTwoFactorAuthentication(rule)
        resetDrawerHash(history)
        notification.success({
          message: intl.formatMessage(messages['messages.success.settings.updateUser']),
          placement: 'topRight',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.updateUser']),
        placement: 'topRight',
      })
    }
  }
  const openEditEmail = (): void => {
    setDrawerHash(history, '#account/drawer-email')
  }
  const openEditPhone = (): void => {
    setDrawerHash(history, '#account/drawer-phone')
  }
  const openChangePassword = (): void => {
    setDrawerHash(history, '#account/drawer-password')
  }
  const openChangeLanguage = (): void => {
    setDrawerHash(history, '#account/drawer-language')
  }
  const openUpdate2FA = (): void => {
    setDrawerHash(history, '#account/drawer-2fa')
  }
  const reloadRules = async (): Promise<void> => {
    try {
      const data = await apiRules.getRules()
      setTwoFactorAuthentication(data.logic.twoFactorAuthentication.rule)
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.updateUser']),
        placement: 'topRight',
      })
    }
  }
  const handleDeleteAccount = async (): Promise<void> => {
    try {
      if (entityId) {
        await api.deleteAccount(entityId)
        notification.success({
          message: intl.formatMessage(messages['messages.success.settings.deleteUser']),
          placement: 'topRight',
        })
        void logoutUser()
      }
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.deleteUser']),
        placement: 'topRight',
      })
    }
  }
  const showDeleteConfirmationModal = () => {
    ModalInterface.confirm({
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(messages['messages.prompt.delete']),
      okText: intl.formatMessage(messages['messages.prompt.ok']),
      cancelText: intl.formatMessage(messages['messages.prompt.cancel']),
      onOk: () => handleDeleteAccount(),
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  const showTabFromHash = (): void => {
    const windowHash = window.location.hash
    if (windowHash) {
      const hashArray = windowHash.split(/[#,/]/)
      const hashId = hashArray[1]
      const hashSub = hashArray[2]
      let activeTabKey = ''
      if (hashArray.length === 2) {
        switch (hashId) {
          case 'account': {
            activeTabKey = '1'
            break
          }
          case 'notification_preferences': {
            activeTabKey = '2'
            break
          }
          case 'documents': {
            activeTabKey = '3'
            break
          }
          case 'connections': {
            activeTabKey = '4'
            break
          }
          default:
            activeTabKey = '1'
            break
        }
        setActiveTab(activeTabKey)
      } else if (hashArray.length > 2) {
        switch (hashSub) {
          case 'drawer-password':
          case 'drawer-email':
          case 'drawer-phone':
          case 'drawer-language':
          case 'drawer-2fa': {
            activeTabKey = '1'
            break
          }
          case 'drawer-new': {
            activeTabKey = '3'
            break
          }
          default:
            activeTabKey = '4'
            break
        }
        setActiveTab(activeTabKey)
      }
    }
  }
  useEffect(() => {
    void showTabFromHash()
  }, [window.location.hash])
  useEffect(() => {
    setUserDetails(user!.user)
    corp = profile?.entity?.class || { corp: { cin: '', title: '' } }
    if (corp?.corp) {
      setOrganizationDetails(corp.corp)
    }
  }, [user])
  useEffect(() => {
    if (corp?.corp) {
      setOrganizationDetails(corp.corp)
    }
    if (window.location.hash.length === 0) {
      history.replace('#account')
    }
  }, [])
  useEffect(() => {
    if (twoFA) {
      setTwoFactorAuthentication(twoFA)
    }
  }, [twoFA])

  const checkIsCorp = (): boolean => {
    const activeProfileClass = user!.profiles.find((profile) => profile.id === user!.activeProfileId)?.entity.class
    return !!(activeProfileClass && activeProfileClass['corp'])
  }

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: intl.formatMessage(page['page.settings.tab.account.label']),
      children: (
        <AccountDetails
          userDetails={userDetails}
          organizationDetails={organizationDetails}
          hasCorp={hasCorp}
          twoFactorAuthentication={twoFactorAuthentication}
          openEdit={openEditEmail}
          openEditPhone={openEditPhone}
          openChangePassword={openChangePassword}
          openChangeLanguage={openChangeLanguage}
          openUpdate2FA={openUpdate2FA}
        />
      ),
    },
    {
      key: '2',
      label: intl.formatMessage(page['page.settings.tab.messages.label']),
      children: <Messages />,
    },
    {
      key: '3',
      label: intl.formatMessage(page['page.settings.tab.documents.label']),
      children: <Documents />,
    },
  ]

  return (
    <>
      <Modal open={otpVerificationRequired} closable={false} centered footer={null}>
        <div className="otp-input-container">
          <OTPInput
            token={otpToken}
            sessionId={sessionId}
            method={typeOfMessage}
            phone={phoneNumber}
            onSuccess={otpVerified}
            takeMeBackUrl="/app/settings"
          />
        </div>
      </Modal>

      <Page title={intl.formatMessage(page['page.admin.settings.title'])} mobileMenuOptions={[]}>
        <React.Fragment>
          <div className="settings-container">
            <div style={{ backgroundColor: 'transparent' }}>
              <Tabs
                className="settings-tabs"
                activeKey={activeTab}
                onChange={(key: string) => {
                  let activeTab = ''
                  switch (key) {
                    case '1': {
                      activeTab = 'account'
                      break
                    }
                    case '2': {
                      activeTab = 'notification_preferences'
                      break
                    }
                    case '3': {
                      activeTab = 'documents'
                      break
                    }
                    default: {
                      activeTab = 'connections'
                      break
                    }
                  }
                  history.push(`#${activeTab}`)
                }}
                items={tabItems}
              />
            </div>
            {activeTab === '1' && (
              <div className="actions-settings">
                <a
                  className="default"
                  href={
                    checkIsCorp()
                      ? `https://billhop.com/${language}/${intl.formatMessage(
                          hyperlink['hyperlink.enterprisePrivacyPolicy']
                        )}`
                      : `https://billhop.com/${language}/${intl.formatMessage(hyperlink['hyperlink.privacyPolicy'])}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(action['action.settings.privacyPolicy'])}
                </a>
                {!checkIsCorp() && (
                  <a
                    className="default"
                    href={`https://billhop.com/${language}/${intl.formatMessage(hyperlink['hyperlink.terms'])}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage(action['action.settings.terms'])}
                  </a>
                )}
                <a
                  className="default"
                  href={`https://billhop.com/${language}/${intl.formatMessage(hyperlink['hyperlink.cookiePolicy'])}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(action['action.settings.cookiePolicy'])}
                </a>
                <a className="danger" onClick={() => showDeleteConfirmationModal()}>
                  {intl.formatMessage(action['action.settings.deleteMyAccount'])}
                </a>
              </div>
            )}
          </div>
          <ActionPage
            pathname={history.location.pathname}
            hash="#account/drawer-email"
            title={intl.formatMessage(page['page.settings.slider.email.title'])}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <EmailForm onSubmit={handleUpdateEmail} />
          </ActionPage>
          <ActionPage
            pathname={history.location.pathname}
            hash="#account/drawer-phone"
            title={intl.formatMessage(page['page.settings.slider.phone.title'])}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <PhoneForm onSubmit={verifyOtpAndUpdatePhone} userDetails={userDetails} />
          </ActionPage>
          <ActionPage
            pathname={history.location.pathname}
            hash="#account/drawer-password"
            title={intl.formatMessage(page['page.settings.slider.password.title'])}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ChangePasswordForm onSubmit={handleChangePassword} />
          </ActionPage>
          <ActionPage
            pathname={history.location.pathname}
            hash="#account/drawer-language"
            title={intl.formatMessage(page['page.settings.slider.language.title'])}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <LanguageForm onSubmit={handleChangeLanguage} />
          </ActionPage>
          <ActionPage
            pathname={history.location.pathname}
            hash="#account/drawer-2fa"
            title={intl.formatMessage(page['page.settings.slider.2FA.title'])}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <UpdateTwoFactorAuthenticationForm rule={twoFactorAuthentication} onSubmit={handleUpdate2FA} />
          </ActionPage>
        </React.Fragment>
      </Page>
    </>
  )
}
export default Settings
