import React, { useState, useEffect } from 'react'
import { WarningFilled } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { Alert } from 'antd'
import { pi } from '../../lang/definitions'
import './ForeignCurrencyWarning.less'
import { Payment } from '../../types/paymentInstruction'
import { useSession } from 'stores/session'

interface ForeignCurrencyWarningProps {
  payments: Payment[]
}
const ForeignCurrencyWarning = ({ payments }: ForeignCurrencyWarningProps): React.JSX.Element => {
  const intl = useIntl()
  const { state: sessionState } = useSession()
  const rules = sessionState.rules!
  const user = sessionState.user!
  const [isCorp, setIsCorp] = useState<boolean>(false)
  const [showWarning, setShowWarning] = useState<boolean>(false)

  useEffect(() => {
    const activeProfile = user.profiles.find((profile) => profile.id === user.activeProfileId)
    const isCorp = !!activeProfile?.entity.class?.corp
    setIsCorp(isCorp)
  }, [])

  useEffect(() => {
    handleDisplayWarning()
  }, [payments, isCorp])

  const handleDisplayWarning = (): void => {
    if (Array.isArray(payments)) {
      if (isCorp) {
        const shouldShowWarning = payments.some(
          (payment) => payment.source && payment.currency !== payment.source.preferredCurrency
        )
        setShowWarning(shouldShowWarning)
      } else {
        const defaultCurrency = rules.logic?.defaultCurrency?.rule
        const shouldShowWarning = payments.some((payment) => payment.currency !== defaultCurrency)
        setShowWarning(shouldShowWarning)
      }
    }
  }

  return (
    <React.Fragment>
      {showWarning && (
        <div className="foreign-currency-warning-container">
          <Alert
            message={intl.formatMessage(pi['pi.foreignCurrencyWarning.message'])}
            description={intl.formatMessage(pi['pi.foreignCurrencyWarning.text'])}
            type="warning"
            showIcon
            icon={<WarningFilled />}
            closable
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default ForeignCurrencyWarning
