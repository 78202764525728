import { defineMessages } from 'react-intl'

export const support = defineMessages({
  'support.intro': {
    id: 'support.intro',
    defaultMessage:
      'Here you’ll find all the information about Billhop, how to pay bills, how to manage your businesses and accounts and how to solve potential problems. If you can’t find the answer to your question, please contact us using the details below.',
    description: 'An introduction to the support page',
  },
  'support.email': {
    id: 'support.email',
    defaultMessage: 'help@billop.co.uk',
    description: 'Help email',
  },
  'support.phone': {
    id: 'support.phone',
    defaultMessage: '020 3868 3439',
    description: 'Help phone number',
  },
  'support.chatWithUs': {
    id: 'support.chatWithUs',
    defaultMessage: 'Chat with us',
    description: 'Heading to the chat button',
  },
  'support.callUs': {
    id: 'support.callUs',
    defaultMessage: 'Call us',
    description: 'Heading to the call us button',
  },
  'support.emailUs': {
    id: 'emailUs',
    defaultMessage: 'Send an email',
    description: 'Heading to the email button',
  },
  'support.phone.domicile.uk': {
    id: 'support.phone.domicile.uk',
    defaultMessage: '+44 20 3868 3439',
    description: 'Support phone number for billhop uk domicile users',
  },
  'support.email.id.domicile.uk': {
    id: 'support.email.id.domicile.uk',
    defaultMessage: 'help@billhop.co.uk',
    description: 'Support email for billhop uk domicile users',
  },
  'support.email.domicile.ab': {
    id: 'support.email.domicile.ab',
    defaultMessage: 'help@billhop.com',
    description: 'Support email for billhop ab domicile users',
  },
  'support.phone.domicile.ab': {
    id: 'support.phone.domicile.ab',
    defaultMessage: '+46 8410 585 40',
    description: 'Support phone number for billhop ab domicile users',
  },
})
