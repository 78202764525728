import React from 'react'
import { Button } from 'antd'
import { Payment, PAYMENT_KIND, FilterFields } from 'types/paymentInstruction'
import { useCurrentPayment } from 'stores/Payment'
import Payments from '../../components/Payments/Payments'
import PaymentsHeader from '../../components/PaymentsHeader'
import { useTranslation } from 'utils/helpers'
import './SelectPayments.less'

interface SelectPaymentsProps {
  addSelectedPaymentsToSet: () => void
  selectPayments: (payments: Payment[]) => void
  selectAllPayments: () => void
  deselectPayments: (payments: Payment[]) => void
  deselectAllPayments: () => void
  showPaymentDetails: (kind: PAYMENT_KIND, paymentId: string) => void
  deletePayment?: (payment: Payment) => void
}
const SelectPayments = ({
  addSelectedPaymentsToSet,
  selectPayments,
  selectAllPayments,
  deselectPayments,
  deselectAllPayments,
  showPaymentDetails,
  deletePayment,
}: SelectPaymentsProps): React.JSX.Element => {
  const t = useTranslation()

  const {
    state: { selectedPayments, paymentsOnCurrentPage, filteredPayments },
  } = useCurrentPayment()

  return (
    <div className="select-payments-container">
      <PaymentsHeader
        label={t('pi.review.selectPayments.header')}
        filterFields={[FilterFields.CURRENCY, FilterFields.CARD]}
        selectAllPayments={selectAllPayments}
        deselectAllPayments={deselectAllPayments}
        enablePaymentsFilter
      />
      {paymentsOnCurrentPage.length > 0 ? (
        <React.Fragment>
          <Payments
            paymentList={paymentsOnCurrentPage}
            totalNumberOfPayments={filteredPayments.length}
            selectPayments={selectPayments}
            deselectPayments={deselectPayments}
            showPaymentDetails={showPaymentDetails}
            deletePayment={deletePayment}
          />
          <div className="add-payments-button">
            <Button size="large" block disabled={!selectedPayments.length} onClick={addSelectedPaymentsToSet}>
              {selectedPayments.length
                ? t('pi.pay.selectPayments.submitButton', {
                    numberOfPayments: selectedPayments.length,
                  })
                : t('pi.pay.selectPayments.submitButton.disabled')}
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <div className="no-data-message">
          <span>{t('pi.pay.selectPayments.noDataMessage')}</span>
        </div>
      )}
    </div>
  )
}

export default SelectPayments
