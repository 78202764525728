/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useLayoutEffect, useState } from 'react'
import './ChooseProfile.less'
import { User, UserResponseBody } from '../../types/user'
import { Profile } from '../../types/profile'
import { useIntl } from 'react-intl'
import { page } from '../../lang/definitions'
import ExternalContainer from '../ExternalContainer/ExternalContainer'
import { validateProfile, getUser } from '../../api/auth'
import { getSessionId, saveAuthenticationStatus, saveIsProfileChosen, saveSessionId } from '../../utils/storage'
import { useHistory } from 'react-router-dom'
import { DEVICE_TYPE, getDeviceType, Size } from '../../utils/getDeviceType'
import { useWindowSize } from '../../hooks/useWindowSize'
import { SplitContainer } from '../SplitContainer/SplitContainer'
import Loader from '../Loader/Loader'
import { compareEntityNames, useTranslation } from '../../utils/helpers'
import { notifyError } from 'utils/error'
import { useSession } from 'stores/session'

const ChooseProfile: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const sessionId = getSessionId()
  const {
    actions: { setUser: setContextUser },
  } = useSession()

  const title = intl.formatMessage(page['page.choose.account.text'])
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [user, setUser] = useState<User>({
    sessionId: '',
    profiles: [],
    user: {} as UserResponseBody,
    activeProfileId: '',
  })
  const [loading, setLoading] = useState<boolean>(false)
  const size: Size = useWindowSize()
  const t = useTranslation()

  useLayoutEffect(() => {
    if (user.profiles.length > 1) {
      setProfiles(user.profiles)
    } else {
      if (user.profiles.length) {
        void handleProfileSelection(user.profiles[0])
      }
    }
  }, [user])

  useEffect(() => {
    setLoading(true)

    const fetchUser = async () => {
      try {
        const user = await getUser()
        setUser(user)
      } catch (error) {
        handleGetUserError(error)
      } finally {
        setLoading(false)
      }
    }

    void fetchUser()
  }, [])

  const handleGetUserError = (error: unknown) => {
    // Avoiding double error notification
    if (error instanceof Error && error?.message !== 'Network error') {
      notifyError(t('error.default'))
    }
  }

  const handleProfileSelection = async (selectedProfile: Profile) => {
    if (!sessionId) {
      return
    }

    setLoading(true)
    try {
      const data = await validateProfile(selectedProfile?.id)

      saveSessionId(data?.sessionId)
      saveAuthenticationStatus()
      const user = await getUser()
      setContextUser(user)
      void saveIsProfileChosen('true')

      history.replace('/')
    } catch (error) {
      handleGetUserError(error)
    }
    setLoading(false)
  }

  const sortProfiles = profiles.sort(compareEntityNames)

  return (
    <React.Fragment>
      <Loader showLoader={loading} delay={0} bg="#f0f2f5">
        {user.profiles.length > 1 ? (
          <SplitContainer>
            {getDeviceType(size) === DEVICE_TYPE.DESKTOP ? <div className="left-side"></div> : null}
            <div className="right-side">
              <ExternalContainer loading={loading} title={title} data-testid="external-container">
                <div className="account-list" data-testid="account-list">
                  {sortProfiles?.length &&
                    sortProfiles.map((item: Profile) => (
                      <React.Fragment key={item?.id}>
                        <div
                          onClick={() => handleProfileSelection(item)}
                          className="account-user"
                          data-testid={`account-user-${item?.id}`}
                        >
                          <div className="account-name">
                            {item?.entity?.class?.corp?.title ||
                              item?.entity?.class?.sme?.title ||
                              item?.entity?.class?.person?.name?.first}
                          </div>

                          <span className="account-id">
                            {item?.entity?.class?.corp
                              ? item?.entity?.class?.corp.cin || 'Corp account'
                              : item?.entity?.class?.sme
                                ? item?.entity?.class?.sme.cin || 'SME account'
                                : 'Personal Account'}
                          </span>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              </ExternalContainer>
            </div>
          </SplitContainer>
        ) : null}
      </Loader>
    </React.Fragment>
  )
}

export default ChooseProfile
