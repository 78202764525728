import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { Carousel, Modal } from 'antd'
import { sortBy, differenceBy, uniqBy } from 'lodash'
import classNames from 'classnames'
import SelectPayments from './SelectPayments/SelectPayments'
import ReviewPayments from './ReviewPayments/ReviewPayments'
import SubmitPayments from '../components/SubmitPayments/SubmitPayments'
import SubmitProgress from '../components/SubmitProgress/SubmitProgress'
import PaymentInstructionDetails from '../PaymentInstructionDetails'
import PaymentInstructionTemplateDetails from '../PaymentInstructionTemplateDetails'
import ActionPage from 'components/ActionPage/ActionPage'
import * as piApi from 'api/paymentInstruction'
import {
  mapPaymentInstructionToPayment,
  mapTemplateToPayment,
  applyPaymentsFilter,
  sortPaymentsByKind,
  getTotalNumberOfPages,
} from '../Add/utils'
import {
  PaymentInstruction,
  PaymentInstructionTemplate,
  Set,
  Payment,
  PAYMENT_KIND,
  PAYMENT_STATE,
  SetActionsTypes,
  SetStates,
} from 'types/paymentInstruction'
import { Source } from 'types/source'
import { page, pi } from 'lang/definitions'
import { QueryParams } from 'types/general'
import Page, { MobileMenuOption } from 'components/Page/Page'
import { useHistory } from 'react-router-dom'
import { setDrawerHash } from 'components/Drawers/utils'
import { SCREEN_LG, Size } from 'utils/getDeviceType'
import { useWindowSize } from 'hooks/useWindowSize'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useActiveUser, useSetPolling } from 'hooks'
import { useCurrentPayment } from 'stores/Payment'
import { useCurrentPaymentEffects, useProgressSlide } from 'stores/Payment/hooks'
import { CarouselRef } from 'antd/lib/carousel'
import { useSession } from 'stores/session'

const Pay = (): React.JSX.Element => {
  const [set, setSet] = useState<Set>()
  const {
    state: { user },
  } = useSession()
  const { profileId } = useActiveUser()
  const { chargedSet, chargingSet, startPolling, requireActionSet, resetState: resetPoolingState } = useSetPolling()
  const carouselRef = useRef<CarouselRef>(null)
  const size: Size = useWindowSize()
  const history = useHistory()
  const intl = useIntl()

  const { goBackTwoSlides, goToProgressSlide, goToSlide } = useProgressSlide(carouselRef)

  const {
    state: {
      disableReviewPage,
      selectedPayments,
      filteredPayments,
      paymentInstructions,
      paymentInstructionTemplates,
      paymentInstruction,
      paymentInstructionTemplate,
      payments,
      paymentsFilter,
      currentPaymentsPage,
      currentPaymentsInSetPage,
      paymentsInSetOnCurrentPage,
    },
    actions: {
      setSelectedPayments,
      setFilteredPayments,
      setCards,
      setPaymentInstructions,
      setPaymentInstructionTemplates,
      setPaymentInstruction,
      setPaymentInstructionTemplate,
      setPayments,
      setCurrentPaymentsPage,
      setPaymentsInSet,
      setCurrentPaymentsInSetPage,
      resetState: resetGlobalState,
    },
  } = useCurrentPayment()

  useCurrentPaymentEffects()

  useEffect(() => {
    void initData()
  }, [user])

  useEffect(() => {
    const paymentsInSet: Payment[] = []
    let paymentsOutOfSet: Payment[] = []

    if (!set) {
      paymentsOutOfSet = [...payments]
    } else {
      payments.forEach((payment: Payment): void => {
        const { id, kind } = payment
        let paymentIndex = -1
        if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
          paymentIndex = set.paymentInstructionIds!.indexOf(id)
        } else {
          paymentIndex = set.paymentInstructionTemplateIds!.indexOf(id)
        }
        if (paymentIndex !== -1) {
          paymentsInSet.push(payment)
        } else {
          paymentsOutOfSet.push(payment)
        }
      })
    }

    setPaymentsInSet(paymentsInSet)
    const filteredPayments = applyPaymentsFilter(paymentsOutOfSet, paymentsFilter)
    setFilteredPayments(filteredPayments)
  }, [payments, paymentsFilter, set])

  const initData = async (): Promise<void> => {
    const readyState = 'ready'
    const params: QueryParams = {
      limit: 0,
    }

    try {
      // Look for SET in PROCESSING
      const processingPIs = await piApi.searchSetByState({
        state: [SetStates.PROCESSING],
        profileId: [user!.activeProfileId!],
      })

      const PIsWithChargeGroup = processingPIs.filter((pi) => pi.chargeGroups?.length)
      if (PIsWithChargeGroup.length) {
        startPolling(PIsWithChargeGroup[0])
        goToProgressSlide()

        return
      }

      let paymentInstructions: PaymentInstruction[] = []
      let templates: PaymentInstructionTemplate[] = []

      const response = await Promise.all([
        // Get MY ready payments that has not been charged
        piApi.getPaymentInstructionsByState(profileId, readyState, params),
        // Get MY ready Templates that has not been charged
        piApi.getPaymentInstructionTemplatesByState(profileId, readyState, params),
      ])

      // Then combine PIs and templates, see Add.tsx
      paymentInstructions = response[0]
      templates = response[1]

      setPaymentInstructions(paymentInstructions)
      setPaymentInstructionTemplates(templates)

      const paymentsFromPaymentInstructions = paymentInstructions.map(mapPaymentInstructionToPayment)
      const paymentsFromTemplates = templates.map(mapTemplateToPayment)

      const sortedPayments = sortBy(
        [...paymentsFromPaymentInstructions, ...paymentsFromTemplates],
        (payment: Payment) => payment.dateDue
      )

      setPayments(sortedPayments)

      const sources: Source[] = sortedPayments
        .filter((payment: Payment) => !!payment.source)
        .map((payment: Payment) => payment.source!)

      if (sources.length) {
        setCards(uniqBy(sources, (source) => source.id))
      }
    } catch (error) {
      Sentry.captureException(error)
    }

    await getSet()
  }

  const getSet = async (): Promise<void> => {
    const readyState = 'ready'
    const additionalIdentifiers = {
      key: 'action',
      value: SetActionsTypes.PAY,
    }
    try {
      const set = await piApi.getSetByAdditionalIdentifiers(profileId, readyState, additionalIdentifiers)
      setSet(set)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const selectPayments = (payments: Payment[]): void => {
    setSelectedPayments([...selectedPayments, ...payments])
  }
  const deselectPayments = (deselectedPayments: Payment[]): void => {
    const payments = differenceBy(selectedPayments, deselectedPayments, (payment: Payment) => payment.id)

    setSelectedPayments(payments)
  }
  const selectAllPayments = (): void => {
    const notSelectedPayments = differenceBy(filteredPayments, selectedPayments, (payment: Payment) => payment.id)
    selectPayments(notSelectedPayments)
  }

  const deselectAllPayments = (): void => {
    deselectPayments(filteredPayments)
  }

  const showPaymentDetails = (kind: PAYMENT_KIND, paymentId: string): void => {
    if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
      const paymentInstruction = paymentInstructions.find((pi: PaymentInstruction) => pi.id! === paymentId)
      setPaymentInstruction(paymentInstruction)
      setPaymentInstructionTemplate(undefined)
      setDrawerHash(history, '#drawer-pay-details-pi')
    } else {
      const template = paymentInstructionTemplates.find(
        (template: PaymentInstructionTemplate) => template.id! === paymentId
      )
      setPaymentInstructionTemplate(template)
      setPaymentInstruction(undefined)
      setDrawerHash(history, '#drawer-pay-details-pi-template')
    }
  }

  const isOnlyPaymentOnPage = (page: Payment[], id: string): boolean => {
    return page.length === 1 && page[0].id === id
  }

  const removePaymentFromSet = async (kind: PAYMENT_KIND, paymentId: string): Promise<void> => {
    try {
      if (kind === PAYMENT_KIND.PAYMENT_INSTRUCTION) {
        const updatedSet = await piApi.removePaymentInstructionFromSet(set!.id!, [paymentId])
        setSet(updatedSet)
      } else {
        const updatedSet = await piApi.removeTemplateFromSet(set!.id!, [paymentId])
        setSet(updatedSet)
      }
      if (isOnlyPaymentOnPage(paymentsInSetOnCurrentPage, paymentId) && currentPaymentsInSetPage > 1) {
        setCurrentPaymentsInSetPage(currentPaymentsInSetPage - 1)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const createSet = async (piIds: string[] = [], templateIds: string[] = []): Promise<void> => {
    const newSet: Set = {
      profileId,
      paymentInstructionState: PAYMENT_STATE.READY,
      additionalIdentifiers: [
        {
          key: 'action',
          value: SetActionsTypes.PAY,
        },
      ],
    }

    if (piIds.length) {
      newSet.paymentInstructionIds = [...piIds]
    }
    if (templateIds.length) {
      newSet.paymentInstructionTemplateIds = [...templateIds]
    }

    try {
      const response = await piApi.createSet(newSet)

      setSet(response)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const addSelectedPaymentsToSet = async (): Promise<void> => {
    const { paymentInstructionIds, templateIds } = sortPaymentsByKind(selectedPayments)
    if (set) {
      try {
        if (paymentInstructionIds.length) {
          await piApi.addPaymentInstructionToSet(set.id!, paymentInstructionIds)
        }

        if (templateIds.length) {
          await piApi.addTemplateToSet(set.id!, templateIds)
        }

        void getSet()
      } catch (error) {
        Sentry.captureException(error)
      }
    } else {
      await createSet(paymentInstructionIds, templateIds)
    }

    const numberOfRemainingPayments = filteredPayments.length - selectedPayments.length
    // how many pages are required for payments that are not selected
    const remainingNumberOfPages = getTotalNumberOfPages(numberOfRemainingPayments)
    // go to the last page if the current page no longer exists
    if (remainingNumberOfPages < currentPaymentsPage) {
      setCurrentPaymentsPage(remainingNumberOfPages)
    }

    setSelectedPayments([])
    if ((size.width || 0) < SCREEN_LG) {
      history.push('#review-payments')
    }
  }

  const handleGoBackAfterSubmit = (): void => {
    goBackTwoSlides()

    setTimeout(() => {
      resetState()
      void initData()
    }, 500)
  }

  const resetState = (): void => {
    resetGlobalState()
    setSet(undefined)
    resetPoolingState()
  }

  const mobileMenuOptions: Array<MobileMenuOption> = []

  // used to remove array of payments
  const deletePayments = async (payments: Payment[]): Promise<void> => {
    try {
      const { paymentInstructionIds, templateIds } = sortPaymentsByKind(payments)

      await Promise.all([
        ...paymentInstructionIds.map((paymentInstructionId: string) =>
          piApi.deletePaymentInstruction(paymentInstructionId)
        ),
        ...templateIds.map((templateId: string) => piApi.deletePaymentInstructionTemplate(templateId)),
      ])
      void initData()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleDeletePayment = (payment: Payment): void => {
    showDeleteConfirmationModal([payment])
  }

  const showDeleteConfirmationModal = (payments: Payment[]) => {
    Modal.confirm({
      title: intl.formatMessage(pi['pi.modal.delete.header']),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(pi['pi.modal.delete.text'], {
        numberOfPayments: payments.length,
      }),
      okText: intl.formatMessage(pi['pi.modal.button.confirmDelete']),
      cancelText: intl.formatMessage(pi['pi.modal.button.cancel']),
      onOk: () => deletePayments(payments),
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  return (
    <Page
      title={intl.formatMessage(page['page.pay.payments.page.title'])}
      mobileMenuOptions={mobileMenuOptions}
      goToSlide={goToSlide}
      numberOfPayments={payments ? payments.length : 0}
    >
      <div className="pay-container">
        <Carousel
          ref={carouselRef}
          className="pay-carousel"
          slidesToShow={2}
          dots={false}
          infinite={false}
          swipeToSlide={false}
          swipe={false}
          responsive={[
            {
              breakpoint: SCREEN_LG,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              },
            },
          ]}
        >
          <div className="pay-container-step scrollable disable-scrollbars">
            <SelectPayments
              addSelectedPaymentsToSet={() => void addSelectedPaymentsToSet()}
              selectPayments={selectPayments}
              selectAllPayments={selectAllPayments}
              deselectPayments={deselectPayments}
              deselectAllPayments={deselectAllPayments}
              showPaymentDetails={showPaymentDetails}
              deletePayment={handleDeletePayment}
            />
          </div>
          <div
            className={classNames('pay-container-step scrollable disable-scrollbars', {
              disabled: disableReviewPage,
            })}
          >
            <ReviewPayments
              set={set}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              removePaymentFromSet={removePaymentFromSet}
              showPaymentDetails={showPaymentDetails}
              submitButton={
                <SubmitPayments
                  set={set}
                  goToProgressSlide={goToProgressSlide}
                  startPolling={startPolling}
                  requireActionSet={requireActionSet}
                />
              }
            />
          </div>
          <div className="pay-container-step scrollable disable-scrollbars with-background">
            <SubmitProgress
              chargingSet={chargingSet}
              chargedSet={chargedSet}
              goBackAfterSubmit={handleGoBackAfterSubmit}
            />
          </div>
        </Carousel>
        <ActionPage
          title={intl.formatMessage(page['page.pi.slider.label.detail'])}
          hash="#drawer-pay-details-pi"
          pathname={history.location.pathname}
        >
          {paymentInstruction && <PaymentInstructionDetails paymentInstructionId={paymentInstruction.id!} />}
        </ActionPage>
        <ActionPage
          title={intl.formatMessage(page['page.pi.slider.label.detail'])}
          hash="#drawer-pay-details-pi-template"
          pathname={history.location.pathname}
        >
          {paymentInstructionTemplate && (
            <PaymentInstructionTemplateDetails templateId={paymentInstructionTemplate.id!} />
          )}
        </ActionPage>
      </div>
    </Page>
  )
}

export default Pay
