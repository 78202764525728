import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { notification } from 'antd'
import { SplitContainer } from 'components/SplitContainer/SplitContainer'
import UpdatePasswordForm from '../Settings/AccountDetails/UpdatePasswordForm'
import { getPasswordToken, getTokenDateExpire, removePasswordToken } from 'utils/storage'
import { api } from 'api/utils'
import { messages } from 'lang/definitions'
import { password } from 'lang/definitions/password'
import Logo from 'assets/logo/billhop-dark-gray.svg'

interface ParamsType {
  userId: string
}

const PasswordUpdatePage = (): React.JSX.Element => {
  const { userId } = useParams<ParamsType>()
  const history = useHistory()
  const intl = useIntl()
  const tokenDateExpire = getTokenDateExpire()

  const checkIsTokenExpired = (): boolean => {
    if (tokenDateExpire) {
      const tokenTimeExpire = new Date(tokenDateExpire).getTime()
      const currentTime = new Date().getTime()
      if (currentTime < tokenTimeExpire) {
        return false
      }
      return true
    }
    return true
  }

  const handleSubmit = async (newPassword: string): Promise<void> => {
    const notifyError = () => {
      notification.warning({
        message: intl.formatMessage(messages['messages.error.password.update']),
        placement: 'topRight',
      })
    }

    if (checkIsTokenExpired()) {
      notifyError()
      return
    }
    const data = {
      new: {
        password: btoa(newPassword),
        legacy: false,
        userId: userId,
        passwordHash: '',
      },
    }

    try {
      const token = getPasswordToken()
      const url = `/external/zuul/password/${userId}`
      const response = await api.putWithToken(url, token!, data)

      if (!response.ok) {
        notifyError()
        return
      }

      notification.success({
        message: intl.formatMessage(messages['messages.success.password.update']),
        placement: 'topRight',
      })

      history.push('/')
      removePasswordToken()
    } catch (err) {
      notifyError()
    }
  }

  return (
    <SplitContainer>
      <div className="external-container">
        <div className="external-container-wrapper update-password-wrapper">
          <div className="external-container-inner">
            <div className="logo-container">
              <img src={Logo} />
            </div>
            <div className="title-container">
              <span>{intl.formatMessage(password['password.update.required.text'])}</span>
            </div>
          </div>
        </div>
        <div className="external-container-wrapper update-password-wrapper">
          <div className="external-container-inner">
            <UpdatePasswordForm onSubmit={(newPassword) => void handleSubmit(newPassword)} userId={userId} />
          </div>
        </div>
      </div>
    </SplitContainer>
  )
}

export default PasswordUpdatePage
