import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Button, Select } from 'antd'
import UploadDocumentIcon from '../../../assets/upload-document.svg'
import { BhUpload } from '../../../components/Upload/Upload'
import { action, pi, document, messages } from '../../../lang/definitions'
import { DocumentDetails } from '../../../types/documents'
import { useUtils } from '../../../hooks/useUtils'
import './DocumentUploadForm.less'
const FILES_UPLOAD_LIMIT = 1

interface DocumentUploadFormProps {
  onSubmit: (data: DocumentDetails) => void
}

const DocumentUploadForm = ({ onSubmit }: DocumentUploadFormProps): React.JSX.Element => {
  const intl = useIntl()
  const { listOfTypeOfDocuments } = useUtils()

  const [showRequiredFieldError, setShowRequiredFieldError] = useState(false)

  const handleSubmit = (values: {
    attachment: { base64Content: string; name: string }[]
    typeOfDocument: string
    comment: string
  }): void => {
    if (values.attachment && values.typeOfDocument) {
      const filename = values.attachment[0].name.replace(/(?:\.(?![^.]+$)|[^\w.]|[_])+/g, '')
      const data = {
        documents: [
          {
            filename: filename,
            fileContents: values.attachment[0].base64Content,
            type: values.typeOfDocument,
            comment: values.comment && values.comment,
          },
        ],
      }
      onSubmit(data as DocumentDetails)
    } else {
      setShowRequiredFieldError(true)
    }
  }

  const handleOnChange = (): void => {
    if (showRequiredFieldError) {
      setShowRequiredFieldError(false)
    }
  }

  return (
    <div className="document-upload-form-container">
      <Form onFinish={handleSubmit} onChange={handleOnChange}>
        <Form.Item name="attachment" className="document-upload">
          <BhUpload
            multiple={false}
            dragger
            accept="image/*,.pdf"
            limitUploads={FILES_UPLOAD_LIMIT}
            onChange={() => undefined}
          >
            <React.Fragment>
              <p className="ant-upload-drag-icon">
                <img src={UploadDocumentIcon} />
              </p>
              <p className="ant-upload-text">{intl.formatMessage(document['document.attachment.uploader.text'])}</p>
            </React.Fragment>
          </BhUpload>
        </Form.Item>

        <Form.Item
          name="typeOfDocument"
          rules={[
            {
              required: true,
              message: intl.formatMessage(messages['messages.error.uploadDocument.typeOfDocument']),
            },
          ]}
        >
          <Select
            placeholder={intl.formatMessage(document['document.typeOfDocument.select.placeholder'])}
            size="small"
            allowClear={true}
          >
            {listOfTypeOfDocuments.map(
              ({ key, value }): React.ReactNode => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>

        <Form.Item name="comment">
          <Input.TextArea
            className="uploader-comment"
            placeholder={intl.formatMessage(pi['pi.attachment.comment.placeholder'])}
            showCount
            maxLength={100}
          />
        </Form.Item>

        <Form.Item className="form-submit-button-container">
          <Button className="upload-button" htmlType="submit" type="primary">
            {intl.formatMessage(action['action.settings.uploadDocument'])}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default DocumentUploadForm
