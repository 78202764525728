import React, { useState } from 'react'
import { Button, Col, Collapse, Input, Row, Typography, Modal, Tooltip, notification } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { useIntl } from 'react-intl'
import { ProfileGroup } from '../../../../../types/profileGroups'
import { getActiveUser } from '../../../../../utils/helpers'
import { Profile } from '../../../../../types/profile'
import * as apiBarracks from '../../../../../api/barracks'
import * as Sentry from '@sentry/react'
import './ProfileGroups.less'
import ProfileGroupItem from './ProfileGroupItem'
import { entity, messages } from '../../../../../lang/definitions'
import { uniq } from 'lodash'
import { useSession } from 'stores/session'

const { Panel } = Collapse
const { Paragraph } = Typography

interface Props {
  groups: ProfileGroup[]
  setGroups: (groups: ProfileGroup[]) => void
}

const ProfileGroups = ({ groups, setGroups }: Props): React.JSX.Element => {
  const intl = useIntl()
  const {
    state: { user },
  } = useSession()
  const { entityId } = getActiveUser(user!)

  const [isAddGroupOpen, setIsAddGroupOpen] = useState<boolean>(false)
  const [newGroupTitle, setNewGroupTitle] = useState<string>('')
  const [profiles, setProfiles] = useState<Profile[]>([])

  const handleCancel = (): void => {
    setIsAddGroupOpen(false)
    setNewGroupTitle('')
  }

  const addGroup = () => {
    const newGroup: ProfileGroup = {
      id: `new_${uuidv4()}`,
      title: newGroupTitle,
      description: '',
      profileIds: [],
    }

    const groupsUpdated = [...groups]
    groupsUpdated.push(newGroup)
    setGroups(groupsUpdated)
    setNewGroupTitle('')
    setIsAddGroupOpen(false)
  }

  const editGroupTitle = (profileGroupId: string, title: string): void => {
    const groupsUpdated = [...groups]
    const groupIndex = groups.findIndex((group) => group.id === profileGroupId)

    if (groupIndex !== -1) {
      const groupToUpdate = groups[groupIndex]
      groupToUpdate.title = title

      if (!groupToUpdate.id.startsWith('new')) {
        groupToUpdate.updated = true
      }

      groupsUpdated[groupIndex] = groupToUpdate
      setGroups(groupsUpdated)
    }
  }

  const addMemberToGroup = (profileGroupId: string, profileId: string): void => {
    const groupsUpdated = [...groups]
    const groupIndex = groups.findIndex((group) => group.id === profileGroupId)

    if (groupIndex !== -1) {
      const groupToUpdate = groups[groupIndex]
      groupToUpdate.profileIds = uniq([...(groupToUpdate.profileIds || []), profileId])
      if (!groupToUpdate.id.startsWith('new_')) {
        groupToUpdate.updated = true
      }
      groupsUpdated[groupIndex] = groupToUpdate
      setGroups(groupsUpdated)
    }
  }

  const deleteProfileGroup = async (profileGroupId: string): Promise<void> => {
    try {
      await apiBarracks.deleteProfileGroup(entityId, profileGroupId)
    } catch (error) {
      notification.error({
        message: intl.formatMessage(entity['organizationSettings.signature.deleting.error']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const removeProfileGroup = async (profileGroupId: string): Promise<void> => {
    const groupsUpdated = [...groups]
    const groupIndex = groups.findIndex((group) => group.id === profileGroupId)

    if (groupIndex !== -1) {
      const groupToUpdate = groups[groupIndex]
      groupsUpdated.splice(groupIndex, 1)

      if (!groupToUpdate.id.startsWith('new')) {
        try {
          await deleteProfileGroup(groupToUpdate.id)
        } catch (error) {
          Sentry.captureException(error)
        }
      }

      setGroups(groupsUpdated)
    }
  }

  const removeMemberFromGroup = (profileGroupId: string, profileId: string): void => {
    const groupsUpdated = [...groups]
    const groupIndex = groups.findIndex((group) => group.id === profileGroupId)

    if (groupIndex !== -1) {
      const groupToUpdate = groups[groupIndex]

      if (!groupToUpdate.id.startsWith('new')) {
        groupToUpdate.updated = true
      }
      const profileIdsUpdated = groupToUpdate.profileIds
      const profileIndex = profileIdsUpdated.findIndex((id) => id === profileId)
      profileIdsUpdated.splice(profileIndex, 1)
      groupToUpdate.profileIds = profileIdsUpdated
      groupsUpdated[groupIndex] = groupToUpdate
      setGroups(groupsUpdated)
    }
  }

  const showDeleteConfirmationModal = (
    event: React.MouseEvent<HTMLSpanElement | MouseEvent>,
    profileGroupId: string
  ) => {
    event.stopPropagation()
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(messages['messages.prompt.delete']),
      okText: intl.formatMessage(messages['messages.prompt.ok']),
      cancelText: intl.formatMessage(messages['messages.prompt.cancel']),
      onOk: () => removeProfileGroup(profileGroupId),
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  return (
    <div className="p-groups-wrapper">
      <div className="p-groups-header">
        <h4 className="p-header-title">{intl.formatMessage(entity['profile.groups.title'])}</h4>
        <p className="p-header-desc">{intl.formatMessage(entity['profile.groups.description'])}</p>
      </div>
      {groups && groups.length > 0 && (
        <Collapse ghost className="p-group-collapse">
          {groups.map((group, index) => (
            <Panel
              header={
                <Paragraph
                  className="p-group-title"
                  editable={{
                    onChange: (value) => editGroupTitle(group.id, value),
                  }}
                >
                  {group.title}
                </Paragraph>
              }
              extra={
                <Tooltip title="Delete">
                  <DeleteOutlined
                    style={{ color: '#FF0000', cursor: 'pointer' }}
                    onClick={(event) => showDeleteConfirmationModal(event, group.id)}
                  />
                </Tooltip>
              }
              key={group.id}
            >
              <ProfileGroupItem
                key={index}
                profileGroup={group}
                profiles={profiles}
                updateProfileGroupMembers={addMemberToGroup}
                removeProfileGroupMember={removeMemberFromGroup}
                entityId={entityId}
                setProfiles={setProfiles}
              />
            </Panel>
          ))}
        </Collapse>
      )}
      {!isAddGroupOpen && (
        <a onClick={() => setIsAddGroupOpen(!isAddGroupOpen)}>
          + {intl.formatMessage(entity['profile.groups.add.group'])}
        </a>
      )}
      {isAddGroupOpen && (
        <Row gutter={16}>
          <Col span={5}>
            <Input onChange={(e) => setNewGroupTitle(e.target.value)} placeholder="Enter profile group title" />
          </Col>
          <Col span={2}>
            <Button type="primary" size="small" onClick={addGroup} disabled={!newGroupTitle} block>
              {intl.formatMessage(entity['profile.groups.save'])}
            </Button>
          </Col>
          <Col span={2}>
            <Button danger size="small" onClick={handleCancel} block>
              {intl.formatMessage(entity['profile.groups.cancel'])}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ProfileGroups
