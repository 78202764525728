import { Beneficiary } from './beneficiary'
import { Moment } from 'moment'
import { Source } from './source'
import { Approver } from './user'
import { Entity } from './entity'
import { Charge } from './charge'

interface CreationChannel {
  channel: string
  value?: string
}

interface SupplierFundedInfo {
  feeFixed: number
  feeFixedCurrency: string
  feePercent: string
}
export interface PaymentInstruction {
  charges?: Charge[]
  id?: string
  externalId?: string
  profileId: string
  entityId?: string
  beneficiaryId: string
  sourceId?: string
  state?: PAYMENT_STATE
  dateDue: string
  currency: string
  amount: number
  beneficiary?: Beneficiary
  reference: string[]
  templateId?: string
  dateCreated?: string
  dateUpdated?: string
  fee?: number
  amountBase?: number
  feeBase?: number
  source?: Source
  creationChannel: CreationChannel
  remittanceAdvice?: {
    emails: string[]
  }
  signatures?: Signatures
  attachments?: Attachment[]
  supplierFunded?: SupplierFundedInfo
  /**
   * all new payments will have feeCharge and amountPayout, but thay are not required for backward compatibility
   * if they are not present, a fee and amount should be used
   * */
  feeCharge?: number
  amountPayout?: number
  contributors?: Contributor[]
}

export interface Interval {
  unit: string
  value: number
  infinity: boolean
}

export interface PaymentInstructionTemplate {
  id?: string
  profileId: string
  entityId?: string
  state?: PAYMENT_STATE
  occurrencesRemaining?: number
  interval: Interval
  dateDueNext: string
  datePlannedCharge?: string
  creationChannel: CreationChannel
  paymentInstructionProperties: PaymentInstruction
  signatures?: Signatures
  dateCreated?: string
  dateUpdated?: string
  reference?: string[]
}

export interface CardNetworkBankDay {
  cardNetwork: string
  bankDay: Moment
}

export interface CardNetworkPaymentDays {
  cardNetwork: string
  days: number
}

export interface Recurrence {
  limit: boolean
  occurrencesRemaining?: number
  interval?: string
}

export interface PaymentFormData {
  sourceId?: string
  beneficiaryId?: string
  reference?: string
  amount?: string
  currency?: string
  recurring?: boolean
  sendRemittance?: boolean
  remittanceAdvice?: string
}

export enum PAYMENT_KIND {
  PAYMENT_INSTRUCTION,
  TEMPLATE,
}

export enum PAYMENT_STATE {
  READY = 'READY',
  REQUIRE_SIGNATURE = 'REQUIRE_SIGNATURE',
  PENDING = 'PENDING',
}

export interface Payment {
  kind: PAYMENT_KIND
  state: PAYMENT_STATE
  id: string
  profileId: string
  beneficiaryTitle: string
  beneficiaryAccountNumber: string
  beneficiaryRoutingNumber?: string
  beneficiaryClearingNetwork: string
  newBeneficiary?: boolean
  cardNetwork?: string
  cardNumber?: string
  currency: string
  amount: number
  dateDue: string
  datePlannedCharge?: string
  sourceId?: string
  signatures?: Signatures
  source?: Source
  reference?: string
  duplicateCheckId?: string
  isSupplierFunded: boolean
  creationChannel: CreationChannel
}

export interface DetailsTotal {
  details: SetTotalDetails[]
}

export interface Set {
  id?: string
  state?: string
  paymentInstructionIds?: string[]
  paymentInstructionTemplateIds?: string[]
  profileId: string
  entityId?: string
  paymentInstructionState: PAYMENT_STATE
  detailsTotal?: DetailsTotal
  chargeGroups?: ChargGroup[]
  additionalIdentifiers?: {
    key: string
    value: string
  }[]
  outcome?: Outcome
}

export interface SetTotalDetails {
  kind: string
  count: number
  sums: {
    amount: number
    currency: string
    fee: number
    feeDetails: {
      cardNetwork: string
      currency: string
      value: number
      bin: string
      last4: string
    }[]
    amountDetails: {
      cardNetwork: string
      currency: string
      value: number
      bin: string
      last4: string
    }[]
  }[]
  sumBase: SumBase
}

export interface SumBase {
  feeBase?: number
}

export interface ChargGroup {
  id: string
  setId: string
  currency: string
  sourceId: string
  type: string
  amount: number
  threeDSecure: boolean
  charge: Charge
  paymentInstructionIds: string[]
}

export interface ProgressData {
  current: number
  total: number
}

export interface Outcome {
  sucess: boolean
  errorCode?: string
  errorMessage?: string
  providerErrorCode?: string
  providerErrorMessage?: string
  successfulChargeIds?: string[]
  failedChargeIds?: string[]
  successfulFeeChargeIds?: string[]
  failedFeeChargeIds?: string[]
  status: 'FAIL' | 'SUCCESS'
}

export enum SetStates {
  REQUIRE_SIGNATURE = 'REQUIRE_SIGNATURE',
  READY = 'READY',
  PENDING = 'PENDING',
  BANK = 'BANK',
  PROCESSING = 'PROCESSING',
  PENDING_AUTHORIZATION = 'PENDING_AUTHORIZATION',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  CLOSED = 'CLOSED',
}

export interface PaymentsFilter {
  [key: string]: string | undefined
  pendingApprovals?: string
  state?: string
  currency?: string
  card?: string
  beneficiary?: string
}

export enum FilterFields {
  PENDING_APPROVALS,
  STATE,
  CURRENCY,
  CARD,
  BENEFICIARY,
}

export interface Signatures {
  requiredNumber: number
  signatureOrder: 'parallel' | 'sequential'
  signatureRequests: Approver[][]
  signedBy: Approver[][]
}

export enum SetActionsTypes {
  ADD = 'add',
  PAY = 'pay',
  APPROVE = 'approve',
}

export interface Attachment {
  attachmentId?: string
  filename: string
  fileContents?: string
  path?: string
  mimeType?: string
  size?: string
  dateCreated?: string
  comment?: string
}

export interface PaymentInstructionAndTemplate {
  id: string
  externalId?: string
  profileId?: string
  entityId?: string
  beneficiaryId?: string
  sourceId?: string
  state?: string
  dateDue?: string
  currency?: string
  amount?: number
  beneficiary?: Beneficiary
  reference?: string[]
  templateId?: string
  dateCreated?: string
  dateUpdated?: string
  fee?: number
  amountBase?: number
  feeBase?: number
  source?: Source
  creationChannel?: CreationChannel
  remittanceAdvice?: {
    emails: string[]
  }
  signatures?: Signatures
  attachments?: Attachment[]
  supplierFunded?: SupplierFundedInfo
  feeCharge?: number
  amountPayout?: number
  occurrencesRemaining?: number
  interval?: Interval
  dateDueNext?: string
  datePlannedCharge?: string
  paymentInstructionProperties?: PaymentInstruction
  contributors?: Contributor[]
  entity: Entity
}
export interface Contributor {
  action: Actions
  profileId: string
  userId: string
  name: string
  dateCreated: string
}
export enum Actions {
  CREATED = 'created',
  SIGNED = 'signed',
  PAID = 'paid',
}

export interface PaymentInstructionReferencePrefixAndSuffixLength {
  prefixLength: number
  suffixLength: number
}
