import React, { useState, useEffect } from 'react'

import { Form, Input, Button } from 'antd'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { page } from '../../../lang/definitions/index'
import { api } from '../../../api/utils'
import PasswordPolicy from '../../../components/PasswordPolicy/PasswordPolicy'
import { checkPasswordStrength } from '../../../utils/helpers'
import { PasswordPolicy as PasswordPolicyType, PasswordPolicyRule } from '../../../types/rules'
import { getPasswordToken } from '../../../utils/storage'

interface UpdatePasswordFormProps {
  onSubmit: (newPassword: string) => void
  userId: string
}

interface UpdatePasswordFormFields {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const UpdatePasswordForm = (props: UpdatePasswordFormProps): React.JSX.Element => {
  const intl = useIntl()
  const { onSubmit, userId } = props
  const passwordToken = getPasswordToken()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState<string[]>([])
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicyRule>()
  const [form] = Form.useForm()

  const handleSubmit = (newPassword: string): void => {
    onSubmit(newPassword)
  }

  const handleValuesChange = (changedValues: Partial<UpdatePasswordFormFields>): void => {
    const { newPassword, confirmPassword } = changedValues

    if (newPassword) {
      setNewPassword(newPassword)
      passwordPolicy && setPasswordErrors(checkPasswordStrength(newPassword, passwordPolicy))
    }

    if (confirmPassword) {
      setConfirmPassword(confirmPassword)
    }
  }

  const isSubmitDisabled: boolean =
    newPassword == '' || confirmPassword == '' || newPassword != confirmPassword || passwordErrors.length > 0

  const getPasswordPolicy = async (): Promise<void> => {
    try {
      const url = `/external/rules/user/${userId}/rule/passwordPolicy`
      if (passwordToken) {
        const response = await api.getWithToken(url, passwordToken)
        const policy = (await response.json()) as PasswordPolicyType
        setPasswordPolicy(policy.rule)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    void getPasswordPolicy()
    if (passwordPolicy) {
      setPasswordErrors(checkPasswordStrength(newPassword, passwordPolicy))
    }
  }, [passwordToken])

  return (
    <Form
      className="change-password-form"
      form={form}
      id="change-password-form"
      layout="vertical"
      name="basic"
      requiredMark={false}
      size="small"
      onValuesChange={handleValuesChange}
      onFinish={() => handleSubmit(newPassword)}
    >
      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(page['page.settings.slider.password.new.label'])}
        name="newPassword"
      >
        <Input
          className="bh-input"
          type="password"
          placeholder={intl.formatMessage(page['page.settings.password.new.placeholder'])}
        />
      </Form.Item>
      {passwordPolicy && <PasswordPolicy passwordPolicy={passwordPolicy} error={passwordErrors} />}

      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(page['page.settings.slider.password.confirm.label'])}
        name="confirmPassword"
      >
        <Input
          className="bh-input"
          type="password"
          placeholder={intl.formatMessage(page['page.settings.password.confirm.placeholder'])}
        />
      </Form.Item>

      <Form.Item className="bh-form-item">
        <Button type="primary" size="large" htmlType="submit" block disabled={isSubmitDisabled}>
          {intl.formatMessage(page['page.settings.slider.password.update.button.label'])}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UpdatePasswordForm
