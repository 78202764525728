import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Button, Col, Row, Tag } from 'antd'
import { useLanguageState } from '../../../stores/language/LanguageStore'
import { UserResponseBody } from '../../../types/user'
import { Corp } from '../../../types/entity'
import { TwoFactorAuthenticationRule } from '../../../types/rules'
import Date from '../../../components/Format/Date/Date'
import { useIntl } from 'react-intl'
import { user, entity, page } from '../../../lang/definitions/index'
import SettingsCard from '../SettingsCard/SettingsCard'

import userCircle from '../../../assets/user-circle.svg'
import orgDetails from '../../../assets/organisation-details.svg'
import accDetails from '../../../assets/account-details.svg'
import { getRulesBase } from '../../../api/rules'
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber'

interface AccountDetailsProps {
  userDetails: UserResponseBody
  organizationDetails: Corp
  hasCorp: boolean
  twoFactorAuthentication: TwoFactorAuthenticationRule
  openEdit: () => void
  openEditPhone: () => void
  openChangePassword: () => void
  openChangeLanguage: () => void
  openUpdate2FA: () => void
}

interface TranslateFuncProps {
  color: string
  label: string
}

const AccountDetails = ({
  userDetails,
  organizationDetails,
  hasCorp,
  twoFactorAuthentication,
  openEdit,
  openEditPhone,
  openChangePassword,
  openChangeLanguage,
  openUpdate2FA,
}: AccountDetailsProps): React.JSX.Element => {
  const intl = useIntl()
  const [languageState] = useLanguageState()
  const language = languageState.language
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance()

  useEffect(() => {
    void getAuthMethodsRule()
  }, [])

  const getAuthMethodsRule = async (): Promise<void> => {
    try {
      const response = await getRulesBase()
      const authMethods = response.logic.authorizationMethods.rule
      if (authMethods?.credentials) {
        setPasswordVisible(true)
      } else {
        setPasswordVisible(false)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const formatPhoneNumber = (phoneNumber: string): string => {
    const numberProto: PhoneNumber = phoneUtil.parse(phoneNumber, '')
    const formattedNumber = phoneUtil.format(numberProto, PhoneNumberFormat.INTERNATIONAL)
    return formattedNumber
  }

  const translate2FA = (payload: TwoFactorAuthenticationRule): TranslateFuncProps => {
    let status: string
    let color: string
    color = 'success'

    const { what, when } = payload
    if (what === 'sms' && when === 'device') {
      status = intl.formatMessage(page['page.settings.tab.account.tfa.what.sms']).toUpperCase()
    } else if (what === 'sms' && when === 'always') {
      status =
        intl.formatMessage(page['page.settings.tab.account.tfa.what.sms']).toUpperCase() +
        ' ' +
        intl.formatMessage(page['page.settings.tab.account.tfa.status.per.device'])
    } else if (what === 'mail' && when === 'device') {
      status = intl.formatMessage(page['page.settings.tab.account.tfa.what.email']).toUpperCase()
    } else if (what === 'mail' && when === 'always') {
      status =
        intl.formatMessage(page['page.settings.tab.account.tfa.what.email']).toUpperCase() +
        ' ' +
        intl.formatMessage(page['page.settings.tab.account.tfa.status.per.device'])
    } else if (what === 'call' && when === 'device') {
      status = intl.formatMessage(page['page.settings.tab.account.tfa.what.call']).toUpperCase()
    } else if (what === 'call' && when === 'always') {
      status =
        intl.formatMessage(page['page.settings.tab.account.tfa.what.call']).toUpperCase() +
        ' ' +
        intl.formatMessage(page['page.settings.tab.account.tfa.status.per.device'])
    } else {
      status = intl.formatMessage(page['page.settings.tab.account.tfa.status.not.enabled'])
      color = 'error'
    }

    return {
      label: status,
      color: color,
    }
  }

  const render2FA = (data: TwoFactorAuthenticationRule): React.JSX.Element => {
    const payload: TwoFactorAuthenticationRule = {
      what: data.what,
      when: data.when,
    }
    return <Tag color={translate2FA(payload).color}>{translate2FA(payload).label}</Tag>
  }

  return (
    <div>
      <SettingsCard title={intl.formatMessage(page['page.settings.acccount.section.personal.title'])} icon={userCircle}>
        <React.Fragment>
          {userDetails.birthDate && (
            <Row className="row">
              <Col xl={4} md={12} sm={12} xs={12} className="left">
                {intl.formatMessage(user['user.dateOfBirth'])}
              </Col>
              <Col xl={18} md={12} sm={12} xs={12} className="right">
                <Date value={userDetails.birthDate} locale={language} time={false} />
              </Col>
            </Row>
          )}

          {userDetails.name && (
            <Row className="row">
              <Col xl={4} md={12} sm={12} xs={12} className="left">
                {intl.formatMessage(user['user.name'])}
              </Col>
              <Col xl={18} md={12} sm={12} xs={12} className="right">
                {userDetails.name.first} &nbsp; {userDetails.name.last}
              </Col>
            </Row>
          )}

          {userDetails.dateCreated && (
            <Row className="row">
              <Col xl={4} md={12} sm={12} xs={12} className="left">
                {intl.formatMessage(user['user.dateOfRegistration'])}
              </Col>
              <Col xl={18} md={12} sm={12} xs={12} className="right">
                <Date value={userDetails.dateCreated} locale={language} time={true} />
              </Col>
            </Row>
          )}
        </React.Fragment>
      </SettingsCard>

      {hasCorp && (
        <SettingsCard title={intl.formatMessage(page['page.settings.acccount.section.org.title'])} icon={orgDetails}>
          <React.Fragment>
            {organizationDetails.cin && (
              <Row className="row">
                <Col xl={4} md={12} sm={12} xs={12} className="left">
                  {intl.formatMessage(entity['entity.orgNumber'])}
                </Col>
                <Col xl={18} md={12} sm={12} xs={12} className="right">
                  {organizationDetails.cin}
                </Col>
              </Row>
            )}

            {organizationDetails.title && (
              <Row className="row">
                <Col xl={4} md={12} sm={12} xs={12} className="left">
                  {intl.formatMessage(entity['entity.title'])}
                </Col>
                <Col xl={18} md={12} sm={12} xs={12} className="right">
                  {organizationDetails.title}
                </Col>
              </Row>
            )}

            {organizationDetails.dateCreated && (
              <Row className="row">
                <Col xl={4} md={12} sm={12} xs={12} className="left">
                  {intl.formatMessage(entity['entity.dateOfRegistration'])}
                </Col>
                <Col xl={18} md={12} sm={12} xs={12} className="right">
                  <Date value={organizationDetails.dateCreated} locale={language} time={true} />
                </Col>
              </Row>
            )}
          </React.Fragment>
        </SettingsCard>
      )}

      <SettingsCard title={intl.formatMessage(page['page.settings.acccount.section.account.title'])} icon={accDetails}>
        <React.Fragment>
          <Row className="row">
            <Col xl={4} md={8} sm={8} xs={8} className="left">
              {intl.formatMessage(user['user.email'])}
            </Col>
            <Col xl={4} md={12} sm={12} xs={12} className="right">
              {userDetails.email}
            </Col>
            <Col xl={12} md={4} sm={4} xs={4} className="action">
              <Button type="link" key="edit-email" style={{ padding: 0 }} onClick={() => openEdit()}>
                {intl.formatMessage(page['page.settings.acccount.section.edit.link.label'])}
              </Button>
            </Col>
          </Row>

          <Row className="row">
            <Col xl={4} md={8} sm={8} xs={8} className="left">
              {intl.formatMessage(user['user.mobile'])}
            </Col>
            <Col xl={4} md={12} sm={12} xs={12} className="right">
              {userDetails?.phone && userDetails.phone.number && (
                <span>{formatPhoneNumber(userDetails.phone.number)}</span>
              )}
            </Col>
            <Col xl={12} md={4} sm={4} xs={4} className="action">
              <Button type="link" key="change-password" style={{ padding: 0 }} onClick={() => openEditPhone()}>
                {intl.formatMessage(page['page.settings.acccount.section.edit.link.label'])}
              </Button>
            </Col>
          </Row>

          {passwordVisible && (
            <Row className="row">
              <Col xl={4} md={8} sm={8} xs={8} className="left">
                {intl.formatMessage(user['user.password'])}
              </Col>
              <Col xl={4} md={12} sm={12} xs={12} className="right">
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              </Col>
              <Col xl={12} md={4} sm={4} xs={4} className="action">
                <Button type="link" key="change-password" style={{ padding: 0 }} onClick={() => openChangePassword()}>
                  {intl.formatMessage(page['page.settings.acccount.section.edit.link.label'])}
                </Button>
              </Col>
            </Row>
          )}

          <Row className="row">
            <Col xl={4} md={8} sm={8} xs={8} className="left">
              {intl.formatMessage(user['user.language'])}
            </Col>
            <Col xl={4} md={12} sm={12} xs={12} className="right">
              {intl.formatDisplayName(language, { type: 'language' }) || language}
            </Col>
            <Col xl={12} md={4} sm={4} xs={4} className="action">
              <Button type="link" key="change-language" style={{ padding: 0 }} onClick={() => openChangeLanguage()}>
                {intl.formatMessage(page['page.settings.acccount.section.edit.link.label'])}
              </Button>
            </Col>
          </Row>

          <Row className="row">
            <Col xl={4} md={8} sm={8} xs={8} className="left">
              {intl.formatMessage(user['user.2FA'])}
            </Col>
            <Col xl={4} md={12} sm={12} xs={12} className="right">
              {render2FA(twoFactorAuthentication)}
            </Col>
            <Col xl={12} md={4} sm={4} xs={4} className="action">
              <Button type="link" key="change-2fa" style={{ padding: 0 }} onClick={() => openUpdate2FA()}>
                {intl.formatMessage(page['page.settings.acccount.section.edit.link.label'])}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      </SettingsCard>
    </div>
  )
}

export default AccountDetails
