import React, { useState } from 'react'
import { Button, Form, Input, Modal, notification, Select } from 'antd'
import { Class, Entity } from '../../../../types/entity'
import { useIntl } from 'react-intl'
import { action, entity, messages } from '../../../../lang/definitions'
import { useLanguageState } from '../../../../stores/language/LanguageStore'
import './EntityInfoTab.less'
import { hyperlink } from '../../../../lang/definitions/hyperlink'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import * as api from '../../../../api/user'
import * as Sentry from '@sentry/react'
import { useOrganisationHierarchy, useOrganisationHierarchyUtils } from 'stores/OrganisationHierarchy/hooks'
import { getEntityTitle } from '../utils'
import { useSession } from 'stores/session'

interface InfoFormData {
  countryCode: string
  parentEntityId: string
  zipCode: string
  streetAddress: string
  city: string
  title: string
  cin: string
}

const EntityInfoTab = ({ selectedEntity }: { selectedEntity?: Entity }): React.JSX.Element => {
  const intl = useIntl()
  const { state: sessionState } = useSession()
  const user = sessionState.user!

  const [languageState] = useLanguageState()
  const language = languageState.language

  const {
    state: { entities, countryOptions, usersFilterBody },
  } = useOrganisationHierarchy()
  const { getEntityHierarchies, getUsers } = useOrganisationHierarchyUtils()

  const [form] = Form.useForm()

  const [parentEntityEditable, setParentEntityEdit] = useState<boolean>(false)
  const [entityEditable, setEntityEdit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isCorp = (): boolean => !!selectedEntity?.class?.corp
  const isSme = (): boolean => !!selectedEntity?.class?.sme

  /**
   * Clean entity props that are not supposed to be updated
   * @param entity - entity to clean
   * @returns Entity - entity with cleaned props
   */
  const cleanEntityProps = (entity: Entity): Entity => {
    const entityCopy: Entity = { ...entity }
    const PROPS_TO_CLEAN: Array<keyof Entity> = [
      'numberOfPayments',
      'numberOfUsers',
      'children',
      'availableRoles',
      'dateCreated',
      'dateUpdated',
      'dateLastLogin',
    ]

    PROPS_TO_CLEAN.forEach((prop) => {
      delete entityCopy[prop]
    })

    return entityCopy
  }
  const handleSubmit = async (values: InfoFormData): Promise<void> => {
    if (!selectedEntity) return
    if (!isCorp() && !isSme()) return

    try {
      setIsLoading(true)
      const [classType] = Object.keys(selectedEntity.class as Class) as Array<keyof Class>
      const cleanedEntity = cleanEntityProps(selectedEntity)
      const payload: Entity = {
        ...cleanedEntity,
        parentEntityId: values.parentEntityId,
        countryCode: values.countryCode,
        class: {
          [classType]: {
            ...cleanedEntity.class?.[classType],
            title: values.title,
            cin: values.cin,
            address: {
              ...(cleanedEntity.class?.[classType]?.address || {}),
              street: [values.streetAddress],
              zip: values.zipCode,
              city: values.city,
              countryCode: values.countryCode,
            },
          },
        },
      }

      await api.updateEntity(selectedEntity.id, payload)
      await getEntityHierarchies(user.user.id)
      await getUsers(usersFilterBody)

      notification.success({
        message: intl.formatMessage(entity['entity.form.action.edit']),
        placement: 'topRight',
      })
    } catch (error) {
      notification.warning({
        message: 'Action failed!',
        description: intl.formatMessage(entity['organizationSettings.entity.add']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteAccount = async (entityId: string | undefined): Promise<void> => {
    try {
      if (entityId) {
        await api.deleteAccount(entityId)
        notification.success({
          message: intl.formatMessage(messages['messages.success.settings.deleteUser']),
          placement: 'topRight',
        })
      }
    } catch (error) {
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.deleteUser']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const showDeleteConfirmationModal = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(messages['messages.prompt.deleteAccount']),
      okText: intl.formatMessage(messages['messages.prompt.ok']),
      cancelText: intl.formatMessage(messages['messages.prompt.cancel']),
      onOk: () => handleDeleteAccount(selectedEntity?.id),
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  return (
    <React.Fragment>
      <Form
        initialValues={{
          parentEntityId: selectedEntity?.parentEntityId,
          countryCode: selectedEntity?.class?.corp?.address?.countryCode,
          title: selectedEntity?.class?.corp?.title,
          cin: selectedEntity?.class?.corp?.cin,
          streetAddress: selectedEntity?.class?.corp?.address?.street?.join(''),
          zipCode: selectedEntity?.class?.corp?.address?.zip,
          city: selectedEntity?.class?.corp?.address?.city,
        }}
        className="info-tab-wrapper"
        form={form}
        id="credentials-login-form"
        layout="vertical"
        name="basic"
        requiredMark={false}
        size="middle"
        onFinish={(values: InfoFormData) => void handleSubmit(values)}
      >
        {isCorp() && (
          <div className="card-wrapper">
            <div className="header">
              <div className="title">{intl.formatMessage(entity['entity.form.parentEntity'])}</div>
              <div className="edit-label" onClick={() => setParentEntityEdit(true)}>
                {intl.formatMessage(entity['entity.form.edit'])}
              </div>
            </div>
            <div className="content">
              <Form.Item name="parentEntityId">
                <Select
                  disabled={!parentEntityEditable}
                  placeholder={intl.formatMessage(entity['entity.form.parentEntity.placeholder'])}
                  options={entities.map((entity: Entity) => ({
                    label: getEntityTitle(entity),
                    value: entity.id,
                  }))}
                />
              </Form.Item>
            </div>
          </div>
        )}

        <div className="card-wrapper">
          <div className="header">
            <div className="title">{intl.formatMessage(entity['entity.form.details.title'])}</div>
            <div data-testid="edit-form-details-btn" className="edit-label" onClick={() => setEntityEdit(true)}>
              {intl.formatMessage(entity['entity.form.edit'])}
            </div>
          </div>
          <div className="content">
            <Form.Item label={intl.formatMessage(entity['entity.form.country'])} name="countryCode">
              <Select
                disabled={!entityEditable}
                placeholder={intl.formatMessage(entity['entity.form.country'])}
                options={countryOptions.map((item: string) => ({
                  label: intl.formatDisplayName(item.toUpperCase(), {
                    type: 'region',
                  }),
                  value: item,
                }))}
              />
            </Form.Item>

            <Form.Item label={intl.formatMessage(entity['entity.form.name'])} name="title">
              <Input
                disabled={!entityEditable}
                placeholder={intl.formatMessage(entity['entity.form.name.placeholder'])}
              />
            </Form.Item>

            <Form.Item label={intl.formatMessage(entity['entity.form.companyRegistrationNumber'])} name="cin">
              <Input
                disabled={!entityEditable}
                placeholder={intl.formatMessage(entity['entity.form.companyRegistrationNumber.placeholder'])}
              />
            </Form.Item>

            <Form.Item label={intl.formatMessage(entity['entity.form.streetAddress'])} name="streetAddress">
              <Input
                disabled={!entityEditable}
                placeholder={intl.formatMessage(entity['entity.form.streetAddress.placeholder'])}
              />
            </Form.Item>

            <Form.Item label={intl.formatMessage(entity['entity.form.zipCode'])} name="zipCode">
              <Input
                disabled={!entityEditable}
                placeholder={intl.formatMessage(entity['entity.form.zipCode.placeholder'])}
              />
            </Form.Item>

            <Form.Item label={intl.formatMessage(entity['entity.form.city'])} name="city">
              <Input
                disabled={!entityEditable}
                placeholder={intl.formatMessage(entity['entity.form.city.placeholder'])}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
            {intl.formatMessage(entity['entity.form.button.save'])}
          </Button>
        </Form.Item>
      </Form>
      <div className="action-links">
        <a
          className="default"
          href={`https://billhop.com/${language}/${intl.formatMessage(hyperlink['hyperlink.enterprisePrivacyPolicy'])}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage(action['action.settings.privacyPolicy'])}
        </a>
        <a
          className="default"
          href={`https://billhop.com/${language}/${intl.formatMessage(hyperlink['hyperlink.cookiePolicy'])}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage(action['action.settings.cookiePolicy'])}
        </a>
        <a className="danger" onClick={() => showDeleteConfirmationModal()}>
          {intl.formatMessage(action['action.settings.deleteMyAccount'])}
        </a>
      </div>
    </React.Fragment>
  )
}

export default EntityInfoTab
