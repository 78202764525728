import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { DateRange } from '../../components/Filter/Filter'
import { Entity } from '../../types/entity'
import moment from 'moment'
import { Opts } from '../../types/angelia'
import { DataNode } from 'antd/lib/tree'

export enum FORMAT_TYPE {
  EXCEL = 'billhop-excel-transaction-list',
  ID_LIST = 'billhop-id-list',
}
export interface Report {
  profileId: string
  entityId: string
  format: FORMAT_TYPE
  formatProperties: FormatProperties
  delivery: DeliverProperties
  output?: FileOutput
  id?: string
  status?: string
  paymentInstructionIds?: string[]
}

export interface PdfEmailRequest {
  type: string
  paymentInstructionIds: Array<string>
  locale: string
  opts: Opts
  profileId: string
  entityId: string
  format: string
  formatProperties: FormatProperties
  delivery: DeliverProperties
  output?: FileOutput
  id?: string
  status?: string
}

export interface FileOutput {
  file?: {
    format?: string
    path?: string
    fileName?: string
    mimeType?: string
  }
}

export interface DeliverProperties {
  email?: EmailProperties
  download?: DownloadProperties
}

export interface EmailProperties {
  addresses: Array<string>
}

export interface DownloadProperties {
  fileName: string
}
export interface FormatProperties {
  keys?: Array<string>
  dateDue?: DateRange
  dateUserPaid?: DateRange
  beneficiaryIds?: Array<string>
  sourceIds?: Array<string>
  currency?: Array<string>
  clearingNetworks?: Array<string>
  cardNetworks?: Array<string>
  reportFields?: Array<string>
  entityIds?: Array<string>
  states?: Array<string>
  profileIds?: Array<string>
  [key: string]: Array<string> | undefined | DateRange
}

export interface TransferItem {
  key: string
  title: string
  description?: string
  disabled?: boolean
  chosen?: boolean
  children?: TransferItem[]
}

export interface MockData {
  key: string
  title: string
  id?: string
}

export interface MockTreeData {
  key: string
  title: string
  id?: string
  children?: Array<MockTreeData>
}
export interface TitleList {
  title: string
  id: string
  filters: Array<string>
  tagTitle: string
  tags: Array<MockTreeData>
  targetKeys: Array<string>
  mockData: Array<MockTreeData>
  numberedTag?: boolean
  treeList?: boolean
  treeLength?: number
  display?: boolean
  include?: boolean
}

export interface SearchUser {
  countryCode: string
  dateCreated: string
  email: string
  id: string
  mocked?: boolean
  name: {
    first: string
    last: string
  }
}

export interface OptionData {
  label: string
  value: string
  disabled?: boolean
  checked?: boolean
}

export interface CheckedProps {
  [key: string]: CheckboxValueType[] | string[]
}

export interface CheckedAllProps {
  [key: string]: boolean
}

export interface DateRangeObject {
  from: string | null
  to: string | null
}

export interface ReportKeys {
  key: string
  label: string
  disabled?: boolean
}

export const initialState: Report = {
  profileId: '',
  entityId: '',
  format: FORMAT_TYPE.EXCEL,
  formatProperties: { keys: [] },
  delivery: {
    email: { addresses: [] },
    download: {
      fileName: '',
    },
  },
}

// paymentStatus, reportFields are constants until api is finished for them

export const dateOptions: OptionData[] = [
  {
    value: '1',
    label: 'Last 30 days',
  },
  {
    value: '2',
    label: `${moment().subtract(1, 'months').format('MMMM YYYY')}`,
  },
  {
    value: '3',
    label: `${moment().subtract(2, 'months').format('MMMM YYYY')}`,
  },
  {
    value: '4',
    label: `Year ${moment().subtract(0, 'years').format('YYYY')}`,
  },
  {
    value: '5',
    label: `Specific date range`,
  },
]

export const generateKeys = (data: MockData[]): Array<string> => data.map((item: MockData) => item.key)

export const transformData = (array: Array<string>): Array<OptionData> => {
  const arrayCopy = array.map((item: string) => {
    return {
      label: item.toUpperCase(),
      value: item.toLowerCase(),
      disabled: true,
    }
  })

  return [...arrayCopy]
}

export const renderTitle = (entity: Entity): string => {
  const corpTitle = entity?.class?.corp?.title ?? ''
  const smeTitle = entity?.class?.sme?.title ?? ''
  const personName = `${entity?.class?.person?.name?.first ?? ''}` + ' ' + `${entity?.class?.person?.name?.last ?? ''}`
  return corpTitle || smeTitle || personName
}

export const generateEntityTree = (data: Entity[]): DataNode[] =>
  data.map((item) => ({
    key: item.id,
    title: renderTitle(item),
    value: item.id,
    children: generateEntityTree(item.children || []),
  }))

export const getEntityTreeIds = (data: DataNode[]): string[] => {
  const allEntityIds: string[] = []
  JSON.stringify(data, (key, value) => {
    if (key === 'key') {
      allEntityIds.push(value as string)
    }
    return value
  })
  return allEntityIds
}

export enum DELIVERY_OPTIONS {
  EMAIL = 'email',
  DOWNLOAD = 'download',
}

export enum DATE_OPTIONS {
  LAST_30_DAYS = '1',
  SECOND_MONTH = '2',
  THIRD_MONTH = '3',
  THIS_YEAR = '4',
  SPECIFIC_DATE = '5',
  RESET_TO_LAST_30_DAYS = '6',
}

export enum REPORT_ITEMS {
  DATE_RANGE = 'dateRange',
  BENEFICIARY = 'beneficiaryIds',
  CARD_NETWORKS = 'cardNetworks',
  CLEARING_NETWORKS = 'clearingNetworks',
  CURRENCY = 'currency',
  ENTITY = 'entityIds',
  REPORT_FIELDS = 'reportFields',
  SOURCES = 'sourceIds',
  STATES = 'states',
  DATE_USER_PAID = 'dateUserPaid',
  EXTRA_INFO = 'extraInfo',
  NAME_ON_REPORT = 'nameOnReport',
  REPORT_FORMAT = 'reportFormat',
}

export enum ENTITY_OPTIONS {
  ALL = 'all',
  SELECT = 'select',
}

export enum DATE_OPTION_TYPE {
  DATE_DUE = 'dateDue',
  DATE_USER_PAID = 'dateUserPaid',
}

export enum REPORT_FORMAT_OPTION_TYPE {
  SINGLE_PI = 'single',
  TABLE_OF_PIS = 'multiple',
}
