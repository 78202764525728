import React from 'react'
import { Form, Radio, RadioChangeEvent, Space } from 'antd'
import { OptionData, DateRangeObject, DATE_OPTIONS } from './utils'
import { useIntl } from 'react-intl'
import { reports } from '../../lang/definitions/reports'
import { filters } from '../../lang/definitions'
import BHDatePicker from '../../components/DatePicker/BHDatePicker'
interface DateProps {
  optionArray: OptionData[]
  dateRange: DateRangeObject
  selectedDateOption: DATE_OPTIONS
  handleDateChange: (date: string, isFrom: boolean) => void
  handleSelectDateOption: (value: RadioChangeEvent) => void
}

const DateRangeSelect = ({
  optionArray,
  dateRange,
  selectedDateOption,
  handleDateChange,
  handleSelectDateOption,
}: DateProps): React.JSX.Element => {
  const intl = useIntl()
  return (
    <div className="radio-group-wrapper">
      <Radio.Group className="radio-group" value={selectedDateOption} onChange={handleSelectDateOption}>
        <Space direction="vertical">
          {optionArray.map((item: OptionData, index: number) => (
            <Radio className="radio" value={item.value} key={index} data-testid="radio-value-dateRange">
              {item.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Space direction="horizontal" className="date-wrapper">
        <Form.Item
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          label={intl.formatMessage(reports['reports.label.from'])}
          className="form-item no-space"
          data-testid="bh-date-picker-from"
        >
          <BHDatePicker
            disabled={selectedDateOption !== DATE_OPTIONS.SPECIFIC_DATE} //disabled if not selected 'specific date range'
            className="bh-datepicker"
            value={dateRange.from}
            onChange={(dateString) => handleDateChange(dateString, true)}
            placeholder={intl.formatMessage(filters['filter.date.range.start.date.placeholder'])}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          label={intl.formatMessage(reports['reports.label.to'])}
          className="form-item no-space"
          data-testid="bh-date-picker-to"
        >
          <BHDatePicker
            disabled={selectedDateOption !== DATE_OPTIONS.SPECIFIC_DATE} //disabled if not selected 'specific date range'
            className="bh-datepicker"
            value={dateRange.to}
            onChange={(dateString) => handleDateChange(dateString, false)}
            placeholder={intl.formatMessage(filters['filter.date.range.end.date.placeholder'])}
          />
        </Form.Item>
      </Space>
    </div>
  )
}

export default DateRangeSelect
