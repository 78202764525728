import { Alert, Button, Layout, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { WarningFilled } from '@ant-design/icons'
import * as Sentry from '@sentry/react'
import { useIntl } from 'react-intl'
import { Redirect, Route, Switch } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useInterval } from '../../hooks/useInterval'
import { page } from '../../lang/definitions/index'
import { getIsProfileChosen, getSessionId } from '../../utils/storage'
import { useWindowSize } from '../../hooks/useWindowSize'
import { DEVICE_TYPE, getDeviceType, Size } from '../../utils/getDeviceType'
import { getSessionInfo } from '../../api/auth'
import AlertMessagesContainer from '../../components/AlertMessage/AlertMessagesContainer'
import { useUtils } from '../../hooks/useUtils'
import { HeaderContent } from '../../components/Header/HeaderContent'
import { useTranslation } from '../../utils/helpers'
import useNetworkStatus from 'hooks/useNetworkStatus'
import './Home.less'
import { useSession } from 'stores/session'

interface RouteParameters {
  exact: boolean
  path: string
  name: string
  component: React.ComponentType<{ name: string; routes: RouteParameters[] }>
  private?: boolean
  routes: Array<RouteParameters>
  isMobileDisabled?: boolean
}

interface Props {
  routes: Array<RouteParameters>
}

const Home: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const t = useTranslation()
  const { handleSessionExpire } = useUtils()
  const { routes } = props
  const {
    state: { user },
    actions: { setUser },
  } = useSession()
  const sessionId = getSessionId()
  const { isOnline } = useNetworkStatus()
  const [checkSession, setCheckSession] = useState<boolean>(false)
  const [showContentLoader, setShowContentLoader] = useState(false)
  const size: Size = useWindowSize()
  const { Header, Content, Sider } = Layout

  useInterval(
    async () => {
      try {
        // session is fetched without extending the Session TTL
        await getSessionInfo()
      } catch (error) {
        Sentry.captureException(error)
        handleSessionExpire()
        setCheckSession(false)
      }
    },
    checkSession ? 60000 : null
  )

  useEffect(() => {
    if (sessionId) {
      setCheckSession(true)
    }
    if (!sessionId) {
      setCheckSession(false)
    }
  }, [sessionId])

  return (
    <div className="Home">
      <Layout>
        <Header className="site-header">
          {getDeviceType(size) === DEVICE_TYPE.MOBILE && <Sidebar user={user!} setUser={setUser} />}
          <div className="header-content">
            <HeaderContent user={user!} setUser={setUser} setLoader={setShowContentLoader} />
          </div>
        </Header>
        <Layout hasSider>
          {getDeviceType(size) !== DEVICE_TYPE.MOBILE && (
            <Sider className="site-sidebar">
              <Sidebar user={user!} setUser={setUser} />
            </Sider>
          )}
          <Layout className="site-layout">
            <Loader showLoader={showContentLoader} delay={0} bg="#f0f2f5">
              <Content>
                <AlertMessagesContainer placement="everywhere" />
                {!isOnline && (
                  <Alert
                    message={t('page.allPages.user.offline.warning.message')}
                    description={t('page.allPages.user.offline.warning.description')}
                    type="warning"
                    showIcon
                    icon={<WarningFilled />}
                    className="offline-warning"
                  />
                )}
                <Switch>
                  {routes &&
                    routes.map((route: RouteParameters, i: number) => (
                      <PrivateRoute key={i} exact={route.exact} path={route.path} setIsLoading={setShowContentLoader}>
                        {getDeviceType(size) === DEVICE_TYPE.MOBILE && route.isMobileDisabled ? (
                          <Result
                            status="warning"
                            title={intl.formatMessage(page['page.mobile.limitation.warning.text'])}
                            extra={
                              <Button type="primary" onClick={() => (window.location.href = '/')}>
                                {intl.formatMessage(page['page.404.link.back'])}
                              </Button>
                            }
                          />
                        ) : (
                          <route.component name={route.name} routes={route.routes} />
                        )}
                      </PrivateRoute>
                    ))}
                  {sessionId ? (
                    <Result
                      status="404"
                      title={intl.formatMessage(page['page.404.title'])}
                      subTitle={intl.formatMessage(page['page.404.subTitle'])}
                      extra={
                        <Button type="primary" onClick={() => (window.location.href = '/')}>
                          {intl.formatMessage(page['page.404.link.back'])}
                        </Button>
                      }
                    />
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Switch>
              </Content>
            </Loader>
          </Layout>
        </Layout>
      </Layout>
    </div>
  )
}

export default Home

interface PrivateRouteProps {
  children: React.ReactElement
  exact: boolean
  path: string
  setIsLoading: (isLoading: boolean) => void
}

function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const sessionId = getSessionId()
  const isProfileChosen = getIsProfileChosen()
  if (sessionId && !isProfileChosen) {
    return <Redirect to={'/choose-profile'} />
  }

  return (
    <Route
      {...rest}
      render={(restProps) => {
        return sessionId ? (
          React.Children.map(children, (child) => {
            return React.cloneElement(child, { ...restProps, ...rest })
          })
        ) : (
          <Redirect to={'/login'} />
        )
      }}
    />
  )
}
