import React, { useEffect, useState } from 'react'
import { Avatar, Tooltip } from 'antd'
import { getInitials, getRoleName, stringToColor } from './utils'
import { AvatarSize } from 'antd/lib/avatar/SizeContext'
import { Actions, Contributor } from '../../types/paymentInstruction'
import { useIntl } from 'react-intl'

interface BHAvatarProps {
  contributors: Contributor[]
  size?: AvatarSize
}

const BHAvatar = (props: BHAvatarProps): React.JSX.Element => {
  const { size, contributors } = props

  const intl = useIntl()
  const [piCreator, setPiCreator] = useState<Contributor>()

  useEffect(() => {
    void getCreator(contributors)
  }, [contributors])

  const getCreator = (contributors: Contributor[]) => {
    //look for created action in contributors array
    const creatorProfile = contributors.find((contributors) => contributors.action === Actions.CREATED)

    if (creatorProfile) {
      setPiCreator(creatorProfile)
    }
  }

  return (
    <div>
      {piCreator && (
        <Tooltip placement="bottom" title={`${piCreator.name} - ${getRoleName(piCreator.action, intl)}`}>
          <Avatar
            style={{
              backgroundColor: stringToColor(piCreator.name),
              verticalAlign: 'middle',
              color: 'black',
            }}
            size={size ? size : 'large'}
          >
            {getInitials(piCreator.name)}
          </Avatar>
        </Tooltip>
      )}
    </div>
  )
}

export default BHAvatar
