import React, { ReactElement, useEffect, useState, Key } from 'react'
import { useIntl } from 'react-intl'
import { Button, Modal, notification, Table } from 'antd'
import { ExclamationCircleOutlined, DeleteOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { deleteSource, deleteSources, getUserSourcesOnFile } from '../../api/card'
import ActionMenu, { Action } from 'components/ActionMenu/ActionMenu'
import ActionPage from 'components/ActionPage/ActionPage'
import Filter, { FilterOption } from 'components/Filter/Filter'
import Page, { MobileMenuOption } from 'components/Page/Page'
import { useLanguageState } from 'stores/language/LanguageStore'
import CardDetails from './CardDetails'
import CardForm from './CardForm'
import { sourceColumns, useGetCardIdColumn } from './utils'
import { getActiveUser, useTranslation } from 'utils/helpers'
import { Source } from 'types/source'
import { action, page, filters, messages, card } from 'lang/definitions'
import ACL from 'components/ACL/ACL'
import { FilterState } from 'types/general'
import SecondaryMenu from 'components/SecondaryMenu/SecondaryMenu'
import { useHistory } from 'react-router-dom'
import { resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import { Profile } from 'types/profile'
import acl from 'utils/acl'
import * as Sentry from '@sentry/react'
import { PaymentGateway } from 'types/rules'
import { TableRowSelection } from 'antd/lib/table/interface'
import { DEVICE_TYPE } from 'utils/getDeviceType'
import { isDeviceType } from 'hooks'
import 'styles/bh-action-page.less'
import './Cards.less'
import { useSession } from 'stores/session'

const PAGE_SIZE = 10

export interface CardFilter {
  kind?: string
  operator?: string
  profileId?: Array<string>
  title?: string
  accountNumber?: string
}

const Cards = (): React.JSX.Element => {
  const history = useHistory()
  const { state: sessionState } = useSession()
  const rules = sessionState.rules!
  const iam = sessionState.iam!
  const me = sessionState.user!
  const user = sessionState.user!

  const [languageState] = useLanguageState()
  const language = languageState.language
  const isMobile = isDeviceType(DEVICE_TYPE.MOBILE)
  const activeProfile = user?.profiles.find((profile: Profile) => profile.id === user?.activeProfileId)
  const isCardAdmin = acl({
    iam,
    me,
    kind: 'source',
    barracksId: user.activeProfileId,
    action: 'create',
  })
  const hasDeleteEnabled = acl({
    iam,
    me,
    kind: 'source',
    barracksId: user.activeProfileId,
    action: 'delete',
  })
  const { profileId } = getActiveUser(user)

  const intl = useIntl()
  const t = useTranslation()
  const isCardIdRuleEnabled = rules.toggles.customCardIdByCustomer?.rule
  const [showLoader, setShowLoader] = useState(true)
  const [initSources, setInitSources] = useState<Source[]>([])
  const [sources, setSources] = useState<Source[]>([])
  const [source, setSource] = useState<Source>({} as Source)
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [sourceFilter, setSourceFilter] = useState<CardFilter | undefined>()
  const [showClearFilter, setShowClearFilter] = useState(false)
  const [total, setTotal] = useState<number>()
  const [cardNetworks, setCardNetworks] = useState<Array<string>>([])
  const [filterState, setFilterState] = useState<FilterState>()
  const [isDeleteSourceWindowOpen, setIsDeleteSourceWindowOpen] = useState(false)
  const [isCorp, setIsCorp] = useState<boolean>()
  const [emptyMessage, setEmptyMessage] = useState<string>(intl.formatMessage(card['card.not.added']))
  const [gateway, setGateways] = useState<Array<PaymentGateway>>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isEntityCardManagedByAdmin, setIsEntityCardManagedByAdmin] = useState<boolean>()
  const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<Source['id'][]>([])

  const cardColumnsKeys = ['icon', 'title', 'number', 'cardExpiryDate']

  const preferredCurrencyColumn = {
    title: intl.formatMessage(card['card.preferredCurrency']),
    dataIndex: 'preferredCurrency',
    key: 'preferredCurrency',
    render: (data: string): ReactElement => {
      return <>{data && data.toUpperCase()}</>
    },
  }

  useEffect(() => {
    if (sourceFilter && Object.keys(sourceFilter).length > 0) {
      setShowClearFilter(true)
    } else {
      setShowClearFilter(false)
    }
  }, [sourceFilter])

  useEffect(() => {
    void showDrawersFromHash()
  }, [window.location.hash, sources])

  const showDrawersFromHash = (): void => {
    const windowHash = window.location.hash
    if (windowHash && sources) {
      const isDetails = windowHash.includes('details')
      const hashArray = windowHash.split('?key=')
      const hashId = hashArray[1]

      isDetails && openDetails(hashId)
    }
  }

  const selectSource = (id: string): void => {
    const source = sources && sources.find((source: Source) => source && source.id === id)

    if (source) {
      setSource(source)
    }
  }

  const openDetails = (id: string): void => {
    selectSource(id)
    setDrawerHash(history, `#drawer-details?key=${id}`)
  }

  const openDelete = (id: string): void => {
    const source = sources && sources.find((source: Source) => source && source.id === id)

    if (source) {
      !isDeleteSourceWindowOpen ? setIsDeleteSourceWindowOpen(true) : setIsDeleteSourceWindowOpen(false)
      showDeleteConfirmationModal(source)
    }
  }

  const handleDeleteCard = async (id: string): Promise<void> => {
    try {
      await deleteSource(id)
      setSelectedRowsToDelete((selectedRows) => selectedRows.filter((row) => row !== id))
      void getSources(user.user.id)
      notification.success({
        message: intl.formatMessage(messages['messages.success.cards.delete'], { source: 1 }),
        placement: 'topRight',
      })
    } catch (error) {
      Sentry.captureException(error)
      notification.error({
        message: intl.formatMessage(messages['messages.error.cards.delete'], { source: 1 }),
        placement: 'topRight',
      })
    }
  }

  const handleDeleteMultipleCards = async (sourceIds: Source['id'][]): Promise<void> => {
    try {
      const { fail, success } = await deleteSources({ sourceIds })
      await getSources(user.user.id)
      if (fail) {
        notification.warning({
          message: intl.formatMessage(messages['messages.partial.error.cards.delete']),
          placement: 'topRight',
        })
      } else {
        notification.success({
          message: intl.formatMessage(messages['messages.success.cards.delete'], { source: success }),
          placement: 'topRight',
        })
      }
      setSelectedRowsToDelete([])
    } catch (error) {
      Sentry.captureException(error)
      notification.error({
        message: intl.formatMessage(messages['messages.error.cards.delete'], { source: sourceIds.length }),
        placement: 'topRight',
      })
    }
  }

  const showDeleteConfirmationModal = (source: Source | Source['id'][]) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: intl.formatMessage(messages['messages.prompt.deleteSource.title'], {
        source: Array.isArray(source) ? source.length : 1,
      }),
      content: intl.formatMessage(messages['messages.prompt.deleteSource'], {
        source: Array.isArray(source) ? source.length : 1,
      }),
      okText: intl.formatMessage(messages['messages.prompt.ok']),
      cancelText: intl.formatMessage(messages['messages.prompt.cancel']),
      maskClosable: true,
      onOk: () => (Array.isArray(source) ? handleDeleteMultipleCards(source) : handleDeleteCard(source?.id)),
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  const getActions = (): Array<Action> => {
    const actions: Array<Action> = []
    if ((isEntityCardManagedByAdmin && isCardAdmin) || !isEntityCardManagedByAdmin) {
      actions.push({
        id: 'add-card',
        label: intl.formatMessage(action['action.card.add']),
        type: 'primary',
        callback: () => setDrawerHash(history, '#drawer-new-source'),
        requiredACL: [{ kind: 'source', action: 'create' }],
        className: 'bh-track-add-card-button',
      })

      if (hasDeleteEnabled && selectedRowsToDelete.length) {
        actions.push({
          label: t('card.form.delete'),
          type: 'button',
          elementType: 'danger',
          badge: { count: selectedRowsToDelete.length, color: '#D66666' },
          requiredACL: [{ kind: 'source', action: 'delete' }],
          callback: () => showDeleteConfirmationModal(selectedRowsToDelete),
        })
      }
    }

    return actions
  }

  const actionMenu = (
    <ActionMenu
      isOpen={isActionMenuOpen}
      actions={getActions()}
      label={intl.formatMessage(action['action.card.mobileMenu.label'])}
      closeMenu={() => setIsActionMenuOpen(false)}
    />
  )

  const handleFilterChange = (value: string): void => {
    let filter = undefined
    if (value.length) {
      filter = {
        title: value,
        accountNumber: value,
      }
      setEmptyMessage(intl.formatMessage(card['card.not.found']))
    } else {
      setEmptyMessage(intl.formatMessage(card['card.not.added']))
    }
    setSourceFilter(filter)
    handleSetFilterState(value, 'search')
  }

  const filterOptions: Array<FilterOption> = [
    {
      label: intl.formatMessage(filters['filter.search']),
      type: 'search',
      callback: (value): void => handleFilterChange(value as string),
    },
  ]

  const handleClearFilters = (): void => {
    handleFilterChange('')
  }

  const secondMenuGroup = [
    {
      align: 'center',
      id: 'second-group-filter',
      elements: [
        {
          label: intl.formatMessage(filters['filter.clear']),
          type: 'button' as const,
          icon: <CloseOutlined />,
          visibility: showClearFilter,
          callback: () => handleClearFilters(),
        },
      ],
    },
  ]

  const secondaryMenu = (
    <SecondaryMenu label={intl.formatMessage(action['action.pi.page.second.action.title'])} groups={secondMenuGroup} />
  )

  const handleSetFilterState = (value: string, key: string): void => {
    setFilterState((state) => ({
      ...state,
      [key]: value,
    }))
  }

  const filter = (
    <Filter
      isOpen={isFilterOpen}
      label={intl.formatMessage(filters['filter.cards.page.title'])}
      closeFilter={() => setIsFilterOpen(false)}
      groups={[{ elements: filterOptions }]}
      filterState={filterState}
      setFilterState={handleSetFilterState}
    />
  )

  const getSources = async (id: string) => {
    if (!id) return
    try {
      setShowLoader(true)
      const { sources, total } = await getUserSourcesOnFile(id)
      setTotal(total)
      setInitSources(sources)
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.cards.get']),
        description: intl.formatMessage(messages['messages.errorDescription.cards.get']),
        placement: 'topRight',
      })
    } finally {
      setShowLoader(false)
    }
  }

  useEffect(() => {
    let filterResult: Source[] = [...initSources] || []
    if (sourceFilter) {
      filterResult = filterResult.filter((source) =>
        source.title.toLowerCase().includes(sourceFilter.title?.toLowerCase() as string)
      )
    }
    // get current page
    const startIndex = (currentPage - 1) * PAGE_SIZE
    const page = filterResult.slice(startIndex, startIndex + PAGE_SIZE)
    setSources(page)
  }, [initSources, sourceFilter, currentPage])

  useEffect(() => {
    void getSources(user.user.id)
    setIsCorp(!!activeProfile?.entity.class?.corp)
  }, [])

  useEffect(() => {
    let ruleList: Array<string> = []
    // Make sure that rules variable exists
    if (rules && rules.logic) {
      for (const [key, value] of Object.entries(rules.logic.cardNetworks.rule)) {
        if (value) ruleList = [...ruleList, key]
      }
      setCardNetworks(ruleList)
      setGateways(rules.logic.paymentGateways.rule)
      setIsEntityCardManagedByAdmin(!!rules.toggles?.cardManagedByAdmin?.rule)
    }
  }, [rules])

  useEffect(() => {
    isDeleteSourceWindowOpen && resetDrawerHash(history)
  }, [isDeleteSourceWindowOpen])

  const mobileSearchFilter: MobileMenuOption[] = [
    {
      label: intl.formatMessage(filters['filter.search']),
      searchCallback: (value) => handleFilterChange(value),
    },
  ]

  const getMobileActions = (): MobileMenuOption[] => {
    let mobileActionButtons: MobileMenuOption[] = []
    if ((isEntityCardManagedByAdmin && isCardAdmin) || !isEntityCardManagedByAdmin) {
      mobileActionButtons = [
        {
          id: 'placeholder',
          label: '',
          callback: () => null,
          className: 'd-none',
        },
        {
          id: 'add-card-btn',
          label: intl.formatMessage(action['action.card.add']),
          icon: <PlusOutlined />,
          callback: () => setDrawerHash(history, '#drawer-new-source'),
          type: 'primary',
          className: 'bh-track-add-card-button',
        },
      ]
      return mobileActionButtons
    }
    return mobileActionButtons
  }

  const paginationProps = {
    total: sourceFilter ? sources.length : total,
    current: currentPage,
    onChange: setCurrentPage,
  }

  const rowSelection: TableRowSelection<{ id: string }> = {
    onChange: (_: Key[], selectedRows: { id: string }[]) => {
      setSelectedRowsToDelete(selectedRows.map((row) => row.id))
    },
    type: 'checkbox',
  }

  const getCardIdColumn = useGetCardIdColumn()

  const getColumns = () => {
    const baseColumns = sourceColumns(language, intl).filter((c) => cardColumnsKeys.includes(c.key || ''))
    if (isCardIdRuleEnabled) {
      return isCorp ? [...baseColumns, preferredCurrencyColumn, getCardIdColumn()] : [...baseColumns, getCardIdColumn()]
    }
    return isCorp ? [...baseColumns, preferredCurrencyColumn] : baseColumns
  }

  const columns = getColumns()

  return (
    <Page
      title={intl.formatMessage(page['page.cards.title'])}
      mobileMenuOptions={getMobileActions()}
      mobileSearch={mobileSearchFilter}
      actionMenu={actionMenu}
      filter={filter}
      secondaryMenu={secondaryMenu}
      bhTrackClassName="bh-track-cards-page-container"
    >
      <>
        <div className={isMobile ? 'mobile-display' : 'desktop-display'}>
          <Table
            rowSelection={!isMobile && hasDeleteEnabled ? rowSelection : undefined}
            size="small"
            className={`bh-table ${isMobile ? 'mt-10' : ''}`}
            rowClassName="clickable bh-track-edit-card-list-item"
            dataSource={sources}
            data-testid="cards-table"
            loading={showLoader}
            locale={{
              emptyText: emptyMessage,
            }}
            columns={columns}
            showSorterTooltip={false}
            pagination={{ ...paginationProps, position: isMobile ? ['bottomCenter'] : undefined }}
            showHeader={!isMobile}
            rowKey={(record: { id: string }) => `${record.id}`}
            onRow={(record: { id: string }) => {
              return {
                onClick: () => {
                  openDetails(record.id)
                },
              }
            }}
          />
        </div>

        <ActionPage
          title={intl.formatMessage(card['card.slider.label.details'])}
          hash={`#drawer-details?key=${source.id}`}
          pathname={history.location.pathname}
          additionalClass="bh-action-page"
        >
          <CardDetails
            source={source}
            refresh={() => {
              void getSources(user.user.id)
              resetDrawerHash(history)
            }}
          />
          <div className="actions-container">
            <ACL kind="source" barracksId={profileId} action="delete">
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                size="large"
                shape="round"
                block
                danger
                onClick={() => openDelete(source.id)}
                disabled={isEntityCardManagedByAdmin && !isCardAdmin}
              >
                {intl.formatMessage(card['card.form.delete'])}
              </Button>
            </ACL>
          </div>
        </ActionPage>
        <ActionPage
          title={intl.formatMessage(card['card.slider.label.add'])}
          hash="#drawer-new-source"
          pathname={history.location.pathname}
          additionalClass="bh-action-page"
        >
          <CardForm
            cardNetworks={cardNetworks}
            gateways={gateway}
            profileId={profileId}
            refresh={() => {
              void getSources(user.user.id)
              resetDrawerHash(history)
            }}
          />
        </ActionPage>
      </>
    </Page>
  )
}

export default Cards
