import React, { useState, useEffect } from 'react'
import { Dropdown, Button, Checkbox, Badge } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import { FilterFields } from 'types/paymentInstruction'
import { useCurrentPayment } from 'stores/Payment'
import { useTranslation } from 'utils/helpers'
import PaymentsFilter from '../PaymentsFilter/PaymentsFilter'
import './PaymentsHeader.less'

interface PaymentsHeaderProps {
  label: string
  filterFields?: FilterFields[]
  menuOptions?: { label: string; callback: () => void; disabled: boolean }[]
  selectAllPayments?: () => void
  deselectAllPayments?: () => void
  enablePaymentsFilter: boolean
}

const PaymentsHeader = ({
  label,
  filterFields,
  menuOptions,
  selectAllPayments,
  deselectAllPayments,
  enablePaymentsFilter,
}: PaymentsHeaderProps): React.JSX.Element => {
  const t = useTranslation()

  const {
    state: { isAllPaymentsSelectedButtonActive, paymentsFilter, isSelectingPayments },
  } = useCurrentPayment()

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)
  const [numberOfActiveFilters, setNumberOfActiveFilters] = useState<number>(0)

  useEffect(() => {
    if (!paymentsFilter) {
      return
    }

    const activeFilterCount = Object.values(paymentsFilter).filter((value) => value !== undefined && value !== '')

    setNumberOfActiveFilters(activeFilterCount.length)
  }, [paymentsFilter])

  const togglePaymentsSelect = () => {
    isAllPaymentsSelectedButtonActive ? deselectAllPayments?.() : selectAllPayments?.()
  }

  return (
    <div className="review-payments-header">
      <div className="header-select-all-label-container">
        {selectAllPayments && (
          <div className="all-payments-select-options">
            <Checkbox
              checked={isAllPaymentsSelectedButtonActive}
              className={isAllPaymentsSelectedButtonActive ? 'primary' : 'default'}
              onClick={togglePaymentsSelect}
              disabled={isSelectingPayments}
            />
          </div>
        )}
        <div className="header-label">
          <span>{label}</span>
        </div>
      </div>
      <div className="header-menus-container">
        {!!menuOptions?.length && (
          <Dropdown.Button
            className="header-btn-more"
            size="small"
            menu={{
              items: menuOptions.map((option, index) => ({
                key: `menu-option-${index}`,
                label: option.label,
                disabled: option.disabled,
                onClick: option.callback,
              })),
            }}
            trigger={['click']}
          />
        )}
        {filterFields?.length && enablePaymentsFilter && (
          <Dropdown
            open={dropdownVisible}
            onOpenChange={(visible: boolean) => setDropdownVisible(visible)}
            menu={{
              items: [
                {
                  key: 'filter',
                  className: 'filter-payment-dropdown',
                  label: (
                    <PaymentsFilter
                      filterFields={filterFields}
                      filter={paymentsFilter}
                      setDropdownVisible={setDropdownVisible}
                    />
                  ),
                },
              ],
            }}
            trigger={['click']}
          >
            <Badge count={numberOfActiveFilters} size="small" offset={[0, 5]}>
              <Button className="toolbar-btn" onClick={() => setDropdownVisible(!dropdownVisible)}>
                {t('pi.payments.filter.btn')}
                <FilterOutlined />
              </Button>
            </Badge>
          </Dropdown>
        )}
      </div>
    </div>
  )
}

export default PaymentsHeader
