import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/logo/billhop-white.svg'
import { Link, useHistory } from 'react-router-dom'
import logoMobile from '../../assets/logo/mobile-logo.svg'
import { LogoutOutlined, DownOutlined } from '@ant-design/icons'
import { User } from '../../types/user'
import { Avatar, Divider, Dropdown, Space } from 'antd'
import { Logout } from '../Logout/Logout'
import { compareEntityNames, getEntityName } from '../../utils/helpers'
import { getSessionInfo, validateProfile } from '../../api/auth'
import { find } from 'lodash'
import { Profile } from '../../types/profile'
import { saveSessionId } from '../../utils/storage'
import { getInitials, stringToColor } from '../BHAvatar/utils'
import { DEVICE_TYPE, getDeviceType, Size } from '../../utils/getDeviceType'
import { useWindowSize } from '../../hooks/useWindowSize'
import './HeaderContent.less'

export interface HeaderProps {
  user: User
  setUser: (user: User) => void
  setLoader: React.Dispatch<React.SetStateAction<boolean>>
  onLogin?: () => void
  onLogout?: () => void
  onCreateAccount?: () => void
}

export const HeaderContent: React.FC<HeaderProps> = ({ user, setUser, setLoader }: HeaderProps) => {
  const history = useHistory()
  const size: Size = useWindowSize()

  const [currentUser, setCurrentUser] = useState<Profile>()
  const [userInfo, setUserInfo] = useState<string>('')
  const [entityClass, setEntityClass] = useState('')

  const profilesRef = useRef(user?.profiles?.sort(compareEntityNames) || [])
  const profiles = profilesRef.current

  const initials = getInitials(userInfo)
  const color = stringToColor(userInfo)

  useEffect(() => {
    const getCurrentSession = async () => {
      if (user?.sessionId) {
        const currentSession = await getSessionInfo()
        const currentProfile = find(user?.profiles, {
          id: currentSession.profileId,
        })

        setCurrentUser(currentProfile)
        setEntityClass(Object.keys(currentProfile?.entity?.class || {})[0])

        if (currentProfile?.user?.name)
          setUserInfo([currentProfile?.user?.name.first, currentProfile?.user?.name.last].join(' '))
      }
    }
    void getCurrentSession()
  }, [user])

  const handleChangeProfile = async (profileId: string): Promise<void> => {
    setLoader(true)
    try {
      const data = await validateProfile(profileId)
      saveSessionId(data?.sessionId)

      if (user?.sessionId) {
        const currentSession = await getSessionInfo()
        setCurrentUser(find(user?.profiles, { id: currentSession.profileId }))
        const newUser = {
          ...user,
          activeProfileId: currentSession.profileId,
        }
        setUser(newUser)
        history.push('/')
      }
      window.scrollTo(0, 0)
    } catch (error) {
      console.log('Fetching error', error)
    } finally {
      setTimeout(() => {
        setLoader(false)
      }, 1000)
    }
  }

  const menu =
    profiles.length > 1 && entityClass !== 'person'
      ? profiles.map((profile: Profile) => {
          const entityTitle = getEntityName(profile)
          return {
            label: entityTitle,
            key: profile.id.toString(), // Convert to string if it's a number
            className: 'submenu-item ' + (profile.id === currentUser?.id ? 'active' : ''),
            onClick: () => handleChangeProfile(profile.id),
          }
        })
      : []

  const logo = getDeviceType(size) === DEVICE_TYPE.MOBILE ? logoMobile : Logo

  return (
    <div className="header-content-wrapper">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className={'menu-container'}>
        <Dropdown
          menu={{ items: menu }}
          trigger={['click']}
          placement="bottomLeft"
          dropdownRender={(menu) => (
            <div className="dropdown-content">
              {menu}
              <Divider style={{ margin: 0 }} />
              <Logout customClass="logout-item" icon={<LogoutOutlined className="user-icon" />} />
            </div>
          )}
        >
          <Space>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()} data-testid="dropdown-link">
              {currentUser?.entity?.class?.corp || currentUser?.entity?.class?.sme ? (
                <span className="company-title" data-testid="entity-title">
                  {currentUser?.entity?.class?.corp?.title || currentUser?.entity?.class?.sme?.title}
                </span>
              ) : (
                <span className="user-title">{userInfo}</span>
              )}
              <DownOutlined />
            </a>
          </Space>
        </Dropdown>
        <div className={`user-info ${entityClass === 'sme' ? 'user-info-sme' : ''}`}>
          {entityClass === 'corp' && getDeviceType(size) !== DEVICE_TYPE.MOBILE && (
            <Avatar
              style={{
                backgroundColor: color,
                margin: '8px',
              }}
            >
              {initials}
            </Avatar>
          )}
          {entityClass !== 'person' && <span>{userInfo}</span>}
        </div>
      </div>
    </div>
  )
}
