import React, { useState, useEffect, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Alert, message } from 'antd'
import OtpInput from 'react-otp-input'
import * as Sentry from '@sentry/react'
import { sendNewOtp, validateOtp } from 'api/auth'
import { getProfileId } from 'api/pIMethods'
import queryString from 'query-string'
import {
  saveDeviceToken,
  saveAuthenticationStatus,
  removeSessionId,
  saveSessionId,
  removeIsProfileChosen,
  getIsProfileChosen,
} from 'utils/storage'
import Loader from 'components/Loader/Loader'
import { useIntl } from 'react-intl'
import { messages, page } from 'lang/definitions/index'
import { getError } from 'utils/error'
import './OTPInput.less'
import { useSession } from 'stores/session'

export interface Props {
  token: string // tfa Token
  email?: string | null
  phone?: string | null
  sessionId: string
  method: string
  onSuccess?: () => void
  takeMeBackUrl?: string
}

const OTPInput = (props: Props): ReactElement => {
  const intl = useIntl()
  const numInputs = 6 // hardCoded, should this be a prop?
  const history = useHistory()
  const isProfileChosen = getIsProfileChosen()
  const {
    actions: { setUser, setSessionId },
  } = useSession()

  const [showLoader, setShowLoader] = useState(false)
  const [otpState, setOtpState] = useState('')
  const [error, setError] = useState<Error | undefined>(undefined)
  const [validationAttempts, setValidationAttempts] = useState(1)
  const { token, email, phone, method, sessionId, onSuccess, takeMeBackUrl } = props

  useEffect(() => {
    // if the input is 6 digits, try to validate
    if (otpState.length === numInputs) {
      void validateOTPInput()
    }
  }, [otpState])

  const validateOTPInput = async () => {
    setShowLoader(true)
    try {
      const response = await validateOtp(token, otpState)
      saveDeviceToken(response.deviceToken as string)
      saveSessionId(sessionId)
      setSessionId(sessionId)
      saveAuthenticationStatus()
      const user = await getProfileId()
      setUser(user)

      // if the prop onSuccess exists, run it (used in OTPSetup.tsx)
      if (onSuccess) void onSuccess()

      if (!takeMeBackUrl) {
        const params = queryString.parse(history.location.search)
        const url = (params.previousLocation || '/') as string
        if (!isProfileChosen) history.replace('/choose-profile')
        else history.replace(url)
      }
    } catch (error) {
      console.log('Error in OTP', { error })
      removeSessionId()
      removeIsProfileChosen()
      if (validationAttempts > 9) setError(getError(error))
      else {
        setValidationAttempts(validationAttempts + 1)
        wrongInputMessage()
      }
    } finally {
      setShowLoader(false)
    }
  }

  const successMessage = (type: string) => {
    if (type === 'sms' || type === 'mail')
      void message.success(intl.formatMessage(messages['messages.success.otp.sms']))
    if (type === 'voice') void message.success(intl.formatMessage(messages['messages.success.otp.voice']))
  }

  const wrongInputMessage = () => {
    void message.error(intl.formatMessage(messages['messages.error.otp.incorrect']))
  }

  const showErrorMessage = () => {
    void message.error(intl.formatMessage(messages['messages.error.otp.send']))
  }

  const getNewCode = async () => {
    setOtpState('')

    try {
      await sendNewOtp(undefined, '', method, undefined, token, sessionId)
      setError(undefined)
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const sendAnotherCode = async () => {
    try {
      setOtpState('')
      await sendNewOtp(undefined, '', method, undefined, token, sessionId)
      successMessage(method)
    } catch (error) {
      showErrorMessage()
      setError(getError(error))
    }
  }

  const otpLinkCall = async () => {
    try {
      setOtpState('')
      await sendNewOtp(undefined, '', 'voice', undefined, token, sessionId)
      successMessage('voice')
    } catch (error) {
      showErrorMessage()
      setError(getError(error))
    }
  }

  return (
    <React.Fragment>
      <Loader showLoader={showLoader}>
        {token && (
          <div className="otp-container">
            {error ? (
              <>
                <div className="otp-error-container">
                  <Alert message={intl.formatMessage(messages['messages.error.otp.incorrect'])} type="error" showIcon />
                  <Button className="button-new-code" block type="primary" onClick={() => void getNewCode()}>
                    Get new code
                  </Button>
                </div>
                <div className="otp-footer">
                  <div className="take-me-back">
                    <a href="/login">
                      <span>&#8592; {intl.formatMessage(page['page.otp.link.back'])}</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4 className="otp-heading">{intl.formatMessage(page['page.otp.title'])}</h4>
                <OtpInput
                  value={otpState}
                  onChange={(e: string) => setOtpState(e)}
                  numInputs={numInputs}
                  isInputNum={true}
                  separator={<span> </span>}
                  inputStyle="inputStyle"
                />
                <h4 className="otp-heading">
                  {intl.formatMessage(page['page.otp.recipient.message'], {
                    method: `${method}`,
                    endPoint: method === 'mail' ? `${email as string}` : `${phone as string}`,
                  })}
                </h4>
                <div className="otp-footer">
                  <span>{intl.formatMessage(page['page.otp.text.noCode'])}</span>
                  <div className="send-another-code">
                    <a onClick={() => void sendAnotherCode()}>
                      <span>{intl.formatMessage(page['page.otp.link.sendAnother'])}</span>
                    </a>{' '}
                    or{' '}
                    <a onClick={() => void otpLinkCall()}>
                      <span>{intl.formatMessage(page['page.otp.link.call'])}</span>
                    </a>
                  </div>
                  <div className="take-me-back">
                    <a href={`${takeMeBackUrl ? takeMeBackUrl : '/login'}`}>
                      <span>&#8592; {intl.formatMessage(page['page.otp.link.back'])}</span>
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Loader>
    </React.Fragment>
  )
}
export default OTPInput
