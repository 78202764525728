import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Form, Input, Button, Result } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import ExternalContainer from 'components/ExternalContainer/ExternalContainer'
import Loader from 'components/Loader/Loader'
import { validatePasswordToken } from 'api/auth'
import { Redirect } from 'react-router-dom'
import { api } from 'api/utils'
import PasswordPolicy from 'components/PasswordPolicy/PasswordPolicy'
import { PasswordPolicy as PasswordPolicyType, PasswordPolicyRule } from 'types/rules'
import { SplitContainer } from 'components/SplitContainer/SplitContainer'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'utils/helpers'
import { notifyError } from 'utils/error'
import './ResetPassword.less'

interface ParamTypes {
  token: string
}

interface MatchingPasswords {
  newPassword: string
  confirmNewPassword: string
}

interface ErrorResponse {
  message: string
  details?: {
    errors?: Record<string, string[]>
  }
}

const ResetPassword: React.FC = () => {
  const t = useTranslation()
  const { token } = useParams<ParamTypes>()
  const [form] = Form.useForm()
  const [showLoader, setShowLoader] = useState(false)
  const [isTokenValid, setIsTokenValid] = useState<boolean>()
  const [userId, setUserId] = useState<string>('')
  const [passwordPolicyRule, setPasswordPolicyRule] = useState<PasswordPolicyRule>()
  const [error, setError] = useState<string[] | undefined>()
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState<boolean>(false)

  const getPasswordPolicyRule = async () => {
    setShowLoader(true)

    try {
      const url = `/external/rules/user/${userId}/rule/passwordPolicy`
      const response = await api.getWithToken(url, token)
      const policy = (await response.json()) as PasswordPolicyType

      setPasswordPolicyRule(policy.rule)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const checkIsTokenValid = async () => {
    setShowLoader(true)

    try {
      const { userId } = await validatePasswordToken(token)
      setUserId(userId)
      setIsTokenValid(true)
    } catch (error) {
      Sentry.captureException(error)
      notifyError(t('error.invalid.token'))
      setIsTokenValid(false)
    } finally {
      setShowLoader(false)
    }
  }

  const handleRequestUpdatePassword = async ({ newPassword, confirmNewPassword }: MatchingPasswords) => {
    if (newPassword !== confirmNewPassword) {
      form.setFields([
        {
          name: 'confirmNewPassword',
          errors: [t('password.reset.form.error.dontMatch.passwords')],
        },
      ])
      return
    }

    try {
      const payload = {
        new: {
          password: window.btoa(newPassword),
        },
      }

      const response = await api.putWithToken(`/external/zuul/password/${userId}`, token, payload)

      if (!response.ok) {
        const errorResponse = (await response.json()) as ErrorResponse

        if (errorResponse.details?.errors && typeof errorResponse.details.errors === 'object') {
          const errors = Object.keys(errorResponse.details.errors)
          setError(errors)
        } else {
          notifyError(t('error.default'))
        }
      }

      if (response.status === 204) {
        setPasswordChangeSuccess(true)
      }
    } catch (err) {
      Sentry.captureException(err)
      notifyError(t('error.default'))
    }
  }

  useEffect(() => {
    if (token) {
      void checkIsTokenValid()
    }
  }, [])

  useEffect(() => {
    if (userId) {
      void getPasswordPolicyRule()
    }
  }, [userId])

  return (
    <Loader showLoader={showLoader}>
      <SplitContainer>
        {passwordChangeSuccess ? (
          <ExternalContainer data-testid="reset-password-success">
            <Result
              style={{ padding: '16px 0px' }}
              icon={<CheckOutlined />}
              title={t('password.reset.success.title')}
              extra={
                <Button type="primary" key="console" href="/login">
                  {t('password.reset.form.backButton')}
                </Button>
              }
            />
          </ExternalContainer>
        ) : (
          <ExternalContainer title={t('password.reset.title')}>
            <div>
              {passwordPolicyRule && <PasswordPolicy passwordPolicy={passwordPolicyRule} error={error} />}
              {isTokenValid === true && (
                <Form
                  form={form}
                  id="resetPassword-submit-form"
                  layout="vertical"
                  name="basic"
                  requiredMark={false}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onFinish={handleRequestUpdatePassword}
                  data-testid="reset-password-form"
                >
                  <Form.Item
                    label={t('password.reset.form.newPassword.label')}
                    name="newPassword"
                    validateTrigger={false}
                    rules={[
                      {
                        required: true,
                        message: t('password.reset.form.error.finding.password'),
                      },
                    ]}
                    className="bh-input"
                  >
                    <Input.Password size="large" autoFocus className="bh-input" />
                  </Form.Item>

                  <Form.Item
                    label={t('password.reset.form.confirmPassword.label')}
                    name="confirmNewPassword"
                    validateTrigger={false}
                    rules={[
                      {
                        message: t('password.reset.form.error.finding.confirmPassword'),
                        required: true,
                      },
                    ]}
                  >
                    <Input.Password size="large" autoFocus className="bh-input" />
                  </Form.Item>

                  <Button type="primary" htmlType="submit" block className="login-submit">
                    {t('password.reset.form.submit.button')}
                  </Button>
                </Form>
              )}
              {isTokenValid === false && <Redirect to="/login" />}
            </div>
          </ExternalContainer>
        )}
      </SplitContainer>
    </Loader>
  )
}
export default ResetPassword
