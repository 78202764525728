import React from 'react'
import { Form, Input } from 'antd'
import './PaymentForm.less'
import { useTranslation } from 'utils/helpers'
import { useCurrentPayment } from 'stores/Payment'

interface CurrencySelectorFormItemProps {
  currencies?: string[]
}
const CurrencySelectorFormItem: React.FC<CurrencySelectorFormItemProps> = () => {
  const t = useTranslation()

  const {
    state: { selectedBeneficiary: beneficiary },
  } = useCurrentPayment()

  return (
    <Form.Item
      className="currency"
      label={t('pi.add.addPayments.form.currency.label')}
      name="currency"
      rules={[
        {
          required: true,
          message: t('pi.add.addPayments.form.currency.errorMessage.required'),
        },
      ]}
    >
      <Input
        className="bh-input uppercase"
        disabled={!beneficiary || !!beneficiary?.currency}
        data-testid="currency-input"
        placeholder="-"
        value={beneficiary?.currency ? beneficiary.currency.toUpperCase() : ''}
      />
    </Form.Item>
  )
}

export default CurrencySelectorFormItem
