import React, { useEffect, useState } from 'react'
import * as api from 'api/reports'
import { useIntl } from 'react-intl'
import moment from 'moment'
import './Reports.less'
import { reports } from 'lang/definitions/reports'
import { messages } from 'lang/definitions'
import Page from 'components/Page/Page'
import { Affix, Button, Col, Form, notification, RadioChangeEvent, Row, Radio, Space } from 'antd'
import { getEntitySourcesOnFile } from 'api/card'
import ReportCard from './ReportCard'
import { useUtils } from 'hooks/useUtils'
import {
  DateRangeObject,
  OptionData,
  CheckedProps,
  Report,
  REPORT_ITEMS as RI,
  DATE_OPTION_TYPE as DT,
  REPORT_FORMAT_OPTION_TYPE as RF,
  DELIVERY_OPTIONS,
  DATE_OPTIONS,
  FORMAT_TYPE,
  ENTITY_OPTIONS,
  CheckedAllProps,
  ReportKeys,
  PdfEmailRequest,
  generateEntityTree,
  getEntityTreeIds,
  transformData,
  REPORT_FORMAT_OPTION_TYPE,
} from './utils'
import { find, uniq } from 'lodash'
import { getEntityProfile, getUsers } from 'api/barracks'
import { getBeneficiariesEntity } from 'api/beneficiary'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import CheckBoxFields from './CheckBoxFields'
import DateRangeSelect from './DateRangeSelect'
import { getActiveUser } from 'utils/helpers'
import { DataNode } from 'rc-tree/lib/interface'
import cardIcon from 'assets/card.svg'
import currencyIcon from 'assets/currency.svg'
import paymentStatusIcon from 'assets/payment-status.svg'
import dateRangeIcon from 'assets/date-range.svg'
import entityIcon from 'assets/organisation-details.svg'
import RadioGroupFields from './RadioGroupFields'
import Loader from 'components/Loader/Loader'
import SelectTreeGroup from './SelectTreeGroup'
import emailIcon from 'assets/email.svg'
import { User } from 'types/user'
import { v4 as uuidv4 } from 'uuid'
import { resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import { formatLocaleDateString } from 'utils/dates'
import { useHistory } from 'react-router-dom'
import { PdfRequest } from 'types/angelia'
import { createReportTemplate } from 'api/reportTemplate'
import ReportSummary from './ReportSummary'
import { QueryParams } from 'types/general'
import EmailTags from './EmailTags'
import DeliveryTypeSelect from './DeliveryTypeSelect'
import { ReportTemplate } from 'types/report'
import TemplateForm from './Templates/TemplateForm'
import { createPDFReport, emailPDFReport } from 'api/angelia'
import { ClearingNetworkRule } from 'types/beneficiary'
import * as Sentry from '@sentry/react'
import { useSession } from 'stores/session'

interface Props {
  location: Location
  template?: ReportTemplate
  getReportTemplates: (profileId: string, params?: QueryParams) => void
}

const initialEntities = {
  id: '',
  dateCreated: '',
  countryCode: '',
  dateUpdated: '',
  parentEntityId: '',
  slug: '',
  state: '',
}

export interface ReportFields {
  value: string
  label: string
  disabled: boolean
}

const Reports = ({ location, template, getReportTemplates }: Props): React.JSX.Element => {
  const {
    state: { user, rules },
  } = useSession()
  const intl = useIntl()
  const history = useHistory()
  const [form] = Form.useForm()

  const { paymentStatusV2, dateOptions, getReportFieldLabel } = useUtils()

  const ADMIN_ROUTE = '/app/admin/reports'

  const [currencyOptions, setCurrencies] = useState<OptionData[]>([])
  const [clearingNetworksOptions, setClearingNetworks] = useState<OptionData[]>([])
  const [cardNetworkOptions, setCardNetworks] = useState<OptionData[]>([])
  const [reportFields, setReportFields] = useState<OptionData[]>([])
  const [submitLoader, setSubmitLoader] = useState<boolean>(false)
  const [fetchingLoader, setFetchingLoader] = useState<boolean>(false)
  const [updatingLoader, setUpdatingLoader] = useState<boolean>(false)
  const [isFetchingFinished, setFetchingFinished] = useState<boolean>(false)
  const [sourceOptions, setSources] = useState<OptionData[]>([])
  const [paymentStatus, setPaymentStatus] = useState<OptionData[]>([])
  const [checkedValues, setCheckedValues] = useState<CheckedProps>({})
  const [selectedDateOption, setDateOption] = useState<DATE_OPTIONS>(DATE_OPTIONS.LAST_30_DAYS)
  const [deliveryType, setDeliveryType] = useState<DELIVERY_OPTIONS>(DELIVERY_OPTIONS.DOWNLOAD)
  const [entitySelectionType, setEntitySelectionType] = useState<string>(ENTITY_OPTIONS.ALL)
  const [beneficiarySelectionType, setBeneficiarySelectionType] = useState<ENTITY_OPTIONS>(ENTITY_OPTIONS.ALL)
  const [entityIDs, setEntityIDs] = useState<string[]>([])
  const [beneficiaryIDs, setBeneficiaryIDs] = useState<string[]>([])
  const [checkedAllFields, setCheckedAllFields] = useState<CheckedAllProps>({})
  const [paymentsCount, setPaymentsCount] = useState<number>(0)
  const [entityTreeData, setTreeData] = useState<DataNode[]>([])
  const [beneficiariesTree, setBeneficiariesTree] = useState<DataNode[]>([])
  const [emails, setEmails] = useState<string[]>([])
  const [currentProfile, setProfile] = useState<User>()
  const [dateRange, setDateRange] = useState<DateRangeObject>({
    from: moment().subtract(1, 'M').toISOString(),
    to: moment().toISOString(),
  })
  const [reportType, setReportType] = useState<FORMAT_TYPE>(FORMAT_TYPE.EXCEL)

  const [includeExtraInfo, setIncludeExtraInfo] = useState<OptionData[]>([])
  const [nameOnReport, setNameOnReport] = useState<OptionData[]>([])
  const [locale, setLocale] = useState<string>('en-GB')
  const [isLegacyReport, setIsLegacyReport] = useState<boolean>(false)

  // used for checkbox
  const [isAvailableToAllEntityIds, setIsAvailableToAllEntityIds] = useState<boolean>(false)

  const datePaidOptions: OptionData[] = [
    {
      label: intl.formatMessage(reports['reports.date.due']),
      value: DT.DATE_DUE,
    },
    {
      label: intl.formatMessage(reports['reports.date.user.paid']),
      value: DT.DATE_USER_PAID,
    },
  ]

  const reportTypeOptions: OptionData[] = [
    {
      value: FORMAT_TYPE.ID_LIST,
      label: intl.formatMessage(reports['reports.pdf.report']),
    },
    {
      value: FORMAT_TYPE.EXCEL,
      label: intl.formatMessage(reports['reports.excel.report']),
    },
  ]

  const reportFormatOptions: OptionData[] = [
    {
      label: intl.formatMessage(reports['reports.receipts.single']),
      value: RF.SINGLE_PI,
    },
    {
      label: intl.formatMessage(reports['reports.transactions.multiple']),
      value: RF.TABLE_OF_PIS,
    },
  ]

  const extraInfoOptions: OptionData[] = [
    {
      value: 'includeBillhopFee',
      label: intl.formatMessage(reports['reports.report.include.bh.fee']),
    },
    {
      value: 'includePaymentCardInformation',
      label: intl.formatMessage(reports['reports.report.include.card.info']),
    },
  ]

  const nameOnReportOptions: OptionData[] = [
    {
      value: 'personsName',
      label: intl.formatMessage(reports['reports.report.person.name']),
    },
    {
      value: 'companyName',
      label: intl.formatMessage(reports['reports.report.company.name']),
    },
  ]

  useEffect(() => {
    user && void fetchData()
  }, [user])

  useEffect(() => {
    if (template !== undefined) {
      updateReportFormBasedOnTemplate(template)
      notification.success({
        message: intl.formatMessage(messages['messages.success.template.selected']),
        placement: 'topRight',
      })
    }
  }, [template])

  useEffect(() => {
    if (beneficiarySelectionType === ENTITY_OPTIONS.SELECT) {
      const entityId =
        find(currentProfile?.profiles, {
          id: currentProfile?.activeProfileId,
        })?.entityId || ''
      void fetchBeneficiaries(entityId)
    }
  }, [beneficiarySelectionType])

  useEffect(() => {
    if (location?.pathname === ADMIN_ROUTE && currentProfile) {
      void fetchEntities(currentProfile.user.id)
    }
  }, [currentProfile])

  useEffect(() => {
    setUpdatingLoader(true)
    if (!isEmptyState() && isFetchingFinished) {
      const payload: Report = generatePayload(FORMAT_TYPE.ID_LIST)

      void countSelectedPayments(payload)
    } else {
      setPaymentsCount(0)
      setUpdatingLoader(false)
    }
  }, [checkedValues, entityIDs, beneficiaryIDs, dateRange, isFetchingFinished])

  const countSelectedPayments = async (payload: Report): Promise<void> => {
    try {
      const response = await api.createReport(payload)
      const piIds = response?.paymentInstructionIds
      setPaymentsCount(piIds?.length ?? 0)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setUpdatingLoader(false)
    }
  }

  const fetchEntities = async (userId: string): Promise<void> => {
    const allEntities = (await getUsers(userId)) || []

    const treeData = generateEntityTree(allEntities)
    setTreeData(treeData)

    const allEntityIds = getEntityTreeIds(treeData)
    setEntityIDs(allEntityIds)
  }

  const fetchBeneficiaries = async (entityId: string): Promise<void> => {
    const beneficiaries = (await getBeneficiariesEntity(entityId, { limit: 10000, skip: 0 })) || []

    const beneficiariesTree: DataNode[] = beneficiaries.map((item) => {
      return {
        value: item.id as string,
        key: item.id as string,
        title: item.title,
        children: [],
      }
    })

    setBeneficiariesTree(beneficiariesTree)
  }

  const fetchData = async (): Promise<void> => {
    setFetchingLoader(true)
    setFetchingFinished(false)
    setCheckedAllFields((oldState) => ({
      ...oldState,
      [RI.CURRENCY]: true,
      [RI.CLEARING_NETWORKS]: true,
      [RI.BENEFICIARY]: true,
      [RI.ENTITY]: location?.pathname === ADMIN_ROUTE ? true : false,
      [RI.CARD_NETWORKS]: true,
      [RI.SOURCES]: true,
      [RI.REPORT_FIELDS]: true,
      [RI.STATES]: true,
      [RI.DATE_USER_PAID]: true,
      [RI.EXTRA_INFO]: true,
      [RI.NAME_ON_REPORT]: true,
      [RI.REPORT_FORMAT]: true,
    }))
    const ruleCurrencies = rules?.logic.currencies?.rule || {}
    const currencies = Object.keys(ruleCurrencies)
    const locale = rules?.logic.languagePreferences?.rule || 'en-GB'

    const ruleClearingNetworks: ClearingNetworkRule[] = rules?.logic.clearingNetworks.rule || []

    const clearingNetworks = ruleClearingNetworks.map((network) => network.clearingNetwork)

    const ruleCardNetworks = rules?.logic.cardNetworks?.rule || {}
    const cardNetworks = Object.keys(ruleCardNetworks)

    setLocale(locale)
    setProfile(user)
    const profileId =
      find(user?.profiles, {
        id: user?.activeProfileId,
      })?.id || ''

    const entityId =
      find(user?.profiles, {
        id: user?.activeProfileId,
      })?.entityId || ''

    const emailsCopy = [...emails]
    user && emailsCopy.push(user.user.email)
    setEmails(uniq(emailsCopy))

    const [currentEntityResponse, sourceResponse] = await Promise.all([
      getEntityProfile(profileId),
      getEntitySourcesOnFile(entityId),
    ])

    const currentEntity = currentEntityResponse || initialEntities
    const sources = sourceResponse || []

    if (location?.pathname !== ADMIN_ROUTE) {
      setEntityIDs([currentEntity.id])
    }

    const sourceCopy = sources.map((item) => {
      return {
        value: item.id,
        label: item.title,
        disabled: true,
      }
    })
    let reportFields: ReportKeys[] = []

    try {
      reportFields = await api.getKeys()
    } catch (error) {
      Sentry.captureException(error)
    }

    const fields: ReportFields[] = reportFields.map((item: ReportKeys) => ({
      value: item.key,
      label: getReportFieldLabel(item.key),
      disabled: true,
    }))

    const statuses = paymentStatusV2.map((field) => {
      return {
        value: field.value,
        label: field.label,
        disabled: true,
      }
    })

    const extraInfos = extraInfoOptions.map((field) => {
      return {
        value: field.value,
        label: field.label,
        disabled: true,
      }
    })

    const nameOnReport = nameOnReportOptions.map((field) => {
      return {
        value: field.value,
        label: field.label,
        disabled: true,
      }
    })

    setIncludeExtraInfo(extraInfos)
    setNameOnReport(nameOnReport)
    setCurrencies(transformData(currencies))
    setClearingNetworks(transformData(clearingNetworks))
    setCardNetworks(transformData(cardNetworks))
    setSources(sourceCopy)
    setPaymentStatus(statuses)
    setReportFields(fields)

    setCheckedValues((oldState) => ({
      ...oldState,
      [RI.CURRENCY]: [...transformData(currencies).map((item) => item.value)],
      [RI.CLEARING_NETWORKS]: [...transformData(clearingNetworks).map((item) => item.value)],
      [RI.CARD_NETWORKS]: [...transformData(cardNetworks).map((item) => item.value)],
      [RI.SOURCES]: [...sourceCopy.map((item) => item.value)],
      [RI.REPORT_FIELDS]: [...fields.map((item: ReportFields) => item.value)],
      [RI.STATES]: [...statuses.map((item) => item.value)],
      [RI.DATE_USER_PAID]: [datePaidOptions[0].value],
      [RI.EXTRA_INFO]: [...extraInfos.map((item) => item.value)],
      [RI.NAME_ON_REPORT]: [...nameOnReport.map((item) => item.value)],
      [RI.REPORT_FORMAT]: [reportFormatOptions[0].value],
    }))
    setFetchingLoader(false)
    setFetchingFinished(true)
  }

  const handleCheckboxChange = (checkedValues: Array<CheckboxValueType>, type: string): void => {
    setCheckedValues((oldState) => ({
      ...oldState,
      [type]: [...checkedValues],
    }))
  }

  const handleRadioChange = (event: RadioChangeEvent, type: string): void => {
    setCheckedValues((oldState) => ({
      ...oldState,
      [type]: [event.target.value],
    }))
  }

  const handleDateChange = (date: string, isFrom: boolean): void => {
    const dateRangeCopy = { ...dateRange }
    isFrom ? (dateRangeCopy.from = date) : (dateRangeCopy.to = date)
    setDateRange(dateRangeCopy)
  }

  /**
   * A Function that checks the correct radiobutton in the Reports form based on the from/to date on the template.
   * @param from string date YYYY-MM-DD
   * @param to string date YYYY-MM-DD
   */
  const selectDateRangeFromTemplate = (from: string, to: string) => {
    const secondMonth = {
      from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    }

    const thirdMonth = {
      from: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
      to: moment().subtract(2, 'months').endOf('month').format('YYYY-MM-DD'),
    }

    const thisYear = {
      from: moment().subtract(0, 'years').startOf('year').format('YYYY-MM-DD'),
      to: moment().subtract(0, 'years').endOf('year').format('YYYY-MM-DD'),
    }

    const lastThirtyDays = {
      from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    }

    if (from === lastThirtyDays.from && to === lastThirtyDays.to) {
      setDateOption(DATE_OPTIONS.LAST_30_DAYS)
    } else if (from === secondMonth.from && to === secondMonth.to) {
      setDateOption(DATE_OPTIONS.SECOND_MONTH)
    } else if (from === thirdMonth.from && to === thirdMonth.to) {
      setDateOption(DATE_OPTIONS.THIRD_MONTH)
    } else if (from === thisYear.from && to === thisYear.to) {
      setDateOption(DATE_OPTIONS.THIS_YEAR)
    } else {
      setDateOption(DATE_OPTIONS.SPECIFIC_DATE)
      setDateRange({ from, to })
    }
  }

  const handleSelectDateOption = (event: RadioChangeEvent): void => {
    const type = event.target.value as DATE_OPTIONS
    setDateOption(type)
    switch (type) {
      case DATE_OPTIONS.LAST_30_DAYS:
        setDateRange((oldState) => ({
          ...oldState,
          from: moment().subtract(1, 'M').toISOString(),
          to: moment().toISOString(),
        }))
        break
      case DATE_OPTIONS.SECOND_MONTH:
        setDateRange((oldState) => ({
          ...oldState,
          from: moment().subtract(1, 'months').startOf('month').toISOString(),
          to: moment().subtract(1, 'months').endOf('month').toISOString(),
        }))
        break
      case DATE_OPTIONS.THIRD_MONTH:
        setDateRange((oldState) => ({
          ...oldState,
          from: moment().subtract(2, 'months').startOf('month').toISOString(),
          to: moment().subtract(2, 'months').endOf('month').toISOString(),
        }))
        break
      case DATE_OPTIONS.THIS_YEAR:
        setDateRange((oldState) => ({
          ...oldState,
          from: moment().subtract(0, 'years').startOf('year').toISOString(),
          to: moment().subtract(0, 'years').endOf('year').toISOString(),
        }))
        break
      case DATE_OPTIONS.SPECIFIC_DATE:
        setDateRange((oldState) => ({
          ...oldState,
          from: moment().subtract(1, 'M').toISOString(),
          to: moment().toISOString(),
        }))
        break
      default:
        setDateRange((oldState) => ({
          ...oldState,
          from: null,
          to: null,
        }))
    }
  }

  const handleChangeDeliveryType = (event: RadioChangeEvent): void => {
    setDeliveryType(event.target.value as DELIVERY_OPTIONS)
  }

  const handleEntityChange = (values: string[]): void => {
    setEntityIDs(values)
  }

  const handleBeneficiaryChange = (values: string[]): void => {
    setBeneficiaryIDs(values)
  }

  const handleEntityType = (value: ENTITY_OPTIONS): void => {
    setEntitySelectionType(value)
    if (value === ENTITY_OPTIONS.ALL) {
      const allIds = getEntityTreeIds(entityTreeData)
      setEntityIDs(allIds)
      handleSelectAll(true, RI.ENTITY)
    } else {
      handleSelectAll(false, RI.ENTITY)
    }
  }

  const handleBeneficiaryType = (value: ENTITY_OPTIONS): void => {
    setBeneficiarySelectionType(value)
    if (value === ENTITY_OPTIONS.ALL) {
      setBeneficiaryIDs([])
      handleSelectAll(true, RI.BENEFICIARY)
    } else {
      handleSelectAll(false, RI.BENEFICIARY)
    }
  }

  const handleRemove = (value: string, type: RI): void => {
    if (type === RI.DATE_RANGE) {
      setDateRange({ from: null, to: null })
      setDateOption(DATE_OPTIONS.LAST_30_DAYS)
    } else if (type === RI.ENTITY) {
      const entityIDsCopy = [...entityIDs]
      const index = entityIDsCopy.indexOf(value)
      if (index !== -1) {
        entityIDsCopy.splice(index, 1)
      }
      setEntityIDs(entityIDsCopy)
    } else if (type === RI.BENEFICIARY) {
      const idsCopy = [...beneficiaryIDs]
      const index = idsCopy.indexOf(value)
      if (index !== -1) {
        idsCopy.splice(index, 1)
      }
      setBeneficiaryIDs(idsCopy)
    } else {
      const checkedValuesCopy = { ...checkedValues }
      if (checkedValuesCopy[type]) {
        const index = checkedValuesCopy[type].indexOf(value)
        if (index !== -1) {
          checkedValuesCopy[type].splice(index, 1)
        }
      }
      setCheckedValues(checkedValuesCopy)
    }
  }

  const handleResetTree = (type: ENTITY_OPTIONS | RI): void => {
    if (type === RI.ENTITY) {
      setEntityIDs([])
      handleEntityType(ENTITY_OPTIONS.SELECT)
    } else if (type === RI.BENEFICIARY) {
      setBeneficiaryIDs([])
      handleBeneficiaryType(ENTITY_OPTIONS.SELECT)
    }
  }

  const handleDownloadPdf = async (report: Report) => {
    try {
      const requestBody: PdfRequest = {
        type: checkedValues.reportFormat[0] as string,
        paymentInstructionIds: report.paymentInstructionIds || [],
        locale,
        opts: {
          fee: !!checkedValues.extraInfo.includes('includeBillhopFee'),
          source: !!checkedValues.extraInfo.includes('includePaymentCardInformation'),
          sme: !!checkedValues.nameOnReport.includes('companyName'),
          corp: !!checkedValues.nameOnReport.includes('companyName'),
          person: !!checkedValues.nameOnReport.includes('personsName'),
        },
      }
      const response = await createPDFReport(requestBody)
      const fileNameId = report.paymentInstructionIds ? report.paymentInstructionIds[0] : uuidv4()
      const fileName = `billhop-receipt-${fileNameId}`

      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${fileName}.pdf`)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleEmailPdf = async (report: PdfEmailRequest) => {
    try {
      await emailPDFReport(report)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleDownloadReport = async (fileId: Report) => {
    try {
      const response = await api.downloadReport(fileId.id || '')
      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', fileId?.output?.file?.fileName || 'report-file.xlsx')
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const generateKeys = (): string[] => {
    return checkedValues[RI.REPORT_FIELDS]?.length ? (checkedValues[RI.REPORT_FIELDS] as string[]) : []
  }

  const generatePayload = (formatType: FORMAT_TYPE): Report => {
    const { profileId, entityId } = getActiveUser(user!)
    const payload: Report = {
      profileId,
      entityId,
      format: formatType,
      delivery: {
        email: {
          addresses: deliveryType === DELIVERY_OPTIONS.DOWNLOAD ? [] : [...emails],
        },
        download: {
          fileName: '',
        },
      },
      formatProperties: {
        keys: generateKeys(),
        beneficiaryIds: [...beneficiaryIDs],
        cardNetworks: checkedValues[RI.CARD_NETWORKS] as string[],
        clearingNetworks: checkedValues[RI.CLEARING_NETWORKS] as string[],
        currency: checkedValues[RI.CURRENCY] as string[],
        entityIds: [...entityIDs],
        sourceIds: checkedValues[RI.SOURCES] as string[],
        states: checkedValues[RI.STATES] as string[],
      },
    }

    if (deliveryType === DELIVERY_OPTIONS.EMAIL) {
      delete payload.delivery.download
    }

    if (location?.pathname !== ADMIN_ROUTE || !entityIDs.length) {
      delete payload.formatProperties.entityIds
    }

    // TODO: a better way to distinguish the type of report is needed (profile or admin report)
    if (location?.pathname !== ADMIN_ROUTE) {
      payload.formatProperties.profileIds = [profileId]
    }

    const checkedFields: string[] = Object.keys(checkedValues) || []

    checkedFields.forEach((field: string) => {
      if (checkedValues[field] && !checkedValues[field].length) {
        delete payload.formatProperties[field]
      }
    })

    checkedFields.forEach((field: string) => {
      if (checkedAllFields[field]) {
        delete payload.formatProperties[field]
      }
    })

    if (checkedAllFields[RI.BENEFICIARY]) {
      delete payload.formatProperties[RI.BENEFICIARY]
    }

    if (checkedValues[RI.DATE_USER_PAID] && checkedValues[RI.DATE_USER_PAID][0] === DT.DATE_DUE) {
      payload.formatProperties.dateDue = {
        from: formatLocaleDateString(dateRange.from, 'sv-SE'), //api accept only sweden date format
        to: formatLocaleDateString(dateRange.to, 'sv-SE'),
      }
    } else if (checkedValues[RI.DATE_USER_PAID] && checkedValues[RI.DATE_USER_PAID][0] === DT.DATE_USER_PAID) {
      payload.formatProperties.dateUserPaid = {
        from: formatLocaleDateString(dateRange.from, 'sv-SE'),
        to: formatLocaleDateString(dateRange.to, 'sv-SE'),
      }
    }

    if (!dateRange.from || !dateRange.to) {
      delete payload.formatProperties.dateDue
      delete payload.formatProperties.dateUserPaid
    }

    if (isLegacyReport) {
      delete payload.formatProperties['keys']
    }
    return payload
  }

  const handleSubmit = async (): Promise<void> => {
    setSubmitLoader(true)

    try {
      const payload: Report = generatePayload(reportType)
      const response = await api.createReport(payload)
      if (response) {
        const { status, format, paymentInstructionIds = [] } = response
        const requestBody: PdfEmailRequest = {
          profileId: payload.profileId,
          entityId: payload.entityId,
          delivery: payload.delivery,
          formatProperties: payload.formatProperties,
          output: payload.output,
          format: payload.format,
          type: checkedValues.reportFormat[0] as string,
          paymentInstructionIds: response.paymentInstructionIds || [],
          locale,
          opts: {
            fee: !!checkedValues.extraInfo.includes('includeBillhopFee'),
            source: !!checkedValues.extraInfo.includes('includePaymentCardInformation'),
            sme: !!checkedValues.nameOnReport.includes('companyName'),
            corp: !!checkedValues.nameOnReport.includes('companyName'),
            person: !!checkedValues.nameOnReport.includes('personsName'),
          },
        }
        switch (deliveryType) {
          case DELIVERY_OPTIONS.DOWNLOAD:
            if (status === 'success' && format === FORMAT_TYPE.EXCEL) {
              await handleDownloadReport(response)
            }
            if (paymentInstructionIds.length && reportType === FORMAT_TYPE.ID_LIST) {
              await handleDownloadPdf(response)
            }
            break
          case DELIVERY_OPTIONS.EMAIL:
            if (paymentInstructionIds.length && reportType === FORMAT_TYPE.ID_LIST) {
              await handleEmailPdf(requestBody)
            }
            notification.success({
              message: intl.formatMessage(messages['messages.success.reports.create']),
              placement: 'topRight',
            })
            break

          default:
            break
        }
      }
    } catch (error) {
      notification.warn({
        message: intl.formatMessage(messages['messages.error.reports.create']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setSubmitLoader(false)
    }
  }

  const handleSelectAll = (selected: boolean, type: string): void => {
    setCheckedAllFields((oldState) => ({
      ...oldState,
      [type]: selected,
    }))

    if (selected) {
      setIsLegacyReport(false)
    }
  }

  const isEmptyState = (): boolean => {
    const checkedValuesCopy = { ...checkedValues }
    delete checkedValuesCopy[RI.DATE_USER_PAID]
    const fields = Object.keys(checkedValuesCopy)
    const adminConditions =
      !fields.length && !entityIDs.length && !beneficiaryIDs.length && !dateRange.from && !dateRange.to

    const userConditions = !fields.length && !beneficiaryIDs.length && !dateRange.from && !dateRange.to

    if (location?.pathname === ADMIN_ROUTE) {
      if (adminConditions) {
        return true
      } else {
        if (
          fields.some((field) => checkedValuesCopy[field].length) ||
          entityIDs.length ||
          beneficiaryIDs.length ||
          (dateRange.from && dateRange.to)
        ) {
          return false
        } else return true
      }
    } else {
      if (userConditions) {
        return true
      } else {
        if (
          fields.some((field) => checkedValuesCopy[field].length) ||
          beneficiaryIDs.length ||
          (dateRange.from && dateRange.to)
        ) {
          return false
        } else return true
      }
    }
  }

  const handleLegacyReportChange = (value: boolean): void => {
    setIsLegacyReport(value)
    if (value) {
      handleSelectAll(false, RI.REPORT_FIELDS)

      const fields: ReportFields[] = reportFields.map((field) => {
        return {
          value: field.value,
          label: getReportFieldLabel(field.value),
          disabled: true,
        }
      })
      setReportFields(fields)
    } else {
      handleSelectAll(true, RI.REPORT_FIELDS)
    }
  }

  /*** Template functions ***/
  const updateReportFormBasedOnTemplate = (template: ReportTemplate) => {
    const {
      values,
      includeExtraInfo: includeExtraInfoFromTemplate,
      nameOnReport: nameOnReportFromTemplate,
      delivery,
      reportType: templateReportType,
      reportFormat,
    } = template

    const {
      dateDue,
      dateUserPaid,
      states,
      cardNetworks,
      clearingNetworks,
      currency,
      entityIds,
      sourceIds,
      beneficiaryIds,
      keys,
    } = values

    // DATE FIELD
    if (dateDue || dateUserPaid) {
      if (dateDue) {
        setDateFromTemplate(DT.DATE_DUE)
        selectDateRangeFromTemplate(dateDue.from, dateDue.to)
      }
      if (dateUserPaid) {
        setDateFromTemplate(DT.DATE_USER_PAID)
        selectDateRangeFromTemplate(dateUserPaid.from, dateUserPaid.to)
      }
    } else {
      handleResetDateField()
    }
    // PAYMENT STATUS
    if (states) {
      handleCheckboxChange(states, RI.STATES)
    } else {
      handleResetCheckboxOptions(RI.STATES, paymentStatus)
    }
    // CURRENCY
    if (currency) {
      handleCheckboxChange(currency, RI.CURRENCY)
    } else {
      handleResetCheckboxOptions(RI.CURRENCY, currencyOptions)
    }
    // CARD NETWORKS
    if (cardNetworks) {
      handleCheckboxChange(cardNetworks, RI.CARD_NETWORKS)
    } else {
      handleResetCheckboxOptions(RI.CARD_NETWORKS, cardNetworkOptions)
    }
    // CLEARING NETWORKS
    if (clearingNetworks) {
      handleCheckboxChange(clearingNetworks, RI.CLEARING_NETWORKS)
    } else {
      handleResetCheckboxOptions(RI.CLEARING_NETWORKS, clearingNetworksOptions)
    }
    // ENTITIES
    if (entityIds) {
      handleEntityChange(entityIds)
      handleEntityType(ENTITY_OPTIONS.SELECT)
    } else {
      handleEntityType(ENTITY_OPTIONS.ALL)
    }
    // RECIPIENTS
    if (beneficiaryIds) {
      setRecipientsFromTemplate(beneficiaryIds)
    } else {
      handleBeneficiaryType(ENTITY_OPTIONS.ALL)
    }
    // CARDS
    if (sourceIds) {
      handleCheckboxChange(sourceIds, RI.SOURCES)
    } else {
      handleResetCheckboxOptions(RI.SOURCES, sourceOptions)
    }
    // REPORT FIELDS
    if (keys) {
      handleCheckboxChange(keys, RI.REPORT_FIELDS)
    } else {
      handleResetCheckboxOptions(RI.REPORT_FIELDS, reportFields)
    }

    // DELIVERY OPTION
    if (delivery && delivery.email && delivery.email.addresses.length > 0) {
      setDeliveryType(DELIVERY_OPTIONS.EMAIL)
      setEmails(delivery.email.addresses)
    } else {
      setDeliveryType(DELIVERY_OPTIONS.DOWNLOAD)
    }
    // REPORT TYPE Excel
    if (templateReportType === FORMAT_TYPE.EXCEL) {
      setReportTypeFromTemplate(FORMAT_TYPE.EXCEL)
    }
    // REPORT TYPE pdf
    if (templateReportType === FORMAT_TYPE.ID_LIST) {
      setReportTypeFromTemplate(FORMAT_TYPE.ID_LIST)
      if (includeExtraInfoFromTemplate) {
        handleCheckboxChange(includeExtraInfoFromTemplate, RI.EXTRA_INFO)
      }
      if (nameOnReportFromTemplate) {
        handleCheckboxChange(nameOnReportFromTemplate, RI.NAME_ON_REPORT)
      }
      if (reportFormat) {
        reportFormat === RF.SINGLE_PI
          ? setReportFormatFromTemplate(RF.SINGLE_PI)
          : setReportFormatFromTemplate(RF.TABLE_OF_PIS)
      }
    }
  }

  const setDateFromTemplate = (type: DT) => {
    setCheckedValues((oldState) => ({
      ...oldState,
      dateUserPaid: [type],
    }))
  }

  const setReportFormatFromTemplate = (type: RF) => {
    setCheckedValues((oldState) => ({
      ...oldState,
      reportFormat: [type],
    }))
  }

  const setReportTypeFromTemplate = (type: FORMAT_TYPE) => {
    setReportType(type)
  }

  const setRecipientsFromTemplate = (beneficiaryIds: string[]) => {
    setBeneficiaryIDs(beneficiaryIds)
    handleBeneficiaryType(ENTITY_OPTIONS.SELECT)
  }

  const handleResetDateField = () => {
    // Default the Date field to "Date due".
    setCheckedValues((oldState) => ({
      ...oldState,
      dateUserPaid: [DT.DATE_DUE],
    }))
    // Default the select date range field to "Last 30 days".
    setDateOption(DATE_OPTIONS.LAST_30_DAYS)
    // Default the select date range from: to: fields (uses last 30 days).
    setDateRange((oldState) => ({
      ...oldState,
      from: moment().subtract(1, 'M').toISOString(),
      to: moment().toISOString(),
    }))
  }

  const handleResetCheckboxOptions = (type: RI, options: OptionData[]) => {
    // Default so the "All" option is selected
    handleSelectAll(true, type)
    // Default so all options is disabled.
    const optionsCopy = [...options]
    optionsCopy.map((option: OptionData) => {
      option.disabled = true
    })
    // Default so all options is selected.
    handleCheckboxChange(
      optionsCopy.map((option) => option.value),
      type
    )
  }

  const handleSubmitTemplate = async (values: { title: string; description: string }): Promise<void> => {
    setSubmitLoader(true)
    const { title = intl.formatMessage(reports['reports.template.form.title']), description = '' } = values
    const reportData = generatePayload(reportType)
    const { formatProperties, delivery } = reportData
    const templateToSave: ReportTemplate = {
      title,
      description,
      values: {
        ...formatProperties,
      },
      availableToEntityIds: isAvailableToAllEntityIds ? [(user && user.profiles[0].entityId) || ''] : [],
      delivery,
      reportType,
      includeExtraInfo: checkedValues[RI.EXTRA_INFO] as string[],
      nameOnReport: checkedValues[RI.NAME_ON_REPORT] as string[],
      reportFormat: checkedValues[RI.REPORT_FORMAT][0] as REPORT_FORMAT_OPTION_TYPE,
    }

    try {
      user && (await createReportTemplate(user.activeProfileId as string, templateToSave))
      user && void getReportTemplates(user.activeProfileId as string)
      notification.success({
        message: intl.formatMessage(messages['messages.success.template.create']),
        placement: 'topRight',
      })
      form.resetFields()
    } catch (error) {
      notification.warn({
        message: intl.formatMessage(messages['messages.error.template.create']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setSubmitLoader(false)
      resetDrawerHash(history)
    }
  }

  return (
    <Page title={intl.formatMessage(reports['reports.header'])} mobileMenuOptions={[]}>
      <React.Fragment>
        <div className="settings-container">
          <div className="card-container" style={{ backgroundColor: 'transparent' }}>
            <Loader showLoader={fetchingLoader}>
              <div className="reports-page" data-testid="reports-component">
                <Row gutter={30}>
                  <Col xl={12} lg={12}>
                    <ReportCard title={intl.formatMessage(reports['reports.date.field'])} icon={dateRangeIcon}>
                      <RadioGroupFields
                        options={datePaidOptions}
                        type={RI.DATE_USER_PAID}
                        onRadioChange={handleRadioChange}
                        selectedValue={
                          checkedValues[RI.DATE_USER_PAID] ? (checkedValues[RI.DATE_USER_PAID][0] as string) : ''
                        }
                      />
                    </ReportCard>
                    <ReportCard title={intl.formatMessage(reports['reports.date.range'])} icon={dateRangeIcon}>
                      <DateRangeSelect
                        optionArray={dateOptions}
                        handleDateChange={handleDateChange}
                        dateRange={dateRange}
                        handleSelectDateOption={handleSelectDateOption}
                        selectedDateOption={selectedDateOption}
                      />
                    </ReportCard>

                    <ReportCard title={intl.formatMessage(reports['reports.payment.status'])} icon={paymentStatusIcon}>
                      <CheckBoxFields
                        optionArray={paymentStatus}
                        type={RI.STATES}
                        checkedValues={checkedValues}
                        setState={setPaymentStatus}
                        handleCheckboxChange={handleCheckboxChange}
                        setCheckedAll={handleSelectAll}
                        checkedAll={checkedAllFields}
                      />
                    </ReportCard>

                    <ReportCard title={intl.formatMessage(reports['reports.currency'])} icon={currencyIcon}>
                      <CheckBoxFields
                        optionArray={currencyOptions}
                        type={RI.CURRENCY}
                        checkedValues={checkedValues}
                        setState={setCurrencies}
                        handleCheckboxChange={handleCheckboxChange}
                        setCheckedAll={handleSelectAll}
                        checkedAll={checkedAllFields}
                      />
                    </ReportCard>

                    {location?.pathname === ADMIN_ROUTE && (
                      <ReportCard title={intl.formatMessage(reports['reports.entities'])} icon={entityIcon}>
                        <SelectTreeGroup
                          type={RI.ENTITY}
                          checkedAll={checkedAllFields}
                          data={entityTreeData}
                          dataIDs={entityIDs}
                          selectionType={entitySelectionType}
                          onChange={handleEntityChange}
                          setSelectionType={handleEntityType}
                          placeholder={intl.formatMessage(reports['reports.select.entity.placeholder'])}
                        />
                      </ReportCard>
                    )}

                    <ReportCard title={intl.formatMessage(reports['reports.card.networks'])} icon={cardIcon}>
                      <CheckBoxFields
                        optionArray={cardNetworkOptions}
                        type={RI.CARD_NETWORKS}
                        checkedValues={checkedValues}
                        setState={setCardNetworks}
                        handleCheckboxChange={handleCheckboxChange}
                        setCheckedAll={handleSelectAll}
                        checkedAll={checkedAllFields}
                      />
                    </ReportCard>

                    <ReportCard title={intl.formatMessage(reports['reports.clearing.networks'])} icon={cardIcon}>
                      <CheckBoxFields
                        optionArray={clearingNetworksOptions}
                        type={RI.CLEARING_NETWORKS}
                        checkedValues={checkedValues}
                        setState={setClearingNetworks}
                        handleCheckboxChange={handleCheckboxChange}
                        setCheckedAll={handleSelectAll}
                        checkedAll={checkedAllFields}
                      />
                    </ReportCard>

                    <ReportCard title={intl.formatMessage(reports['reports.beneficiaries'])} icon={entityIcon}>
                      <SelectTreeGroup
                        data={beneficiariesTree}
                        type={RI.BENEFICIARY}
                        checkedAll={checkedAllFields}
                        dataIDs={beneficiaryIDs}
                        selectionType={beneficiarySelectionType}
                        onChange={handleBeneficiaryChange}
                        setSelectionType={handleBeneficiaryType}
                        placeholder={intl.formatMessage(reports['reports.select.beneficiary.placeholder'])}
                      />
                    </ReportCard>

                    {sourceOptions.length > 0 && (
                      <ReportCard title={intl.formatMessage(reports['reports.sources'])} icon={cardIcon}>
                        <CheckBoxFields
                          optionArray={sourceOptions}
                          type={RI.SOURCES}
                          checkedValues={checkedValues}
                          setState={setSources}
                          handleCheckboxChange={handleCheckboxChange}
                          setCheckedAll={handleSelectAll}
                          checkedAll={checkedAllFields}
                        />
                      </ReportCard>
                    )}

                    {reportType === FORMAT_TYPE.EXCEL && (
                      <ReportCard title={intl.formatMessage(reports['reports.report.fields'])} icon={entityIcon}>
                        <CheckBoxFields
                          optionArray={reportFields}
                          type={RI.REPORT_FIELDS}
                          checkedValues={checkedValues}
                          setState={setReportFields}
                          handleCheckboxChange={handleCheckboxChange}
                          setCheckedAll={handleSelectAll}
                          checkedAll={checkedAllFields}
                          isLegacyReport={isLegacyReport}
                          handleLegacyReportChange={handleLegacyReportChange}
                        />
                      </ReportCard>
                    )}

                    {reportType === FORMAT_TYPE.ID_LIST && (
                      <React.Fragment>
                        <ReportCard icon={entityIcon} title={intl.formatMessage(reports['reports.include.extra.info'])}>
                          <CheckBoxFields
                            optionArray={includeExtraInfo}
                            type={RI.EXTRA_INFO}
                            checkedValues={checkedValues}
                            setState={setIncludeExtraInfo}
                            handleCheckboxChange={handleCheckboxChange}
                            setCheckedAll={handleSelectAll}
                            checkedAll={checkedAllFields}
                          />
                        </ReportCard>
                        <ReportCard
                          icon={entityIcon}
                          title={intl.formatMessage(reports['reports.report.name.on.report'])}
                        >
                          <CheckBoxFields
                            optionArray={nameOnReport}
                            type={RI.NAME_ON_REPORT}
                            checkedValues={checkedValues}
                            setState={setNameOnReport}
                            handleCheckboxChange={handleCheckboxChange}
                            setCheckedAll={handleSelectAll}
                            checkedAll={checkedAllFields}
                          />
                        </ReportCard>
                        <ReportCard icon={entityIcon} title={intl.formatMessage(reports['reports.report.format'])}>
                          <RadioGroupFields
                            options={reportFormatOptions}
                            type={RI.REPORT_FORMAT}
                            onRadioChange={handleRadioChange}
                            selectedValue={
                              checkedValues[RI.REPORT_FORMAT] ? (checkedValues[RI.REPORT_FORMAT][0] as string) : ''
                            }
                          />
                        </ReportCard>
                      </React.Fragment>
                    )}
                  </Col>
                  <Col xl={12} lg={12}>
                    <Affix offsetTop={70} className="affix">
                      <div>
                        <ReportSummary
                          location={location}
                          checkedValues={checkedValues}
                          updatingLoader={updatingLoader}
                          paymentsCount={paymentsCount}
                          checkedAllFields={checkedAllFields}
                          selectedDateOption={selectedDateOption}
                          dateRange={dateRange}
                          paymentStatus={paymentStatus}
                          currencyOptions={currencyOptions}
                          entityIDs={entityIDs}
                          entityTreeData={entityTreeData}
                          cardNetworkOptions={cardNetworkOptions}
                          clearingNetworksOptions={clearingNetworksOptions}
                          sourceOptions={sourceOptions}
                          reportFields={reportFields}
                          beneficiaryIDs={beneficiaryIDs}
                          beneficiariesTree={beneficiariesTree}
                          includeExtraInfo={includeExtraInfo}
                          nameOnReport={nameOnReportOptions}
                          reportFormatOptions={reportFormatOptions}
                          selectedReportType={reportType}
                          handleCheckboxChange={handleCheckboxChange}
                          handleRadioChange={handleRadioChange}
                          handleSelectAll={handleSelectAll}
                          handleRemove={handleRemove}
                          handleResetTree={handleResetTree}
                        />

                        <div className="report-card-wrapper">
                          <Row className="row" gutter={[8, 16]}>
                            <Col className="col" span={9}>
                              <div className="header">
                                <div className="icon">
                                  <img src={cardIcon} alt="icon" />
                                </div>
                                <div className="delivery-title">
                                  {intl.formatMessage(reports['reports.report.type'])}
                                </div>
                              </div>
                            </Col>
                            <Col className="col" span={15}>
                              <div className="content">
                                <Radio.Group
                                  data-testid="radio-group-report-type"
                                  onChange={(event: RadioChangeEvent) =>
                                    setReportType(event.target.value as FORMAT_TYPE)
                                  }
                                  options={reportTypeOptions}
                                  value={reportType}
                                />
                              </div>
                            </Col>
                            <Col className="col" span={9}>
                              <div className="header">
                                <div className="icon">
                                  <img src={emailIcon} alt="icon" />
                                </div>
                                <div className="delivery-title">
                                  {intl.formatMessage(reports['reports.delivery.option'])}
                                </div>
                              </div>
                            </Col>
                            <Col className="col" span={15}>
                              <div className="content">
                                <DeliveryTypeSelect
                                  handleChange={handleChangeDeliveryType}
                                  selectedOption={deliveryType}
                                />
                              </div>
                            </Col>
                            <Col span={24}>
                              {deliveryType === DELIVERY_OPTIONS.EMAIL && (
                                <EmailTags tags={emails} setTags={setEmails} />
                              )}
                            </Col>
                          </Row>
                        </div>
                        <Space className="report-buttons-space">
                          <Button
                            loading={submitLoader}
                            className="bh-btn primary right auto-width"
                            data-testid="report-template-button"
                            onClick={() => setDrawerHash(history, '#drawer-new-report-template')}
                          >
                            {intl.formatMessage(reports['reports.generate.reportTemplate'])}
                          </Button>
                          <Button
                            loading={submitLoader}
                            className="bh-btn secondary right auto-width"
                            disabled={isEmptyState()}
                            data-testid="generate-report-button"
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleSubmit}
                          >
                            {intl.formatMessage(reports['reports.generate.report'])}
                          </Button>
                        </Space>
                      </div>
                    </Affix>
                  </Col>
                </Row>
              </div>
            </Loader>
          </div>
        </div>
        <TemplateForm
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleSubmitTemplate={handleSubmitTemplate}
          setIsAvailableToAllEntityIds={setIsAvailableToAllEntityIds}
          summary={
            <ReportSummary
              location={location}
              checkedValues={checkedValues}
              updatingLoader={updatingLoader}
              paymentsCount={paymentsCount}
              checkedAllFields={checkedAllFields}
              selectedDateOption={selectedDateOption}
              dateRange={dateRange}
              paymentStatus={paymentStatus}
              currencyOptions={currencyOptions}
              entityIDs={entityIDs}
              entityTreeData={entityTreeData}
              cardNetworkOptions={cardNetworkOptions}
              clearingNetworksOptions={clearingNetworksOptions}
              sourceOptions={sourceOptions}
              reportFields={reportFields}
              beneficiaryIDs={beneficiaryIDs}
              beneficiariesTree={beneficiariesTree}
              includeExtraInfo={includeExtraInfo}
              nameOnReport={nameOnReportOptions}
              reportFormatOptions={reportFormatOptions}
              selectedReportType={reportType}
              handleCheckboxChange={handleCheckboxChange}
              handleRadioChange={handleRadioChange}
              handleSelectAll={handleSelectAll}
              handleRemove={handleRemove}
              handleResetTree={handleResetTree}
            />
          }
        />
      </React.Fragment>
    </Page>
  )
}

export default Reports
