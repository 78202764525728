import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import Filter, { Data, FilterBody, FilterOption } from 'components/Filter/Filter'
import Page from 'components/Page/Page'
import { QueryParams, FilterState } from 'types/general'
import { UserData } from 'types/user'
import * as api from 'api/user'
import { useLanguageState } from 'stores/language/LanguageStore'
import { userColumns } from './utils'
import { Button, Modal, notification, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getEntityRoles } from 'api/rules'
import ActionPage from 'components/ActionPage/ActionPage'
import UserForm from './UserForm'
import { Entity } from 'types/entity'
import { useIntl } from 'react-intl'
import { action } from 'lang/definitions/action'
import { admin } from 'lang/definitions/admin'
import { filters } from 'lang/definitions/filter'
import { entity, messages } from 'lang/definitions'
import { getActiveUser } from 'utils/helpers'
import { RightOutlined, DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { setDrawerHash } from 'components/Drawers/utils'
import { useOrganisationHierarchy, useOrganisationHierarchyUtils } from 'stores/OrganisationHierarchy/hooks'
import './Users.less'
import { useSession } from 'stores/session'

interface FormattedEntityRolesOptions {
  type: string
  text: string
}

enum FILTER_KIND {
  SEARCH = 'searchFilter',
  ENTITY_ROLES = 'entityRolesFilter',
  ENTITY = 'entityFilter',
  COUNTRY = 'countryFilter',
}

const Users = (): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()

  const {
    state: { user },
  } = useSession()
  const { entityId, profile } = getActiveUser(user!)
  const { userId } = profile

  const { getUsers } = useOrganisationHierarchyUtils()
  const {
    state: { users, usersTotal, isFetchingUsers, selectedUser, usersFilterBody, countryOptions, entities },
    actions: { setSelectedUser, setUsersFilterBody },
  } = useOrganisationHierarchy()

  const [languageState] = useLanguageState()
  const language = languageState.language

  const [filterState, setFilterState] = useState<FilterState>()
  const [formattedEntityRolesOptions, setFormattedEntityRolesOptions] = useState<FormattedEntityRolesOptions[]>([])

  const actionColumn = {
    title: intl.formatMessage(admin['admin.user.columns.actions']),
    dataIndex: 'id',
    key: 'type',
    width: 170,
    render: (id: string): React.JSX.Element => {
      return (
        <React.Fragment>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={(event) => {
              event.stopPropagation()
              openEdit(id)
            }}
          >
            <RightOutlined
              style={{
                color: '#BDBDBD',
                fontSize: '1rem',
              }}
            />
          </Button>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={(event) => {
              event.stopPropagation()
              openDelete(id)
            }}
          >
            <DeleteOutlined
              style={{
                color: 'red',
                fontSize: '1rem',
                marginLeft: '0.5rem',
              }}
            />
          </Button>
        </React.Fragment>
      )
    },
  }

  const filterOptions: Array<FilterOption> = [
    {
      id: FILTER_KIND.ENTITY,
      label: intl.formatMessage(filters['filter.entity']),
      type: 'select',
      callback: (value) => updateEntityFilter(value as string),
      // Filter the entities to aonly list corps and sme
      dataSource:
        entities && entities.length
          ? entities
              .filter((e) => e.class!.corp || e.class!.sme)
              .map((entity: Entity, index: number): Data => {
                const title = entity.class?.corp?.title || entity.class?.sme?.title || ''

                return {
                  id: index,
                  name: title,
                  value: entity.id,
                }
              })
          : [],
      placeholder: intl.formatMessage(filters['filter.entity.placeholder']),
    },
    {
      id: FILTER_KIND.COUNTRY,
      label: intl.formatMessage(filters['filter.country']),
      type: 'countrySelect',
      callback: (value) => updateCountryFilter(value as string),
      dataSource: countryOptions.map((countryCode: string, index: number) => {
        return {
          id: index,
          name: countryCode,
        }
      }),
      placeholder: intl.formatMessage(filters['filter.country']),
    },
    {
      id: FILTER_KIND.ENTITY_ROLES,
      label: intl.formatMessage(filters['filter.accessRights']),
      type: 'select',
      callback: (value) => updateAccessRightsFilter(value as string),
      dataSource: formattedEntityRolesOptions.map((type: FormattedEntityRolesOptions, index: number) => {
        return { id: index, name: type.text, value: type.type }
      }),
      placeholder: intl.formatMessage(filters['filter.accessRights']),
    },
    {
      id: FILTER_KIND.SEARCH,
      label: intl.formatMessage(filters['filter.search']),
      type: 'search',
      callback: (value) => updateSearchFilter(value as string),
    },
  ]

  useEffect(() => {
    void showDrawersFromHash()
  }, [window.location.hash, users])

  useEffect(() => {
    void getUsers(usersFilterBody)
  }, [usersFilterBody])

  useEffect(() => {
    void getEntityRolesOptions()
  }, [entityId])

  const showDrawersFromHash = (): void => {
    const windowHash = window.location.hash

    if (windowHash && users) {
      const isEdit = windowHash.includes('edit-user')
      const hashArray = windowHash.split('?key=')
      const hashId = hashArray[1]
      isEdit && openEdit(hashId)
    }
  }

  const handleSetFilterState = (value: string, key: string): void => {
    setFilterState((state) => ({
      ...state,
      [key]: value,
    }))
  }

  const renderFilterOptions = (): Array<FilterOption> => {
    const filters = [...filterOptions]
    // filter out entity filter when have only one record
    if (entities.length === 1) {
      return filters.filter((option) => option.id !== FILTER_KIND.ENTITY)
    }
    return filters
  }

  const updateSearchFilter = (searchTerm: string) => {
    const filter: FilterBody = {
      ...usersFilterBody,
    }

    if (searchTerm) {
      filter.title = searchTerm
    } else {
      delete filter.title
    }

    setUsersFilterBody(filter)
  }

  const updateEntityFilter = (selectedEntityId: string) => {
    const filter: FilterBody = {
      ...usersFilterBody,
    }

    if (selectedEntityId) {
      filter.entityId = [selectedEntityId]
    } else {
      delete filter.entityId
    }

    setUsersFilterBody(filter)
  }

  const updateCountryFilter = (selectedCountry: string) => {
    const filter: FilterBody = {
      ...usersFilterBody,
    }

    if (selectedCountry) {
      filter.countryCode = selectedCountry
    } else {
      delete filter.countryCode
    }

    setUsersFilterBody(filter)
  }

  const updateAccessRightsFilter = (selectedEntityRole: string) => {
    const filter: FilterBody = {
      ...usersFilterBody,
    }

    if (selectedEntityRole) {
      filter.entityRoles = [selectedEntityRole]
    } else {
      delete filter.entityRoles
    }

    setUsersFilterBody(filter)
  }

  const getEntityRolesOptions = async (): Promise<void> => {
    try {
      const entityRoles = await getEntityRoles()
      formatEntityRolesOptions(entityRoles)
    } catch (error) {
      notification.warning({
        message: 'Error!',
        description: intl.formatMessage(messages['messages.error.entityRoles.fetch']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const formatEntityRolesOptions = (entityRoles: string[]) => {
    const formattedEntityRolesOptions: FormattedEntityRolesOptions[] = []
    entityRoles.forEach((role) => {
      const formattedEntityOptions: FormattedEntityRolesOptions = {
        type: '',
        text: '',
      }
      switch (role) {
        case 'owner':
          formattedEntityOptions.type = 'owner'
          formattedEntityOptions.text = 'Owner'
          break
        case 'admin':
          formattedEntityOptions.type = 'admin'
          formattedEntityOptions.text = 'Admin'
          break
        case 'payer':
          formattedEntityOptions.type = 'payer'
          formattedEntityOptions.text = 'Make payments'
          break
        case 'viewer':
          formattedEntityOptions.type = 'viewer'
          formattedEntityOptions.text = 'View payments'
          break
        case 'approver':
          formattedEntityOptions.type = 'approver'
          formattedEntityOptions.text = 'Approve payments'
          break
        case 'adder':
          formattedEntityOptions.type = 'adder'
          formattedEntityOptions.text = 'Add payments'
          break
        case 'member':
          formattedEntityOptions.type = 'member'
          formattedEntityOptions.text = 'Member'
          break

        default:
          break
      }

      formattedEntityRolesOptions.push(formattedEntityOptions)
    })
    setFormattedEntityRolesOptions(formattedEntityRolesOptions)
  }

  const selectUser = (id: string): UserData => {
    const user = users.find((user: UserData) => user.id! === id)!
    setSelectedUser(user)
    return user
  }

  const openEdit = (id: string): void => {
    selectUser(id)
    setDrawerHash(history, `#drawer-edit-user?key=${id}`)
  }

  const openDelete = (id: string): void => {
    const selectedUser = selectUser(id)
    showDeleteConfirmationModal(selectedUser)
  }

  const showDeleteConfirmationModal = (user: UserData) => {
    const { id, name } = user
    const { first, last } = name
    Modal.confirm({
      title: 'Please confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete ${first} ${last}?`,
      okText: intl.formatMessage(admin['admin.modal.delete.text']),
      cancelText: intl.formatMessage(admin['admin.modal.cancel.text']),
      onOk: () => userId && handleDeleteUser(id!),
      okButtonProps: {
        style: {
          backgroundColor: 'red',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  const handleDeleteUser = async (id: string): Promise<void> => {
    try {
      if (entityId) {
        await api.deleteUser(id)
        void getUsers(usersFilterBody)
        notification.success({
          message: intl.formatMessage(messages['messages.success.user.delete']),
          placement: 'topRight',
        })
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    const params: QueryParams = {}
    const { current, pageSize } = pagination
    if (current && pageSize) {
      const skip = (current - 1) * pageSize
      if (skip) {
        params.skip = skip
      }
    }
    void getUsers(usersFilterBody, params)
  }

  return (
    <Page
      title=""
      mobileMenuOptions={[]}
      filter={
        <Filter
          isOpen={false}
          closeFilter={() => {}} // not needed
          label={intl.formatMessage(filters['filter.users.label'])}
          groups={[{ elements: renderFilterOptions() }]}
          filterState={filterState}
          setFilterState={handleSetFilterState}
        />
      }
    >
      <>
        <Table
          className="bh-table"
          loading={isFetchingUsers}
          size="small"
          rowKey={(record: UserData) => `profile-key-${record.id!}`}
          dataSource={users}
          columns={[...userColumns(language, intl), actionColumn]}
          scroll={{ x: true }}
          showSorterTooltip={false}
          pagination={{ total: usersTotal }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => openEdit(record.id!),
          })}
          locale={{
            emptyText: intl.formatMessage(entity['user.not.added']),
          }}
        />
        <ActionPage
          title={intl.formatMessage(action['action.admin.user.edit'])}
          hash={`#drawer-edit-user?key=${selectedUser?.id || ''}`}
          pathname={history.location.pathname}
          additionalClass="edit-user-drawer"
        >
          <UserForm />
        </ActionPage>
      </>
    </Page>
  )
}

export default Users
