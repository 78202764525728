import { Col, Row } from 'antd'
import React from 'react'
import './ReportCard.less'

interface CardProps {
  title: string
  icon: string
  children: React.JSX.Element
}

const ReportCard = ({ title, icon, children }: CardProps): React.JSX.Element => {
  return (
    <div className="report-card-wrapper">
      <Col className="col">
        <Row className="row" gutter={9}>
          <div className="header">
            <div className="icon">
              <img src={icon} alt="icon" />
            </div>
            <div className="title">{title}</div>
          </div>
        </Row>
        <Row className="col" gutter={15}>
          <div className="content">{children}</div>
        </Row>
      </Col>
    </div>
  )
}

export default ReportCard
