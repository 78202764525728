import React from 'react'
import { get } from 'lodash'
import { IntlShape } from 'react-intl'
import { PaymentInstructionAndTemplate } from '../../types/paymentInstruction'
import Currency from '../../components/Format/Currency/Currency'
import Date from '../../components/Format/Date/Date'
import { pi } from '../../lang/definitions'
import AccountNumber from '../../components/Format/AccountNumber/AccountNumber'
import { Tag, Tooltip } from 'antd'
import { PaymentAndTemplateRow } from './components/Tables/CombinedPITable'
import { ColumnsType } from 'antd/lib/table'
import BHAvatar from '../../components/BHAvatar/BHAvatar'
import { Entity } from '../../types/entity'
import { Profile } from '../../types/profile'
import { getEntityName } from '../../utils/helpers'
import PaymentState from './components/PaymentState/PaymentState'
import './utilsPiCombo.less'

export const columnsPaymentsCombo = ({
  language,
  intl,
  hasAvatar,
  hasReference,
  hasEntity,
}: {
  language: string
  intl: IntlShape
  hasAvatar: boolean
  hasReference: boolean
  hasEntity: boolean
}): ColumnsType<PaymentAndTemplateRow> => {
  const columns = [
    {
      isRendered: hasAvatar,
      title: intl.formatMessage(pi['pi.details.createdBy']),
      dataIndex: '',
      align: 'center' as const,
      key: 'createdBy',
      width: 85,
      className: 'created-by',
      render: (data: PaymentInstructionAndTemplate) => {
        return hasAvatar && data.contributors && <BHAvatar contributors={data.contributors}></BHAvatar>
      },
    },
    {
      isRendered: true,
      title: intl.formatMessage(pi['pi.status']),
      dataIndex: 'state',
      key: 'state',
      width: 200,
      sorter: hasEntity,
      render: (state: string) => {
        return <PaymentState state={state} />
      },
    },
    {
      isRendered: true,
      title: intl.formatMessage(pi['pi.beneficiary.title']),
      dataIndex: '',
      ellipsis: true,
      className: 'beneficiary-title',
      width: 350,
      render: (data: {
        creationChannel?: {
          channel: string
          value?: string
        }
        beneficiary: {
          title: string
          routingNumber: string | number
          accountNumber: string
          clearingNetwork: string
        }
      }) => {
        return (
          <div className="recipient-wrapper">
            <div className="recipient-details-container">
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                <Tooltip placement="top" title={data.beneficiary.title}>
                  {get(
                    data,
                    'beneficiary.title',
                    get(data, 'beneficiary.routingNumber') || get(data, 'beneficiary.accountNumber')
                  )}
                </Tooltip>
              </div>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  fontWeight: 400,
                }}
              >
                <AccountNumber
                  accountNumber={data.beneficiary.accountNumber}
                  routingNumber={data.beneficiary.routingNumber?.toString()}
                  clearingNetwork={data.beneficiary.clearingNetwork}
                ></AccountNumber>
              </div>
            </div>
            {data.creationChannel?.channel === 'integration' && data.creationChannel.value && (
              <div className="integration-logo-container">
                <Tag color="#fff">{data.creationChannel.value}</Tag>
              </div>
            )}
          </div>
        )
      },
    },
    {
      isRendered: hasReference,
      title: intl.formatMessage(pi['pi.reference']),
      dataIndex: 'reference',
      className: 'desktop-display reference',
      width: 250,
      ellipsis: true,
    },
    {
      isRendered: hasEntity,
      title: intl.formatMessage(pi['pi.entity']),
      dataIndex: 'entity',
      className: 'desktop-display entity',
      width: 250,
      ellipsis: true,
      render: (data: Entity) => {
        // helper function expects Profile
        return getEntityName({ entity: data } as Profile)
      },
    },
    {
      isRendered: true,
      title: intl.formatMessage(pi['pi.dateDue']),
      dataIndex: '',
      align: 'right' as const,
      key: 'dateDue',
      className: 'date-due',
      sorter: true,
      render: (data: { dateDue: string; dateDueNext: string }) => {
        return <Date value={data.dateDue || data.dateDueNext} locale={language} time={false} />
      },
    },
    {
      isRendered: true,
      title: intl.formatMessage(pi['pi.amount']),
      dataIndex: '',
      align: 'right' as const,
      key: 'amount',
      sorter: true,
      className: 'amount',
      render: (data: { amount: number; currency: string }) => {
        return (
          <span style={{ fontSize: 16 }}>
            <Currency value={data.amount.toString()} locale={language} currency={data.currency} />
          </span>
        )
      },
    },
  ]

  return columns
    .filter((column) => column.isRendered)
    .map((column) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isRendered, ...rest } = column
      return { ...rest }
    })
}
