import React from 'react'
import './SettingsCard.less'

interface CardProps {
  title: string
  subtitle?: string
  icon: string
  children: React.JSX.Element
}

const SettingsCard = ({ title, icon, subtitle, children }: CardProps): React.JSX.Element => {
  return (
    <div className="settings-card-wrapper">
      <div className="header">
        <span className="title">
          <span className="icon">
            <img src={icon} alt="logo" />
          </span>
          {title}
        </span>
        {subtitle && <span className="subtitle">{subtitle}</span>}
      </div>
      <div className="content">{children}</div>
    </div>
  )
}

export default SettingsCard
