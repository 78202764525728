import React, { useEffect, useState } from 'react'
import { Button, Result, Layout, ConfigProvider } from 'antd'
import queryString from 'query-string'
import * as Sentry from '@sentry/react'
import './components/Tables.less' //Global styling for tables
import './components/FormInputs.less'
import './components/Common.less'
import { IntlProvider, MissingTranslationError } from 'react-intl'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { getExternalCountryRule, getIam, getRules } from './api/rules'
import Loader from './components/Loader/Loader'
import routes from './routes'
import { Rules } from './types/rules'
import { AlertMessagesProvider } from './utils/context'
import {
  getIsProfileChosen,
  getPreferredLanguage,
  getSessionId,
  savePreferredLanguage,
  saveFileDownloadTriggerInfo,
  removeAuthenticationStatus,
  removeIsProfileChosen,
} from './utils/storage'
import { validateSessionId } from './utils/validate'
import { getCountryCodeFromLanguageCode } from './utils/helpers'
import { getUser } from './api/auth'
import { User } from './types/user'
import { loadLocaleData, DEFAULT_LOCALE, getAlertMessages, getIsB2BUser, Message } from './utils/init'
import moment from 'moment'

import localeGB from 'antd/lib/locale/en_GB'
import localeSE from 'antd/lib/locale/sv_SE'
import localeFI from 'antd/lib/locale/fi_FI'
import localeIT from 'antd/lib/locale/it_IT'
import localeNL from 'antd/lib/locale/nl_NL'

import 'moment/locale/en-gb'
import 'moment/locale/en-ie'
import 'moment/locale/sv'
import 'moment/locale/fi'
import 'moment/locale/it'
import 'moment/locale/nl'
import { AlertMessage } from './types/messages'

import { useLanguageState } from './stores/language/LanguageStore'
import { SET_LANGUAGE } from './stores/language/LanguageActionTypes'
import { useHubspotChat } from 'hooks/useHubspotChat'
import { getHubspotToken } from 'api/hubspot'

import stringsGB from './lang/en-GB.json'
import { useSession } from 'stores/session'

const SUPPORTED_COUNTRIES = ['fi', 'gb', 'ie', 'it', 'nl', 'se']
const DEFAULT_COUNTRY = 'gb'

type ErrorWithCode = Error & { code: string }
const App: React.FC = () => {
  const { search: searchParams, pathname } = window.location

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<Message>(stringsGB)
  const [country, setCountry] = useState<string>(DEFAULT_COUNTRY)
  const [antLocale, setAntLocale] = useState(localeGB)
  const [alertMessages, setAlertMessages] = useState<AlertMessage[]>([])
  const [didInitialRender, setDidInitialRender] = useState(false)

  const {
    state: { user, rules, iam, sessionId },
    actions: { setUser, setRules, setIam, setIsB2BUser, resetContext },
  } = useSession()

  const isLocalhost = window.location.hostname === 'localhost'

  const [languageState, dispatchLanguage] = useLanguageState()
  const language = languageState.language

  const setLanguage = (locale: string) => dispatchLanguage({ type: SET_LANGUAGE, language: locale })

  const { loadHubspotChat, removeHubspotChat, initalizeHubspotChat, chatInitialized } = useHubspotChat()

  useEffect(() => {
    void initApp()
  }, [sessionId])

  useEffect(() => {
    if (!user) return

    void handleRules()
    void handleAlertMessages()
    handleSentryUser(user)
    handleB2BUser(user)

    if (!isLocalhost) {
      void authenticateHubspot(user)
    }
  }, [user])

  useEffect(() => {
    void loadLocaleData(language, setMessage)
    loadAntLanguage(language)
  }, [language])

  useEffect(() => {
    Sentry.setContext('page', {
      pathName: location.pathname,
    })
  }, [location.pathname])

  useEffect(() => {
    // skip chat initialization on localhost
    if (isLocalhost) {
      return
    }
    if (sessionId && chatInitialized) {
      loadHubspotChat()
    }

    if (!sessionId && chatInitialized) {
      removeHubspotChat()
    }
  }, [sessionId, chatInitialized])

  useEffect(() => {
    const params = queryString.parse(searchParams)
    if (params.fileId && pathname === '/action/download') {
      saveFileDownloadTriggerInfo({
        fileId: params.fileId as string,
        nextAction: 'download',
      })
    }
  }, [searchParams])

  const authenticateHubspot = async (user: User) => {
    try {
      const { token } = await getHubspotToken()
      if (token) {
        initalizeHubspotChat(user.user.email, token)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const loadAntLanguage = (locale: string): void => {
    switch (locale) {
      case 'it-IT':
        setAntLocale(localeIT)
        moment.locale('it')
        break

      case 'fi-FI':
        setAntLocale(localeFI)
        moment.locale('fi')
        break

      case 'en-IE':
        setAntLocale(localeGB)
        moment.locale('en-ie')
        break

      case 'sv-SE':
        setAntLocale(localeSE)
        moment.locale('sv')
        break

      case 'nl-NL':
        setAntLocale(localeNL)
        moment.locale('nl')
        break

      case 'en-GB':
        setAntLocale(localeGB)
        moment.locale('en-gb')
        break

      default:
        break
    }
  }

  const handleB2BUser = (user: User): void => {
    const isB2B = getIsB2BUser(user)
    setIsB2BUser(isB2B)
  }

  const handleSentryUser = (user: User): void => {
    Sentry.setUser({
      ip_address: '{{auto}}',
      id: user.user.id,
      profileId: user.activeProfileId,
      sessionId: user.sessionId,
    })
  }

  const handleAlertMessages = async (): Promise<void> => {
    try {
      const alertMessages = await getAlertMessages(country, user)
      setAlertMessages(alertMessages)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleRules = async (): Promise<void> => {
    try {
      const rules = await getRules()
      setRules(rules)
      handlePreferredLanguage(rules)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handlePreferredLanguage = (rules: Rules): void => {
    const existingLanguage = getPreferredLanguage()
    const storedLanguage = rules.logic.languagePreferences.rule

    if (existingLanguage !== storedLanguage) {
      savePreferredLanguage(storedLanguage)
      setLanguage(storedLanguage)
    }
  }

  const initApp = async (): Promise<void> => {
    setLoading(true)
    try {
      await getCountry(true)
      if (sessionId) {
        const isSessionValid = await validateSessionId()
        if (isSessionValid) {
          const [userResult, iamResult] = await Promise.all([getUser(), getIam()])
          setUser(userResult)
          setIam(iamResult)
        } else {
          resetContext()
          removeAuthenticationStatus()
          removeIsProfileChosen()
        }
      } else {
        // not using the fallback default country when getting messages for countries
        const countryForMessages = await getCountry(false)
        const alertMessages = countryForMessages ? await getAlertMessages(countryForMessages, undefined) : []
        setAlertMessages(alertMessages)
      }
    } catch (error) {
      console.error('Error in initApp', error)
    } finally {
      setDidInitialRender(true)
    }
  }

  useEffect(() => {
    if (user && rules && iam) {
      setLoading(false)
    }
  }, [user, rules, iam])

  useEffect(() => {
    const checkIsSessionValid = async () => {
      try {
        const isValid = await validateSessionId()
        if (!isValid) setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    if (didInitialRender && !sessionId) {
      setLoading(false)
    }
    if (didInitialRender && sessionId) {
      void checkIsSessionValid()
    }
  }, [didInitialRender])

  // fetching and sets client country rules
  const getCountryRules = async (country: string): Promise<void> => {
    try {
      const response = await getExternalCountryRule(country)
      setRules(response)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // get country by priority:
  // 1. from preferredLanguage if it's saved in the local storage
  // 2. from x-client-country header
  // 3. hardcoded default country - gb
  const getCountry = async (fallbackToDefault: boolean) => {
    let newCountry = fallbackToDefault ? DEFAULT_COUNTRY : undefined
    const preferredLanguage = getPreferredLanguage()

    if (preferredLanguage) {
      newCountry = getCountryCodeFromLanguageCode(preferredLanguage).toLowerCase()
    } else {
      const response = await fetch(window.location.href)
      const xClientCountry = response.headers.get('x-client-country')
      if (xClientCountry && SUPPORTED_COUNTRIES.map((c) => c.toLowerCase()).includes(xClientCountry.toLowerCase())) {
        newCountry = xClientCountry.toLowerCase()
      }
    }
    if (newCountry) {
      setCountry(newCountry)
      // get country rules
      if (!sessionId) {
        void getCountryRules(newCountry)
      }
    }
    return newCountry
  }

  const redirectRoute = (route: string | undefined) => {
    if (!route) {
      return <Redirect to={`/login`} />
    }
    if (route.includes('register')) {
      return <Redirect to={`/register/${country}`} />
    } else if (route.includes('errare-humanum-est')) {
      return <Redirect to={`/errare-humanum-est/${country}`} />
    } else {
      return <Redirect to={`/login`} />
    }
  }

  function isMissingTranslationError(error: ErrorWithCode): error is MissingTranslationError {
    return 'code' in error && error.code === 'MISSING_TRANSLATION'
  }

  return (
    //Config provider applies scrolling fix for all antd components with dropdown
    <ConfigProvider
      getPopupContainer={(trigger?: HTMLElement) => trigger?.parentNode as HTMLElement}
      locale={antLocale}
    >
      <div data-testid="app-test-id" />
      <IntlProvider
        locale={language}
        messages={message}
        defaultLocale={DEFAULT_LOCALE}
        onError={(err) => {
          if (isMissingTranslationError(err)) {
            console.warn('Missing translation', err.message)
            return
          }
          throw err
        }}
      >
        <AlertMessagesProvider value={{ messages: alertMessages, setMessages: setAlertMessages }}>
          <Router>
            {didInitialRender && !loading ? (
              <Switch>
                {routes.map((route, i) => {
                  const key = `route-${i}`
                  return (
                    <Route
                      key={key}
                      exact={route.exact}
                      path={route.path}
                      // eslint-disable-next-line
                      render={({ props }: any) => {
                        const sessionId = getSessionId()
                        const isProfileChosen = getIsProfileChosen()
                        if ((sessionId === undefined || sessionId === '') && !route?.private) {
                          return <route.component {...props} routes={route} />
                        } else if ((sessionId === undefined || sessionId === '') && route.private) {
                          return redirectRoute('/login')
                        } else if (route.private && sessionId) {
                          if (isProfileChosen && !route.routes) {
                            return <Redirect to={'/'} />
                          } else {
                            return <route.component {...props} routes={route.routes} />
                          }
                        } else if (sessionId) {
                          return <Redirect to={'/'} />
                        }
                      }}
                    />
                  )
                })}
                {sessionId ? (
                  <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={
                      <Button type="primary" onClick={() => (window.location.href = '/')}>
                        Back Home
                      </Button>
                    }
                  />
                ) : (
                  redirectRoute(window.location.pathname)
                )}
              </Switch>
            ) : (
              <Layout
                style={{
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Loader showLoader={true} delay={0} />
              </Layout>
            )}
          </Router>
        </AlertMessagesProvider>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default App
