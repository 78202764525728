import React, { useEffect } from 'react'
import { Checkbox, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { OptionData, CheckedProps, CheckedAllProps } from './utils'
import './ReportCard.less'
import { useIntl } from 'react-intl'
import { reports } from '../../lang/definitions/reports'
import { InfoCircleOutlined } from '@ant-design/icons'

interface FieldsProps {
  optionArray: OptionData[]
  checkedValues: CheckedProps
  setCheckedAll: (selected: boolean, type: string) => void
  checkedAll: CheckedAllProps
  type: string
  setState: (options: OptionData[]) => void
  handleCheckboxChange: (values: CheckboxValueType[], type: string) => void
  isLegacyReport?: boolean
  handleLegacyReportChange?: (value: boolean) => void
}

const CheckBoxFields = ({
  optionArray,
  type,
  checkedAll,
  setCheckedAll,
  setState,
  handleCheckboxChange,
  checkedValues,
  isLegacyReport,
  handleLegacyReportChange,
}: FieldsProps): React.JSX.Element => {
  const intl = useIntl()

  useEffect(() => {
    if (checkedValues && checkedValues[type]) {
      if (checkedValues[type].length !== optionArray.length) {
        const optionsCopy = [...optionArray]

        optionsCopy.map((option: OptionData) => {
          option.disabled = false
        })
        setCheckedAll(false, type)
      }
    }
  }, [checkedValues])

  const handleSelectAll = (event: CheckboxChangeEvent): void => {
    setCheckedAll(event.target.checked, type)

    const optionsCopy = [...optionArray]

    if (event.target.checked) {
      optionsCopy.map((option: OptionData) => {
        option.disabled = true
      })
      handleCheckboxChange(
        optionsCopy.map((option) => option.value),
        type
      )
    } else {
      optionsCopy.map((option: OptionData) => {
        option.disabled = false
      })
      handleCheckboxChange([optionsCopy[0].value], type)
    }

    setState(optionsCopy)
  }

  return (
    <div className="checkbox-wrapper" data-testid={`checkbox-wrapper-${type}`}>
      <Checkbox
        className="checkbox"
        checked={checkedAll[type]}
        onChange={handleSelectAll}
        data-testid={`checkbox-checkall-${type}`}
      >
        {intl.formatMessage(reports['reports.all'])}
      </Checkbox>
      {handleLegacyReportChange && (
        <Checkbox
          className="checkbox"
          checked={isLegacyReport}
          data-testid={`checkbox-legacy-${type}`}
          onChange={(e: CheckboxChangeEvent) => {
            handleLegacyReportChange(e.target.checked)
          }}
        >
          {intl.formatMessage(reports['reports.legacy'])}
          <Tooltip title={intl.formatMessage(reports['reports.legacy.tooltip'])}>
            <InfoCircleOutlined style={{ color: '@daybreak-blue', paddingLeft: '5px' }} />
          </Tooltip>
        </Checkbox>
      )}
      <Checkbox.Group
        className="checkbox-group"
        data-testid={`checkbox-group-${type}`}
        defaultValue={checkedValues[type]}
        value={checkedValues[type]}
        onChange={(values: CheckboxValueType[]) => handleCheckboxChange(values, type)}
      >
        {optionArray.map((item: OptionData, index: number) => (
          <Checkbox
            className="checkbox"
            key={index}
            value={item.value}
            disabled={item.disabled}
            data-testid={`checkbox-${type}-${index}`}
          >
            {item.label}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </div>
  )
}

export default CheckBoxFields
