import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { Select, Button, Tabs, Pagination, Badge, notification, TabsProps } from 'antd'
import classNames from 'classnames'
import { UploadFile } from 'antd/lib/upload/interface'
import { InboxOutlined } from '@ant-design/icons'
import PaymentImport from './PaymentImport'
import CreditCard from 'components/CreditCard/CreditCard'
import { BhUpload } from 'components/Upload/Upload'
import ActionPage from 'components/ActionPage/ActionPage'
import CardForm from 'pages/Cards/CardForm'
import * as importApi from 'api/importer'
import { getCurrentPage } from '../../utils'
import { Import, FileProperties } from 'types/import'
import { Source } from 'types/source'
import { PaymentGateway, SignChainRule } from 'types/rules'
import { pi } from 'lang/definitions'
import { QueryParams } from 'types/general'
import { useHistory } from 'react-router-dom'
import { replaceDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import ACL from 'components/ACL/ACL'
import { useActiveUser } from 'hooks'
import { useCurrentPayment } from 'stores/Payment'
import './ImportPayments.less'
import { useCurrentPaymentUtils } from 'stores/Payment/hooks'
import { getBackendRootUrl } from 'api/utils'
import { useSession } from 'stores/session'

const DEFAULT_IMPORTS_PAGE_SIZE = 5
const IMPORTS_PING_INTERVAL = 2000

interface File extends UploadFile {
  base64Content?: string
}

export interface ImportPaymentsProps {
  cardNetworks: string[]
  gateways: PaymentGateway[]
  getDataAndSelectPayments: (paymentInstructionIds: string[], paymentInstructionTemplateIds: string[]) => void
}
const ImportPayments = (props: ImportPaymentsProps): React.JSX.Element => {
  const { cardNetworks, gateways, getDataAndSelectPayments } = props
  const {
    state: { user, rules },
  } = useSession()
  const history = useHistory()
  const intl = useIntl()
  const { profileId, entityId } = useActiveUser()
  const [cardId, setCardId] = useState<string>()
  const [existingFiles, setExistingFiles] = useState<File[]>([])
  const [processingImports, setProcessingImports] = useState<Import[]>([])
  const [completedImports, setCompletedImports] = useState<Import[]>([])
  const [processingImportsPage, setProcessingImportsPage] = useState<Import[]>([])
  const [completedImportsPage, setCompletedImportsPage] = useState<Import[]>([])
  const [currentProcessingImportsPage, setCurrentProcessingImportsPage] = useState(1)
  const [currentCompletedImportsPage, setCurrentCompletedImportsPage] = useState(1)
  const [numberOfNewCompleted, setNumberOfNewCompleted] = useState(0)
  const [activeTab, setActiveTab] = useState('in-progress')
  const [showCardError, setShowCardError] = useState(false)
  const [showExampleTemplates, setShowExampleTemplates] = useState(true)
  const [preselectedCardId, setPreselectedCardId] = useState<string>()
  const [importStatus, setImportStatus] = useState('')
  const [currentImport, setCurrentImport] = useState<Import>()
  const shouldRenderCardSelect = rules?.toggles?.allowPaymentsInForeignCardCurrency?.rule
  const fileImportRule = rules?.logic?.fileImport?.rule?.importCharge?.autoCharge

  const {
    state: { cards },
  } = useCurrentPayment()

  const { getCards } = useCurrentPaymentUtils()

  useEffect(() => {
    if (profileId) {
      void getProcessingImports()
      void getCompletedImports()
    }
  }, [profileId])

  const updateImportStatus = async (paymentImport: Import) => {
    try {
      let importData
      if (paymentImport.id) {
        importData = await importApi.getImport(paymentImport.id)
      }
      if (importData?.status) {
        setImportStatus(importData?.status)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleNotification = () => {
    if (activeTab === 'in-progress' && !processingImports.length) {
      if (importStatus === 'success') {
        notification.success({
          message: intl.formatMessage(pi['pi.add.addPayments.file.import.success']),
          placement: 'topRight',
        })
      }
      if (importStatus === 'fail') {
        notification.error({
          message: intl.formatMessage(pi['pi.add.addPayments.file.import.fail']),
          placement: 'topRight',
        })
      }
      if (importStatus === 'partialSuccess') {
        notification.success({
          message: intl.formatMessage(pi['pi.add.addPayments.file.import.partialSuccess']),
          placement: 'topRight',
        })
      }
      setImportStatus('')
    }
  }

  useEffect(() => {
    const getImports = async (): Promise<void> => {
      if (processingImports.length) {
        await timeout(IMPORTS_PING_INTERVAL)
        void getProcessingImports()
      }
    }
    void getImports()
    setCurrentImport(processingImports[0])
    if (currentImport) {
      void updateImportStatus(currentImport)
    }
    !fileImportRule && handleNotification()
  }, [processingImports, importStatus])

  useEffect(() => {
    const page = getCurrentPage(processingImports, currentProcessingImportsPage, DEFAULT_IMPORTS_PAGE_SIZE) as Import[]
    setProcessingImportsPage(page)
  }, [processingImports, currentProcessingImportsPage])
  useEffect(() => {
    const page = getCurrentPage(completedImports, currentCompletedImportsPage, DEFAULT_IMPORTS_PAGE_SIZE) as Import[]
    setCompletedImportsPage(page)
  }, [completedImports, currentCompletedImportsPage])
  const timeout = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  const createImport = async (file: File): Promise<Import> => {
    if (!user?.user.id) {
      return Promise.reject()
    }

    const fileName = file.name.replace(/(?:\.(?![^.]+$)|[^\w.]|[_])+/g, '')
    // const fileExtension = fileName.split(".").pop() // Get the file extension
    //const format = fileExtension === "csv" ? "billhop-csv" : "billhop-excel"

    const sourceProperties: FileProperties = {
      fileName: fileName,
      format: 'billhop-excel',
      content: file.base64Content!,
    }

    const data: Import = {
      userId: user.user.id,
      profileId,
      entityId,
      source: 'file',
      sourceProperties,
      createLegacy: false,
    }
    if (cardId) {
      data.sourceId = cardId
    }
    return await importApi.createImport(data)
  }
  const getProcessingImports = async (): Promise<void> => {
    try {
      const filter = {
        status: 'processing',
        profileId: [profileId],
      }
      const result = await importApi.searchImports(filter)
      const completedImports = processingImports.filter(
        (value: Import) => result.findIndex((processedImport: Import) => processedImport.id! === value.id!) === -1
      )
      setProcessingImports(result)
      // If there is a difference between result and state, fetch history
      if (!result.length || completedImports.length) {
        if (activeTab === 'in-progress') {
          setNumberOfNewCompleted(numberOfNewCompleted + completedImports.length)
        } else {
          setNumberOfNewCompleted(0)
        }
        void getCompletedImports()
        // get payments and select array of payment instructions and templates
        getDataAndSelectPayments([], [])
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  const getCompletedImports = async (): Promise<void> => {
    try {
      const params: QueryParams = {
        limit: 0,
      }
      const result = await importApi.getProfileImports(profileId, params)
      const completedImports = result.filter((paymentsImport: Import) => paymentsImport.status !== 'processing')
      setCompletedImports(completedImports)
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  const isAllowedToImportWithoutCard = (): boolean => {
    if (!rules) {
      return false
    }

    const signChainRules = rules.logic.requiredSignChain.rule
    const isSignRequired = signChainRules.every((rule: SignChainRule) => rule.requiredSignatures !== 0)
    return isSignRequired || !shouldRenderCardSelect
  }
  const handleUpload = async (files: File[]): Promise<void> => {
    setActiveTab('in-progress')
    const newFiles = files.filter(
      (file: File) => existingFiles.findIndex((existingFile: File) => existingFile.uid === file.uid) === -1
    )
    setExistingFiles([...existingFiles, ...newFiles])
    if (newFiles.length) {
      if (cardId || isAllowedToImportWithoutCard()) {
        try {
          const result = await Promise.all(newFiles.map((file: File) => createImport(file)))
          setProcessingImports(result)
        } catch (error) {
          Sentry.captureException(error)
        }
      } else {
        setShowCardError(true)
      }
    }
  }
  const handleSelectCard = (cardId: string): void => {
    setShowCardError(false)
    setCardId(cardId)
  }
  const handleCardRefresh = (sourceId: string): void => {
    setPreselectedCardId(sourceId)
    replaceDrawerHash(history, '/app/payments/add/import')
    void getCards()
  }
  // triggering after adding new card and refreshing cards state
  useEffect(() => {
    if (preselectedCardId) {
      handleSelectCard(preselectedCardId)
    }
  }, [cards])
  const handleChangeTab = (activeTab: string): void => {
    setNumberOfNewCompleted(0)
    setActiveTab(activeTab)
  }
  const toggleExampleTemplates = (): void => {
    setShowExampleTemplates((prev: boolean) => !prev)
  }

  const download = (url: string, fileName: string) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleDownloadExcel = (): void => {
    const downloadFileName = 'Billhop Demo File Excel SEPA and SWIFT.xlsx'

    const version2Url = `${getBackendRootUrl()}/static/PaymentImportTemplate-v2.xlsx`
    download(version2Url, downloadFileName)
  }

  const tabItems: TabsProps['items'] = [
    {
      key: 'in-progress',
      label: intl.formatMessage(pi['pi.import.tab.inProgress']),
      children: processingImports.length ? (
        <React.Fragment>
          <div className="import-list-container">
            {processingImportsPage.map((paymentImport: Import) => (
              <PaymentImport key={`import-${paymentImport.id!}`} paymentImport={paymentImport} />
            ))}
          </div>
          {processingImports.length > DEFAULT_IMPORTS_PAGE_SIZE && (
            <div className="imports-pagination">
              <Pagination
                current={currentProcessingImportsPage}
                defaultPageSize={DEFAULT_IMPORTS_PAGE_SIZE}
                showSizeChanger={false}
                total={processingImports.length}
                size="small"
                onChange={setCurrentProcessingImportsPage}
              />
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="no-imports-label">
          <span>{intl.formatMessage(pi['pi.import.noImportsInProgress'])}</span>
        </div>
      ),
    },
    {
      key: 'history',
      label: (
        <React.Fragment>
          {intl.formatMessage(pi['pi.import.tab.history'])}
          {activeTab === 'in-progress' && <Badge className="number-of-new-completed" count={numberOfNewCompleted} />}
        </React.Fragment>
      ),
      children: completedImports.length ? (
        <React.Fragment>
          <div className="import-list-container">
            {completedImportsPage.map((paymentImport: Import) => (
              <PaymentImport key={`import-${paymentImport.id!}`} paymentImport={paymentImport} />
            ))}
          </div>
          {completedImports.length > DEFAULT_IMPORTS_PAGE_SIZE && (
            <div className="imports-pagination">
              <Pagination
                current={currentCompletedImportsPage}
                defaultPageSize={DEFAULT_IMPORTS_PAGE_SIZE}
                showSizeChanger={false}
                total={completedImports.length}
                size="small"
                onChange={setCurrentCompletedImportsPage}
              />
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className="no-imports-label">
          <span>{intl.formatMessage(pi['pi.import.noCompletedImports'])}</span>
        </div>
      ),
    },
  ]

  return (
    <div className="import-payment-container">
      <div className="uploader">
        {shouldRenderCardSelect && (
          <div className="select-card-container">
            <div className="select-card-label">
              <span>{intl.formatMessage(pi['pi.add.addPayments.form.card.select.label'])}</span>
            </div>
            <div className="select-card-add-card">
              <Select
                placeholder={intl.formatMessage(pi['pi.add.addPayments.form.card.select.placeholder'])}
                value={cardId}
                onChange={handleSelectCard}
              >
                {cards?.map((card: Source, index: number): React.ReactNode => {
                  const optionClassName = classNames('payment-form-select-option', {
                    ['odd-option']: index % 2 === 0,
                  })
                  return (
                    <Select.Option key={`card-${card.id}`} className={optionClassName} value={card.id}>
                      <CreditCard card={card} />
                    </Select.Option>
                  )
                })}
              </Select>
              <ACL kind="source" barracksId={profileId} action="create">
                <Button type="primary" onClick={(): void => setDrawerHash(history, '#drawer-new-import-card')}>
                  {intl.formatMessage(pi['pi.add.addPayments.form.card.new'])}
                </Button>
              </ACL>
            </div>
            {showCardError && (
              <div className="source-error">
                <span>{intl.formatMessage(pi['pi.add.addPayments.form.card.errorMessage.required'])}</span>
              </div>
            )}
          </div>
        )}
        <div className="uploader-container">
          <div className="uploader-label">
            <span>{intl.formatMessage(pi['pi.import.importer.label'])}</span>
          </div>
          <BhUpload
            multiple={true}
            dragger
            accept=".csv, .xls, .xlsx, .xml"
            showUploadList={false}
            onChange={(files) => void handleUpload(files)}
          >
            <React.Fragment>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{intl.formatMessage(pi['pi.import.importer.instruction'])}</p>
              <p className="import-files-instructions">{intl.formatMessage(pi['pi.import.importer.instruction.2'])}</p>
            </React.Fragment>
          </BhUpload>
          <div className="supported-formats-container">
            <Button className="ant-dropdown-link" type="link" onClick={toggleExampleTemplates}>
              {intl.formatMessage(pi['pi.import.supportedFormatExample.label'])}
            </Button>
            {showExampleTemplates && (
              <div className="example-container">
                <div>{intl.formatMessage(pi['pi.import.supportedFormatExample.helpText'])}</div>
                <div className="download-link-container">
                  <a type="link" onClick={() => void handleDownloadExcel()}>
                    {intl.formatMessage(pi['pi.import.supportedFormatList.downlnoad.excel'])}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <Tabs className="import-tabs" activeKey={activeTab} onChange={handleChangeTab} items={tabItems} />
        </div>
      </div>
      <ActionPage
        title={intl.formatMessage(pi['pi.add.addPayments.action.addCard'])}
        hash="#drawer-new-import-card"
        pathname={history.location.pathname}
      >
        <CardForm cardNetworks={cardNetworks} gateways={gateways} profileId={profileId} refresh={handleCardRefresh} />
      </ActionPage>
    </div>
  )
}
export default ImportPayments
