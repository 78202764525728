import React from 'react'
import { Dropdown, MenuProps } from 'antd'
import { useIntl } from 'react-intl'
import { DownOutlined } from '@ant-design/icons'
import './CountrySelect.less'
interface Props {
  defaultCountry: string // initial country to be pre-selected in dropdown
  countries: string[] // The Array of countries select from
  handleLangChange: (countryCode: string) => void // a function that handle what should happen if language changes
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CountrySelect = (props: Props) => {
  const intl = useIntl()
  const { defaultCountry, countries, handleLangChange } = props

  const setCountryFlag = (countryCode: string) => {
    return (
      <img
        style={{ height: 12, marginRight: 5, marginBottom: 1 }}
        src={`https://flagcdn.com/84x63/${countryCode}.png`}
        srcSet={`https://flagcdn.com/168x126/${countryCode}.png 2x, https://flagcdn.com/252x189/${countryCode}.png 3x`}
        alt={countryCode}
      />
    )
  }

  const items: MenuProps['items'] = countries.map((country) => ({
    key: country,
    icon: setCountryFlag(country),
    onClick: () => {
      handleLangChange(country)
    },
    ['data-testid']: `login-country-picker-${country}`,
    label: (
      <span style={{ marginLeft: 5 }}>
        {intl.formatDisplayName(country.toUpperCase(), {
          type: 'region',
        })}
      </span>
    ),
  }))

  return (
    <div className="select-country" data-testid="login-country-picker">
      <Dropdown
        className="bh-track-country-picker"
        trigger={['click']}
        placement="bottom"
        menu={{
          items,
        }}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {setCountryFlag(defaultCountry)}
          {intl.formatDisplayName(defaultCountry.toUpperCase(), {
            type: 'region',
          })}
          <DownOutlined />
        </a>
      </Dropdown>
    </div>
  )
}

export default CountrySelect
