import { IPhoneInput } from 'pages/Register/utils'
import React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import { PhoneNumberMask, phoneInputMasks } from 'utils/helpers'

export type PhoneValue = {
  number: string
  countryCode: string
}

export interface PhoneInputProps {
  value?: PhoneValue
  onChange?: (data: PhoneValue) => void
  enableSearch?: boolean
  preferredCountries?: string[]
  onlyCountries?: string[]
  defaultCountry?: string
  placeholder?: string
  inputClass?: string
  buttonClass?: string
  containerClass?: string
  disabled?: boolean
  masks?: () => PhoneNumberMask
  [key: string]: unknown
}

export const MIN_PHONE_NUMBER_LENGTH = 4

export const PREFERRED_COUNTRIES = ['ie', 'fi', 'nl', 'it', 'se', 'gb']

const PhoneInput = React.forwardRef((props: PhoneInputProps, ref: React.Ref<React.FC<PhoneInputProps>>) => {
  const handleChange = (value: string, details: IPhoneInput) => {
    props.onChange &&
      props.onChange({
        number: value,
        countryCode: details.countryCode,
      })
  }

  return (
    <ReactPhoneInput
      {...props}
      country={props.value?.countryCode || props.defaultCountry}
      inputProps={{ ref }}
      value={props.value?.number}
      onChange={handleChange}
      inputClass={props.inputClass || 'bh-phone-input ant-input'}
      buttonClass={props.buttonClass || 'bh-phone-btn'}
      containerClass={props.containerClass || 'bh-phone'}
      disabled={props.disabled}
      masks={props.masks || phoneInputMasks}
      preferredCountries={props.preferredCountries || PREFERRED_COUNTRIES}
    />
  )
})

export default PhoneInput
