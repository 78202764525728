import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import classNames from 'classnames'
import { Calendar, Button } from 'antd'
import './DueDateSelector.less'
import { Holiday } from '../../../../../types/greg'
import { CardNetworkBankDay } from '../../../../../types/paymentInstruction'
import { formatCardNetworksBankDays } from '../../utils'
import { pi } from '../../../../../lang/definitions'

const DATE_FORMAT = 'YYYY-MM-DD'

interface DueDateSelectorInterface {
  bankDays: CardNetworkBankDay[]
  bankHolidays: Holiday[]
  dateDue?: moment.Moment
  onSelectDueDate: (date: moment.Moment) => void
}
const DueDateSelector = (props: DueDateSelectorInterface): React.JSX.Element => {
  const { bankDays, bankHolidays, dateDue, onSelectDueDate } = props

  const intl = useIntl()

  const [hoveredDay, setHoveredDay] = useState<moment.Moment>()
  const [selectedDay, setSelectedDay] = useState<moment.Moment>()

  useEffect(() => {
    if (dateDue) {
      setSelectedDay(dateDue)
    }
  }, [])

  const findBankDateIndex = (date: moment.Moment): number => {
    const formatedBankDays = formatCardNetworksBankDays(
      bankDays.map((day: CardNetworkBankDay) => ({
        cardNetwork: day.cardNetwork,
        bankDay: getDay(day.bankDay),
      }))
    )
    const bankDateIndex = formatedBankDays.findIndex(
      (day: CardNetworkBankDay) => day.bankDay.format(DATE_FORMAT) === date.format(DATE_FORMAT)
    )

    return bankDateIndex
  }

  const isBankHoliday = (date: moment.Moment): boolean => {
    const bankHoliday = bankHolidays.find((holiday: Holiday) => {
      return holiday.date === date.format(DATE_FORMAT)
    })
    return bankHoliday !== undefined
  }

  const isDateBeforeFirstBankDay = (date: moment.Moment): boolean => {
    if (!date || !bankDays.length) return false
    return date.isBefore(bankDays[0].bankDay)
  }

  const isWeekendDate = (date: moment.Moment): boolean => {
    const day = date.day()

    return day === 6 || day === 0 // 6 = Saturday; 0 = Sunday
  }

  const isDisabledDate = (date: moment.Moment): boolean => {
    return isWeekendDate(date) || isDateBeforeFirstBankDay(date) || isBankHoliday(date)
  }

  const onSelect = (date: moment.Moment): void => {
    const formatedDate = date.format(DATE_FORMAT)
    setSelectedDay(moment(formatedDate))
  }

  const handleOnMouseOver = (date: moment.Moment): void => {
    setHoveredDay(date)
  }

  const handleOnMouseLeave = (): void => {
    setHoveredDay(undefined)
  }

  const dateCellRender = (date: moment.Moment): React.ReactNode => {
    const disabled = isDisabledDate(date)
    const bankDateIndex = findBankDateIndex(date)
    const dateClassName = classNames('date', {
      disabled,
      ['bank-date']: bankDateIndex !== -1,
      ['red']: bankDateIndex === 1,
      ['selected']: selectedDay && date.format(DATE_FORMAT) === selectedDay.format(DATE_FORMAT),
    })
    return (
      <div
        className="calendar-cell"
        onMouseOver={() => !disabled && handleOnMouseOver(date)}
        onMouseLeave={handleOnMouseLeave}
        onClick={() => !disabled && onSelect(date)}
        id={date.toISOString().split('T')[0]}
      >
        <div className={dateClassName}>{date.date()}</div>
      </div>
    )
  }

  const getDay = (bankDay: moment.Moment): moment.Moment => {
    if (hoveredDay) {
      if (hoveredDay.isAfter(bankDay)) {
        return hoveredDay
      } else {
        return bankDay
      }
    } else if (selectedDay && selectedDay.isAfter(bankDay)) {
      return selectedDay
    } else {
      return bankDay
    }
  }

  const isUpdateButtonDisabled = (): boolean => {
    return !selectedDay || selectedDay.format(DATE_FORMAT) === dateDue?.format(DATE_FORMAT)
  }
  return (
    <div className="due-date-selector-container" data-testid="date-due-drawer">
      <Calendar fullscreen={false} dateFullCellRender={dateCellRender} />
      <div className="bank-days-container">
        {formatCardNetworksBankDays(
          bankDays.map((day: CardNetworkBankDay) => ({
            cardNetwork: day.cardNetwork,
            bankDay: getDay(day.bankDay),
          }))
        ).map((day: CardNetworkBankDay, index: number): React.ReactNode => {
          const dateClassName = classNames('date', {
            red: index > 0,
          })

          return (
            <div key={`bank-day-${day.cardNetwork}`} className="bank-date">
              <div className={dateClassName}>{day.bankDay.date()}</div>
              <span className="card-network">{day.cardNetwork}</span>
            </div>
          )
        })}
      </div>

      <div className="due-date-update-button-container">
        <Button
          type="primary"
          size="large"
          block
          disabled={isUpdateButtonDisabled()}
          onClick={() => onSelectDueDate(selectedDay!)}
          data-testid="submit-date-due-btn"
        >
          {intl.formatMessage(pi['pi.add.addPayments.dateDueForm.update'])}
        </Button>
      </div>
    </div>
  )
}

export default DueDateSelector
