import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Badge, Dropdown, MenuProps } from 'antd'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import './ActionMenu.less'
import { RequiredACL, RulesLogic } from '../../types/rules'
import acl from '../../utils/acl'
import { useSession } from 'stores/session'

export interface Action {
  id?: string
  label: string
  type: 'button' | 'primary' | 'link' | 'dropdown-button' | 'other'
  link?: string
  externalLink?: boolean
  disabled?: boolean
  elementType?: string
  badge?: {
    color?: string
    count: number
  }
  callback?: () => void
  requiredACL?: RequiredACL[]
  requiredRoles?: string[]
  applicableEntityClasses?: string[]
  requiredRuleSettings?: {
    kind: string
    action: (a: Partial<RulesLogic>) => boolean
  }
  menu?: React.JSX.Element | MenuProps['items']
  loading?: boolean
  className?: string
}

export interface Group {
  id?: string
  actions: Array<Action>
}

interface ActionMenuProps {
  isOpen: boolean
  actions?: Array<Action>
  groups?: Array<Group>
  label?: string
  closeMenu?: () => void
  mobile?: boolean
  className?: string
}

const ActionMenu = (props: ActionMenuProps): React.JSX.Element => {
  const { state: sessionState } = useSession()
  const iam = sessionState.iam!
  const me = sessionState.user!
  const rules = sessionState.rules!

  const { activeProfileId, profiles } = me
  const [entityClass, setEntityClass] = useState('')

  useEffect(() => {
    const currentProfile = profiles.find((profile) => profile.id === activeProfileId)
    setEntityClass(Object.keys(currentProfile?.entity?.class || {})[0])
  }, [activeProfileId])

  const renderButton = (action: Action, index: number): React.JSX.Element => {
    const { badge, elementType, label, type, disabled, callback, className } = action

    const button = (
      <Button
        danger={elementType === 'danger'}
        block
        key={`action-button-${index}`}
        data-testid={action.id}
        type={type === 'primary' ? 'primary' : type === 'button' ? 'default' : 'link'}
        onClick={() => callback && callback()}
        disabled={disabled}
        size="large"
        className={className && className}
      >
        {label}
      </Button>
    )
    if (badge) {
      return (
        <Badge count={badge.count} style={badge.color ? { backgroundColor: badge.color } : {}}>
          {button}
        </Badge>
      )
    } else {
      return button
    }
  }

  const renderDropdownButton = (action: Action, index: number): React.JSX.Element => {
    const { badge, elementType, label, type, disabled, menu, loading } = action

    const button = (
      <Dropdown
        menu={{
          items: menu as MenuProps['items'],
        }}
      >
        <Button
          danger={elementType === 'danger'}
          block
          key={`action-button-${index}`}
          type={type === 'button' ? 'primary' : 'link'}
          disabled={disabled}
          loading={loading}
        >
          {label} <DownOutlined />
        </Button>
      </Dropdown>
    )
    if (badge) {
      return (
        <Badge count={badge.count} style={badge.color ? { backgroundColor: badge.color } : {}}>
          {button}
        </Badge>
      )
    } else {
      return button
    }
  }

  const renderAction = (action: Action, index: number): React.JSX.Element => {
    if ((action.type === 'button' || action.type === 'primary') && action.callback) {
      return (
        <li key={`action-${index}`} className={`action action-button ${action.id ? `${action.id}` : ''}`}>
          {renderButton(action, index)}
        </li>
      )
    } else if (action.type === 'link' && action.link?.length) {
      if (action.externalLink) {
        return (
          <li key={`action-${index}`} className="action action-button">
            <a href={`https://${action.link}`} target="_blank" rel="noopener noreferrer">
              {renderButton(action, index)}
            </a>
          </li>
        )
      }
      return (
        <li key={`action-${index}`} className="action action-button">
          <Link to={action.link}>{renderButton(action, index)}</Link>
        </li>
      )
    } else if (action.type === 'link' && action.callback) {
      return (
        <li key={`action-${index}`} className="action action-button">
          {renderButton(action, index)}
        </li>
      )
    } else if (action.type === 'dropdown-button') {
      return (
        <li key={`action-${index}`} className="action action-button">
          {renderDropdownButton(action, index)}
        </li>
      )
    } else {
      return (
        <li key={`action-${index}`} className="action">
          <span>{action.label}</span>
        </li>
      )
    }
  }

  const actionMenuClassName = classNames('action-menu-container', {
    open: props.isOpen,
  })

  const filterAction = (action: Action) => {
    const { requiredACL } = action
    if (requiredACL) {
      // an array of requirements, make sure at least one is fullfilled
      const tests = requiredACL.map((a) => {
        const { kind, action } = a
        return acl({ iam, me, kind, action })
      })

      const test = tests.some((v) => v === true)

      return test
    }
    // Return true iof no requirenents are set
    return true
  }

  const filterByRule = (item: {
    requiredRuleSettings?: {
      kind: string
      action: (a: Partial<RulesLogic>) => boolean
    }
  }) => {
    if (!item.requiredRuleSettings) {
      return true
    }

    const { kind, action } = item.requiredRuleSettings
    return action(rules?.logic[kind] as Partial<RulesLogic>)
  }

  const filterActionEntityClass = (action: Action) => {
    const { applicableEntityClasses } = action
    if (applicableEntityClasses) {
      return action.applicableEntityClasses?.includes(entityClass)
    } else {
      return true
    }
  }
  // Filter actions based on ACL requirements
  const filteredActions = (props.actions || []).filter(filterAction)

  const filteredActionGroups = (props.groups || []).reduce((groups: Array<Group>, group: Group) => {
    const filteredByAction = (group.actions || []).filter(filterAction)

    const filteredByRule = filteredByAction.filter(filterByRule)

    const filterBasedOnEntityClass = filteredByRule.filter(filterActionEntityClass)

    if (filterBasedOnEntityClass && filterBasedOnEntityClass.length) {
      groups.push({ ...group, actions: filterBasedOnEntityClass })
    }

    return groups
  }, [])

  // if groups, do the same

  return (
    <div className={actionMenuClassName}>
      <div className="action-menu-header">
        <div className="label">
          <h2>{props.label}</h2>
        </div>
        <div className="close-button">
          <button type="button" onClick={() => props.closeMenu && props.closeMenu()}>
            <CloseOutlined />
          </button>
        </div>
      </div>
      {!!filteredActions.length && (
        <ul className="actions">
          {filteredActions.map((action: Action, index: number) => renderAction(action, index))}
        </ul>
      )}
      {!!filteredActionGroups.length &&
        filteredActionGroups.map((group) => {
          return (
            <ul key={`group-${group.id!}`} className="actions">
              {group.actions.map((action: Action, index: number) => renderAction(action, index))}
            </ul>
          )
        })}
    </div>
  )
}

export default ActionMenu
