import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Button, Alert, Typography } from 'antd'
import { InboxOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { BhUpload } from 'components/Upload/Upload'
import { Attachment } from 'types/paymentInstruction'
import { pi } from 'lang/definitions'
import './PaymentAttachmentForm.less'

const FILES_UPLOAD_LIMIT = 1
const { Text } = Typography

interface PaymentAttachmentFormProps {
  setAttachment: (attachment?: Attachment) => void
}

const PaymentAttachmentForm = ({ setAttachment }: PaymentAttachmentFormProps): React.JSX.Element => {
  const intl = useIntl()
  const [showRequiredFieldError, setShowRequiredFieldError] = useState(false)
  const [hasFileError, setHasFileError] = useState(false)
  const [updateFailAlert, setUpdateFailAlert] = useState(false)

  const handleSubmit = (values: { attachment: { base64Content: string; name: string }[]; comment: string }): void => {
    if (!values.attachment || values.attachment.length === 0) {
      setAttachment()
      return
    }

    const attachment: Attachment = {
      filename: values.attachment[0].name,
      fileContents: values.attachment[0].base64Content,
    }

    if (values.comment) {
      attachment.comment = values.comment
    }

    if (hasFileError) {
      setUpdateFailAlert(true)
    } else {
      setUpdateFailAlert(false)
      setAttachment(attachment)
    }
  }

  const handleOnChange = (): void => {
    if (showRequiredFieldError) {
      setShowRequiredFieldError(false)
    }
  }

  return (
    <div className="payment-attachment-form-container">
      <Form onFinish={handleSubmit} onChange={handleOnChange}>
        {updateFailAlert && (
          <Alert
            message={intl.formatMessage(pi['pi.attachment.error.alert.message'])}
            description={intl.formatMessage(pi['pi.attachment.commonError.alert.description'])}
            type="warning"
            icon={<ExclamationCircleFilled />}
            showIcon
            className="invoice-attachment-alert"
          />
        )}
        <Form.Item name="attachment" style={{ marginBottom: 8 }}>
          <BhUpload
            multiple={false}
            dragger
            limitUploads={FILES_UPLOAD_LIMIT}
            onChange={() => {}}
            accept=".png, .jpg, .jpeg, .pdf"
            checkInvoiceFileTypeAndSize
            dontPreviewFileImage
            setHasFileError={setHasFileError}
            setUpdateFailAlert={setUpdateFailAlert}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{intl.formatMessage(pi['pi.attachment.uploader.text'])}</p>
            <p className="ant-upload-hint">{intl.formatMessage(pi['pi.attachment.uploader.hintText'])}</p>
          </BhUpload>
        </Form.Item>

        {hasFileError && (
          <div style={{ marginBottom: 16 }}>
            <Text type="danger">{intl.formatMessage(pi['pi.attachment.fileError'])}</Text>
          </div>
        )}

        <Form.Item name="comment">
          <Input.TextArea
            className="uploader-comment bh-textarea"
            placeholder={intl.formatMessage(pi['pi.attachment.comment.placeholder'])}
          />
        </Form.Item>
        <Form.Item className="form-submit-button-container">
          <Button htmlType="submit" type="primary" size="large" block>
            {intl.formatMessage(pi['pi.attachment.updateButton'])}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default PaymentAttachmentForm
