import { api } from './utils'
import { Source, SourceData, DeleteSourcesBody, DeleteSourcesResponse } from '../types/source'
import { FilterBody } from '../components/Filter/Filter'
import { QueryParams } from '../types/general'
import * as Sentry from '@sentry/react'
import { Charge } from 'types/charge'

const BASE_PATH = 'trams'

export const getUserSourcesOnFile = async (id: string): Promise<{ sources: Array<Source>; total: number }> => {
  const url = `/${BASE_PATH}/source/user/${id}/on-file?limit=10000`

  const response = await api.get(url)

  const sources = (await response.json()) as Array<Source>
  const total = response.headers.get('x-total-count')

  return {
    sources,
    total: Number.parseInt(total || '', 10),
  }
}

export const getProfileSourcesOnFile = async (id: string): Promise<{ sources: Array<Source>; total: number }> => {
  const url = `/${BASE_PATH}/source/profile/${id}/on-file`

  const response = await api.get(url)

  const sources = (await response.json()) as Array<Source>
  const total = response.headers.get('x-total-count')

  return {
    sources,
    total: Number.parseInt(total || '', 10),
  }
}

export const deleteSource = async (id: string): Promise<void> => {
  const url = `/${BASE_PATH}/source/${id}`

  await api.delete(url)
}

export const deleteSources = async (body: DeleteSourcesBody): Promise<DeleteSourcesResponse> => {
  const url = `/${BASE_PATH}/source`

  const response = await api.delete(url, body)
  return response.json()
}

export const editSource = async (data: Partial<Source>, id: string): Promise<void> => {
  const url = `/${BASE_PATH}/source/${id}`

  const response = await api.patch(url, data)
  return response.json()
}

export const sourceCreate = async (data: SourceData): Promise<Source> => {
  const url = `/${BASE_PATH}/source/`
  const remoteData = await api.post(url, data)
  return remoteData.json()
}

export const pingCharge = async (id: string): Promise<Charge> => {
  const url = `/${BASE_PATH}/charge/${id}`
  const remoteData = await api.get(url)

  return remoteData.json()
}

export const getEntitySourcesOnFile = async (id: string): Promise<Source[] | undefined> => {
  const url = `/${BASE_PATH}/source/entity/${id}/on-file`

  try {
    const response = await api.get(url)

    const sources = (await response.json()) as Source[]

    return sources
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const getEntitySources = async (id: string): Promise<Source[]> => {
  const url = `/${BASE_PATH}/source/entity/${id}`

  const response = await api.get(url)

  const sources = (await response.json()) as Source[]

  return sources
}

export const searchSources = async (
  filter: FilterBody,
  params?: QueryParams
): Promise<{
  sources: Array<Source>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`
  const body: FilterBody = {
    operator: 'and',
    ...filter,
  }
  const response = await api.post(url, body, params)

  const sources = (await response.json()) as Array<Source>
  const total = response.headers.get('x-total-count')

  return {
    sources,
    total: Number.parseInt(total || '', 10),
  }
}
