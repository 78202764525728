import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { User } from '../../types/user'
import acl from '../../utils/acl'
import { menuItems, profileMenuItems, adminMenuItems, MenuItem as Item } from '../../utils/menu'
import './Sidebar.less'
import { useIntl } from 'react-intl'
import { menu } from '../../lang/definitions/index'
import { getSessionInfo } from '../../api/auth'
import { RulesLogic } from '../../types/rules'
import { find } from 'lodash'
import { Menu, Button, MenuProps } from 'antd'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { useSession } from 'stores/session'

type MenuItem = Required<MenuProps>['items'][number]

export interface SideBarMenuProps {
  user: User
  setUser: (user: User) => void
}

const Sidebar: React.FC<SideBarMenuProps> = ({ user }: SideBarMenuProps) => {
  const intl = useIntl()
  const history = useHistory()
  const { state: sessionState } = useSession()

  const iam = sessionState.iam!
  const me = sessionState.user!
  const rules = sessionState.rules!

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false)

  const [selectedKey] = useState<string>('')
  const [entityClass, setEntityClass] = useState('')

  const checkIsPerson = (): boolean => {
    const activeProfileClass = user?.profiles.find((x) => x.id === user.activeProfileId)?.entity.class
    return !!(activeProfileClass && activeProfileClass['person'])
  }

  useEffect(() => {
    const getCurrentSession = async () => {
      if (user?.sessionId) {
        const currentSession = await getSessionInfo()
        const currentProfile = find(user?.profiles, {
          id: currentSession.profileId,
        })

        setEntityClass(Object.keys(currentProfile?.entity?.class || {})[0])
      }
    }
    void getCurrentSession()
  }, [user])

  useEffect(() => {
    const location = history.location.pathname
    const isAdminMenuOpen = location.startsWith('/app/admin')
    setIsAdminMenuOpen(isAdminMenuOpen)
  })

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState)
  }

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen((prevState) => !prevState)
  }

  const prepareItems = (): Array<Item> => {
    if (isProfileMenuOpen) {
      return profileMenuItems(intl)
    } else if (isAdminMenuOpen) {
      return adminMenuItems(intl)
    } else {
      return menuItems(intl)
    }
  }

  /**
   * Will go through all menu options and filter out the ones that is not meeting the ACL requirements
   */

  const filterAction = (item: Item) => {
    const { requiredACL } = item
    if (requiredACL) {
      // an array of requirements, make sure at least one is fullfilled
      const tests = requiredACL.map((a) => {
        const { kind, action } = a
        return acl({ iam, me, kind, action })
      })

      const test = tests.some((v) => v === true)

      return test
    }
    // Return true iof no requirenents are set
    return true
  }

  const filterByRule = (item: {
    requiredRuleSettings?: {
      kind: string
      action: (a: Partial<RulesLogic>) => boolean
    }
  }) => {
    if (!item.requiredRuleSettings) {
      return true
    }

    const { kind, action } = item.requiredRuleSettings
    return action(rules.logic[kind] as Partial<RulesLogic>)
  }

  const filterChildren = (item: Item) => {
    const { children } = item
    const newItem = { ...item }

    if (children) {
      const filteredByAction = children.filter(filterAction)
      const filteredByRule = filteredByAction.filter(filterByRule)

      const childrenToReturn = filteredByRule.filter((child) => {
        return child.applicableEntityClasses?.includes(entityClass)
      })

      const childrenWithoutDefault = childrenToReturn.filter((child) => child?.default !== true)

      if (childrenWithoutDefault.length > 0) {
        newItem.children = childrenToReturn
      } else {
        newItem.children = []
      }

      return newItem
    } else {
      return item
    }
  }

  const aclAndCorpCheck = (items: Array<Item>): Array<Item> => {
    let filteredParentBasedOnAclRules = items.filter(filterAction)

    if (checkIsPerson()) {
      filteredParentBasedOnAclRules = filteredParentBasedOnAclRules.filter((item) => {
        return item.path && !item.path.startsWith('/app/admin')
      })
    }

    const finalFilteredItems = filteredParentBasedOnAclRules.map(filterChildren)

    const itemsToReturn = finalFilteredItems.filter((item) => {
      const { requiredRoles } = item
      let passRoles = true
      if (requiredRoles) {
        passRoles = acl({ iam, me, requiredRoles })
      }

      return passRoles
    })
    return itemsToReturn
  }

  const renderBackItem = (): React.ReactNode => {
    if (!isAdminMenuOpen && !isProfileMenuOpen) return

    const label = isProfileMenuOpen
      ? intl.formatMessage(menu['menu.admin.back'])
      : intl.formatMessage(menu['menu.admin.backHome'])
    return (
      <div className="back-button-container">
        <Button
          block
          onClick={() => {
            if (isProfileMenuOpen) {
              toggleProfileMenu()
            } else {
              history.push('/')
              toggleMenu()
            }
          }}
          type="primary"
        >
          {label}
        </Button>
      </div>
    )
  }

  const isMenuItemActive = (path: string): boolean => {
    const hash = history.location.hash

    if (hash && path.includes('#')) {
      return path === `${history.location.pathname}${hash}`
    }

    return path === history.location.pathname
  }

  const sidebarClassName = classNames('sidebar', {
    hidden: !isMenuOpen,
  })

  const items: MenuItem[] = aclAndCorpCheck(prepareItems()).map((item: Item) => ({
    key: `item__${item.path}`,
    label: item.label,
    icon: <item.icon />,
    className: classNames('menu-item', {
      active: item.path ? isMenuItemActive(item.path) : false,
      ['settings-menu-override']: item.path?.includes('settings'),
    }),
    children: item.children?.map((child) => ({
      key: `child__${child.path}`,
      label: child.label,
      icon: <child.icon />,
      className: classNames('child-menu-item', {
        active: isMenuItemActive(child.path),
      }),
    })),
  }))

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    const path = e.key.split('__')[1]
    history.push(path)
    toggleMenu()
  }

  return (
    <React.Fragment>
      <div className="sidebar-button-container">
        {isMenuOpen ? (
          <CloseOutlined
            onClick={toggleMenu}
            style={{
              color: '#ffffff',
              fontSize: '28px',
            }}
          />
        ) : (
          <MenuOutlined
            onClick={toggleMenu}
            style={{
              color: '#ffffff',
              fontSize: '28px',
            }}
          />
        )}
      </div>
      <div className={sidebarClassName} data-testid="sidebar-container">
        <div className="menu-container">
          {isAdminMenuOpen && !isProfileMenuOpen && (
            <div className="menu-label">
              <span>{intl.formatMessage(menu['menu.left.adminConsole'])}</span>
            </div>
          )}
          <Menu
            data-testid="sidebar-menu-list"
            onClick={handleMenuClick}
            theme="dark"
            className="menu-list"
            mode="inline"
            selectedKeys={[`${selectedKey}`]}
            items={items}
          />
          {renderBackItem()}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
