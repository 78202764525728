import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import ForeignCurrencyWarning from 'components/ForeignCurrencyWarning/ForeignCurrencyWarning'
import { Set, SetTotalDetails, PAYMENT_KIND, PAYMENT_STATE } from 'types/paymentInstruction'
import { pi } from 'lang/definitions'
import { useCurrentPayment } from 'stores/Payment'
import Payments from '../../components/Payments/Payments'
import PaymentsSummary from '../../components/PaymentsSummary/PaymentsSummary'
import PaymentsHeader from '../../components/PaymentsHeader'
import './ReviewPayments.less'

interface ReviewPaymentsProps {
  set?: Set
  removePaymentFromSet: (kind: PAYMENT_KIND, id: string) => void
  showPaymentDetails: (kind: PAYMENT_KIND, paymentId: string) => void
  submitButton?: React.JSX.Element
}
const ReviewPayments = (props: ReviewPaymentsProps): React.JSX.Element => {
  const { set, removePaymentFromSet, showPaymentDetails, submitButton } = props

  const intl = useIntl()

  const [paymentInstructionDetails, setPaymentInstructionDetails] = useState<SetTotalDetails>()
  const [templateDetails, setTemplateDetails] = useState<SetTotalDetails>()

  const {
    state: { paymentsInSet },
  } = useCurrentPayment()

  useEffect(() => {
    if (set && set.paymentInstructionState === PAYMENT_STATE.READY) {
      const paymentInstructionDetails = set.detailsTotal?.details.find(
        (details: SetTotalDetails) => details.kind === 'paymentInstruction'
      )

      const templateDetails = set.detailsTotal?.details.find(
        (details: SetTotalDetails) => details.kind === 'paymentInstructionTemplate'
      )

      setPaymentInstructionDetails(paymentInstructionDetails)
      setTemplateDetails(templateDetails)
    } else {
      setPaymentInstructionDetails(undefined)
      setTemplateDetails(undefined)
    }
  }, [set])

  return (
    <div className="review-payments-container">
      <PaymentsHeader label={intl.formatMessage(pi['pi.review.reviewPayments.header'])} enablePaymentsFilter={false} />
      {paymentsInSet.length ? (
        <React.Fragment>
          <Payments
            paymentList={paymentsInSet}
            totalNumberOfPayments={paymentsInSet.length}
            disableSelect={true}
            selectPayments={() => {
              return
            }}
            deselectPayments={() => {
              return
            }}
            removePaymentFromSet={removePaymentFromSet}
            showPaymentDetails={showPaymentDetails}
          />
          <React.Fragment>
            {paymentInstructionDetails && paymentInstructionDetails.count > 0 && (
              <PaymentsSummary setTotalDetails={paymentInstructionDetails} />
            )}
            {templateDetails && templateDetails.count > 0 && <PaymentsSummary setTotalDetails={templateDetails} />}
          </React.Fragment>
          <ForeignCurrencyWarning payments={paymentsInSet} />

          {submitButton ? submitButton : null}
        </React.Fragment>
      ) : (
        <div className="no-data-message">
          <span>{intl.formatMessage(pi['pi.pay.reviewPayments.noDataMessage'])}</span>
        </div>
      )}
    </div>
  )
}

export default ReviewPayments
