import React, { useContext } from 'react'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { AlertMessagesContext } from 'utils/context'
import { AlertMessage, AlertMessageContent } from 'types/messages'
import { updateMessageSeenBy, updateMessageClosedBy } from 'api/timesSquare'
import { useLanguageState } from 'stores/language/LanguageStore'
import AlertMessages from './AlertMessages'
import './AlertMessagesContainer.less'
import { useSession } from 'stores/session'

interface AlertMessagesContainerProps {
  placement: string | string[]
}
const AlertMessagesContainer = ({ placement }: AlertMessagesContainerProps): React.JSX.Element => {
  const { messages: alertMessages } = useContext(AlertMessagesContext)
  const {
    state: { user },
  } = useSession()
  const [languageState] = useLanguageState()
  const language = languageState.language

  const markAsSeen = async (seenMessages: AlertMessage[], userId: string): Promise<void> => {
    try {
      const response = await Promise.allSettled(
        seenMessages.map((message: AlertMessage) => updateMessageSeenBy(message.id, userId))
      )
      // update message in context without creating new instance of object
      response.forEach((response: PromiseSettledResult<AlertMessage>) => {
        if (response.status === 'fulfilled') {
          const { id } = response.value
          const messageIndexInContext = alertMessages.findIndex((message: AlertMessage) => message.id === id)
          alertMessages[messageIndexInContext].seenBy = [userId]
        }
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleCloseAlert = (messageId: string, userId: string): void => {
    try {
      void updateMessageClosedBy(messageId, userId)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const getContentByLanguage = (messageContent: AlertMessageContent[]): AlertMessageContent[] => {
    const content = messageContent.filter((content) => content.locale === language)

    if (content.length) {
      return content
    }

    return messageContent.filter((content) => content.locale === 'en-GB')
  }

  const messages = alertMessages
    .filter((message: AlertMessage) => !!message.placement)
    .filter((message: AlertMessage) => placement.includes(message.placement))
    .map((message: AlertMessage) => ({
      ...message,
      content: getContentByLanguage(message.content),
    }))
    // only return messages with actual content
    .filter((message: AlertMessage) => message.content && message.content.length)

  return (
    <div
      className={classNames('alert-messages-container', {
        ['add-card']: placement === 'addCard',
      })}
    >
      <AlertMessages
        isBanner={!(placement === 'addCard') && !(placement === 'addPayment')}
        messages={messages}
        user={user}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        markAsSeen={markAsSeen}
        handleCloseAlert={handleCloseAlert}
      />
    </div>
  )
}

export default AlertMessagesContainer
