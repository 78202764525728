import { api } from './utils'

const BASE_PATH = 'external'

export const loginSSO = async (provider: string): Promise<{ redirectUrl: string }> => {
  const url = `/${BASE_PATH}/zuul/sso/${provider}`

  const response = await api.get(url)
  return response.json()
}

export const getNonProvisionedUserMessage = async (provider: string): Promise<{ nonProvisionUserMessage: string }> => {
  const url = `/${BASE_PATH}/zuul/sso/nonprovisioneduser/${provider}`
  //try catch here is gandled in the component in order to catch specific errors
  try {
    const response = await api.get(url)
    return response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const motorolaSSOLogin = async (): Promise<{ redirectUrl: string }> => {
  const url = `/${BASE_PATH}/zuul/sso/motorola`

  try {
    const response = await api.get(url)
    return response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}
