import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { Payment, PAYMENT_KIND, FilterFields } from 'types/paymentInstruction'
import NewBeneficiaryWarning from 'components/NewBeneficiaryWarning/NewBeneficiaryWarning'
import { useTranslation } from 'utils/helpers'
import { useCurrentPayment } from 'stores/Payment'
import PaymentsHeader from '../../components/PaymentsHeader'
import Payments from '../../components/Payments/Payments'
import './SelectPayments.less'

interface SelectPaymentsProps {
  totalNumberOfPayments: number
  addSelectedPaymentsToSet: () => void
  selectPayments: (payments: Payment[]) => void
  selectAllPayments: () => void
  deselectPayments: (payments: Payment[]) => void
  deselectAllPayments: () => void
  showPaymentDetails: (kind: PAYMENT_KIND, paymentId: string) => void
  deletePayment?: (payment: Payment) => void
}

const SelectPayments = ({
  totalNumberOfPayments,
  addSelectedPaymentsToSet,
  selectPayments,
  selectAllPayments,
  deselectPayments,
  deselectAllPayments,
  showPaymentDetails,
  deletePayment,
}: SelectPaymentsProps): React.JSX.Element => {
  const t = useTranslation()

  const {
    state: { selectedPayments, paymentsOnCurrentPage },
  } = useCurrentPayment()

  const [isSelectedPaymentsWithSource, setIsSelectedPaymentsWithSource] = useState(false)

  useEffect(() => {
    if (!selectedPayments.length) {
      return
    }

    const isSelectedPaymentsWithSource = selectedPayments.some((payment: Payment) => !payment.sourceId)

    setIsSelectedPaymentsWithSource(isSelectedPaymentsWithSource)
  }, [selectedPayments])

  const getSubmitButtonLabel = (): string => {
    if (selectedPayments.length && isSelectedPaymentsWithSource) {
      return t('pi.review.selectPayments.submitButton.disabled.noSource')
    }

    if (selectedPayments.length) {
      return t('pi.review.selectPayments.submitButton', {
        numberOfPayments: selectedPayments.length,
      })
    }

    return t('pi.review.selectPayments.submitButton.disabled')
  }

  return (
    <div className="select-payments-container">
      <PaymentsHeader
        label={t('pi.review.selectPayments.header')}
        filterFields={[FilterFields.PENDING_APPROVALS, FilterFields.CURRENCY, FilterFields.CARD]}
        selectAllPayments={selectAllPayments}
        deselectAllPayments={deselectAllPayments}
        enablePaymentsFilter
      />
      {paymentsOnCurrentPage.length > 0 ? (
        <React.Fragment>
          <Payments
            paymentList={paymentsOnCurrentPage}
            totalNumberOfPayments={totalNumberOfPayments}
            selectPayments={selectPayments}
            deselectPayments={deselectPayments}
            showPaymentDetails={showPaymentDetails}
            deletePayment={deletePayment}
          />
          <NewBeneficiaryWarning payments={paymentsOnCurrentPage} />
          <div className="add-payments-button">
            <Button
              type="default"
              block
              size="large"
              disabled={!selectedPayments.length || isSelectedPaymentsWithSource}
              onClick={addSelectedPaymentsToSet}
            >
              {getSubmitButtonLabel()}
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <div className="no-data-message">
          <span>{t('pi.review.selectPayments.noDataMessage')}</span>
        </div>
      )}
    </div>
  )
}

export default SelectPayments
