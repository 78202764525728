import React from 'react'
import { Button, Modal, Typography } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import './ErrorFallbackComponent.less'

interface Props {
  errorId: string
  resetErrorBoundary: () => void
}

const ErrorFallback: React.FC<Props> = ({ errorId, resetErrorBoundary }) => {
  return (
    <Modal
      data-testid="error-boundary-modal"
      mask={true}
      maskStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
      }}
      closable={false}
      className="error-boundary-modal"
      title={
        <div className="error-boundary-title-container">
          <ExclamationCircleFilled className="error-boundary-icon" />
          <Typography.Title className="error-boundary-title" level={3}>
            Something went wrong
          </Typography.Title>
        </div>
      }
      open={true}
      footer={[
        <Button key="button" type="primary" onClick={resetErrorBoundary}>
          Refresh
        </Button>,
      ]}
    >
      <Typography.Paragraph>
        Please refresh the page and contact Customer Support if the problem persists.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Error ID: <strong>{errorId}</strong>
      </Typography.Paragraph>
    </Modal>
  )
}

export default ErrorFallback
