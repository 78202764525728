import React from 'react'
import { PageHeader } from 'antd'
import classNames from 'classnames'
import Logo from '../../assets/logo/billhop-dark-gray.svg'
import Loader from '../Loader/Loader'
import AlertMessagesContainer from '../AlertMessage/AlertMessagesContainer'
import './ExternalContainer.less'

interface Props {
  children: React.ReactNode
  title?: string
  subTitle?: string
  loading?: boolean
  contentBelow?: React.ReactNode[] // displays Components below the visual container (e.g countryPicker at login)
  showAlertMessages?: boolean
  bhTrackClassName?: string
  ['data-testid']?: string
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExternalContainer = (props: Props) => {
  const { children, title, subTitle, loading, contentBelow, showAlertMessages, bhTrackClassName } = props

  return (
    <div
      data-testid={props['data-testid']}
      className={classNames(
        'external-container',
        {
          ['justify-center']: !showAlertMessages,
        },
        bhTrackClassName
      )}
    >
      {showAlertMessages && (
        <div className="external-alert-message-container">
          <AlertMessagesContainer placement={['login', 'everywhere']} />
        </div>
      )}
      <div className="external-container-wrapper">
        <div className="external-container-inner">
          <div className="logo-container">
            <img src={Logo} />
          </div>
          {title && <PageHeader className="external-container-heading" title={title} subTitle={subTitle} />}
          <Loader showLoader={loading || false} delay={0} bg="white">
            {children}
          </Loader>
        </div>
      </div>
      {contentBelow?.length && contentBelow.map((content) => content)}
    </div>
  )
}

export default ExternalContainer
