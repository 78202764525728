import { FileObject } from '../types/file'
import { api } from './utils'

const BASE_PATH = 'static'

export const getStaticFile = async (fileId: string): Promise<FileObject> => {
  const url = `/${BASE_PATH}/file/${fileId}`

  const response = await api.get(url)

  const staticFile = (await response.json()) as FileObject
  return staticFile
}
