import React, { CSSProperties, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import { formatLocaleDateString, getLocaleDateStringFormat } from '../../utils/dates'
import { useLanguageState } from '../../stores/language/LanguageStore'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

interface BHRangePickerProps {
  className?: string
  values: string[] | null
  disabled?: boolean
  onChange?: (values: string[]) => void
  placeholder?: Array<string>
  size?: SizeType
  popupStyle?: CSSProperties
  allowClear?: boolean
  dropdownClassName?: string
}

const { RangePicker } = DatePicker

const BHRangePicker = ({
  className,
  values,
  disabled,
  placeholder,
  size,
  popupStyle,
  allowClear,
  dropdownClassName,
  onChange,
}: BHRangePickerProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const [from, setDateFrom] = useState<moment.Moment | null>(null)
  const [to, setDateTo] = useState<moment.Moment | null>(null)

  const langFormat = (value: moment.Moment) => {
    return `${value.format(getLocaleDateStringFormat(language))}`
  }

  useEffect(() => {
    if (Array.isArray(values) && values.length) {
      setDateFrom(moment(values[0], [getLocaleDateStringFormat(language), moment.ISO_8601]))
      setDateTo(moment(values[1], [getLocaleDateStringFormat(language), moment.ISO_8601]))
    } else {
      setDateFrom(null)
      setDateTo(null)
    }
  }, [values])

  const handleChange = (values: string[]): void => {
    if (Array.isArray(values) && values.length) {
      const from = formatLocaleDateString(values[0], language)
      const to = formatLocaleDateString(values[1], language)

      onChange && onChange([from, to])
    } else {
      onChange && onChange([])
    }
  }

  return (
    <RangePicker
      className={`bh-rangepicker ${className ? className : ''}`}
      value={from && to ? [from, to] : null}
      format={langFormat}
      disabled={disabled || false}
      onChange={(_values, stringValues: string[]) => handleChange(stringValues)}
      placeholder={placeholder ? [placeholder[0], placeholder[1]] : undefined}
      size={size}
      popupStyle={popupStyle}
      allowClear={allowClear || true}
      dropdownClassName={dropdownClassName || ''}
    />
  )
}

export default BHRangePicker
