import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Alert } from 'antd'
import { pi } from '../../lang/definitions'
import './NewBeneficiaryWarning.less'
import { Payment } from '../../types/paymentInstruction'

interface NewBeneficiaryWarningProps {
  payments: Payment[]
}
const NewBeneficiaryWarning = ({ payments }: NewBeneficiaryWarningProps): React.JSX.Element => {
  const intl = useIntl()

  const [showWarning, setShowWarning] = useState<boolean>(false)
  const [multiple, setMultiple] = useState<number>(0)
  useEffect(() => {
    handleDisplayWarning()
  }, [payments])

  const handleDisplayWarning = (): void => {
    if (Array.isArray(payments)) {
      let shouldShowWarning = false
      shouldShowWarning = payments.some((payment) => payment.newBeneficiary === true)
      setShowWarning(shouldShowWarning)
      let count = 0
      payments.forEach((pi) => {
        if (pi.newBeneficiary === true) {
          ++count
        }
      })
      setMultiple(count)
    }
  }
  return (
    <React.Fragment>
      {showWarning && (
        <Alert
          message={
            multiple > 1
              ? intl.formatMessage(pi['pi.multipleNewBeneficiaryWarning.message'])
              : intl.formatMessage(pi['pi.newBeneficiaryWarning.message'])
          }
          type="warning"
          showIcon
          className="new-beneficiary-warning-container"
        />
      )}
    </React.Fragment>
  )
}

export default NewBeneficiaryWarning
