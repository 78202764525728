/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect, useRef } from 'react'
import { Alert, Popover } from 'antd'
import * as Sentry from '@sentry/react'
import { useIntl } from 'react-intl'
import { PaperClipOutlined, DeleteOutlined, ArrowRightOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import { isEqual, pickBy } from 'lodash'
import classNames from 'classnames'
import { Form, Input, Select, Radio, Button, Tag, Checkbox } from 'antd'
import ActionPage from 'components/ActionPage/ActionPage'
import Beneficiary from 'components/Beneficiary/Beneficiary'
import DueDateSelector from './DueDateSelector'
import RecurrenceForm from './RecurrenceForm'
import PaymentAttachmentForm from './PaymentAttachmentForm'
import CardForm from '../../../../Cards/CardForm'
import { Beneficiary as BeneficiaryType, BlacklistEntry } from 'types/beneficiary'
import { Holiday } from 'types/greg'
import { CardNetworkBankDay, Recurrence, PaymentFormData, Attachment } from 'types/paymentInstruction'
import { PaymentGateway } from 'types/rules'
import { convertFormAmountToCents } from '../../utils'
import { pi } from 'lang/definitions'
import ACL from 'components/ACL/ACL'
import { useLanguageState } from 'stores/language/LanguageStore'
import PaymentCard from './PaymentCard'
import './PaymentForm.less'
import { SCREEN_LG, Size } from 'utils/getDeviceType'
import { useHistory } from 'react-router-dom'
import { replaceDrawerHash, resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import { getLocaleDateStringFormat } from 'utils/dates'
import SelectCardForm from './SelectCardForm'
import { useTranslation, validateEmail } from 'utils/helpers'
import { BillhopError as GeneralError } from 'types/general'
import RecipientsDrawer from '../../../../Beneficiaries/RecipientsDrawer'
import { resetDetailsHash } from '../../../../../utils/detailsHash'
import { RuleObject } from 'antd/lib/form'
import { CloseCircleFilled } from '@ant-design/icons'
import { BENEFICIARY_TITLE_PATTERN } from 'utils/validators'
import CurrencySelectorFormItem from './CurrencySelectorFormItem'
import { useCurrentPayment } from 'stores/Payment'
import { useClearingNetworkMaxLength, useWindowSize, useShowCreatePiFields, useActiveUser } from 'hooks'
import { useCurrentPaymentUtils } from 'stores/Payment/hooks'
import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import { useSession } from 'stores/session'

export interface PaymentFormProps {
  bankHolidays: Holiday[]
  cardNetworks: string[]
  currentCardNetwork?: string | undefined
  cardNetworksBankDays: CardNetworkBankDay[]
  currencies: string[]
  dateDue?: moment.Moment
  formattedCardNetworksBankDays: CardNetworkBankDay[]
  gateways: PaymentGateway[]
  initialFormData?: PaymentFormData
  isDateDueUpdated: boolean
  isRecurrenceDataUpdated: boolean
  isAttachmentUpdated: boolean
  paymentLimit: number
  recurrenceData?: Recurrence
  attachment?: Attachment
  blacklist?: BlacklistEntry[]
  blockType?: string
  selectCard: (cardId?: string) => void
  selectBeneficiary: (beneficiaryId?: string) => Promise<void>
  cancelEdit: () => void
  createBeneficiary: (beneficiary: BeneficiaryType) => Promise<BeneficiaryType | undefined>
  setDateDue: (dateDue: moment.Moment) => void
  submitForm: (values: PaymentFormData) => Promise<void>
  setRecurrenceData: (recurrenceData: Recurrence | undefined) => void
  setAttachment: (attachment: Attachment | undefined) => void
  checkBlacklistType: (blockType: string) => string
  searchBlacklists: (accountNumber: string, routingNumber?: string | undefined) => void
  isBlacklisted: boolean
  setIsBlacklisted: (isBlacklisted: boolean) => void
  isInWhiteList: boolean
  setIsInWhiteList: (isInWhiteList: boolean) => void
}

const PaymentForm = (props: PaymentFormProps): React.JSX.Element => {
  const {
    bankHolidays,
    cardNetworks,
    cardNetworksBankDays,
    dateDue,
    formattedCardNetworksBankDays,
    gateways,
    initialFormData,
    paymentLimit,
    recurrenceData,
    attachment,
    isDateDueUpdated,
    isRecurrenceDataUpdated,
    isAttachmentUpdated,
    blacklist,
    blockType,
    currentCardNetwork,
    isBlacklisted,
    setIsBlacklisted,
    selectCard,
    selectBeneficiary,
    createBeneficiary,
    setDateDue,
    submitForm,
    setRecurrenceData,
    setAttachment,
    cancelEdit,
    checkBlacklistType,
    searchBlacklists,
    isInWhiteList,
    setIsInWhiteList,
  } = props

  const { profileId } = useActiveUser()

  const { state: sessionState } = useSession()
  const rules = sessionState.rules!
  const me = sessionState.user!
  const isB2BUser = sessionState.isB2BUser

  const {
    state: {
      beneficiaries,
      selectedCard: card,
      selectedBeneficiary: beneficiary,
      selectedAmount: amount,
      selectedCurrency,
      cards,
      paymentInstruction,
      paymentInstructions,
      paymentInstructionTemplate,
      paymentInstructionTemplates,
    },
    actions: { dispatchCurrentAmountToStore, dispatchCurrentCurrency },
  } = useCurrentPayment()

  const { getCards } = useCurrentPaymentUtils()

  const [maxLength, updateMaxLength] = useClearingNetworkMaxLength(rules)

  const {
    recurring: showRecurring,
    dateDue: showDateDue,
    attachment: showAttachment,
    remittanceAdvice: showRemittanceAdvice,
  } = useShowCreatePiFields()

  const [languageState] = useLanguageState()
  const language = languageState.language
  const t = useTranslation()

  const [form] = Form.useForm<PaymentFormData>()
  const history = useHistory()
  const intl = useIntl()
  const size: Size = useWindowSize()
  const reference = Form.useWatch<string>('reference', form)

  const [isFormUpdated, setIsFormUpdated] = useState(false)
  const [showRecurrenceData, setShowRecurrenceData] = useState(false)
  const [showDateDuePopover, setShowDateDuePopover] = useState(true)
  const [isReferenceInputInvalid, setIsReferenceInputInvalid] = useState<boolean>(false)

  const [preselectedCardId, setPreselectedCardId] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const selectCardRef = useRef<HTMLDivElement>(null)
  const selectRecipientRef = useRef<HTMLDivElement>(null)
  const selectAmountRef = useRef<HTMLDivElement>(null)

  const beneficiaryId = Form.useWatch('beneficiaryId', form)
  const showRecipientEmail = Form.useWatch('sendRemittance', form)
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<BeneficiaryType>()

  useEffect(() => {
    form.resetFields()

    if (!initialFormData) {
      return
    }

    form.setFieldsValue({
      ...initialFormData,
      sendRemittance: !!initialFormData.remittanceAdvice,
    })
  }, [initialFormData])

  useEffect(() => {
    form.setFieldValue('sourceId', card?.id)
  }, [card])

  useEffect(() => {
    if (!beneficiaryId) {
      setSelectedBeneficiary(undefined)
    }

    const foundBeneficiary = beneficiaries.find((beneficiary) => beneficiary.id === beneficiaryId)
    setSelectedBeneficiary(foundBeneficiary)
  }, [beneficiaryId, beneficiaries])

  useEffect(() => {
    setShowDateDuePopover(true)
  }, [initialFormData])

  useEffect(() => {
    void showDrawersFromHash()
  }, [window.location.hash, initialFormData])

  const showDrawersFromHash = (): void => {
    const windowHash = window.location.hash

    if (windowHash && initialFormData) {
      const isNewCard = windowHash.includes('drawer-new-datedue')
      isNewCard && setDrawerHash(history, '#drawer-new-datedue')
    }
  }

  useEffect(() => {
    form.setFieldsValue({ currency: selectedCurrency })
  }, [selectedCurrency])

  useEffect(() => {
    // initialFormData should have priority
    if (beneficiary?.email && !initialFormData?.remittanceAdvice) {
      form.setFieldsValue({ remittanceAdvice: beneficiary.email })
    }
  }, [beneficiary])

  useEffect(() => {
    if (!recurrenceData || recurrenceData.occurrencesRemaining === 1) {
      setShowRecurrenceData(false)
    } else {
      setShowRecurrenceData(true)
    }
  }, [recurrenceData])

  const handleRecurrenceUpdate = (recurrence: Recurrence): void => {
    setRecurrenceData(recurrence)
    resetDrawerHash(history)
  }

  const selectDateDue = (date: moment.Moment): void => {
    setShowDateDuePopover(false)
    setDateDue(date)
    resetDrawerHash(history)
  }

  const checkAndSetIsFormUpdated = (initialValues: PaymentFormData, currentValues: PaymentFormData): void => {
    const init = pickBy(initialValues)
    const current = pickBy(currentValues)
    const isFormUpdated = !isEqual(init, current)

    setIsFormUpdated(isFormUpdated)
  }

  const handleCardRefresh = (sourceId: string): void => {
    setPreselectedCardId(sourceId)
    replaceDrawerHash(history, '/app/payments/add/input')
    void getCards()
  }
  // triggering after adding new card and refreshing cards state
  useEffect(() => {
    if (preselectedCardId) {
      selectCard(preselectedCardId)
    }
  }, [cards])

  useEffect(() => {
    const sortCode = selectedBeneficiary?.routingNumber
    const accountNumber = selectedBeneficiary?.accountNumber
    const clearingNetwork = selectedBeneficiary?.clearingNetwork

    if (!accountNumber) {
      setIsBlacklisted(false)
    }
    const formattedSortCode = sortCode?.replace(/\D/g, '')
    if (accountNumber && clearingNetwork) {
      let parsedAccountNumber
      if (clearingNetwork === 'sepa' || clearingNetwork === 'swift') {
        parsedAccountNumber = accountNumber.replace(/\s/g, '')
      } else {
        parsedAccountNumber = accountNumber.replace(/[^0-9.]+/g, '')
      }
      if (parsedAccountNumber) {
        if (formattedSortCode) {
          void searchBlacklists(parsedAccountNumber, sortCode)
        } else {
          void searchBlacklists(parsedAccountNumber)
        }
      }
    }
  }, [selectedBeneficiary, currentCardNetwork])

  const handleFormSubmit = async (data: PaymentFormData): Promise<void> => {
    setIsLoading(true)

    const beneficiaryId = form.getFieldValue('beneficiaryId') as string | undefined
    const sourceId = form.getFieldValue('sourceId') as string | undefined
    const values = { ...data, beneficiaryId, sourceId }

    if (!showRecipientEmail) {
      delete values.remittanceAdvice
    }

    Sentry.addBreadcrumb({
      category: 'payments',
      message: 'Payment added with source: ' + (card ? card.id : 'null'),
      level: 'info',
    })

    try {
      await submitForm({ ...values, beneficiaryId })
    } catch (error) {
      Sentry.captureException(error)
      const { details: { errorMessage = '' } = {} } = error as GeneralError

      if (errorMessage === 'FOREIGN_CARD_CURRENCY' || errorMessage === 'BIN_BLOCKED') {
        if (selectCardRef.current) {
          selectCardRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          })
        }
      }
    } finally {
      setIsLoading(false)
      setIsFormSubmitting(false)
    }

    if (size.width || 0 < SCREEN_LG) history.push('#review-payments')
  }

  const handleChange = async (value: Partial<PaymentFormData>, values: PaymentFormData): Promise<void> => {
    if ('sendRemittance' in value) return

    if (initialFormData) {
      checkAndSetIsFormUpdated(initialFormData, values)
    }
    const { recurring, beneficiaryId, sourceId, currency, amount } = value

    if (beneficiaryId) {
      await selectBeneficiary(beneficiaryId)
    }

    if (sourceId) {
      selectCard(sourceId)
    }

    if (recurring !== undefined) {
      if (!recurring) {
        setRecurrenceData(undefined)
      }
    }

    if (currency) {
      dispatchCurrentCurrency(currency)
    }

    if (amount) {
      const amountInCents = convertFormAmountToCents(amount)
      dispatchCurrentAmountToStore(amountInCents)
    }
  }

  const handleSetAttachment = (attachment?: Attachment) => {
    setAttachment(attachment)
    resetDrawerHash(history)
    void form.validateFields(['invoice'])
  }

  const openBeneficiaryDrawer = () => {
    setDrawerHash(history, '#drawer-new-beneficiary')
  }

  const handleCreateNewBeneficiary = async (): Promise<void> => {
    form.setFieldsValue({ beneficiaryId: undefined })
    await selectBeneficiary()

    openBeneficiaryDrawer()
  }

  const getIntervalString = (interval: string): string => {
    if (interval === 'everyWeek') {
      return intl.formatMessage(pi['pi.paymentsFrequency.option.everyWeek'])
    }

    if (interval === 'every2Weeks') {
      return intl.formatMessage(pi['pi.paymentsFrequency.option.every2Weeks'])
    }

    if (interval === 'everyMonth') {
      return intl.formatMessage(pi['pi.paymentsFrequency.option.everyMonth'])
    }

    if (interval === 'every2Months') {
      return intl.formatMessage(pi['pi.paymentsFrequency.option.every2Months'])
    }

    return intl.formatMessage(pi['pi.paymentsFrequency.option.everyQuarter'])
  }

  const isForeignCurrency = () => {
    return (
      card &&
      selectedCurrency &&
      card.preferredCurrency !== selectedCurrency &&
      !rules.toggles?.allowPaymentsInForeignCardCurrency?.rule
    )
  }

  const scrollToFirstError = () => {
    if (isForeignCurrency() && selectCardRef.current) {
      selectCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
      return
    }
    if (selectRecipientRef.current && (!beneficiary || !reference)) {
      selectRecipientRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
      return
    }

    if (!amount && selectAmountRef.current) {
      selectAmountRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
      return
    }
  }

  const onSubmitBeneficiary = async (data: BeneficiaryType) => {
    const beneficiary = await createBeneficiary(data)

    if (!beneficiary) {
      return
    }
    await selectBeneficiary(beneficiary.id)
    form.setFieldsValue({ beneficiaryId: beneficiary?.id })
    resetDetailsHash(history)
  }

  const formFieldNumberUpdate = (name: string): number => {
    let items = ['card', 'beneficiary', 'amountAndDate', 'attachment', 'recurring']
    if (!showAttachment) {
      items = items.filter((item) => item !== 'attachment')
    }
    return items.indexOf(name) + 1
  }

  const onCancelEditClick: React.MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    cancelEdit()
  }

  useEffect(() => {
    const currentClearingNetwork = selectedBeneficiary?.clearingNetwork

    updateMaxLength(currentClearingNetwork)
  }, [selectedBeneficiary])

  useEffect(() => {
    // When editing a payment instruction, check if the selected payment instruction still exists
    if (paymentInstruction?.id) {
      const doesNotExist = !paymentInstructions.find((pi) => pi.id === paymentInstruction.id)

      if (doesNotExist) {
        cancelEdit()
      }
    }

    if (paymentInstructionTemplate?.id) {
      const doesNotExist = !paymentInstructionTemplates.find((pi) => pi.id === paymentInstructionTemplate.id)

      if (doesNotExist) {
        cancelEdit()
      }
    }
  }, [paymentInstructions, paymentInstructionTemplates])

  return (
    <div className="payment-form-container scrollable show-scroll transparent">
      <Form
        form={form}
        id="add-payment-form"
        className="add-payment-form"
        requiredMark={false}
        layout="vertical"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onValuesChange={handleChange}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onFinish={handleFormSubmit}
        onFinishFailed={scrollToFirstError}
      >
        <div ref={selectCardRef}>
          <PaymentCard
            title={intl.formatMessage(pi['pi.add.addPayments.form.header.card'])}
            number={formFieldNumberUpdate('card')}
          >
            <React.Fragment>
              {isForeignCurrency() && (
                <Alert
                  className="blocked-beneficiary-alert"
                  message={intl.formatMessage(pi['pi.addPayments.form.card.foreign.currency.error'])}
                  type="error"
                  showIcon
                  icon={<ExclamationCircleFilled />}
                />
              )}
              {card && !isInWhiteList && (
                <Alert
                  className="blocked-beneficiary-alert"
                  message={intl.formatMessage(pi['pi.addPayments.form.card.bin.error'])}
                  type="error"
                  showIcon
                />
              )}
            </React.Fragment>
            <SelectCardForm
              cards={cards}
              activeProfileId={me.activeProfileId}
              isFormSubmitting={isFormSubmitting}
              handleChangeCard={() => {
                selectCard()
                setIsInWhiteList(true)
                form.setFieldsValue({ sourceId: undefined })
              }}
            />
          </PaymentCard>
        </div>
        <div ref={selectRecipientRef}>
          <PaymentCard
            title={intl.formatMessage(pi['pi.add.addPayments.form.header.recepient'])}
            number={formFieldNumberUpdate('beneficiary')}
          >
            <React.Fragment>
              {beneficiary ? (
                <React.Fragment>
                  <React.Fragment>
                    {blacklist && blacklist.length > 0 && (
                      <Alert
                        className="blocked-beneficiary-alert"
                        message={checkBlacklistType(blockType || '')}
                        type="error"
                        showIcon
                      />
                    )}
                  </React.Fragment>
                  <React.Fragment>
                    <div className="selected-option-container green">
                      <Beneficiary beneficiary={beneficiary} />
                    </div>
                    <div className="change-selected">
                      <Button
                        type="link"
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={async () => {
                          await selectBeneficiary()

                          form.setFieldsValue({ beneficiaryId: undefined })
                        }}
                      >
                        {intl.formatMessage(pi['pi.add.addPayments.form.beneficiary.changeRecipient'])}
                      </Button>
                    </div>
                  </React.Fragment>
                </React.Fragment>
              ) : (
                <div className="item-with-action">
                  <Form.Item
                    label=""
                    name="beneficiaryId"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage(pi['pi.add.addPayments.form.beneficiary.errorMessage.required']),
                      },
                    ]}
                  >
                    <Select
                      className="bh-select bh-track-existing-recipient-select"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: string, option: unknown): boolean => {
                        // children is Beneficiary component
                        const { children } = option as {
                          children: {
                            props: {
                              beneficiary: {
                                title: string
                                accountNumber: string
                                routingNumber?: string
                              }
                            }
                          }
                        }
                        const { title, accountNumber, routingNumber } = children.props.beneficiary

                        return (
                          (!!title && title.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                          (!!accountNumber && accountNumber.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
                          (!!routingNumber && routingNumber.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        )
                      }}
                      placeholder={intl.formatMessage(pi['pi.add.addPayments.form.beneficiary.select.placeholder'])}
                    >
                      {beneficiaries.map((beneficiary: BeneficiaryType, index: number): React.ReactNode => {
                        const optionClassName = classNames('payment-form-select-option', {
                          ['odd-option']: index % 2 === 0,
                        })
                        return (
                          <Select.Option
                            key={`beneficiary-${beneficiary.id!}`}
                            className={optionClassName}
                            value={beneficiary.id!}
                          >
                            <Beneficiary beneficiary={beneficiary} />
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <ACL kind="beneficiary" barracksId={me.activeProfileId} action="create">
                    <Button
                      className="btn-yellow bh-track-add-recipient-button"
                      onClick={() => void handleCreateNewBeneficiary()}
                    >
                      {intl.formatMessage(pi['pi.add.addPayments.form.beneficiary.new'])}
                    </Button>
                  </ACL>
                </div>
              )}
              <Form.Item
                label={intl.formatMessage(pi['pi.add.addPayments.form.reference.label'])}
                name="reference"
                validateTrigger="onChange"
                hasFeedback
                rules={[
                  {
                    validator: (_rule: RuleObject, value: string) => {
                      const pattern = BENEFICIARY_TITLE_PATTERN
                      if (pattern.test(value)) {
                        setIsReferenceInputInvalid(false)
                        return Promise.resolve()
                      } else {
                        setIsReferenceInputInvalid(true)
                        return Promise.reject()
                      }
                    },
                    message: intl.formatMessage(pi['pi.add.addPayments.form.reference.errorMessage.invalidCharacters']),
                  },
                  {
                    required: true,
                    message: intl.formatMessage(pi['pi.add.addPayments.form.reference.errorMessage.required']),
                  },
                ]}
              >
                <Input
                  className="bh-input"
                  placeholder={intl.formatMessage(pi['pi.add.addPayments.form.reference.placeholder'])}
                  maxLength={maxLength}
                  suffix={
                    <>
                      <span style={{ color: 'gray', marginRight: '4' }}>{`${
                        reference?.length || 0
                      }/${maxLength}`}</span>
                      {reference && reference.length > 0 && (
                        <CloseCircleFilled
                          style={{ color: 'gray', cursor: 'pointer', marginRight: '4' }}
                          onClick={() => form.setFieldsValue({ reference: '' })}
                        />
                      )}
                    </>
                  }
                />
              </Form.Item>
              {showRemittanceAdvice && (
                <>
                  <Popover
                    placement="top"
                    mouseEnterDelay={0.5}
                    content={intl.formatMessage(pi['pi.add.addPayments.form.sendRemittance.label.info'])}
                  >
                    <Form.Item name="sendRemittance" valuePropName="checked">
                      <Checkbox
                        className="bh-checkbox"
                        disabled={rules.logic.requiredPIFields && rules.logic.requiredPIFields.rule.remittanceAdvice}
                      >
                        {intl.formatMessage(pi['pi.add.addPayments.form.sendRemittance.label'])}
                      </Checkbox>
                    </Form.Item>
                  </Popover>
                  {showRecipientEmail && (
                    <Form.Item
                      label={intl.formatMessage(pi['pi.add.addPayments.form.remittanceAdvice.label'])}
                      name="remittanceAdvice"
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage(
                            pi['pi.add.addPayments.form.remittanceAdvice.errorMessage.required']
                          ),
                        },
                        () => ({
                          validator(rule, value: string) {
                            if (value) {
                              if (!value.split(',').every(validateEmail)) {
                                return Promise.reject(
                                  intl.formatMessage(
                                    pi['pi.add.addPayments.form.remittanceAdvice.email.validation.errorMessage']
                                  )
                                )
                              } else {
                                return Promise.resolve()
                              }
                            } else {
                              return Promise.resolve()
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        className="bh-input"
                        placeholder={intl.formatMessage(pi['pi.add.addPayments.form.remittanceAdvice.placeholder'])}
                      />
                    </Form.Item>
                  )}
                </>
              )}
            </React.Fragment>
          </PaymentCard>
        </div>
        <div ref={selectAmountRef}>
          <PaymentCard
            title={intl.formatMessage(pi['pi.add.addPayments.form.header.amountAndDate'])}
            number={formFieldNumberUpdate('amountAndDate')}
          >
            <React.Fragment>
              <Input.Group compact className="inline-items group-items">
                <CurrencySelectorFormItem />
                <Form.Item
                  label={intl.formatMessage(pi['pi.add.addPayments.form.amount.label'])}
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage(pi['pi.add.addPayments.form.amount.errorMessage.required']),
                    },
                    () => ({
                      validator(_rule: RuleObject, value: string) {
                        if (value) {
                          const valueInCents = convertFormAmountToCents(value)
                          if (valueInCents > paymentLimit) {
                            return Promise.reject(
                              `${intl.formatMessage(pi['pi.add.addPayments.form.amount.errorMessage.notValid'])}`
                            )
                          } else {
                            return Promise.resolve()
                          }
                        } else {
                          return Promise.resolve()
                        }
                      },
                    }),
                  ]}
                >
                  <CurrencyInput
                    className="ant-input payment-amount-input"
                    placeholder={intl.formatMessage(pi['pi.add.addPayments.form.amount.placeholder'])}
                  />
                </Form.Item>
              </Input.Group>
              <div className="due-date-label  item-with-action ">
                {showDateDuePopover ? (
                  <Popover
                    placement="bottom"
                    content={intl.formatMessage(pi['pi.add.addPayments.form.dateDue.label.info'])}
                  >
                    <Form.Item label={intl.formatMessage(pi['pi.add.addPayments.form.dateDue.label'])}>
                      <React.Fragment>
                        {formattedCardNetworksBankDays.map(
                          (bankDay: CardNetworkBankDay): React.ReactNode => (
                            <div key={`bank-day-${bankDay.cardNetwork}`}>
                              <span>{`${bankDay.bankDay.format(getLocaleDateStringFormat(language))} - ${
                                bankDay.cardNetwork
                              }`}</span>
                            </div>
                          )
                        )}
                      </React.Fragment>
                    </Form.Item>
                  </Popover>
                ) : (
                  <Form.Item label={intl.formatMessage(pi['pi.add.addPayments.form.dateDue.label'])}>
                    <React.Fragment>
                      {formattedCardNetworksBankDays
                        .filter((bankDay: CardNetworkBankDay) => {
                          if (card) {
                            if (
                              bankDay.cardNetwork.toLocaleLowerCase().includes(card?.typeProperties.cardNetwork) ||
                              (card.typeProperties.cardNetwork === 'mc' && bankDay.cardNetwork.includes('Mastercard'))
                            ) {
                              return true
                            }
                          } else {
                            return true
                          }
                        })
                        .map((bankDay: CardNetworkBankDay): React.ReactNode => {
                          return (
                            <div key={`bank-day-${bankDay.cardNetwork}`}>
                              <span>
                                {`${bankDay.bankDay.format(getLocaleDateStringFormat(language))} - ${
                                  bankDay.cardNetwork
                                }`}
                              </span>
                            </div>
                          )
                        })}
                    </React.Fragment>
                  </Form.Item>
                )}
                {showDateDue && isB2BUser && (
                  <Button
                    data-testid="change-date-due-btn"
                    className="bh-track-change-date-button"
                    onClick={(): void => setDrawerHash(history, '#drawer-new-datedue')}
                  >
                    {intl.formatMessage(pi['pi.add.addPayments.form.dateDue.change'])}
                  </Button>
                )}
              </div>
            </React.Fragment>
          </PaymentCard>
        </div>
        {showAttachment && (
          <PaymentCard
            title={
              rules.logic.requiredPIFields && rules.logic.requiredPIFields.rule.attachment
                ? intl.formatMessage(pi['pi.add.addPayments.form.header.invoice'])
                : intl.formatMessage(pi['pi.add.addPayments.form.header.invoice.optional'])
            }
            number={formFieldNumberUpdate('attachment')}
          >
            <React.Fragment>
              <div className="item-with-action">
                <Form.Item
                  label=""
                  name="invoice"
                  rules={[
                    {
                      required:
                        rules.logic.requiredPIFields && rules.logic.requiredPIFields.rule.attachment && !attachment,
                      message: intl.formatMessage(pi['pi.add.addPayments.form.attachment.errorMessage.required']),
                    },
                  ]}
                >
                  <React.Fragment>
                    <div className="attachment-label-button-container">
                      <div className="attachment-label">
                        <span>{intl.formatMessage(pi['pi.add.addPayments.form.attachInvoice.label'])}</span>
                      </div>
                      {!attachment && (
                        <Button
                          className="bh-track-attach-invoice-button"
                          onClick={(): void => setDrawerHash(history, '#drawer-new-document')}
                        >
                          {intl.formatMessage(pi['pi.add.addPayments.form.attachInvoice.upload'])}
                        </Button>
                      )}
                    </div>
                    {attachment ? (
                      <div className="payment-attachment">
                        <div className="attachment-file align-center">
                          <div className="align-center">
                            <PaperClipOutlined />
                            <span className="attachment-name">{attachment.filename}</span>
                          </div>
                          <div className="delete-attachment">
                            <Button size="large" onClick={() => setAttachment(undefined)}>
                              <DeleteOutlined />
                            </Button>
                          </div>
                        </div>
                        {attachment.comment && (
                          <div className="attachment-comment text-muted">
                            <span>{attachment.comment}</span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <span>{intl.formatMessage(pi['pi.add.addPayments.form.attachInvoice.text'])}</span>
                      </div>
                    )}
                  </React.Fragment>
                </Form.Item>
              </div>
            </React.Fragment>
          </PaymentCard>
        )}
        {isB2BUser && showRecurring && (
          <PaymentCard
            title={intl.formatMessage(pi['pi.add.addPayments.form.header.recurringPayment'])}
            number={formFieldNumberUpdate('recurring')}
          >
            <React.Fragment>
              <div className="item-with-tags">
                <Form.Item
                  className="bh-payment-form"
                  label={intl.formatMessage(pi['pi.add.addPayments.form.recurring.label'])}
                  name="recurring"
                >
                  <Radio.Group className="bh-radio-group">
                    <Radio value={true} onClick={() => setDrawerHash(history, '#drawer-new-recurrence')}>
                      {intl.formatMessage(pi['pi.add.addPayments.form.recurring.option.yes'])}
                    </Radio>
                    <Radio value={false}>{intl.formatMessage(pi['pi.add.addPayments.form.recurring.option.no'])}</Radio>
                  </Radio.Group>
                </Form.Item>
                {showRecurrenceData && (
                  <div className="tags">
                    <Tag color="#a4be82">{recurrenceData && getIntervalString(recurrenceData.interval!)}</Tag>
                    {recurrenceData && recurrenceData.limit && (
                      <Tag color="#a4be82">
                        {intl.formatMessage(pi['pi.add.addPayments.form.recurring.occurrencesRemaining'], {
                          occurrencesRemaining: recurrenceData.occurrencesRemaining,
                        })}
                      </Tag>
                    )}
                    <Button onClick={() => setDrawerHash(history, '#drawer-new-recurrence')}>
                      {intl.formatMessage(pi['pi.add.addPayments.form.recurring.change'])}
                    </Button>
                  </div>
                )}
              </div>
            </React.Fragment>
          </PaymentCard>
        )}
        <div style={{ display: 'flex', gap: 10, paddingBottom: 15 }}>
          {initialFormData?.amount ? ( // it is not enough to check if there is initialFormData because the sourceId can be preselected for the new payment
            <>
              <Button size="large" block onClick={onCancelEditClick} htmlType="button">
                {intl.formatMessage(pi['pi.add.addPayments.form.button.update.cancel'])}
              </Button>
              <Button
                className="bh-track-add-payment-button"
                type="primary"
                size="large"
                block
                disabled={
                  (!isFormUpdated && !isDateDueUpdated && !isRecurrenceDataUpdated && !isAttachmentUpdated) ||
                  isBlacklisted ||
                  isReferenceInputInvalid
                }
                htmlType="submit"
                onClick={() => setIsFormSubmitting(true)}
                loading={isLoading}
              >
                {intl.formatMessage(pi['pi.add.addPayments.form.button.update'])}
              </Button>
            </>
          ) : (
            <>
              <Button
                className="bh-track-add-payment-button"
                data-testid="add-payment-btn"
                type="primary"
                size="large"
                block
                htmlType="submit"
                onClick={() => setIsFormSubmitting(true)}
                disabled={isBlacklisted || isReferenceInputInvalid}
                loading={isLoading}
              >
                <span>{intl.formatMessage(pi['pi.add.addPayments.form.button.add'])}</span>
              </Button>

              {(size.width || 0) < SCREEN_LG && (
                <Button
                  ghost
                  block
                  type="primary"
                  size="large"
                  className="view-add"
                  htmlType="button"
                  onClick={(event) => {
                    event.preventDefault()
                    history.push('#review-payments')
                  }}
                >
                  {intl.formatMessage(pi['pi.add.addPayments.action.viewPayments'])}
                  <ArrowRightOutlined />
                </Button>
              )}
            </>
          )}
        </div>
      </Form>
      <ActionPage
        title={t('beneficiary.slider.label.add')}
        hash="#drawer-new-beneficiary"
        pathname={history.location.pathname}
        additionalClass="bh-action-page"
      >
        <RecipientsDrawer onSubmit={onSubmitBeneficiary} />
      </ActionPage>
      <ActionPage
        title={intl.formatMessage(pi['pi.add.addPayments.action.addCard'])}
        hash="#drawer-new-card"
        pathname={history.location.pathname}
      >
        <CardForm cardNetworks={cardNetworks} gateways={gateways} profileId={profileId} refresh={handleCardRefresh} />
      </ActionPage>
      <ActionPage
        title={intl.formatMessage(pi['pi.add.addPayments.action.selectDateDue'])}
        hash="#drawer-new-datedue"
        pathname={history.location.pathname}
      >
        <DueDateSelector
          bankDays={cardNetworksBankDays}
          bankHolidays={bankHolidays}
          dateDue={dateDue}
          onSelectDueDate={selectDateDue}
        />
      </ActionPage>
      <ActionPage
        title={intl.formatMessage(pi['pi.add.addPayments.action.uploadInvoice'])}
        hash="#drawer-new-document"
        pathname={history.location.pathname}
        additionalClass="invoice-drawer"
      >
        <PaymentAttachmentForm setAttachment={handleSetAttachment} />
      </ActionPage>
      <ActionPage
        title={intl.formatMessage(pi['pi.add.addPayments.action.recurrence'])}
        hash="#drawer-new-recurrence"
        pathname={history.location.pathname}
      >
        <RecurrenceForm data={recurrenceData} onSubmit={handleRecurrenceUpdate} />
      </ActionPage>
    </div>
  )
}

export default PaymentForm
