import React from 'react'
import { createFromIconfontCN } from '@ant-design/icons'
import { Source } from '../../types/source'
import './CreditCard.less'

const CardIcon = createFromIconfontCN({
  scriptUrl: '/credit-card-icons.js',
})
interface CreaditCardProps {
  card: Source
}

const CreditCard = ({ card }: CreaditCardProps): React.JSX.Element => {
  const getCardIcon = (cardNetwork: string): string => {
    switch (cardNetwork) {
      case 'amex':
        return 'iconicon_zhifuamex'
      case 'diners':
        return 'iconicon_zhifudinersclub'
      case 'mc':
        return 'iconicon_zhifumastercard'
      case 'visa':
        return 'iconicon_zhifuvisa'
      case 'maestro':
        return 'iconicon_zhifumaestro'
      case 'up':
        return 'iconicon_up'
      default:
        return ''
    }
  }

  return (
    <div className="card-container">
      <div className="card-details-wraper">
        <div className="card-title">
          <span>{card.title}</span>
        </div>
        <div className="card-details">
          <div className="card-network-icon">
            <CardIcon
              className={`icon-container ${
                getCardIcon(card.typeProperties.cardNetwork) === 'iconicon_up' ? 'network-up' : ''
              }`}
              type={getCardIcon(card.typeProperties.cardNetwork) || ''}
              style={{
                fontSize: 30,
              }}
            />
          </div>
          <div className="card-number">
            <span>{`${card.typeProperties.bin} •••• •••• ${card.typeProperties.last4}`}</span>
          </div>
          {card.preferredCurrency && <div className="card-currency">{card.preferredCurrency.toUpperCase()}</div>}
        </div>
      </div>
    </div>
  )
}

export default CreditCard
