import React from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'
import { DEVICE_TYPE, getDeviceType, Size } from '../../utils/getDeviceType'
import './SplitContainer.less'

interface Props {
  children: React.ReactNode
}

export const SplitContainer: React.FC<Props> = ({ children }: Props) => {
  const size: Size = useWindowSize()
  return (
    <div className="split-flex">
      {getDeviceType(size) === DEVICE_TYPE.DESKTOP ? <div className="left-side-split"></div> : null}
      <div className="right-side-split">{children}</div>
    </div>
  )
}
