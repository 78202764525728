import React, { CSSProperties, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import { formatLocaleDateToISO, getLocaleDateStringFormat } from '../../utils/dates'
import { useLanguageState } from '../../stores/language/LanguageStore'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

interface BHDatePickerProps {
  className?: string
  value?: string | null
  disabled?: boolean
  onChange?: (date: string) => void
  placeholder?: string
  size?: SizeType
  popupStyle?: CSSProperties
  allowClear?: boolean
}

const BHDatePicker = ({
  className,
  value,
  disabled,
  placeholder,
  size,
  popupStyle,
  allowClear,
  onChange,
}: BHDatePickerProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const [dateValue, setDateValue] = useState<moment.Moment | null>(null)

  const langFormat = (value: moment.Moment) => {
    return `${value.format(getLocaleDateStringFormat(language))}`
  }

  useEffect(() => {
    if (value) {
      setDateValue(moment(value, [getLocaleDateStringFormat(language), moment.ISO_8601]))
    } else {
      setDateValue(null)
    }
  }, [value])

  const handleChange = (date: moment.Moment | null): void => {
    if (date) {
      const momentDate: moment.Moment = moment(date, [getLocaleDateStringFormat(language), moment.ISO_8601])

      const localeStringFormat: string = momentDate.format(getLocaleDateStringFormat(language))

      onChange && onChange(formatLocaleDateToISO(localeStringFormat, language))
    } else {
      onChange && onChange('')
    }
  }

  return (
    <DatePicker
      className={`bh-datepicker ${className ? className : ''}`}
      value={value ? dateValue : null}
      data-testid="bh-date-picker"
      format={langFormat}
      disabled={disabled || false}
      onChange={(date: moment.Moment | null) => handleChange(date)}
      placeholder={placeholder}
      size={size}
      popupStyle={popupStyle}
      allowClear={allowClear || true}
    />
  )
}

export default BHDatePicker
