import React from 'react'
import './PaymentCard.less'
interface PaymentCardProps {
  title: string
  number: number
  children?: React.JSX.Element | React.JSX.Element[]
  cancelAction?: () => void
  cancelText?: string
}

const PaymentCard = ({ title, number, children, cancelAction, cancelText }: PaymentCardProps): React.JSX.Element => {
  return (
    <div className="payment-card">
      <div className="header">
        <div className="title-wrapper">
          <div className="number">{number}</div>
          <div className="title">{title}</div>
        </div>
        {cancelText && (
          <span className="cancel-action" onClick={cancelAction}>
            {cancelText}
          </span>
        )}
      </div>
      <div className="content">{children}</div>
    </div>
  )
}

export default PaymentCard
