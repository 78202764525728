import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { filters } from '../../lang/definitions/index'
import './FilterDropdown.less'
import './RangePickerMobile.less'
import { Button, Collapse, Radio, RadioChangeEvent } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { FilterBody } from '../Filter/Filter'

import BHRangePicker from '../RangePicker/BHRangePicker'

const { Panel } = Collapse

interface Option {
  label: string
  value: string
  drawerKey?: string
  fixedFilters?: unknown[]
}

interface FilterGroup {
  label: string
  value: string
  type: string
  options?: Option[]
}

interface FilterDropdownProps {
  groups: FilterGroup[]
  preselectedFilters?: FilterBody
  closeFilter: () => void
  applyFilter?: (filters: SelectedFilter) => void
  setFixedFilters?: (filters: FilterBody[]) => void
  setActiveTab: (type: string) => void
  paymentTypes?: string
}

interface DateDue {
  from: string
  to: string
}

export interface SelectedFilter {
  [key: string]: string
}

const FilterDropdown = ({
  groups,
  closeFilter,
  applyFilter,
  setFixedFilters,
  preselectedFilters,
  setActiveTab,
  paymentTypes,
}: FilterDropdownProps): React.JSX.Element => {
  const intl = useIntl()
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>({})
  const [dateDue, setDateDue] = useState<DateDue>()
  const [drawerActiveTab, setDrawerActiveTab] = useState<string>('ongoing')

  useEffect(() => {
    if (paymentTypes) {
      setSelectedFilter((oldState) => ({
        ...oldState,
        status: paymentTypes,
      }))
    }
    if (preselectedFilters && preselectedFilters.currency) {
      setSelectedFilter((oldState) => ({
        ...oldState,
        currency: preselectedFilters.currency as string,
      }))
    }
    if (preselectedFilters && preselectedFilters.dateDue) {
      const from = preselectedFilters?.dateDue?.from ? new Date(preselectedFilters?.dateDue.from).toISOString() : null

      const to = preselectedFilters?.dateDue?.to ? new Date(preselectedFilters?.dateDue.to).toISOString() : null

      setDateDue((oldState) => ({
        ...oldState,
        to: to as string,
        from: from as string,
      }))
    }
  }, [])

  const handleSetFilter = (groups: FilterGroup[]): void => {
    const initial = groups.map((group) => {
      return {
        [group.value]: group && group.options ? group.options[0].value : 'all',
      }
    })
    setSelectedFilter(Object.assign({}, ...initial) as SelectedFilter)
    setDateDue(null!)
    setFixedFilters && setFixedFilters([] as FilterBody[])
  }

  const handleChange = (event: RadioChangeEvent, group: FilterGroup): void => {
    setSelectedFilter((oldState: SelectedFilter) => ({
      ...oldState,
      [group.value]: event.target.value as string,
    }))
    const selectedOption = group.options?.find((option: Option) => option.value === event.target.value)
    if (selectedOption && selectedOption.drawerKey) {
      setDrawerActiveTab(selectedOption.drawerKey)
    }
    if (selectedOption && selectedOption.fixedFilters) {
      setFixedFilters && setFixedFilters(selectedOption.fixedFilters as FilterBody[])
    }
  }

  const handleApplyFilters = (): void => {
    const filters = { ...selectedFilter, ...dateDue, activeTab: drawerActiveTab }
    applyFilter && applyFilter(filters)
    setActiveTab(drawerActiveTab)
    closeFilter()
  }

  const handleCalendarChange = (datesString: string[]): void => {
    const from = datesString[0]
    const to = datesString[1]

    setDateDue((oldState) => ({
      ...oldState,
      from: from,
      to: to,
    }))
  }

  const renderRadioMenu = (group: FilterGroup, index: number): React.ReactNode => (
    <Panel className="filter-panel" key={index} header={group.label}>
      <Radio.Group
        onChange={(event: RadioChangeEvent) => handleChange(event, group)}
        value={selectedFilter[group.value]}
        defaultValue={selectedFilter[group.value] || (group && group.options && group.options[0].value)}
      >
        {group?.options?.map((item: Option, index: number) => (
          <div className="radio-wrapper" key={index}>
            <Radio value={item.value} className="filter-radio">
              {item.label}
            </Radio>
          </div>
        ))}
      </Radio.Group>
    </Panel>
  )

  const renderRangePicker = (group: FilterGroup, index: number): React.ReactNode => (
    <Panel className="filter-panel" key={index} header={group.label}>
      <BHRangePicker
        values={dateDue && dateDue.from && dateDue.to ? [dateDue.from, dateDue.to] : null}
        className="range-picker-mobile"
        dropdownClassName="range-dropdown-mobile"
        onChange={(datesString: string[]) => handleCalendarChange(datesString)}
      />
    </Panel>
  )

  const renderBody = (group: FilterGroup, index: number): React.ReactNode => {
    switch (group.type) {
      case 'radio':
        return renderRadioMenu(group, index)
      case 'datepicker':
        return renderRangePicker(group, index)
      default:
        break
    }
  }

  return (
    <div className="dropdown-filter-wrapper">
      <Collapse expandIconPosition="end" className="dropdown-filter" defaultActiveKey={['0']}>
        {groups.map((group: FilterGroup, index: number) => renderBody(group, index))}
      </Collapse>
      <div className="buttons-wrapper">
        <Button size="large" type="primary" block onClick={handleApplyFilters}>
          {intl.formatMessage(filters['filter.apply'])}
        </Button>
      </div>
      <Button type="link" className="clear-btn" icon={<CloseOutlined />} onClick={() => handleSetFilter(groups)}>
        {intl.formatMessage(filters['filter.clear'])}
      </Button>
    </div>
  )
}

export default FilterDropdown
