import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import * as Sentry from '@sentry/react'
import { api } from '../../api/utils'
import OTPInput from '../../components/OTPInput/OTPInput'
import { saveSessionId, saveAuthenticationStatus } from '../../utils/storage'
import { getProfileId } from '../../api/pIMethods'
import { validateSessionId } from '../../utils/validate'
import { User } from '../../types/user'

import './SSO.less'
import { useSession } from 'stores/session'

interface ParamTypes {
  token: string
}
interface Session {
  sessionId: string
  status: string
  userId: string
  tfaMethod: string
  tfaProperties: null | {
    token: string
    phoneMask: string | null
    emailMask: string | null
  }
}

const LegacyIntermediatePage = (): React.JSX.Element => {
  const { token } = useParams<ParamTypes>()
  const [session, setSession] = useState<Session>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()
  const {
    actions: { setUser, setSessionId },
  } = useSession()
  useEffect(() => {
    console.log('ON 1st render LegacyIntermediatePage!', { token, session, loading })
  }, [])
  useEffect(() => {
    const getSession = async () => {
      setLoading(true)
      try {
        const response = await api.get(`/external/zuul/legacy-token/${token}`)
        const result = (await response.json()) as Session
        setSession(result)
      } catch (err) {
        Sentry.captureException(err)
        setError('LOGIN_FAIL')
      } finally {
        setLoading(false)
      }
    }
    try {
      void getSession()
    } catch (err) {
      Sentry.captureException(err)
    }
  }, [token])

  useEffect(() => {
    if (session) {
      const { status, sessionId } = session

      if (status !== 'ALIVE') {
        return
      }

      saveSessionId(sessionId)
      setSessionId(sessionId)
      validateSessionId()
        .then((valid: boolean) => {
          if (valid) {
            saveSessionId(sessionId)
            saveAuthenticationStatus()
            getProfileId()
              .then((user: User) => {
                if (!user) {
                  return
                }

                setUser(user)
                setSessionId(sessionId)
              })
              .catch((err) => {
                Sentry.captureException(err)
              })
          }
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
    }
  }, [session])
  return (
    <div className="sso-intermediate">
      {error && <div>Error {error}</div>}
      {session && session.status === 'OTP_PENDING' && session.tfaProperties && (
        <OTPInput
          token={session.tfaProperties.token}
          sessionId={session.sessionId}
          method={session.tfaMethod}
          email={session.tfaProperties.emailMask}
          phone={session.tfaProperties.phoneMask}
        />
      )}
    </div>
  )
}

export default LegacyIntermediatePage
