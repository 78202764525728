import React from 'react'
import { Form, Input } from 'antd'
import { RuleObject } from 'antd/lib/form'
import { useTranslation } from '../../../../utils/helpers'
import useSuffix from './useSuffix'

type Props = {
  children?: React.JSX.Element
  label: string
  maxLength?: number
  validator: (_ruleObject: RuleObject, value: string) => Promise<void>
}

export default function AccountNumber({ validator, children, label, maxLength }: Props): React.JSX.Element {
  const t = useTranslation()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '')
  }

  const pattern = /[A-Za-z0-9]/g
  const accountNumberSuffix = useSuffix({ fieldName: 'accountNumber', maxLength, pattern })

  return (
    <Form.Item
      label={label}
      name="accountNumber"
      validateTrigger="onChange"
      data-testid="account-number-form-item"
      hasFeedback
      rules={[
        {
          validator,
        },
      ]}
      requiredMark={false}
    >
      {children || (
        <Input
          type="text"
          size="large"
          data-testid="account-number-input"
          suffix={accountNumberSuffix}
          placeholder={t('beneficiary.accountNumber.placeholder')}
          onInput={handleInputChange}
          maxLength={maxLength}
        />
      )}
    </Form.Item>
  )
}
