import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'antd'
import Loader from 'components/Loader/Loader'
import { uniqBy } from 'lodash'
import Payments from '../../components/Payments/Payments'
import PaymentsSummary from '../../components/PaymentsSummary/PaymentsSummary'
import ForeignCurrencyWarning from 'components/ForeignCurrencyWarning/ForeignCurrencyWarning'
import Approvers from './Approvers'
import {
  Payment,
  PAYMENT_KIND,
  SetTotalDetails,
  FilterFields,
  Set,
  PaymentInstruction,
  PaymentInstructionTemplate,
} from 'types/paymentInstruction'
import { pi } from 'lang/definitions'
import PaymentsHeader from '../../components/PaymentsHeader'
import { formatLocale } from 'utils/formatLocale'
import { useLanguageState } from 'stores/language/LanguageStore'
import WarningBox from 'components/WarningBox/WarningBox'
import './ReviewPayments.less'
import SubmitPayments from '../../components/SubmitPayments/SubmitPayments'
import { useCurrentPayment } from 'stores/Payment'
import { FilterBody } from 'components/Filter/Filter'
import * as piApi from 'api/paymentInstruction'

interface ReviewPaymentsProps {
  selectAllPayments: () => void
  deselectAllPayments: () => void
  selectPayments: (Payments: Payment[]) => void
  deselectPayments: (Payments: Payment[]) => void
  handleEditPayment: (kind: PAYMENT_KIND, id: string) => void
  handleDeletePayment: (payment: Payment) => void
  getNumberOfSignaturesRequired: (paymentIds: string[]) => void
  handleDeleteSelectedPayments: (Payments?: Payment[]) => void
  handleDeleteAllPayments: () => void
  goToProgressSlide: () => void
  submitButton?: React.JSX.Element
  startPolling: (set: Set) => void
}
const ReviewPayments = (props: ReviewPaymentsProps): React.JSX.Element => {
  const {
    selectAllPayments,
    deselectAllPayments,
    selectPayments,
    deselectPayments,
    handleEditPayment,
    handleDeletePayment,
    getNumberOfSignaturesRequired,
    handleDeleteSelectedPayments,
    handleDeleteAllPayments,
    goToProgressSlide,
    startPolling,
    submitButton,
  } = props

  const intl = useIntl()
  const [{ language }] = useLanguageState()

  const [paymentInstructionDetails, setPaymentInstructionDetail] = useState<SetTotalDetails>()
  const [templateDetails, setTemplateDetail] = useState<SetTotalDetails>()
  const [duplicatePayments, setDuplicatePayments] = useState<Payment[]>()

  const {
    state: {
      selectedPayments,
      paymentsOnCurrentPage,
      payments,
      filteredPayments,
      showInitLoader,
      showReviewPaymentLoader,
      readySet,
      requireSignatureSet,
      approvers,
    },
  } = useCurrentPayment()

  const showLoader = showInitLoader || showReviewPaymentLoader
  const totalNumberOfPayments = filteredPayments.length

  const resolveDuplicatePayments = (duplicateCheckId: string) => {
    const paymentsToResolve = payments.filter((payment) => payment.duplicateCheckId === duplicateCheckId)

    const paymentToRetain = paymentsToResolve.find((payment) => payment.duplicateCheckId === duplicateCheckId)

    const paymentsToDelete = paymentsToResolve.filter((payment) => payment.id !== paymentToRetain!.id)

    if (paymentsToDelete.length === 1) {
      handleDeletePayment(paymentsToDelete[0])
    } else {
      handleDeleteSelectedPayments(paymentsToDelete)
    }
  }

  const checkDuplicatePayments = () => {
    const duplicatePaymentIds = payments
      .map((payment) => payment.duplicateCheckId)
      .filter((id, index, idArray) => idArray.indexOf(id) !== index)

    if (duplicatePaymentIds.length > 0) {
      const duplicatePaymentsArray = payments.filter((payment) =>
        duplicatePaymentIds.includes(payment.duplicateCheckId)
      )
      setDuplicatePayments(uniqBy(duplicatePaymentsArray, 'duplicateCheckId'))
    } else {
      setDuplicatePayments([])
    }
  }

  useEffect(() => {
    const paymentInstructionDetails = readySet?.detailsTotal?.details.find(
      (details: SetTotalDetails) => details.kind === 'paymentInstruction'
    )
    const templateDetails = readySet?.detailsTotal?.details.find(
      (details: SetTotalDetails) => details.kind === 'paymentInstructionTemplate'
    )

    setPaymentInstructionDetail(paymentInstructionDetails)
    setTemplateDetail(templateDetails)
  }, [readySet])

  useEffect(() => {
    checkDuplicatePayments()
  }, [payments])

  const resolveAllDuplicatePayments = () => {
    handleDeleteSelectedPayments(duplicatePayments)
  }

  const fetchPIsAndTemplatesForSignatureRequest = async (
    set: Set,
    includeSignatures: boolean
  ): Promise<{
    paymentInstructions: PaymentInstruction[]
    paymentInstructionTemplates: PaymentInstructionTemplate[]
  }> => {
    if (!set) {
      return { paymentInstructions: [], paymentInstructionTemplates: [] }
    }
    const { paymentInstructionIds, paymentInstructionTemplateIds } = set

    const piSearchBody: FilterBody = {
      kind: 'paymentInstruction',
      operator: 'and',
      paymentInstructionId: paymentInstructionIds,
    }

    const templateSearchBody: FilterBody = {
      kind: 'paymentInstructionTemplate',
      operator: 'and',
      paymentInstructionId: paymentInstructionTemplateIds,
    }

    const piSearchBodyWithSignatures: FilterBody = {
      kind: 'paymentInstruction',
      operator: 'and',
      paymentInstructionId: paymentInstructionIds,
      state: ['READY', 'REQUIRE_SIGNATURE'],
    }

    const templateSearchBodyWithSignatures: FilterBody = {
      kind: 'paymentInstructionTemplate',
      operator: 'and',
      paymentInstructionId: paymentInstructionTemplateIds,
      state: ['READY', 'REQUIRE_SIGNATURE'],
    }

    const { paymentInstructions = [] } = await piApi.searchPaymentInstructions(
      includeSignatures ? [piSearchBodyWithSignatures] : [piSearchBody]
    )
    const { paymentInstructionTemplates = [] } = await piApi.searchPaymentInstructions(
      includeSignatures ? [templateSearchBodyWithSignatures] : [templateSearchBody]
    )

    return { paymentInstructions, paymentInstructionTemplates }
  }

  return (
    <Loader showLoader={showLoader}>
      <div className="review-payments-container scrollable show-scroll transparent">
        <PaymentsHeader
          label={intl.formatMessage(pi['pi.add.reviewPayments.labele'])}
          filterFields={[FilterFields.STATE, FilterFields.CURRENCY, FilterFields.CARD]}
          menuOptions={[
            {
              label: intl.formatMessage(pi['pi.deletePayments.selected'], {
                numberOfPayments: selectedPayments.length,
              }),
              callback: handleDeleteSelectedPayments,
              disabled: !selectedPayments.length,
            },
            {
              label: intl.formatMessage(pi['pi.deletePayments.all'], {
                numberOfPayments: totalNumberOfPayments,
              }),
              callback: handleDeleteAllPayments,
              disabled: !totalNumberOfPayments,
            },
          ]}
          selectAllPayments={selectAllPayments}
          deselectAllPayments={deselectAllPayments}
          enablePaymentsFilter
        />
        {duplicatePayments?.length ? (
          <WarningBox
            title={intl.formatMessage(pi['pi.duplicatePayments.heading'])}
            action={resolveAllDuplicatePayments}
            actionTitle={intl.formatMessage(pi['pi.duplicatePayments.resolve.all.btn.label'])}
          >
            <div className="description-container">
              {duplicatePayments.map((payment) => {
                return (
                  <div className="description-item" key={payment.id}>
                    <div className="desc-content">
                      <span>
                        {intl.formatMessage(pi['pi.duplicatePayments.content'], {
                          currency: payment.currency.toUpperCase(),
                          value: formatLocale('currency', {
                            value: payment.amount.toString(),
                            locale: language,
                          }),
                          beneficiary: payment.beneficiaryTitle,
                          recipient: payment.beneficiaryTitle,
                        })}
                      </span>
                    </div>
                    <div className="desc-action">
                      <Button
                        key={payment.id}
                        type="text"
                        onClick={() => resolveDuplicatePayments(payment.duplicateCheckId as string)}
                      >
                        {intl.formatMessage(pi['pi.duplicatePayments.resolve.btn.label'])}
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
          </WarningBox>
        ) : null}

        {paymentsOnCurrentPage.length ? (
          <Payments
            paymentList={paymentsOnCurrentPage}
            totalNumberOfPayments={totalNumberOfPayments}
            selectPayments={selectPayments}
            deselectPayments={deselectPayments}
            editPayment={handleEditPayment}
            deletePayment={handleDeletePayment}
          />
        ) : (
          <div className="no-data-message">
            <span>{intl.formatMessage(pi['pi.add.reviewPayments.noData.label'])}</span>
          </div>
        )}
        {!!paymentsOnCurrentPage.length && (
          <React.Fragment>
            {selectedPayments.length ? (
              <React.Fragment>
                {paymentInstructionDetails && paymentInstructionDetails.count > 0 && (
                  <PaymentsSummary setTotalDetails={paymentInstructionDetails} />
                )}
                {templateDetails && templateDetails.count > 0 && <PaymentsSummary setTotalDetails={templateDetails} />}
                <ForeignCurrencyWarning payments={selectedPayments} />
                {requireSignatureSet && (
                  <Approvers
                    set={requireSignatureSet}
                    approvers={approvers}
                    getNumberOfSignaturesRequired={getNumberOfSignaturesRequired}
                    currentRound={1}
                    fetchPIsAndTemplatesForSignatureRequest={fetchPIsAndTemplatesForSignatureRequest}
                  />
                )}
              </React.Fragment>
            ) : (
              <div className="no-data-message">
                <span>{intl.formatMessage(pi['pi.add.reviewPayments.noPaymentSelected.label'])}</span>
              </div>
            )}
          </React.Fragment>
        )}
        <div
          style={{
            display: `${!selectedPayments.length ? 'none' : 'inherit'}`,
          }}
        >
          {submitButton ? (
            submitButton
          ) : (
            <SubmitPayments
              requireSignatureSet={requireSignatureSet}
              goToProgressSlide={goToProgressSlide}
              startPolling={startPolling}
              triggerProcessingPaymentsSearch={true}
              fetchPIsAndTemplatesForSignatureRequest={fetchPIsAndTemplatesForSignatureRequest}
            />
          )}
        </div>
      </div>
    </Loader>
  )
}

export default ReviewPayments
