import React from 'react'
import { RadioChangeEvent } from 'antd'
import { useIntl } from 'react-intl'
import Loader from 'components/Loader/Loader'
import SummaryCard from './SummaryCard'
import { reports } from 'lang/definitions/reports'
import dateRangeIcon from 'assets/date-range.svg'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { DataNode } from 'antd/lib/tree'
import {
  DateRangeObject,
  OptionData,
  CheckedProps,
  REPORT_ITEMS as RI,
  DATE_OPTION_TYPE as DT,
  CheckedAllProps,
  FORMAT_TYPE,
  ENTITY_OPTIONS,
  REPORT_ITEMS,
} from './utils'
import { useUtils } from 'hooks/useUtils'
import cardIcon from 'assets/card.svg'
import currencyIcon from 'assets/currency.svg'
import paymentStatusIcon from 'assets/payment-status.svg'
import entityIcon from 'assets/organisation-details.svg'

export type ReportSummaryProps = {
  displayTitle?: boolean
  location: Location
  checkedValues: CheckedProps
  updatingLoader: boolean
  paymentsCount: number
  checkedAllFields: CheckedAllProps
  selectedDateOption: string
  dateRange: DateRangeObject
  paymentStatus: OptionData[]
  currencyOptions: OptionData[]
  entityIDs: string[]
  beneficiaryIDs: string[]
  entityTreeData: DataNode[]
  beneficiariesTree: DataNode[]
  cardNetworkOptions: OptionData[]
  clearingNetworksOptions: OptionData[]
  sourceOptions: OptionData[]
  reportFields: OptionData[]
  includeExtraInfo: OptionData[]
  nameOnReport: OptionData[]
  reportFormatOptions: OptionData[]
  selectedReportType: FORMAT_TYPE
  handleCheckboxChange: (checkedValues: Array<CheckboxValueType>, type: string) => void
  handleRadioChange: (event: RadioChangeEvent, type: string) => void
  handleSelectAll: (selected: boolean, type: string) => void
  handleRemove: (value: string, type: RI) => void
  handleResetTree: (type: ENTITY_OPTIONS | REPORT_ITEMS) => void
}

const ReportSummary = ({
  displayTitle = true,
  location,
  checkedValues,
  updatingLoader,
  paymentsCount,
  checkedAllFields,
  selectedDateOption,
  dateRange,
  paymentStatus,
  entityIDs,
  beneficiaryIDs,
  entityTreeData,
  beneficiariesTree,
  cardNetworkOptions,
  clearingNetworksOptions,
  sourceOptions,
  reportFields,
  currencyOptions,
  includeExtraInfo,
  nameOnReport,
  reportFormatOptions,
  selectedReportType,
  handleCheckboxChange,
  handleSelectAll,
  handleResetTree,
}: ReportSummaryProps): React.JSX.Element => {
  const intl = useIntl()
  const { dateOptions } = useUtils()
  const ADMIN_ROUTE = '/app/admin/reports'

  const datePaidOptions: OptionData[] = [
    {
      label: intl.formatMessage(reports['reports.date.due']),
      value: DT.DATE_DUE,
    },
    {
      label: intl.formatMessage(reports['reports.date.user.paid']),
      value: DT.DATE_USER_PAID,
    },
  ]

  return (
    <>
      {displayTitle && <span className="summary-title">{intl.formatMessage(reports['reports.summary'])}</span>}
      <div className="summary-card-wrapper">
        <Loader showLoader={updatingLoader} delay={0}>
          <div className="content">
            <div className="item">
              <img src={dateRangeIcon} alt="icon" />
              <span className="title">{intl.formatMessage(reports['reports.payments.selected'])}</span>
            </div>
            <div className="item-selected">{paymentsCount}</div>
          </div>
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.date.field'])}
            type={RI.DATE_USER_PAID}
            selectedItems={checkedValues}
            options={datePaidOptions}
            icon={dateRangeIcon}
          />
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.date.range'])}
            type={RI.DATE_RANGE}
            selectedItems={checkedValues}
            options={dateOptions}
            icon={dateRangeIcon}
            dateOption={selectedDateOption}
            dateRange={dateRange}
          />
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.payment.status'])}
            type={RI.STATES}
            selectedItems={checkedValues}
            options={paymentStatus}
            icon={paymentStatusIcon}
          />
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.currency'])}
            type={RI.CURRENCY}
            selectedItems={checkedValues}
            options={currencyOptions}
            icon={currencyIcon}
          />
          {location?.pathname === ADMIN_ROUTE && (
            <SummaryCard
              handleCheckboxChange={handleCheckboxChange}
              checkedAll={checkedAllFields}
              setCheckedAll={handleSelectAll}
              title={intl.formatMessage(reports['reports.entities'])}
              type={RI.ENTITY}
              selectedItems={{
                entityIds: [...entityIDs],
              }}
              options={entityTreeData}
              icon={entityIcon}
              handleResetTree={handleResetTree}
            />
          )}
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.card.networks'])}
            type={RI.CARD_NETWORKS}
            selectedItems={checkedValues}
            options={cardNetworkOptions}
            icon={cardIcon}
          />
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.clearing.networks'])}
            type={RI.CLEARING_NETWORKS}
            selectedItems={checkedValues}
            options={clearingNetworksOptions}
            icon={cardIcon}
          />
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.beneficiaries'])}
            type={RI.BENEFICIARY}
            selectedItems={{
              [RI.BENEFICIARY]: [...beneficiaryIDs],
            }}
            options={beneficiariesTree}
            icon={entityIcon}
            handleResetTree={handleResetTree}
          />
          <SummaryCard
            handleCheckboxChange={handleCheckboxChange}
            checkedAll={checkedAllFields}
            setCheckedAll={handleSelectAll}
            title={intl.formatMessage(reports['reports.sources'])}
            type={RI.SOURCES}
            selectedItems={checkedValues}
            options={sourceOptions}
            icon={cardIcon}
          />
          {selectedReportType === FORMAT_TYPE.EXCEL && (
            <SummaryCard
              handleCheckboxChange={handleCheckboxChange}
              checkedAll={checkedAllFields}
              setCheckedAll={handleSelectAll}
              title={intl.formatMessage(reports['reports.report.fields'])}
              type={RI.REPORT_FIELDS}
              selectedItems={checkedValues}
              options={reportFields}
              icon={entityIcon}
            />
          )}
          {selectedReportType === FORMAT_TYPE.ID_LIST && (
            <>
              <SummaryCard
                handleCheckboxChange={handleCheckboxChange}
                checkedAll={checkedAllFields}
                setCheckedAll={handleSelectAll}
                title={intl.formatMessage(reports['reports.include.extra.info'])}
                type={RI.EXTRA_INFO}
                selectedItems={checkedValues}
                options={includeExtraInfo}
                icon={entityIcon}
              />
              <SummaryCard
                handleCheckboxChange={handleCheckboxChange}
                checkedAll={checkedAllFields}
                setCheckedAll={handleSelectAll}
                title={intl.formatMessage(reports['reports.report.name.on.report'])}
                type={RI.NAME_ON_REPORT}
                selectedItems={checkedValues}
                options={nameOnReport}
                icon={entityIcon}
              />
              <SummaryCard
                handleCheckboxChange={handleCheckboxChange}
                checkedAll={checkedAllFields}
                setCheckedAll={handleSelectAll}
                title={intl.formatMessage(reports['reports.report.format'])}
                type={RI.REPORT_FORMAT}
                selectedItems={checkedValues}
                options={reportFormatOptions}
                icon={entityIcon}
              />
            </>
          )}
        </Loader>
      </div>
    </>
  )
}

export default ReportSummary
