import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { Table, notification, Button, TablePaginationConfig } from 'antd'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { action, messages, document as doc } from 'lang/definitions'
import ActionPage from 'components/ActionPage/ActionPage'
import DocumentUploadForm from './DocumentUploadForm'
import { Document, DocumentDetails } from 'types/documents'
import { getActiveUser } from 'utils/helpers'
import { useLanguageState } from 'stores/language/LanguageStore'
import { useUtils } from 'hooks/useUtils'
import * as api from 'api/barracks'
import { QueryParams } from 'types/general'
import { resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import { documentColumns } from './utils'
import { DEVICE_TYPE } from 'utils/getDeviceType'
import { isDeviceType } from 'hooks'
import './Documents.less'
import { useSession } from 'stores/session'

const Documents: React.FC = (): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()
  const [languageState] = useLanguageState()
  const language = languageState.language
  const { listOfTypeOfDocuments } = useUtils()
  const { state: sessionState } = useSession()
  const user = sessionState.user!
  const { profileId } = getActiveUser(user)
  const isMobile = isDeviceType(DEVICE_TYPE.MOBILE)

  const [documents, setDocuments] = useState<Document[]>()
  const [total, setTotal] = useState<number>()

  const [showLoader, setShowLoader] = useState(false)

  const getDocuments = async (profileId: string, params?: QueryParams): Promise<void> => {
    try {
      setShowLoader(true)
      const { documents, total } = await api.getDocuments(profileId, params)
      setDocuments(documents)
      setTotal(total)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
    }
  }

  const handleUploadDocument = async (data: DocumentDetails) => {
    try {
      await api.createUploadedDocument(data, profileId)
      resetDrawerHash(history)
      notification.success({
        message: intl.formatMessage(messages['messages.success.settings.document.upload']),
        placement: 'topRight',
      })
      void getDocuments(profileId)
    } catch (error) {
      Sentry.captureException(error)
      notification.warning({
        message: intl.formatMessage(messages['messages.error.settings.document.upload']),
        placement: 'topRight',
      })
    }
  }

  const handleDownloadDocument = async (id: string, record: Document): Promise<void> => {
    try {
      const download = await api.downloadUploadedDocument(id)
      const blob = await download.blob()
      const urls = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', record.filename)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    const params: QueryParams = {}

    const { current, pageSize } = pagination
    if (current && pageSize) {
      const skip = (current - 1) * pageSize

      if (skip) {
        params.skip = skip
      }
    }

    void getDocuments(profileId, params)
  }

  const actionColumn = {
    title: intl.formatMessage(action['action.label']),
    dataIndex: 'id',
    key: 'type',
    render: (id: string, document: Document): React.JSX.Element => {
      return (
        <Button type="link" style={{ padding: 0 }} onClick={() => void handleDownloadDocument(id, document)}>
          <DownloadOutlined
            style={{
              color: '#000000',
              fontSize: '1rem',
            }}
          />
        </Button>
      )
    },
  }

  useEffect(() => {
    profileId && void getDocuments(profileId)
  }, [profileId])

  return (
    <div className="documents-wrapper">
      <Button
        onClick={() => setDrawerHash(history, '#documents/drawer-new')}
        icon={<UploadOutlined />}
        shape="round"
        type="primary"
        size="large"
      >
        {intl.formatMessage(doc['document.uploadDocument.button.description'])}
      </Button>
      <Table
        size="small"
        loading={showLoader}
        className={isMobile ? 'bh-table mt-10' : 'bh-table'}
        rowKey={(record: Document) => `document-key-${record.filename}`}
        dataSource={documents}
        locale={{
          emptyText: intl.formatMessage(doc['document.noRecord.description']),
        }}
        columns={[...documentColumns(intl, language, listOfTypeOfDocuments), actionColumn]}
        showSorterTooltip={false}
        pagination={{ total, position: isMobile ? ['bottomCenter'] : undefined }}
        onChange={handleTableChange}
        showHeader={!isMobile}
      />

      <ActionPage
        title={intl.formatMessage(action['action.settings.uploadDocuments.text'])}
        hash="#documents/drawer-new"
        pathname={history.location.pathname}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <DocumentUploadForm onSubmit={handleUploadDocument} />
      </ActionPage>
    </div>
  )
}

export default Documents
