import React, { useState, useEffect } from 'react'
import { Table, Button, Space, Form, notification, Modal } from 'antd'
import { useIntl } from 'react-intl'
import { getActiveUser } from 'utils/helpers'
import { useLanguageState } from 'stores/language/LanguageStore'
import { BhLoadingIndicator } from 'components/Loader/Loader'
import { reportTemplateColumns } from './utils'
import { QueryParams } from 'types/general'
import * as api from 'api/reportTemplate'
import { action, messages, reports } from 'lang/definitions'
import { useHistory } from 'react-router-dom'
import { resetDrawerHash, setDrawerHash } from 'components/Drawers/utils'
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import TemplateSummary from './TemplateSummary'
import { ReportTemplate } from 'types/report'
import TemplateForm from './TemplateForm'
import * as Sentry from '@sentry/react'
import { useSession } from 'stores/session'

interface Props {
  isLoadingTemplates: boolean
  reportTemplates: ReportTemplate[]
  total?: number
  getReportTemplates: (profileId: string, params?: QueryParams) => void
  handleSetTemplate: (data: ReportTemplate) => void
  setActiveTab: (tab: string) => void
}

const Templates = ({
  isLoadingTemplates,
  reportTemplates,
  total,
  getReportTemplates,
  handleSetTemplate,
  setActiveTab,
}: Props): React.JSX.Element => {
  const history = useHistory()
  const intl = useIntl()
  const [languageState] = useLanguageState()
  const language = languageState.language
  const { state: sessionState } = useSession()
  const user = sessionState.user!
  const { profileId } = getActiveUser(user)
  const [form] = Form.useForm()

  const [showLoader, setShowLoader] = useState(false)
  const [template, setTemplate] = useState<ReportTemplate>()
  const [isDeleteTemplateWindowOpen, setIsDeleteTemplateWindowOpen] = useState(false)

  const selectTemplate = (id: string): void => {
    const template =
      reportTemplates && reportTemplates.find((template: ReportTemplate) => template && template.id === id)
    if (template) {
      setTemplate(template)
    }
  }

  useEffect(() => {
    profileId && void getReportTemplates(profileId)
  }, [profileId])

  useEffect(() => {
    void showDrawersFromHash()
  }, [window.location.hash, reportTemplates])

  const showDrawersFromHash = (): void => {
    const windowHash = window.location.hash

    if (windowHash && reportTemplates) {
      const isEdit = windowHash.includes('edit')
      const hashArray = windowHash.split('?key=')
      const hashId = hashArray[1]
      isEdit && openEdit(hashId)
    }
  }

  const openEdit = (id: string): void => {
    selectTemplate(id)
    setDrawerHash(history, `#templates#drawer-edit?key=${id}`)
  }

  const openDelete = (id: string): void => {
    const template =
      reportTemplates && reportTemplates.find((template: ReportTemplate) => template && template.id === id)

    if (template) {
      !isDeleteTemplateWindowOpen ? setIsDeleteTemplateWindowOpen(true) : setIsDeleteTemplateWindowOpen(false)
      showDeleteConfirmationModal(template)
    }
  }

  const showDeleteConfirmationModal = (template: ReportTemplate) => {
    const { id } = template
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage(messages['messages.prompt.deleteReportTemplate']),
      okText: intl.formatMessage(messages['messages.prompt.ok']),
      cancelText: intl.formatMessage(messages['messages.prompt.cancel']),
      onOk: () => id && handleDeleteTemplate(id),
      okButtonProps: {
        style: {
          backgroundColor: '#C15A5A',
          border: 'none',
          outline: 'none',
          fontWeight: 'bold',
        },
      },
    })
  }

  const handleDeleteTemplate = async (id: string): Promise<void> => {
    try {
      await api.deleteReportTemplate(id)
      user && void getReportTemplates(user.activeProfileId as string)
      notification.success({
        message: intl.formatMessage(messages['messages.success.cards.delete']),
        placement: 'topRight',
      })
    } catch (error) {
      notification.warning({
        message: intl.formatMessage(messages['messages.error.template.delete']),
        description: intl.formatMessage(messages['messages.errorDescription.template.delete']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    }
  }

  const handleEditTemplate = async (): Promise<void> => {
    setShowLoader(true)
    try {
      template && (await api.updateReportTemplate(template.id as string, template))
      user && void getReportTemplates(user.activeProfileId as string)
      notification.success({
        message: intl.formatMessage(messages['messages.success.template.update']),
        placement: 'topRight',
      })
      form.resetFields()
    } catch (error) {
      notification.warn({
        message: intl.formatMessage(messages['messages.error.template.update']),
        placement: 'topRight',
      })
      Sentry.captureException(error)
    } finally {
      setShowLoader(false)
      resetDrawerHash(history)
    }
  }

  const actionColumn = {
    title: intl.formatMessage(action['action.label']),
    dataIndex: 'id',
    key: 'type',
    render: (id: string, template: ReportTemplate): React.JSX.Element => {
      return (
        <Space>
          <Button
            type="primary"
            data-testid="use-template-report"
            onClick={() => {
              handleSetTemplate(template)
              setActiveTab('1')
            }}
          >
            {intl.formatMessage(action['action.reports.page.template.useTemplate'])}
          </Button>
          <Button icon={<EditOutlined />} onClick={() => openEdit(id)} data-testid="edit-template-report">
            {intl.formatMessage(action['action.reports.page.template.editTemplate'])}
          </Button>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            danger
            onClick={() => openDelete(id)}
            data-testid="delete-template-report"
          >
            {intl.formatMessage(action['action.reports.page.template.deleteTemplate'])}
          </Button>
        </Space>
      )
    },
  }

  return (
    <div className="templates-wrapper" data-testid="templates-component">
      <Table
        loading={{
          spinning: isLoadingTemplates || showLoader,
          indicator: BhLoadingIndicator(),
        }}
        size="small"
        className="bh-table"
        rowKey={(record: ReportTemplate) => `document-key-${record.title}`}
        dataSource={reportTemplates}
        columns={[...reportTemplateColumns(intl, language), actionColumn]}
        showSorterTooltip={false}
        pagination={{ total }}
        locale={{
          emptyText: intl.formatMessage(reports['reports.template.noRecord.description']),
        }}
      />
      <>
        {template && (
          <TemplateForm
            template={template}
            setTemplate={setTemplate}
            handleEditTemplate={() => void handleEditTemplate()}
            summary={<TemplateSummary template={template} />}
          />
        )}
      </>
    </div>
  )
}

export default Templates
