import { Radio, Space, TreeSelect } from 'antd'
import { DataNode } from 'rc-tree/lib/interface'
import React from 'react'
import { useIntl } from 'react-intl'
import { reports } from '../../lang/definitions/reports'
import { CheckedAllProps, ENTITY_OPTIONS } from './utils'

interface TreeProps {
  data: DataNode[]
  dataIDs: string[]
  type: string
  placeholder: string
  selectionType: string
  onChange: (values: string[], labels: React.ReactNode[]) => void
  setSelectionType: (value: ENTITY_OPTIONS) => void
  checkedAll: CheckedAllProps
}

const { SHOW_ALL } = TreeSelect

const SelectTreeGroup = ({
  data,
  dataIDs,
  selectionType,
  checkedAll,
  type,
  placeholder,
  onChange,
  setSelectionType,
}: TreeProps): React.JSX.Element => {
  const intl = useIntl()

  return (
    <React.Fragment>
      <Radio.Group
        onChange={(event) => setSelectionType(event.target.value as ENTITY_OPTIONS)}
        value={selectionType}
        data-testid={`reports-radio-group-${type}`}
      >
        <Space direction="vertical">
          <Radio value={ENTITY_OPTIONS.ALL} data-testid="reports-select-all">
            {intl.formatMessage(reports['reports.all'])}
          </Radio>
          <Radio value={ENTITY_OPTIONS.SELECT} data-testid="reports-select">
            {intl.formatMessage(reports['reports.radio.select'])}
          </Radio>
        </Space>
      </Radio.Group>
      <div>
        <TreeSelect
          className="bh-select auto-height"
          disabled={checkedAll[type] === true}
          treeData={data}
          value={dataIDs}
          onChange={(values: string[], labels: React.ReactNode[]) => onChange(values, labels)}
          treeCheckable={true}
          showArrow
          showSearch={false}
          showCheckedStrategy={SHOW_ALL}
          treeDefaultExpandAll={true}
          placeholder={placeholder}
          style={{
            width: '100%',
            marginTop: '16px',
          }}
        />
      </div>
    </React.Fragment>
  )
}

export default SelectTreeGroup
