import { Button, Input, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { action, entity, filters } from '../../../../lang/definitions'
import * as apiUser from '../../../../api/user'
import './EntityUsersTab.less'
import { RightOutlined } from '@ant-design/icons'
import Date from '../../../../components/Format/Date/Date'
import { useLanguageState } from '../../../../stores/language/LanguageStore'
import { Profile } from '../../../../types/profile'
import { QueryParams } from '../../../../types/general'
import { FilterBody } from '../../../../components/Filter/Filter'
import { admin } from '../../../../lang/definitions/admin'
import * as Sentry from '@sentry/react'
import { useOrganisationHierarchy } from 'stores/OrganisationHierarchy/hooks'
import { useHistory } from 'react-router-dom'
import { setDrawerHash } from 'components/Drawers/utils'

interface TableData {
  id: string
  key: number
  name: string
  dateLastLogin: string | undefined
  profileObject: Profile
}

interface FilterProps {
  country?: string
  title?: string
}

const { Search } = Input

const EntityUsersTab = (): React.JSX.Element => {
  const DEFAULT_PAGE_SIZE = 10
  const intl = useIntl()
  const [users, setUsers] = useState<Profile[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataSource, setTableData] = useState<TableData[]>([])
  const [currentPage, setCurrentTablePage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [filterBody, setFilterBody] = useState<FilterBody>({
    kind: 'profile',
    operator: 'and',
  })
  const [emptyMessage, setEmptyMessage] = useState<string>(intl.formatMessage(entity['user.not.added']))

  const history = useHistory()

  const {
    state: { selectedEntity, countryOptions },
    actions: { setSelectedUser },
  } = useOrganisationHierarchy()

  const [languageState] = useLanguageState()
  const language = languageState.language

  useEffect(() => {
    if (filterBody?.entityId) {
      void fetchData(currentPage, filterBody)
    }
  }, [filterBody])

  useEffect(() => {
    if (selectedEntity?.id) {
      setFilterBody((oldState) => ({
        ...oldState,
        entityId: [selectedEntity.id],
      }))
    }
  }, [selectedEntity])

  useEffect(() => {
    const tableData = users.map((user: Profile, index: number) => {
      return {
        id: user.user.id,
        key: index,
        name: user.user.name.first + ' ' + user.user.name.last,
        dateLastLogin: user.user.dateLastLogin,
        profileObject: user,
      }
    })
    setTableData(tableData)
  }, [users])

  useEffect(() => {
    if (currentPage > 1 && selectedEntity?.id) {
      setFilterBody((oldState) => ({
        ...oldState,
        entityId: [selectedEntity.id],
      }))
    }
  }, [currentPage])

  const openEditUserFromEnitity = (profile: Profile): void => {
    setSelectedUser(profile.user)
    setDrawerHash(history, `#drawer-entityUser?key=${profile.user.id}`)
  }

  const openAddUserFromEntity = (): void => {
    setDrawerHash(history, `#drawer-add-user-from-entity?key=${selectedEntity?.id || ''}`)
  }

  const fetchData = async (currentPage: number, filter: FilterBody): Promise<void> => {
    try {
      setIsLoading(true)
      const params: QueryParams = {
        limit: DEFAULT_PAGE_SIZE,
        skip: (currentPage - 1) * DEFAULT_PAGE_SIZE,
      }
      const response = await apiUser.searchProfilesByEntityIDs(filter, params)
      setUsers(response.profiles)
      setTotal(response.total || 0)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickRow = (record: TableData) => {
    return {
      onClick: () => {
        openEditUserFromEnitity(record.profileObject)
      },
    }
  }

  const paginationProps = {
    total,
    pageSize: DEFAULT_PAGE_SIZE,
    current: currentPage,
    hideOnSinglePage: true,
    onChange: (page: number) => setCurrentTablePage(page),
  }

  const handleApplyFilter = (filter: FilterProps): void => {
    if (!selectedEntity?.id) return
    if (filter?.country) {
      setFilterBody((oldState) => ({
        ...oldState,
        entityId: [selectedEntity.id],
        countryCode: filter.country,
      }))
    } else if (filter?.title) {
      setEmptyMessage(intl.formatMessage(entity['user.not.found']))
      setFilterBody((oldState) => ({
        ...oldState,
        entityId: [selectedEntity.id],
        title: filter.title,
      }))
    } else {
      setEmptyMessage(intl.formatMessage(entity['user.not.added']))
      const filterCopy = { ...filterBody }
      filterCopy?.title && delete filterCopy.title
      filterCopy?.countryCode && delete filterCopy.countryCode
      setFilterBody(filterCopy)
    }
  }

  return (
    <div className="users-tab-wrapper">
      <div className="filters-wrapper">
        <Select
          size="middle"
          onSelect={(value: string) => handleApplyFilter({ country: value })}
          placeholder={intl.formatMessage(entity['entity.form.country'])}
          allowClear
          onClear={() => handleApplyFilter({})}
          options={countryOptions?.map((item: string) => ({
            value: item,
            label: intl.formatDisplayName(item.toUpperCase(), { type: 'region' }),
          }))}
        />
        <Search
          size="middle"
          onSearch={(value) => handleApplyFilter({ title: value })}
          placeholder={intl.formatMessage(filters['filter.search'])}
        />
      </div>
      <div className="table-wrapper">
        <Table
          loading={isLoading}
          className="bh-table"
          size="large"
          columns={[
            {
              title: intl.formatMessage(admin['admin.user.columns.name']),
              key: 'name',
              dataIndex: 'name',
              render: (text) => <span>{text}</span>,
            },
            {
              title: intl.formatMessage(admin['admin.user.columns.lastLoggedIn']),
              key: 'dateLastLogin',
              dataIndex: 'dateLastLogin',
              render: (dateLastLogin: string) => {
                return <span>{dateLastLogin && <Date value={dateLastLogin} locale={language} time={true} />}</span>
              },
            },
            {
              title: intl.formatMessage(admin['admin.user.columns.actions']),
              key: 'action',
              dataIndex: 'action',
              render: () => <RightOutlined style={{ color: '#828282', cursor: 'pointer' }} />,
              align: 'center',
            },
          ]}
          dataSource={dataSource}
          onRow={(record) => onClickRow(record)}
          pagination={paginationProps}
          locale={{
            emptyText: emptyMessage,
          }}
        />
      </div>
      <Button type="primary" size="large" onClick={openAddUserFromEntity} block>
        {intl.formatMessage(action['action.admin.user.add'])}
      </Button>
    </div>
  )
}

export default EntityUsersTab
