import { CardNetworkBankDay } from 'types/paymentInstruction'
import { Source } from 'types/source'

export default function getCardNetworkDateDue(
  cardNetworksBankDays: CardNetworkBankDay[],
  card: Source | undefined
): moment.Moment | undefined {
  if (cardNetworksBankDays.length === 0) {
    return undefined
  }

  // Sort by bank day ascending
  const cardNetworksBankDaysSorted = cardNetworksBankDays.sort((a, b) => a.bankDay.diff(b.bankDay))

  const cardNetwork = card?.typeProperties.cardNetwork

  if (cardNetwork) {
    const cardNetworksBankDay = cardNetworksBankDays.find((day: CardNetworkBankDay) => {
      return day.cardNetwork === cardNetwork
    })

    if (cardNetworksBankDay) {
      return cardNetworksBankDay.bankDay
    }
  }

  return cardNetworksBankDaysSorted.at(-1)!.bankDay
}
