import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { EmailEditForm } from '../../../types/user'
import { useIntl } from 'react-intl'
import { page } from '../../../lang/definitions/index'

interface EmailFormProps {
  onSubmit: (email: string) => void
}

const EmailForm = (props: EmailFormProps): React.JSX.Element => {
  const intl = useIntl()
  const { onSubmit } = props

  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [form] = Form.useForm()

  const handleSubmit = (): void => {
    onSubmit(email)
  }

  const handleValuesChange = (changedValue: Partial<EmailEditForm>): void => {
    const { email, confirmEmail } = changedValue

    if (email) {
      setEmail(email)
    }

    if (confirmEmail) {
      setConfirmEmail(confirmEmail)
    }
  }

  const isSubmitDisabled: boolean = confirmEmail == '' || email == '' || confirmEmail != email

  return (
    <Form
      className="email-form"
      form={form}
      id="credentials-login-form"
      layout="vertical"
      name="basic"
      requiredMark={false}
      size="small"
      onValuesChange={handleValuesChange}
      onFinish={handleSubmit}
    >
      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(page['page.settings.slider.email.new.label'])}
        name="email"
      >
        <Input
          className="bh-input"
          placeholder={intl.formatMessage(page['page.settings.slider.email.new.placeholder'])}
        />
      </Form.Item>

      <Form.Item
        className="bh-form-item"
        label={intl.formatMessage(page['page.settings.slider.email.confirm.label'])}
        name="confirmEmail"
      >
        <Input
          className="bh-input"
          placeholder={intl.formatMessage(page['page.settings.slider.email.confirm.placeholder'])}
        />
      </Form.Item>

      <Form.Item className="bh-form-item">
        <Button type="primary" htmlType="submit" size="large" disabled={isSubmitDisabled} block>
          {intl.formatMessage(page['page.settings.slider.email.update.button.label'])}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default EmailForm
